import React from 'react'
import { TextField } from '@mui/material';

export default function Input(props) {

  const { type, name, disabled, label, placeholder, value, error = null, onChange, ...other } = props;
  
  return (
    <TextField
      type={type || 'text'}
      variant="outlined"
      label={label}
      placeholder={placeholder}
      name={name}
      value={value}
      disabled={disabled}
      onChange={onChange}
      {...other}
      {...(error && { error: true, helperText: error })}
    />
  )
}

/* <TextField
  onKeyPress={(ev) => {
    console.log(`Pressed keyCode ${ev.key}`);
    if (ev.key === 'Enter') {
      // Do code here
      ev.preventDefault();
    }
  }}
/> 

<TextField
  onKeyPress={(e) => {
    if (e.key === 'Enter') {
      alert(e.target.value);
    }
  }}
/> */