import { types } from '../types/types';

const initialState = {
    checking: true,
    blankPasswordButtonIcon: false,
    uid: null,
    //name: null
}

export const authReducer = (state = initialState, action) => {

    switch (action.type) {

        case types.authLogin:
            return {
                ...state,
                ...action.payload,
                checking: false
            }

        case types.authCheckingFinish:
            return {
                ...state,
                checking: false
            }

        case types.authBlankPasswordButtonIcon:
            return {
                ...state,
                blankPasswordButtonIcon: action.payload
            }

        case types.authLogout:
            return {
                uid: null,
                checking: false
            }


        default:
            return state;
    }

}


