import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk'
import { rootReducer } from '../reducers/rootReducer';

const composeEnhancers = (
    typeof window !== 'undefined' && 
    process.env.NODE_ENV === "development" && 
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const store = createStore(
    rootReducer,
    composeEnhancers(
        applyMiddleware( thunk )
    )
);


