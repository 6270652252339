import React, { useEffect } from 'react'
import { FormControl, InputLabel, MenuItem, FormHelperText, Autocomplete, TextField, Box, Typography, CircularProgress } from '@mui/material';

export default function AutocompleteInput(props) {

    const { name, label, value, error, onChange, options, disabled, setValue, errors, setErrors, setValues, resetForm, valueMatricula, fixedValueMatricula } = props;
    // console.log({ disabled })
    // useEffect(() => {
    //         // Take the Reference of Close Button
    //         const close = document.getElementsByClassName(
    //             "MuiAutocomplete-clearIndicator"
    //         )[0];

    //         // Add a Click Event Listener to the button
    //         close.addEventListener("click", () => {
    //             alert("Add your Own Functionality Here...");
    //         });
    // }, [])

    // const handleOnChange = (event, value, reason) => {
    //     if (reason === "clear") alert('yeeeaaa')
    // }

    return (
        <>
            <Autocomplete freeSolo
                options={(!disabled) ? options : []}
                getOptionLabel={(option) => option.matricula}
                inputValue={fixedValueMatricula}
                // value={value}
                // onChange={onChange}
                // onChange={(e) => console.log(e.target.constructor.name, 'siii')}
                // onChange={(e) => setValue(
                //     name,
                //     (e.target.constructor.name === 'HTMLLIElement') && e.target.innerText ||
                //     (e.target.constructor.name === 'HTMLImageElement') && e.target.nextSibling.data || ''
                // )}
                // onChange={(el) => {
                //     // if (el === undefined) {
                //     //     console.log('esta undefined')
                //     // } else {
                //     // }
                //     // setValue('nombre', (options[0] !== undefined) ? options.filter(elArr => elArr.matricula === el.target.innerText)[0].nombre : null)
                //     // setValue('nombre', options.length > 0 ? options.filter(elArr => elArr.matricula === el.target.innerText)[0]?.nombre : 'ja');

                //     // setValue('nombre', options.filter(elArr => elArr.matricula === el.target.innerText)[0]?.nombre || '');
                //     // setValue('capacidadPersonas', options.filter(elArr => elArr.matricula === el.target.innerText)[0]?.capacidadPersonas || '');

                //     // setValue('eslora', options.filter(elArr => elArr.matricula === el.target.innerText)[0]?.eslora || '');
                //     // setValue('manga', options.filter(elArr => elArr.matricula === el.target.innerText)[0]?.manga || '');
                //     // setValue('puntal', options.filter(elArr => elArr.matricula === el.target.innerText)[0]?.puntal || '');

                //     // console.log(options.filter(elArr => elArr.matricula === el.target.innerText)[0])

                //     // const jaja = options.filter(e => e.matricula === el.target.innerText)[0].nombre
                //     // console.log(jaja)
                //     // console.log('jaja')

                //     // console.log(el)
                //     // console.log(el.target.constructor.name)
                //     // console.log(options)

                //     // console.log(el.target.constructor.name, 'siii')
                //     if (el.target.constructor.name === 'SVGPathElement' || el.target.constructor.name === 'SVGSVGElement' || el.target.constructor.name === 'HTMLButtonElement') {
                //         resetForm();
                //     } else {
                //         if (el.target.constructor.name === 'HTMLLIElement') {
                //             // console.log('suruuu')
                //             setValues({
                //                 // ...options.filter(elArr => elArr.matricula === el.target.innerText)[0],
                //                 ...options.filter(elArr => elArr.matricula === el.target.innerText)[0],
                //                 titular: true
                //             })
                //         } else if (el.target.constructor.name === 'HTMLImageElement') {
                //             setValues({
                //                 ...options.filter(elArr => elArr.matricula === el.target.nextSibling.data)[0],
                //                 titular: true
                //             })
                //         }
                //     }
                //     // console.log(options.filter(elArr => elArr.matricula === el.target.innerText)[0])
                // }}
                // onChange={handleOnChange}
                disabled={disabled}
                renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        <img
                            loading="lazy"
                            width="100"
                            style={{ borderRadius: 3 }}
                            src={`${process.env.REACT_APP_API_URL_FOR_IMAGES}/boatsImages/${option.imgEmbarcacionUrl}`}
                            alt=""
                        />
                        {option.matricula}
                        {/* <Typography>
                                    {JSON.stringify(option.matricula, null, 5)}
                                </Typography> */}
                    </Box>
                )}
                // renderInput={(params) => <TextField
                //   {...params}
                //   label="Matricula*"
                // />}
                onInputChange={(event, newInputValue, reason) => {
                    // console.log(event)
                    // console.log(newInputValue)
                    // console.log(reason)

                    if (reason === 'clear') {
                        resetForm();
                    }
                    if (event.type === 'click') {
                        // console.log(event.target.constructor.name)
                        if (event.target.constructor.name === 'HTMLImageElement') {
                            setValues({
                                ...options.filter(elArr => elArr.matricula === event.target.nextSibling.data)[0],
                                titular: true
                            })
                            setErrors({...errors, nombre: '', matricula: '', amarre: ''})
                        }
                        if (event.target.constructor.name === 'HTMLLIElement') {
                            setValues({
                                ...options.filter(elArr => elArr.matricula === event.target.innerText)[0],
                                titular: true
                            })
                            setErrors({...errors, nombre: '', matricula: '', amarre: ''})
                        }
                    }
                    valueMatricula(newInputValue);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        name={name}
                        value={value}
                        onChange={onChange}
                        label={label}
                        disabled={disabled}
                        InputProps={{
                            ...params.InputProps,
                            type: 'search',
                            // endAdornment: (
                            //     <React.Fragment>
                            //         {true ? <CircularProgress color="inherit" size={20} /> : null}
                            //         {params.InputProps.endAdornment}
                            //     </React.Fragment>
                            // ),
                        }}
                        error={(error === undefined || error === '') ? false : true}
                        helperText={error}
                    />
                )}
            />
        </>
    )
}
