import Swal from 'sweetalert2';
import { fetchConToken } from '../services/fetch';
import { types } from '../types/types';

// export const setData = (payload) => ({
//     type: types.despachoDatos,
//     payload: payload
// });

export const setReset = () => ({ type: types.despachoReset });

export const startGetListadoDespachadas = () => {
    return async (dispatch) => {
        try {
            const resp = await fetchConToken('despacho/', {});
            const body = await resp.json();
            if (body.ok) {
                dispatch(getListadoDespachadas(body.despacho))
            } else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }
}
const getListadoDespachadas = (payload) => ({
    type: types.despachoGetDB,
    payload: payload
});
export const startDespachoNavegacion = (id) => {
    return async (dispatch, getState) => {

        // if (boat.id == 0) {
        //     const datos = { lugar: place, pendiente: true, estado: true, despachada: false };
        //     const boatWithPendiente = Object.assign(boat, datos);

        const payload = getState().despachoClub;
        const datos = { id: id }
        const temp = Object.assign(payload, datos)
        try {
            const resp = await fetchConToken(`despacho/${id}`, temp, 'POST');
            const body = await resp.json();
            if (body.ok) {
                dispatch(despachoNavegacion())
                dispatch(setReset())
            } else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
             console.log('Error de conexión. Contactese con el administrador.')
        }
    }
}

const despachoNavegacion = () => ({
    type: types.despachoPostDB
});

export const startArriboNavegacion = (id) => {
    return async (dispatch) => {

        try {

            // console.log(event);
            const resp = await fetchConToken(`despacho/${id}`, {}, 'DELETE');
            const body = await resp.json();

            if (body.ok) {
                dispatch(arriboNavegacion());
            } else {
                Swal.fire('Error', body.msg, 'error');
            }

        } catch (error) {
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }
}

const arriboNavegacion = () => ({
    type: types.despachoDeleteDB
});
