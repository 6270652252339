import React from 'react'
import { FormControl, FormLabel, RadioGroup as MuiRadioGroup, FormControlLabel, Radio, FormHelperText } from '@mui/material';
// import { red } from '@mui/material/colors';

export default function RadioGroup(props) {

    const { name, label, value, onChange, onClick, error = null, items, disabled } = props;

    return (
        <FormControl>
            <FormLabel
                {...(error &&
                    { error: true, helperText: error }
                )}
            >{label}</FormLabel>
            <MuiRadioGroup row
                name={name}
                value={value}
                onChange={onChange}
                onClick={onClick}
                sx={{ mt: -1 }}
                style={{ marginBottom: -4 }}
            // FOR TEXT COLOR
            // sx={{
            //     color: red[800],
            //     '&.Mui-checked': {
            //         color: red[600],
            //     },
            // }}
            >
                {
                    items.map(
                        item => (
                            <FormControlLabel key={item.id} value={item.id} control={<Radio color='secondary' />} label={item.title} disabled={disabled} />
                        )
                    )
                }
            </MuiRadioGroup>
            <FormHelperText
                {...(error &&
                    { error: true, helperText: error }
                )}
            >{error}</FormHelperText>
        </FormControl>
    )
}
