import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import SettingsIcon from '@mui/icons-material/Settings';
import { Grid } from '@mui/material';
import { Box } from '@mui/system';

import MailIcon from '@mui/icons-material/Mail';

import PageHeader from '../../components/PageHeader';
import Controls from '../../components/controls/Controls';
import { useForm } from '../../components/useForm';
import { blankPasswordButtonIconLoading, startBlankPassword } from '../../actions/auth';
import { isMobile } from 'react-device-detect';

const initialFValues = {
    email: '',
}

const RecoverPassSendEmailScreen = () => {

    const dispatch = useDispatch();
    const { blankPasswordButtonIcon } = useSelector(state => state.auth);

    const {
        values,
        // setValues,
        errors,
        // setErrors,
        handleInputChange,
        // resetForm
    } = useForm(initialFValues, false);

    return (
        <>
            <PageHeader
                title="Blanqueo de contraseña"
                subTitle="Prefectura Naval Argentina"
                icon={<SettingsIcon fontSize="large" />}
                profile={false}
            />

            <Grid
                container
                justifyContent='center'
            >
                <Grid
                    item
                    xs={(isMobile) ? 12 : 4}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '50vh',
                        flexGrow: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}

                >
                    <Controls.Input
                        name="email"
                        label="Ingrese email"
                        sx={{ width: '100%' }}
                        value={values.email}
                        onChange={handleInputChange}
                        error={errors.email}
                    />
                    <Box
                        sx={{ mt: 2 }}
                    >
                        <Controls.Button
                            text="Resetear contraseña"
                            variant="outlined"
                            // startIcon={<MailIcon />}
                            disabled={blankPasswordButtonIcon}
                            loading={blankPasswordButtonIcon}
                            startIconWithoutLoading={<MailIcon />}
                            onClick={() => {
                                dispatch(blankPasswordButtonIconLoading(true));
                                dispatch(startBlankPassword(values.email))
                            }}
                        />
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default RecoverPassSendEmailScreen
