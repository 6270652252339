import React from 'react'
import PageHeader from '../../components/PageHeader'
import { Paper } from '@mui/material'
import { useSelector } from 'react-redux';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';

export const Carnet = () => {
  const { name, place } = useSelector(state => state.auth);

  return (
    <>
      <PageHeader
        title={`Carnet de timonel`}
        subTitle={`Prefectura ${place}`}
        icon={<DirectionsBoatIcon fontSize="large" />}
        profile={true}
        name={name}
      />

      <Paper sx={{ m: 5, p: 3 }}>
        {/* <Typography variant="custom6" gutterBottom component="div" align='center' sx={{ p: 5, mt: 3 }}>
          Seleccione su embarcación:
        </Typography> */}
      </Paper>
    </>
  )
}
