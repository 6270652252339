import React from 'react'
import { Grid, Paper, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';
import image from '../../assets/prefecturaNaval.png'

const RestrictPortrait = () => {
    
    const useStyles = makeStyles(theme => ({
        pageContent: {
            margin: theme.spacing(2),
            padding: theme.spacing(1)
        },
        scalePaper: {
            height: 'auto',
            width: '100%'
        },
        scaleImg: {
            height: '100%',
            width: '100%'
        },
        explanationJustify: {
            textAlign: 'justify'
        }
    }))

    const classes = useStyles();

    return (
        <>
            <div className={classes.pageContent}>

                <Grid container
                    spacing={2}
                    style={{ minHeight: '100vh' }}
                >

                    <Grid
                        item
                        container
                        alignItems='flex-start'
                        justify='center'
                        style={{ maxWidth: '100vw' }}

                    >
                        <Paper className={classes.scalePaper} variant="outlined">
                            <img className={classes.scaleImg} src={image} alt='errorConection' />
                        </Paper>
                    </Grid>

                    <Grid
                        item
                        container
                        alignItems='center'
                        justify='center'
                    >
                        <Typography>
                            <h1>
                                Atención
                            </h1>
                        </Typography>
                    </Grid>


                    <Grid
                        item
                        container
                        alignItems='flex-end'
                        justify='center'
                    >
                        <Typography>
                            <h5 className={classes.explanationJustify}>
                                Si usted esta usando una computadora, amplie la
                                ventana del navegador web, pero si esta usando un
                                celular, rotar horizontalmente el
                                dispositivo para poder usar el sistema. Gracias.
                            </h5>
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default RestrictPortrait