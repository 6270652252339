import { types } from '../types/types';

const initialState = {
    allBoats: [],
    embarcaciones: [],
    timonel: [],
}

export const embarcacionTimonelReducer = (state = initialState, action) => {

    switch (action.type) {

        case types.embarcacionGetTimonel:
            return {
                ...state,
                embarcaciones: action.payload,
            }

        case types.usuarioGetTimonel:
            return {
                ...state,
                timonel: action.payload,
            }

        case types.embarcacionGetToFormAddRemoveBoatsTimonel:
            return {
                ...state,
                allBoats:  action.payload,
            }

        default:
            return state;
    }

}


