import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import LockIcon from '@mui/icons-material/Lock';
import Swal from 'sweetalert2';

import PageHeader from '../../components/PageHeader';
import Controls from '../../components/controls/Controls';
import { resetPassword } from '../../actions/auth';
import { useForm } from '../../components/useForm';

const useStyles = makeStyles(theme => ({
    newButton: {
        marginTop: 25
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
    },
    inputSeparator: {
        marginBottom: 10
    }
}))

const initialFValues = {
    passnueva: '',
    passnueva2: '',
}

const PasswordChangerScreen = () => {

    const { id, token } = useParams();
    const navigate = useNavigate();
    const classes = useStyles();

    const {
        values,
        // setValues,
        // errors,
        // setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFValues, false);

    const submitPassword = async () => {

        if (values.passnueva === '' || values.passnueva2 === '') return (resetForm(), Swal.fire('Error', 'Debe rellenar los campos.', 'error'));
        if (values.passnueva !== values.passnueva2) return (resetForm(), Swal.fire('Error', 'Los campos deben ser iguales.', 'error'));

        // dispatch(resetPassword(id, token, values.passnueva));
        // history.replace('/login');
        resetPassword(id, token, values.passnueva)
            // .then(() => history.replace('/login'))
            .then(() => navigate('/login', {replace: true}))
            .catch((err) => {console.log(err); resetForm();})
    }
    
    return (
        <>
            <PageHeader
                title="Cambio de contraseña"
                subTitle="Prefectura Naval Argentina"
                icon={<SettingsIcon fontSize="large" />}
                profile={false}
            />
            <Grid
                container
                direction="column"
                alignItems="center"
                justify="center"
                style={{ minHeight: '60vh' }}
            >
                <Grid container item xs={4} direction='column'>
                    <Controls.Input
                        name="passnueva"
                        label="Ingrese nueva contraseña"
                        type="password"
                        value={values.passnueva}
                        onChange={handleInputChange}
                        className={classes.inputSeparator}
                    />
                    <Controls.Input
                        name="passnueva2"
                        type="password"
                        label="Ingrese nuevamente la nueva contraseña"
                        value={values.passnueva2}
                        onChange={handleInputChange}
                        className={classes.LoginTextField}
                    />
                </Grid>
                <Grid item xs={2} container direction='column'>
                    <Controls.Button
                        text="Cambiar contraseña"
                        variant="outlined"
                        startIcon={<LockIcon />}
                        className={classes.newButton}
                        onClick={() => {
                            submitPassword()
                        }}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default PasswordChangerScreen
