import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Grid, TableBody, TableRow, TableCell, Toolbar, InputAdornment, Tooltip, Typography, Dialog, DialogTitle, DialogContent, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Search } from "@mui/icons-material";
import CallMadeIcon from '@mui/icons-material/CallMade';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import CropFreeIcon from '@mui/icons-material/CropFree';
// import { format } from 'date-fns'
import MySwal from 'sweetalert2'

import useTable from "../../components/useTable";
import Controls from '../../components/controls/Controls';
import { startArriboDespacho, startGetBoatsAdespachar, startGetSearchDespachoBoat, startGetUsersFromAboat, startSearchDespachoBoat } from '../../actions/embarcacionesClubes';
import { setData } from '../../actions/despachoClubes';
import EmbarcacionesForm from './EmbarcacionesForm';
import { isMobile } from 'react-device-detect';
import QRCode from "react-qr-code";

const useStyles = makeStyles(theme => ({
    searchInput: {
        width: '50%'
    },
    searchButton: {
        position: 'absolute',
        right: '10px',
        // width: '5%'
    },
    sAlert: {
        zIndex: 9999999999
    },
    dialogWrapper: {
        padding: theme.spacing(2),

        minHeight: p => p.mobile
            ? '100vh'
            : 'unset'
    },
    dialogTitle: {
        padding: 0,
        // paddingRight: '0px',
        // zIndex: '10',
    }
}))

const headCells = [
    { id: 'fullName', label: 'Embarcacion' },
    { id: 'email', label: 'Matricula' },
    { id: 'mobile', label: 'Descripción' },
    { id: 'actions', label: 'Acciones', disableSorting: true }
]

const EmbarcacionesDespacho = (props) => {
    const classes = useStyles();
    const Swal = MySwal.mixin({ customClass: { container: classes.sAlert }, })

    const dispatch = useDispatch();
    const { aDespachar, searchDespacho } = useSelector(state => state.embarcacionClub);
    // const [filterFn, setFilterFn] = useState({ fn: items => { return items; } })
    const [filterFn] = useState({ fn: items => { return items; } })
    // const today = format(new Date(), 'yyyy-MM-dd');
    const [isOpen, setIsOpen] = useState(false);
    const [qrOpenData, setQrOpenData] = useState({
        state: false,
        id: null,
    });
    const [ventanaDespachoData, setVentanaDespachoData] = useState({
        id: null,
        name: '',
        capacidadPersonas: null,
        categoriaCarnet: '',
        // datosUsuario: undefined
    });
    // const [name, setName] = useState('');
    // const [id, setId] = useState();
    // const [capacidadPersonas, setCapacidadPersonas] = useState();
    // const [categoriaCarnet, setCategoriaCarnet] = useState('');

    useEffect(() => {
        dispatch(startGetBoatsAdespachar())
    }, [dispatch])

    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting
    } = useTable(aDespachar, headCells, filterFn);

    const handleSearchDespacho = (e) => {
        dispatch(startSearchDespachoBoat(e.target.value.trim()));
    }

    const handleSearchClick = (searchDespacho) => {
        if (searchDespacho === '') {
            dispatch(startGetBoatsAdespachar())
        } else {
            dispatch(startGetSearchDespachoBoat());
        }
    }

    // #################################### DESPACHAR ITEM SWAL #########################

    // const reportarItem = (ittem, fullName) => {

    //     Swal.fire({
    //         title: '¿Estas seguro?',
    //         text: `Esta acción reportará la embarcación "${fullName}", para su revisión o remoción.`,
    //         icon: 'warning',
    //         // style: { zIndex: 9999 },
    //         showCancelButton: true,
    //         confirmButtonColor: '#3085d6',
    //         cancelButtonColor: '#d33',
    //         cancelButtonText: 'No, esto es un error!',
    //         confirmButtonText: 'Si, hazlo!'
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             console.log('Esta embarcacion fue reportada', ittem)

    //             Swal.fire(
    //                 'Estado:',
    //                 'Esta embarcación fue reportada correctamente.',
    //                 'success'
    //             )
    //         }
    //     })

    // }

    const handleDispatchClick = async ({ id, nombre, capacidadPersonas, categoriaCarnet, prohibicion }) => {
        // console.log(id,
        //     nombre,
        //     capacidadPersonas,
        //     categoriaCarnet,)
        if (!prohibicion) {
            // despacharItem(id, nombre, capacidadPersonas, categoriaCarnet)
            setVentanaDespachoData({ id, nombre, capacidadPersonas, categoriaCarnet })
            await dispatch(startGetUsersFromAboat(id))
            setIsOpen(true);
        } else {
            Swal.fire(
                'Estado:',
                `La embarcación "${nombre}" tiene prohibición de navegar, no podrá despachar.`,
                'error'
            )
        }
    }

    // const despacharItem = (idBoat, nombreBoat, capacidadPersonas, categoriaCarnet) => {
    //     setId(idBoat);
    //     setName(nombreBoat);
    //     setCapacidadPersonas(capacidadPersonas);
    //     setCategoriaCarnet(categoriaCarnet);
    //     setIsOpen(true);
    // }

    const processFormData = (item) => {
        // dispatch(setData(item))
        // dispatch(startArriboDespacho(ventanaDespachoData.id, true));
        dispatch(startArriboDespacho({id: ventanaDespachoData.id, ...item}, true));
        setIsOpen(false);
        // console.log({embarcacionId: ventanaDespachoData.id, ...item})
    }

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Toolbar>
                        <Grid
                            container
                        // xs={12}
                        >
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid item xs={12}>
                                    <Controls.Input
                                        sx={{ width: '90%' }}
                                        label="Buscar embarcaciones"
                                        className={classes.searchInput}
                                        InputProps={{
                                            startAdornment: (<InputAdornment position="start">
                                                <Search />
                                            </InputAdornment>)
                                        }}
                                        onChange={handleSearchDespacho}
                                    />
                                </Grid>
                            </Box>
                            <Grid item justifyContent='flex-end'>
                                <Controls.Button
                                    text="Buscar..."
                                    variant="outlined"
                                    startIcon={<SearchIcon />}
                                    className={classes.searchButton}
                                    onClick={() => { handleSearchClick(searchDespacho) }}
                                />
                            </Grid>
                        </Grid>
                    </Toolbar>
                    <TblContainer>
                        <TblHead />
                        <TableBody>
                            {
                                recordsAfterPagingAndSorting().map(item =>
                                // {
                                //     console.log(item)
                                // capacidadPersonas: "5"
                                // categoriaCarnet: "N7"
                                // }


                                (<TableRow key={item.id}>
                                    <TableCell>{item.nombre}</TableCell>
                                    <TableCell>
                                        {/* <TableCell>{JSON.stringify(item, null, 5)}</TableCell> */}
                                        {
                                            (item.inscripcion === 'REY' || item.inscripcion === 'E/T')
                                                ? `${item.inscripcion} ${item.matricula}`
                                                : `${item.cuatrigrama} ${item.matricula}`
                                        }
                                    </TableCell>
                                    <TableCell>{item.desc}</TableCell>
                                    <TableCell>
                                        {
                                            (item.qr === true) &&
                                            <Controls.ActionButton color="secondary"
                                                onClick={() => setQrOpenData({ ...qrOpenData, state: true, id: item.id })}
                                            >
                                                <Tooltip title="Despachar por QR">
                                                    <CropFreeIcon fontSize="small" />
                                                </Tooltip>
                                            </Controls.ActionButton>
                                        }
                                        <Controls.ActionButton
                                            color="primary"
                                            onClick={() => {
                                                handleDispatchClick(
                                                    item
                                                    // item.id,
                                                    // item.nombre,
                                                    // item.capacidadPersonas,
                                                    // item.categoriaCarnet,
                                                    // item.prohibicion,
                                                    // item.datosUsuario
                                                )
                                            }}
                                        >
                                            <Tooltip title="Despachar">
                                                <CallMadeIcon fontSize="small" />
                                            </Tooltip>
                                        </Controls.ActionButton>
                                    </TableCell>
                                </TableRow>)
                                )
                            }
                        </TableBody>
                    </TblContainer>
                    <TblPagination />

                </Grid>
            </Grid>

            <Dialog
                open={isOpen}
                maxWidth='md'
                sx={{
                    padding: 2,
                    minHeight: (isMobile) ? '100vh' : 'unset'
                }}
            >
                <DialogTitle
                    sx={{
                        ml: 2,
                        padding: 1,
                        // zIndex: 10
                    }}
                >
                    <div style={{ display: 'flex' }}>
                        <Typography mt={1} variant="h6" component="div" style={{ flexGrow: 1 }}>
                            Despachando "{ventanaDespachoData.nombre}"
                        </Typography>
                        <Controls.ActionButton
                            color="secondary"
                            onClick={() => { setIsOpen(false) }}>
                            <CloseIcon />
                        </Controls.ActionButton>
                    </div>
                </DialogTitle>
                <DialogContent dividers>
                    <EmbarcacionesForm
                        saveDataDespacho={processFormData}
                        data={ventanaDespachoData}
                    // maxTripulantes={ventanaDespachoData.capacidadPersonas}
                    // catCarnet={ventanaDespachoData.categoriaCarnet}
                    />
                </DialogContent>
            </Dialog>

            <Dialog open={qrOpenData.state} maxWidth='md' sx={{
                padding: 2,
                minHeight: (isMobile) ? '100vh' : 'unset'
            }}>
                <DialogTitle sx={{
                    ml: 2,
                    padding: 1,
                }}>
                    <div style={{ display: 'flex' }}>
                        <Typography mt={1} variant="h6" component="div" style={{ flexGrow: 1 }}>
                            Escanear QR
                        </Typography>
                        <Controls.ActionButton
                            color="secondary"
                            onClick={() => { setQrOpenData({ ...qrOpenData, state: false }) }}>
                            <CloseIcon />
                        </Controls.ActionButton>
                    </div>
                </DialogTitle>
                <DialogContent dividers>
                    {/* 
                        bgColor 	string 	'#FFFFFF'
                        fgColor 	string 	'#000000'
                        level 	string ('L' 'M' 'Q' 'H') 	'L'
                        size 	number 	256
                        title 	string 	
                        value 	string
                    */}
                    {
                        (qrOpenData.id) &&
                        <QRCode
                            size={256}
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            // value={'something'}
                            value={qrOpenData.id.toString()}
                            viewBox={`0 0 256 256`}
                            level='H'
                        />
                    }
                    {/* {JSON.stringify(qrOpenData.id, null, 5)} */}
                </DialogContent>
            </Dialog>
        </>
    )
}

export default EmbarcacionesDespacho;