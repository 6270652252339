import React from 'react'
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, Card, CardMedia, Grid, Icon, IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import PageHeader from '../../components/PageHeader';
import { useNavigate } from 'react-router-dom';
import Controls from '../../components/controls/Controls';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { Container } from '@mui/system';

import CgontactMailIcon from '@mui/icons-material/ContactMail';
import PersonAddDisabledIcon from '@mui/icons-material/PersonAddDisabled';
const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
    }
}))

const AdminDashboard = () => {
    const navigate = useNavigate();
    const { name } = useSelector(state => state.auth);
    const classes = useStyles();

    return (
        <>
            <PageHeader
                title="Panel de administración"
                subTitle="Prefectura Naval Argentina"
                icon={<SettingsIcon fontSize="large" />}
                profile={true}
                name={name}
            />
            {/* <Grid
                container
                // spacing={2}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: '60vh' }}
            >
                <Grid item xs={12}>
                    <Controls.Button
                        text="Usuarios pendientes"
                        variant="outlined"
                        startIcon={<ContactMailIcon />}
                        onClick={() => navigate('/admin/user/pending')}
                    />
                    <Controls.Button
                        text="Eliminar usuarios"
                        variant="outlined"
                        startIcon={<PersonOffIcon />}
                        onClick={() => navigate('/admin/user/delete')}
                    />
                    <Controls.Button
                        text="Permisos de usuarios"
                        variant="outlined"
                        startIcon={<VpnKeyIcon />}
                        onClick={() => navigate('/admin/user/permissions')}
                    />
                    <Controls.Button
                        text="Pases de usuarios"
                        variant="outlined"
                        startIcon={<PublishedWithChangesIcon />}
                        // onClick={() => navigate('/admin/user/permissions')}
                    />
                </Grid>
            </Grid> */}
            <Grid
                container
                justifyContent='center'
                gap={4}
                sx={{ mt: 10, mb: 10 }}
            >

                <Grid item>
                    <Card sx={{
                        height: '250px',
                        width: '250px',
                        backgroundColor: '#a9a9a9',
                        cursor: 'pointer',
                        userSelect: 'none',
                        justifyContent: "center",
                        alignItems: "center",
                        display: 'flex',
                    }}
                        onClick={() => navigate('/admin/user/pending')}
                    >
                        <Box>
                            <Container sx={{ display: 'flex', justifyContent: "center" }}>
                                <ContactMailIcon sx={{ fontSize: 150, opacity: .8 }} />
                            </Container>
                            <Typography variant="h5" align="center" gutterBottom={false} sx={{ opacity: .85, }}>
                                Usuarios pendientes
                            </Typography>
                        </Box>
                    </Card>
                </Grid>

                <Grid item>
                    <Card sx={{
                        height: '250px',
                        width: '250px',
                        backgroundColor: '#a9a9a9',
                        cursor: 'pointer',
                        userSelect: 'none',
                        justifyContent: "center",
                        alignItems: "center",
                        display: 'flex',
                    }}
                        onClick={() => navigate('/admin/user/delete')}
                    >
                        <Box>
                            <Container sx={{ display: 'flex', justifyContent: "center" }}>
                                <PersonOffIcon sx={{ fontSize: 150, opacity: .8 }} />
                            </Container>
                            <Typography variant="h5" align="center" gutterBottom={false} sx={{ opacity: .85, }}>
                                Eliminar usuarios
                            </Typography>
                        </Box>
                    </Card>
                </Grid>

                <Grid item>
                    <Card sx={{
                        height: '250px',
                        width: '250px',
                        backgroundColor: '#a9a9a9',
                        cursor: 'pointer',
                        userSelect: 'none',
                        justifyContent: "center",
                        alignItems: "center",
                        display: 'flex',
                    }}
                        onClick={() => navigate('/admin/user/permissions')}
                    >
                        <Box>
                            <Container sx={{ display: 'flex', justifyContent: "center" }}>
                                <VpnKeyIcon sx={{ fontSize: 150, opacity: .8 }} />
                            </Container>
                            <Typography variant="h5" align="center" gutterBottom={false} sx={{ opacity: .85, }}>
                                Permisos de usuarios
                            </Typography>
                        </Box>
                    </Card>
                </Grid>

                {/* <Grid item>
                    <Card sx={{
                        height: '250px',
                        width: '250px',
                        backgroundColor: '#a9a9a9',
                        cursor: 'pointer',
                        userSelect: 'none',
                        justifyContent: "center",
                        alignItems: "center",
                        display: 'flex',
                    }}
                    // onClick={() => navigate('/admin/user/permissions')}
                    >
                        <Box>
                            <Container sx={{ display: 'flex', justifyContent: "center" }}>
                                <PublishedWithChangesIcon sx={{ fontSize: 150, opacity: .8 }} />
                            </Container>
                            <Typography variant="h5" align="center" gutterBottom={false} sx={{ opacity: .85, }}>
                                Pases de usuarios
                            </Typography>
                        </Box>
                    </Card>
                </Grid> */}

            </Grid>
        </>
    )
}

export default AdminDashboard
