import Swal from 'sweetalert2'

export const Toast = (state, msg) => {
    const zIndexValue = 9999999999;

    const Toast = Swal.mixin({
        customClass: {
            container: 'mui-toast-container',
            popup: 'mui-toast-popup',
          },
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3100,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    return Toast.fire({
      icon: state,
      title: msg,
      didOpen: () => {
        const toastContainer = document.querySelector('.mui-toast-container');
        if (toastContainer) {
          toastContainer.style.zIndex = zIndexValue;
        }
      },
    });
}