import Swal from 'sweetalert2';
import { fetchSinToken, fetchConToken } from '../services/fetch';
import { types } from '../types/types';

export const startVisorGetBoats = () => {

    return async (dispatch, getState) => {
        const { uid } = getState().auth;

        try {
            // console.log(event);
            const resp = await fetchSinToken(`senade/${uid}`, {});
            const body = await resp.json();

            if (body.ok) {
                // body.embarcaciones.map((i) => {
                    // console.log(i.id)
                    // dispatch(startSenadeGetBoatsResults(i.id))
                // })
                dispatch(visorGetBoats(body.embarcaciones))
                // console.log(body.embarcaciones)
            } else {
                Swal.fire('Error', body.msg, 'error');
            }

        } catch (error) {
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }

}

const visorGetBoats = (boats) => ({
    type: types.observadorVisorGetBoats,
    payload: boats
});

export const startGetListVerificadas = (todayDate) => {

    return async (dispatch, getState) => {
        const { uid } = getState().auth;
        const sendFecha = { fecha: todayDate }

        // console.log(todayDate)

        try {
            // console.log(event);
            const resp = await fetchConToken(`verificacion/control/${uid}`, sendFecha, 'POST');
            const body = await resp.json();

            if (body.ok) {

                dispatch(getListVerificadas(body.verif))
                // console.log(body)
            } else {
                Swal.fire('Error', body.msg, 'error');
            }

        } catch (error) {
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }
}

const getListVerificadas = (verificadas) => ({
    type: types.observadorVisorGetVerificadas,
    payload: verificadas
});

export const startSearchBoat = (text) => {
    return (dispatch) => {
        // console.log(text)
        dispatch(SearchBoat(text));
    }

}

const SearchBoat = (text) => ({
    type: types.observadorVisorSearchBoat,
    payload: text
});

export const startGetListProhibidas = (todayDate) => {

    return async (dispatch, getState) => {
        const { uid } = getState().auth;
        const sendFecha = { fecha: todayDate }

        // console.log(todayDate)

        try {
            // console.log(event);
            const resp = await fetchConToken(`verificacion/prohibicion/${uid}`, sendFecha, 'POST');
            const body = await resp.json();

            if (body.ok) {

                dispatch(getListProhibida(body.prohibidas))
                // console.log(body)
            } else {
                Swal.fire('Error', body.msg, 'error');
            }

        } catch (error) {
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }

}

const getListProhibida = (prohibid) => ({
    type: types.observadorVisorGetProhibic,
    payload: prohibid
});

export const startGetSearchBoat = () => {

    return async (dispatch, getState) => {

        const { uid } = getState().auth;
        const { search } = getState().observador;
        // console.log(search)
        // if ( search === '' ) {
        //     dispatch(startGetBoats())
        // }

        try {
            const resp = await fetchConToken(`visor/id/${uid}/search/${search}`, {});
            const body = await resp.json();

            if (body.ok) {

                dispatch(GetSearchBoat(body.visor))
                // console.log(body.visor)

            } else {

                Swal.fire('Error', body.msg, 'error')
                // console.log(body)

            }

        } catch (error) {
            //throw new Error('Error en el sistema. Contactese con el administrador.')
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }
}

const GetSearchBoat = (boat) => ({
    type: types.observadorVisorGetSearchBoat,
    payload: boat
});
