import React from 'react'

import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux';

export const PublicRoute = ({ children }) => {

    const { uid } = useSelector(state => state.auth);
    // console.log(uid)

    return (uid === null)
        ? children
        : <Navigate to='/' />

}

// PublicRoute.propTypes = {
//     isAuthenticated: PropTypes.bool.isRequired,
//     element: PropTypes.func.isRequired
// }

