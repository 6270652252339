import React from 'react';
import { useSelector } from 'react-redux';
import {
    Routes,
    Navigate,
    Route,
} from 'react-router-dom';

import { DashboardAdminRoute } from './DashboardAdminRoute';
import { UserRoute } from './UserRoute';
import { AdminRoute } from './AdminRoute';
import { DashboardUserRoute } from './DashboardUserRoute';
import { DashboardTimonelesRoute } from './DashboardTimonelesRoute';
import { TimonelRoute } from './TimonelRoute';

export const DashboardRoute = () => {

    const { from, role } = useSelector(state => state.auth);

    return (
        <Routes>

            <Route
                end
                path='/admin/*'
                element={
                    <AdminRoute>
                        <DashboardAdminRoute />
                    </AdminRoute>
                }
            />

            <Route
                // end
                path='/timonel/*'
                element={
                    <TimonelRoute>
                        <DashboardTimonelesRoute />
                    </TimonelRoute>
                }
            />

            <Route
                path='/gestion/*'
                element={
                    <UserRoute>
                        <DashboardUserRoute />
                    </UserRoute>
                }
            />

            <Route
                path='/*'
                element={
                    <UserRoute>
                        {/* <Navigate to={`/gestion/${from}`} /> */}
                        {/* <Navigate to={(from === 'clubes') ? `/gestion/clubes` : '/gestion/dashboard'} /> */}
                        {/* <Navigate
                            to={
                                (from === null)
                                    ? `/timonel/dashboard`
                                    : (from === 'clubes')
                                        ? `/gestion/clubes`
                                        : '/gestion/dashboard'
                            }
                        /> */}

                        <Navigate
                            to={
                                (role === 'timonel')
                                    ? `/timonel/dashboard`
                                    : (from === 'clubes')
                                        ? `/gestion/clubes`
                                        : '/gestion/dashboard'
                            }
                        />
                        {/* <Navigate to={(from === null) && `/timonel/dashboard`} /> */}
                    </UserRoute>
                }
            />

        </Routes>
    )
}
