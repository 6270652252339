import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Grid, TableBody, TableRow, TableCell, Toolbar, InputAdornment, Tooltip, Typography, Dialog, DialogTitle, DialogContent, CardMedia } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Search } from "@mui/icons-material";
import CallMadeIcon from '@mui/icons-material/CallMade';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import Swal from 'sweetalert2'

import useTable from "../../components/useTable";
import Controls from '../../components/controls/Controls';
import { startSearchDespachoBoat } from '../../actions/embarcacionesClubes';
import { startClosePopup } from '../../actions/system';
import { startDeleteBoat, startDeleteRelationshipBoat, startListPendientesDB, startPutEditBoat, startRecordForEdit } from '../../actions/embarcacionesPrefectura';
import EmbarcacionesForm from './EmbarcacionesForm';
import { isMobile } from 'react-device-detect';
import { PdfViewer } from '../../components/PdfViewer';
import { startAcceptRelationship } from '../../actions/embarcacionesTimonel';
import { Toast } from '../../components/Toast';
import { useForm } from '../../components/useForm';

const useStyles = makeStyles(theme => ({
    searchInput: {
        width: '100%'
    },
    searchButton: {
        position: 'absolute',
        right: '10px',
        // width: '5%'
    },
    dialogWrapper: {
        padding: theme.spacing(2),
        minHeight: p => p.mobile
            ? '100vh'
            : 'unset'
    },
    dialogTitle: {
        padding: 0,
        // paddingRight: '0px',
        zIndex: '10',
    }
}))

const headCells = [
    { id: 'nombre', label: 'Nombre y Apellido' },
    { id: 'dni', label: 'D.N.I.' },
    { id: 'fullName', label: 'Embarcacion' },
    { id: 'email', label: 'Matricula' },
    { id: 'mobile', label: 'Concepto' },
    { id: 'mobile', label: 'Img carnet' },
    { id: 'mobile', label: 'Img matricula' },
    { id: 'actions', label: 'Acciones', disableSorting: true }
]

export const RelacionesPendientes = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { relationshipsPendingBoats = [], relationshipsNoPendingBoats = [] } = useSelector(state => state.embarcacion);
    const [filterFn] = useState({ fn: items => { return items; } })
    const [category, setCategory] = useState(1);

    const initialFValues = {
        radioCriterioBusqueda: category,
    }

    const searchItems = [
        { id: 1, title: 'Pendientes' },
        { id: 2, title: 'Activos' },
    ]


    const {
        values,
        // setValues,
        // errors,
        // setErrors,
        handleInputChange,
        // resetForm
    } = useForm(initialFValues, false);
    // } = useForm(busqueda, false);

    useEffect(() => {
        setCategory(values.radioCriterioBusqueda);
    }, [values.radioCriterioBusqueda])

    const openImage = (imageUrl) => {
        const windowName = `image_${Date.now()}`;
        window.open(imageUrl, windowName);
    };

    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting
    } = useTable(
        (category === 1 || category === '1')
            ? relationshipsPendingBoats
            : relationshipsNoPendingBoats,
        headCells,
        filterFn
    );

    const deleteItem = (item) => {
        Swal.fire({
            title: '¿Estas seguro?',
            text: "Esta acción no se puede revertir!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No, conservalo!',
            confirmButtonText: 'Si, eliminalo!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(startDeleteRelationshipBoat(item))
                Toast('success', 'Este registro fue eliminado exitosamente.');
            }
        })
    }

    return (
        <>
            <Toolbar>
                <Grid container spacing={1}
                    direction="row"
                    justifyContent="center"
                    xs={12}
                    alignItems="center"
                >
                    <Grid item container justifyContent="center" xs={12}>
                        <Controls.RadioGroup
                            name="radioCriterioBusqueda"
                            // label="Criterio de busqueda:"
                            value={values.radioCriterioBusqueda}
                            onChange={handleInputChange}
                            items={searchItems}
                        />
                    </Grid>
                    <Grid item justifyContent="center" xs={8}>
                        <Controls.Input
                            // label="Buscar por matricula"
                            label={'Buscar por matricula'}
                            className={classes.searchInput}
                            InputProps={{
                                startAdornment: (<InputAdornment position="start">
                                    <Search />
                                </InputAdornment>)
                            }}
                        // onChange={handleSearch}
                        />
                    </Grid>

                    <Grid item container justifyContent="center" xs={4}>
                        <Controls.Button
                            text="Buscar"
                            variant="outlined"
                            startIcon={<SearchIcon />}
                        // className={classes.searchButton}
                        // onClick={() => { handleSearchClick(search) }}
                        />
                    </Grid>
                </Grid>
            </Toolbar>
            <Grid container>
                <Grid item xs={12}>
                    <TblContainer>
                        <TblHead />
                        <TableBody>
                            {
                                recordsAfterPagingAndSorting().map(item =>
                                (<TableRow key={item.id}>
                                    <TableCell>{item.usuario.nombre} {item.usuario.apellido}</TableCell>
                                    <TableCell>{item.usuario.dni}</TableCell>
                                    <TableCell>{item.embarcacion.nombre}</TableCell>
                                    <TableCell>
                                        {/* {item.matricula} */}
                                        {
                                            (item.embarcacion.inscripcion === 'REY' || item.embarcacion.inscripcion === 'E/T')
                                                ? `${item.embarcacion.inscripcion}${item.embarcacion.matricula}`
                                                : `${item.embarcacion.cuatrigrama}${item.embarcacion.matricula}`
                                        }
                                    </TableCell>
                                    <TableCell>{(item.titular) ? 'Titular' : 'Autorizado'}</TableCell>
                                    <TableCell>
                                        {
                                            <CardMedia
                                                component="img"
                                                sx={{ width: 180, height: 100, cursor: 'zoom-in' }}
                                                onClick={() => {
                                                    openImage(`${process.env.REACT_APP_API_URL_FOR_IMAGES}/credentialsUsers/${item.usuario.imageCarnet}`)
                                                }}
                                                image={`${process.env.REACT_APP_API_URL_FOR_IMAGES}/credentialsUsers/${item.usuario.imageCarnet}`}
                                                alt="img_carnet"
                                            />
                                        }
                                    </TableCell>
                                    <TableCell>
                                        {
                                            (`${process.env.REACT_APP_API_URL_FOR_IMAGES}/boatsCredentialsImages/${item.embarcacion.imgMatriculaUrl}`.split('.').pop() === 'pdf')
                                                ? <PdfViewer
                                                    sx={{ cursor: 'zoom-in' }}
                                                    url={`${process.env.REACT_APP_API_URL_FOR_IMAGES}/boatsCredentialsImages/${item.embarcacion.imgMatriculaUrl}`}
                                                />
                                                : <CardMedia
                                                    component="img"
                                                    sx={{ width: 180, height: 100, cursor: 'zoom-in' }}
                                                    onClick={() => {
                                                        openImage(`${process.env.REACT_APP_API_URL_FOR_IMAGES}/boatsCredentialsImages/${item.embarcacion.imgMatriculaUrl}`)
                                                    }}
                                                    image={`${process.env.REACT_APP_API_URL_FOR_IMAGES}/boatsCredentialsImages/${item.embarcacion.imgMatriculaUrl}`}
                                                    alt="img_matricula"
                                                />
                                        }
                                    </TableCell>
                                    <TableCell>
                                        {
                                            (category === 1 || category === '1') &&
                                            <Controls.ActionButton
                                                color="primary"
                                                onClick={() => { dispatch(startAcceptRelationship(item.id)) }}
                                            >
                                                <Tooltip title="Autorizar">
                                                    <CallMadeIcon fontSize="small" />
                                                </Tooltip>
                                            </Controls.ActionButton>
                                        }
                                        <Controls.ActionButton
                                            color="secondary"
                                            onClick={() => { dispatch(startClosePopup()); deleteItem(item.id) }}>
                                            <Tooltip title="Eliminar">
                                                <CloseIcon fontSize="small" />
                                            </Tooltip>
                                        </Controls.ActionButton>
                                    </TableCell>
                                </TableRow>)
                                )
                            }
                        </TableBody>
                    </TblContainer>
                    <TblPagination />
                </Grid>
            </Grid>
        </>
    )
}
