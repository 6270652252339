import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, Grid, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PersonIcon from '@mui/icons-material/Person';
import { isMobile } from 'react-device-detect';
import Swal from 'sweetalert2';

import { startLogin } from '../../actions/auth';
import Controls from "../../components/controls/Controls";
import PageHeader from '../../components/PageHeader';
import { Form, useForm } from '../../components/useForm';
import { changeLoading } from '../../actions/system';

const useStyles = makeStyles(theme => ({
    LoginButton: {
        marginTop: 20
    },
    LoginTextField: {
        marginBottom: '15px'
    },
    registerButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',

    }
}));

const initialFValues = {
    lEmail: '',
    lPassword: '',
}

const LoginScreen = () => {

    const dispatch = useDispatch();
    const classes = useStyles();
    const navigate = useNavigate();
    const { loading } = useSelector(state => state.system);

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('lEmail' in fieldValues)
            temp.lEmail = fieldValues.lEmail.length !== 0
                ? (/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/).test(fieldValues.lEmail)
                    // ? (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/).test(fieldValues.rEmail)
                    ? ""
                    : "Email no es valido."
                : "Email es requerido."
        if ('lPassword' in fieldValues)
            // temp.rEmail = (/$^|.+@.+..+/).test(fieldValues.rEmail) ? "" : "Email no valido."
            temp.lPassword = fieldValues.lPassword.length > 7 ? "" : "8 caracteres como minimo es requerido."

        setErrors({ ...temp })

        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFValues, true, validate);

    const handleLogin = async (e) => {
        e.preventDefault();
        const { lEmail, lPassword } = values;

        if (validate()) {
            dispatch(changeLoading(true));
            // dispatch(startLogin(lEmail, lPassword));
            dispatch(startLogin(lEmail, lPassword))
                .then(data => {
                    // console.log('Login data:', data);
                    dispatch(changeLoading(false));
                })
                .catch(err => {
                    if (err.message === '400') {
                        setValues({ ...values, lPassword: '' });
                        Swal.fire('Error', 'El usuario esta siendo procesado por los administradores, tenga paciencia.', 'error');
                    }
                    if (err.message === '401') {
                        setValues({ ...values, lPassword: '' });
                        Swal.fire('Error', 'Contraseña incorrecta.', 'error');
                    }
                    if (err.message === '404') {
                        setValues({ ...values, lPassword: '' });
                        Swal.fire('Error', 'El usuario no esta registrado.', 'error');
                    }
                    if (err.message === '410') {
                        setValues({ lEmail: '', lPassword: '' });
                        Swal.fire('Error', 'El usuario ha sido dado de baja. Comuniquese con los administradores.', 'error');
                    }
                    // console.log(err.message)
                    dispatch(changeLoading(false));
                });
            // resetForm()
        }
    }

    return (
        <>
            <PageHeader
                title={(isMobile) ? "Embarcaciones Deportivas" : "Embarcaciones Nauticas Deportivas"}
                subTitle="Prefectura Naval Argentina"
                icon={<SettingsIcon fontSize="large" />}
                register={true}
            />
            <Form onSubmit={handleLogin}>
                <Grid
                    container
                    justifyContent='center'
                >
                    <Grid
                        item
                        xs={(isMobile) ? 12 : 4}
                        sx={{
                            display: "flex",
                            flexDirection: 'column',
                            height: (isMobile) ? '60vh' : '50vh',
                            flexGrow: 1,
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Controls.Input
                            name="lEmail"
                            label="Email"
                            value={values.lEmail.toLowerCase()}
                            onChange={handleInputChange}
                            // sx={style.LoginTextField}
                            // className={classes.LoginTextField}
                            autoFocus
                            error={errors.lEmail}
                        />
                        <Controls.Input
                            name="lPassword"
                            type="password"
                            label="Contraseña"
                            value={values.lPassword}
                            onChange={handleInputChange}
                            className={classes.LoginTextField}
                            error={errors.lPassword}
                        />
                        {/* <a href="/#" style={{ cursor: 'pointer', color: 'blue' }} onClick={(e) => { e.preventDefault(); navigate("/blankpassemail") }}>Olvide mi contraseña</a> */}
                        <Link
                            sx={{
                                marginTop: 1, cursor: 'pointer',
                                ...(loading) && {
                                    color: 'gray',
                                    cursor: 'default'
                                }
                            }}
                            component={(loading) && "button"}
                            href="/#"
                            disabled={loading}
                            underline="none"
                            onClick={(e) => { e.preventDefault(); navigate("/blankpassemail") }}
                        >
                            {'Olvide mi contraseña'}
                        </Link>
                        <Box
                            sx={{ mt: 1 }}
                        >
                            <Controls.Button
                                text="Login..."
                                variant="outlined"
                                // startIcon={<LockOpenIcon />}
                                disabled={loading}
                                loading={loading}
                                startIconWithoutLoading={<LockOpenIcon />}
                                type="submit"
                            // onClick={() => setLoading(true)}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Form>
            {
                isMobile &&
                <Controls.Button
                    className={classes.registerButton}
                    text="Quiero registrarme!"
                    endIcon={<PersonIcon />}
                    onClick={() => {
                        navigate("/admin/user/register");
                    }}
                />
            }
        </>
    )
}

export default LoginScreen;
