import { Box } from '@mui/material';
import { Document, Page, pdfjs } from 'react-pdf'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

export const PdfViewer = ({ url, sx = [] }) => {
    const openPdf = (pdf) => {
        const windowName = `pdf_${Date.now()}`;
        window.open(pdf, windowName);
    };

    return (
        <Box sx={[...(Array.isArray(sx) ? sx : [sx])]}>
            <Document file={url}>
                <Page
                    scale={.2}
                    // height={'200px'}
                    pageNumber={1}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                    onClick={() => openPdf(url)}
                />
            </Document>
        </Box>
    )
}