import { types } from '../types/types';

const initialState = {

    verificadas: [],
    prohibic: [],
    embarcaciones: [],
    search: '',

}

export const observadorVisorReducer = (state = initialState, action) => {

    switch (action.type) {

        case types.observadorVisorGetVerificadas:
            return {
                ...state,
                verificadas: action.payload,
            }

        case types.observadorVisorGetProhibic:
            return {
                ...state,
                prohibic: action.payload,
            }

        case types.observadorVisorGetBoats:
            return {
                ...state,
                embarcaciones: action.payload,
            }

        case types.observadorVisorSearchBoat:
            return {
                ...state,
                search: action.payload,
            }

        case types.observadorVisorGetSearchBoat:
            return {
                ...state,
                embarcaciones: [...action.payload],
            }

        default:
            return state;
    }

}

