import React, { useEffect, useState } from 'react'
import { Grid, FormControl, InputLabel, Select, MenuItem, FormHelperText, CardMedia, Typography, Dialog, DialogTitle, DialogContent, CircularProgress } from '@mui/material';
import Controls from "../../components/controls/Controls";
import { useForm, Form } from '../../components/useForm';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { startListDependenciasDB } from '../../actions/embarcacionesPrefectura';
import { isMobile } from 'react-device-detect';
import CloseIcon from '@mui/icons-material/Close';
import { Opacity } from '@mui/icons-material';
import { PdfViewer } from '../../components/PdfViewer';

const inscripcionItems = [
    { id: 'REY', title: 'REY' },
    { id: 'REJU', title: 'REJU' },
    { id: 'E/T', title: 'E/T' },
]

const catCarnetItems = [
    { id: 1, nombre: 'N1' },
    { id: 2, nombre: 'N2' },
    { id: 3, nombre: 'N3' },
    { id: 4, nombre: 'N4' },
    { id: 5, nombre: 'N5' },
    { id: 6, nombre: 'N6' },
    { id: 7, nombre: 'N7' },
    { id: 8, nombre: 'N8' },
    { id: 9, nombre: 'N9' },
    { id: 10, nombre: 'NA' },
    { id: 11, nombre: 'NB' },
]

const initialFValues = {
    id: 0,
    nombre: '',
    matricula: '',
    amarre: '',
    desc: '',
    motivoProhib: '',
    capacidadPersonas: '',
    categoriaCarnet: 'N2',
    sinMatricula: false,
    cuatrigrama: '',
    inscripcion: 'REY',
    fechaAlta: new Date(),
    prohibicion: false,
}

const matriculaRegex = /^[A-Z]{4}-\d{1,10}\/\d{4}$/;

const EmbarcacionesForm = (props) => {
    const { addOrEdit, recordForEdit } = props
    const { clubes } = useSelector(state => state.admin);
    const dispatch = useDispatch();
    const { dependencias = [] } = useSelector(state => state.admin);
    const [imageLoaded, setImageLoaded] = useState({
        imgEmbarcacion: false,
        imgMatricula: false
    });

    const handleImageBoatLoad = () => {
        setImageLoaded(prevState => ({ ...prevState, imgEmbarcacion: true }));
        // console.log(imageLoaded)
    };

    const handleImagePapersLoad = () => {
        setImageLoaded(prevState => ({ ...prevState, imgMatricula: true }));
        // console.log(imageLoaded)
    };

    // const [popupData, setPopupData] = useState({
    //     title: '',
    //     contain: '',
    //     isOpen: false,
    // });

    const openImage = (imageUrl) => {
        window.open(imageUrl, '_blank');
    };

    useEffect(() => {
        dispatch(startListDependenciasDB());
    }, [])

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('nombre' in fieldValues) {
            temp.nombre = fieldValues.nombre ? "" : "Nombre es requerido."
        }

        // if ('matricula' in fieldValues)
        //     temp.matricula = fieldValues.matricula.length !== 0
        //         ? (/^[0-9]*$/).test(fieldValues.matricula)
        //             ? (fieldValues.matricula.length >= 0 && fieldValues.matricula.length <= 6)
        //                 ? ""
        //                 : "La matricula debe contener 1 caracter como minimo y 6 caracteres como maximo."
        //             : "Matricula solo puede contener numeros."
        //         : "Matricula es requerido."

        if ('matricula' in fieldValues) {
            if (values.inscripcion !== 'E/T') {
                temp.matricula = fieldValues.matricula.length !== 0
                    ? (/^[0-9]*$/).test(fieldValues.matricula)
                        ? (fieldValues.matricula.length >= 1 && fieldValues.matricula.length <= 6)
                            ? ""
                            : "La matricula debe contener al menos 1 caracter y como máximo 6 caracteres."
                        : "Matricula solo puede contener números."
                    : "Matricula es requerido."
            } else {
                // delete temp.matricula
                // temp.matricula = fieldValues.matricula ? "" : "Inscripción es requerido."

                temp.matricula = fieldValues.matricula.length !== 0
                    ? matriculaRegex.test(fieldValues.matricula)
                        ? (fieldValues.matricula.length >= 11 && fieldValues.matricula.length <= 20)
                            ? ""
                            : "La expediente debe contener al menos 11 caracter y como máximo 20 caracteres."
                        : `El formato del expediente debe ser similar al siguiente ejemplo: "SAFE-1234567899/2023".`
                    : "Expediente es requerido."
            }
        }

        if ('capacidadPersonas' in fieldValues)
            temp.capacidadPersonas = fieldValues.capacidadPersonas !== null
                ? (/^[1-9][0-9]*$/).test(fieldValues.capacidadPersonas)
                    ? (fieldValues.capacidadPersonas.length > 0 && fieldValues.capacidadPersonas.length <= 3)
                        ? ""
                        : "La capacidad debe contener 1 caracter como minimo y 3 caracteres como maximo."
                    : "Capacidad solo puede contener numeros y no empezar con 0."
                : "Capacidad es requerido."

        if ('categoriaCarnet' in fieldValues) {
            temp.categoriaCarnet = fieldValues.categoriaCarnet ? "" : "Categoria de carnet es requerido."
        }

        if ('amarre' in fieldValues) {
            temp.amarre = fieldValues.amarre ? "" : "Lugar de amarre es requerido."
        }

        if ('motivoProhib' in fieldValues) {
            temp.motivoProhib = fieldValues.motivoProhib ? "" : "El motivo de prohibición es requerido."
        }

        if ('prohibicion' in fieldValues) {
            if (!fieldValues.prohibicion) {
                delete temp.motivoProhib
            }
        }

        if ('cuatrigrama' in fieldValues)
            temp.cuatrigrama = fieldValues.cuatrigrama ? "" : "Cuatrigrama es requerido."

        if ('inscripcion' in fieldValues) {
            if (fieldValues.inscripcion === 'REY' || fieldValues.inscripcion === 'E/T') {
                delete temp.cuatrigrama
            } else {
                temp.inscripcion = fieldValues.inscripcion ? "" : "Inscripción es requerido."
            }
        }

        if ('sinMatricula' in fieldValues) {
            if (fieldValues.sinMatricula === true) {
                delete temp.cuatrigrama
                delete temp.matricula
            }
        }

        setErrors({ ...temp })
        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFValues, true, validate);

    const handleSubmit = e => {
        e.preventDefault();
        // if (values.sinMatricula === true)
        //     values.matricula = null

        // console.log(errors)

        const temp = {
            ...values,
            matricula: (values.sinMatricula === true) ? null : values.matricula,
            cuatrigrama:
                (values.inscripcion === 'REY' || values.inscripcion === 'E/T')
                    ? null
                    : values.cuatrigrama,
            // desc: (values.desc.trim() !== '') ? values.desc : null
            motivoProhib: (values.prohibicion)
                ? values.motivoProhib
                : null,
        }

        if (validate()) {
            addOrEdit(temp, resetForm);
            // console.log(temp)
        }

        // dispatch(startClosePopup());
        // Swal.fire({
        //     title: '¿Estas seguro?',
        //     text: "Esta acción no se puede revertir!",
        //     icon: 'warning',
        //     showCancelButton: true,
        //     confirmButtonColor: '#3085d6',
        //     cancelButtonColor: '#d33',
        //     cancelButtonText: 'No, dejalo como estaba!',
        //     confirmButtonText: 'Si, guarda este registro con estos datos!'
        // }).then((result) => {
        //     if (result.isConfirmed) {

        //         if (validate()) {
        //             addOrEdit(values, resetForm);
        //         }

        //         Swal.fire(
        //             'Estado:',
        //             'Este registro fue actualizado exitosamente.',
        //             'success'
        //         )
        //     }
        // })

    }

    useEffect(() => {
        if (recordForEdit !== null)
            // console.log(recordForEdit)
            setValues({
                ...recordForEdit
            })
    }, [recordForEdit, setValues])

    useEffect(() => {
        // imgEmbarcacionUrl
        if (recordForEdit !== null) {
            const image = new Image();
            image.onload = handleImageBoatLoad;
            image.src = `${process.env.REACT_APP_API_URL_FOR_IMAGES}/boatsImages/${recordForEdit.imgEmbarcacionUrl}`;
        }
    }, []);

    useEffect(() => {
        // imgMatriculaUrl
        if (recordForEdit !== null) {
            // console.log(`${process.env.REACT_APP_API_URL_FOR_IMAGES}/boatsCredentialsImages/${recordForEdit.imgMatriculaUrl}`)
            // console.log(`${process.env.REACT_APP_API_URL_FOR_IMAGES}/boatsCredentialsImages/${recordForEdit.imgMatriculaUrl}`.split('.').pop() === 'pdf')
            if ((`${process.env.REACT_APP_API_URL_FOR_IMAGES}/boatsCredentialsImages/${recordForEdit.imgMatriculaUrl}`.split('.').pop() === 'pdf') === false) {
                // return;

                const image = new Image();
                image.onload = handleImagePapersLoad;
                image.src = `${process.env.REACT_APP_API_URL_FOR_IMAGES}/boatsCredentialsImages/${recordForEdit.imgMatriculaUrl}`;
                // console.log('falseeete')
            } else {
                handleImagePapersLoad();
            }
        }
    }, []);

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Grid container>
                    <Grid item xs={(recordForEdit !== null) ? 4 : 6}>
                        <Controls.RadioGroup
                            name="inscripcion"
                            label="Inscripción"
                            value={values.inscripcion}
                            onChange={handleInputChange}
                            items={inscripcionItems}
                        />
                        {
                            (values.inscripcion === 'REJU') &&
                            // <Controls.Select
                            //     name="cuatrigrama"
                            //     label="Cuatrigrama"
                            //     value={values.cuatrigrama}
                            //     onChange={handleInputChange}
                            //     options={[{ id: 1, nombre: 'SAFE' }, { id: 2, nombre: 'ROSA' }, , { id: 3, nombre: 'SNIC' }, , { id: 4, nombre: 'PARA' }]}
                            //     error={errors.cuatrigrama}
                            // />

                            <FormControl error={errors.cuatrigrama}>
                                <InputLabel>Cuatrigrama</InputLabel>
                                <Select
                                    name="cuatrigrama"
                                    label="Cuatrigrama"
                                    value={values.cuatrigrama}
                                    onChange={handleInputChange}
                                // renderValue={(value) => `⚠️  - ${value}`}
                                >
                                    {dependencias.map(
                                        item => (<MenuItem key={item.id} value={item.cuatrigrama}>{item.cuatrigrama} - {item.nombre}</MenuItem>)
                                    )}
                                </Select>
                                {errors.cuatrigrama && <FormHelperText>{errors.cuatrigrama}</FormHelperText>}
                            </FormControl>
                        }
                        <Controls.Input
                            name="matricula"
                            label="Matrícula"
                            placeholder="EJ: 012345"
                            value={values.matricula}
                            onChange={handleInputChange}
                            error={errors.matricula}
                            disabled={(values.sinMatricula === true) ? true : false}
                        />
                        <Controls.Checkbox
                            label="No posee matrícula"
                            name="sinMatricula"
                            value={values.sinMatricula}
                            onChange={handleInputChange}
                        />
                        <Controls.Input
                            name="capacidadPersonas"
                            label="Capacidad máxima"
                            value={values.capacidadPersonas}
                            onChange={handleInputChange}
                            error={errors.capacidadPersonas}
                        />
                        <Controls.Select
                            name="amarre"
                            label="Lugar de amarre"
                            value={values.amarre}
                            onChange={handleInputChange}
                            // options={clubes}
                            options={[{ id: 0, nombre: "Propio" }, ...clubes]}
                            error={errors.amarre}
                        />
                        {
                            (values.prohibicion) &&
                            <Controls.Input
                                name="motivoProhib"
                                label="Motivo de prohibición"
                                value={values.motivoProhib}
                                onChange={handleInputChange}
                                error={errors.motivoProhib}
                            />
                        }
                    </Grid>
                    <Grid item xs={(recordForEdit !== null) ? 4 : 6}>
                        <Controls.Input
                            name="nombre"
                            label="Nombre"
                            value={values.nombre}
                            onChange={handleInputChange}
                            error={errors.nombre}
                        />
                        <Controls.DatePickerControl
                            name="fechaAlta"
                            label="Fecha de alta"
                            value={values.fechaAlta}
                            onChange={handleInputChange}
                        />
                        <Controls.Checkbox
                            name="prohibicion"
                            label="¿Prohibición navegar?"
                            value={values.prohibicion}
                            onChange={handleInputChange}
                        />
                        <Controls.Select
                            name="categoriaCarnet"
                            label="Categoria carnet timonel"
                            value={values.categoriaCarnet}
                            onChange={handleInputChange}
                            options={catCarnetItems}
                            error={errors.categoriaCarnet}
                        />
                        <Controls.Input
                            name="desc"
                            label="Observaciones opcionales"
                            value={values.desc}
                            onChange={handleInputChange}
                            error={errors.desc}
                        />
                        <Box
                            sx={{ ml: 1, mt: 1 }}
                        >
                            <Controls.Button
                                text='Reset'
                                color='default'
                                onClick={resetForm}
                            />
                            <Controls.Button
                                type="submit"
                                text="Guardar" />
                        </Box>
                    </Grid>
                    {
                        (recordForEdit !== null)
                        && <Grid item xs={4}>
                            <Box>
                                <Typography sx={{ fontWeight: 'bold', mb: 1 }}>
                                    Imagen embarcación
                                </Typography>
                                {/* {JSON.stringify(imageLoaded, null, 5)} */}
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 150, width: '100%' }}>
                                    {
                                        imageLoaded.imgEmbarcacion
                                            ? <CardMedia
                                                component="img"
                                                sx={{ height: 150, width: 150, cursor: 'zoom-in' }}
                                                // onLoad={() => handleImageBoatLoad()}
                                                onClick={() => {
                                                    // setTitle('Imagen embarcaciones')
                                                    // setIsOpen(true)
                                                    // setPopupData({ title: 'Imagen embarcaciones', contain: 'embarcacion', isOpen: true })
                                                    openImage(`${process.env.REACT_APP_API_URL_FOR_IMAGES}/boatsImages/${values.imgEmbarcacionUrl}`)
                                                }}
                                                image={`${process.env.REACT_APP_API_URL_FOR_IMAGES}/boatsImages/${values.imgEmbarcacionUrl}`}
                                            // alt="Imagen embarcacion"
                                            />
                                            : <CircularProgress size={48} />
                                    }
                                </Box>
                            </Box>
                            <Box sx={{ mt: 2 }}>
                                <Typography sx={{ fontWeight: 'bold', mb: 1 }}>
                                    Imagen matricula
                                </Typography>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 150, width: '100%' }}>
                                    {
                                        imageLoaded.imgMatricula
                                            ? ((`${process.env.REACT_APP_API_URL_FOR_IMAGES}/boatsCredentialsImages/${recordForEdit.imgMatriculaUrl}`.split('.').pop() === 'pdf') === false)
                                                ? <CardMedia
                                                    component="img"
                                                    sx={{ height: 150, width: 150, cursor: 'zoom-in' }}
                                                    // onLoad={() => handleImageBoatLoad()}
                                                    onClick={() => {
                                                        // setTitle('Imagen embarcaciones')
                                                        // setIsOpen(true)
                                                        // setPopupData({ title: 'Imagen embarcaciones', contain: 'embarcacion', isOpen: true })
                                                        openImage(`${process.env.REACT_APP_API_URL_FOR_IMAGES}/boatsCredentialsImages/${values.imgMatriculaUrl}`)
                                                    }}
                                                    image={`${process.env.REACT_APP_API_URL_FOR_IMAGES}/boatsCredentialsImages/${values.imgMatriculaUrl}`}
                                                // alt="Imagen embarcacion"
                                                />
                                                : <PdfViewer
                                                    sx={{ cursor: 'zoom-in', mt: 2 }}
                                                    // url={matricula.imagePreview}
                                                    url={`${process.env.REACT_APP_API_URL_FOR_IMAGES}/boatsCredentialsImages/${values.imgMatriculaUrl}`}
                                                // url={`https://cps-santafe.duckdns.org/uploads/boatsCredentialsImages/REY743457.pdf`}
                                                />
                                            : <CircularProgress size={48} />
                                    }
                                </Box>
                            </Box>
                        </Grid>
                    }
                </Grid>
            </Form>

            {/* <Dialog open={popupData.isOpen} maxWidth='md'
                sx={{
                    padding: 2,
                    minHeight: (isMobile) ? '100vh' : 'unset'
                }}
            >
                <DialogTitle sx={{ ml: 2, padding: 1 }}>
                    <div style={{ display: 'flex' }}>
                        <Typography mt={1} variant="h6" component="div" style={{ flexGrow: 1 }}>
                            {popupData.title}
                        </Typography>
                        <Controls.ActionButton
                            color="secondary"
                            onClick={() => {
                                setPopupData((x) => {
                                    return { ...x, isOpen: false };
                                });
                            }}>
                            <CloseIcon />
                        </Controls.ActionButton>
                    </div>
                </DialogTitle>
                <DialogContent dividers>
                    <CardMedia
                        component="img"
                        sx={{ maxWidth: 800 }}
                        image={`${process.env.REACT_APP_API_URL_FOR_IMAGES}/boatsImages/${values.imgEmbarcacionUrl}`}
                        alt="Imagen embarcacion"
                    />
                </DialogContent>
            </Dialog> */}
        </>
    )
}

export default EmbarcacionesForm;