import React, { useEffect } from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
    // HashRouter
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LoginScreen from '../pages/auth/LoginScreen';
import { startChecking } from '../actions/auth';
import { PublicRoute } from './PublicRoute';
import { PrivateRoute } from './PrivateRoute';
import Loading from '../pages/loading/Loading';
import { DashboardRoute } from './DashboardRoute';
import RegisterScreen from '../pages/auth/RegisterScreen';
import RestrictPortrait from '../pages/Mobile/RestrictPortrait';
import useWindowDimensions from '../components/hooks/useWindowDimensions';
import RecoverPassSendEmailScreen from '../pages/auth/RecoverPassSendEmailScreen.js';
import PasswordChangerScreen from '../pages/auth/PasswordChangerScreen';
import SideMenu from '../components/SideMenu';
import { makeStyles } from '@mui/styles';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles({
    appMain: {
        paddingLeft: '20%',
        '@media (max-width: 960px)': {
            paddingLeft: '0%'
        },
        width: '100%'
    }
})

export const AppRouter = () => {
    const classes = useStyles();
    const [width, ,] = useWindowDimensions();
    const dispatch = useDispatch();
    const { uid, checking } = useSelector(state => state.auth);

    useEffect(() => {
        dispatch(startChecking());
    }, [])

    // if (false) {
    // return (<h1>Espere...</h1>);
    if (checking) {
        return (
            <Loading />
        );
    }

    return (
        <BrowserRouter>
            <div className={classes.appMain}>
                <SideMenu />
                <Routes>
                    <Route
                        end
                        path='/login'
                        // element={
                        //     (width > 600)
                        //         ? <PublicRoute><LoginScreen /></PublicRoute>
                        //         : <RestrictPortrait />
                        // }
                        element={<PublicRoute><LoginScreen /></PublicRoute>}
                    />

                    <Route
                        end
                        path='/admin/user/register'
                        element={
                            (width > 600)
                                ? <PublicRoute><RegisterScreen /></PublicRoute>
                                : <RestrictPortrait />
                        }
                    />

                    <Route
                        end
                        path='/blankpassemail'
                        element={
                            (width > 600)
                                ? <PublicRoute><RecoverPassSendEmailScreen /></PublicRoute>
                                : <RestrictPortrait />
                        }
                    />

                    <Route
                        end
                        path='/resetpassword/:id/:token'
                        element={
                            (width > 600)
                                ? <PublicRoute><PasswordChangerScreen /></PublicRoute>
                                : <RestrictPortrait />
                        }
                    />

                    <Route
                        path='/*'
                        element={
                            (width > 600 || (isMobile && uid == null))
                                ? <PrivateRoute><DashboardRoute /></PrivateRoute>
                                : <RestrictPortrait />
                        }
                    // element={<PrivateRoute><DashboardRoute /></PrivateRoute>}
                    />

                </Routes>
            </div>
        </BrowserRouter >
    )
}
