import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import { Paper, Grid, TableBody, TableRow, TableCell, Toolbar, InputAdornment, Tooltip, Badge } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Search } from "@mui/icons-material";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SearchIcon from '@mui/icons-material/Search';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import Swal from 'sweetalert2'

import PageHeader from "../../components/PageHeader";
import useTable from "../../components/useTable";
import Controls from "../../components/controls/Controls";
import Popup from "../../components/Popup";
import EmbarcacionesForm from './EmbarcacionesForm';
import { startChildPopup, startClosePopup, startOpenPopup, startSizePopup, startTitlePopup } from '../../actions/system';
import { startDeleteBoat, startGetBoats, startGetSearchBoat, startListPendientesDB, startListRelacionesNoPendientesDB, startListRelacionesPendientesDB, startPostNewBoat, startPutEditBoat, startRecordForEdit, startSearchBoat } from '../../actions/embarcacionesPrefectura';
import useWindowDimensions from '../../components/hooks/useWindowDimensions';
import EmbarcacionesPendientes from './EmbarcacionesPendientes';
import CarnetNautico from './CarnetNautico';
import { startListClubesDB } from '../../actions/embarcacionesClubes';
import { RelacionesPendientes } from './RelacionesPendientes';
import { Toast } from '../../components/Toast';

const useStyles = makeStyles(theme => ({
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3)
    },
    // searchInput: {
    //     width: '100%'
    // },
}))

const headTable = [
    { id: 'fullName', label: 'Embarcacion' },
    { id: 'email', label: 'Matricula' },
    // { id: 'gender', label: 'Inscripción' },
    { id: 'city', label: 'Amarre' },
    { id: 'actions', label: 'Acciones', disableSorting: true }
]

const Embarcaciones = () => {

    const dispatch = useDispatch();
    const classes = useStyles();
    const [filterFn] = useState({ fn: items => { return items; } })
    // const [filterFn, setFilterFn] = useState({ fn: items => { return items; } })
    const { name, place } = useSelector(state => state.auth);
    const { recordForEdit, embarcaciones = [], search, pendingBoats = [], relationshipsPendingBoats = [] } = useSelector(state => state.embarcacion);
    const { titlePopup, sizePopup, childPopup } = useSelector(state => state.system);
    const [width, ,] = useWindowDimensions();

    useEffect(() => {
        dispatch(startGetBoats())
        dispatch(startListPendientesDB())
        dispatch(startListRelacionesPendientesDB())
        dispatch(startListRelacionesNoPendientesDB())
        dispatch(startListClubesDB());
    }, [dispatch])

    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting
    } = useTable(embarcaciones, headTable, filterFn);

    const handleSearch = (e) => {
        dispatch(startSearchBoat(e.target.value.trim()));
    }

    const handleSearchClick = (search) => {
        if (search === '') {
            dispatch(startGetBoats())
        } else {
            dispatch(startGetSearchBoat());
        }
    }

    const addOrEdit = (boat, resetForm) => {
        if (boat.id === 0) {
            const lugar = { lugar: place };
            const boatWithPlace = Object.assign(boat, lugar);
            dispatch(startPostNewBoat(boatWithPlace));
        } else {
            dispatch(startPutEditBoat(boat));
        }
        resetForm()
        dispatch(startRecordForEdit(null));
        dispatch(startClosePopup());
    }

    const openInPopup = item => {
        dispatch(startRecordForEdit(item));
        dispatch(startOpenPopup());
    }

    const deleteItem = (item) => {
        Swal.fire({
            title: '¿Estas seguro?',
            text: "Esta acción no se puede revertir!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No, conservalo!',
            confirmButtonText: 'Si, eliminalo!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(startDeleteBoat(item))
                Toast('success', 'Este registro fue eliminado exitosamente.');
            }
        })
    }

    return (
        <>
            <PageHeader
                title={`Oficina Polinave de Prefectura ${place}`}
                subTitle="Listado de embarcaciones."
                icon={<DirectionsBoatIcon fontSize="large" />}
                profile={true}
                name={name}
            />
            <Paper className={classes.pageContent}>
                <Toolbar>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        flexWrap={'wrap-reverse'}
                    >
                        <Grid
                            item
                            container
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                            xl
                            sx={{ m: 1 }}
                        >
                            <Grid
                                item
                                sx={{
                                    flexGrow: 1,
                                    // minWidth: 200
                                }}
                                xs
                            >
                                <Controls.Input
                                    label="Buscar por matricula"
                                    style={{ width: '100%' }}
                                    InputProps={{
                                        startAdornment: (<InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>)
                                    }}
                                    onChange={handleSearch}
                                />
                            </Grid>
                            <Grid
                                item
                            >
                                <Controls.Button
                                    sx={{ marginLeft: 1 }}
                                    text="Buscar"
                                    variant="outlined"
                                    startIcon={(width > 850) ? <SearchIcon /> : null}
                                    onClick={() => { handleSearchClick(search) }}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            xs="auto"
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                        // sx={{m:1}}
                        >
                            {/* <Controls.Button
                                text="Agregar"
                                startIcon={(width > 850) ? <AddIcon /> : null}
                                onClick={() => {
                                    dispatch(startTitlePopup('Datos...'));
                                    dispatch(startSizePopup('md'));
                                    dispatch(startChildPopup('Observar/Nuevo'));
                                    dispatch(startRecordForEdit(null));
                                    dispatch(startOpenPopup());
                                }}
                            /> */}
                            <Badge badgeContent={pendingBoats.length} color="secondary">
                                <Controls.Button
                                    text="Embarcaciones pendientes"
                                    startIcon={(width > 850) ? <QueryBuilderIcon /> : null}
                                    onClick={() => {
                                        dispatch(startTitlePopup('Embarcaciones pendientes...'));
                                        dispatch(startSizePopup('lg'));
                                        dispatch(startChildPopup('Embarcaciones'));
                                        openInPopup(true)
                                    }}
                                />
                            </Badge>
                            <Badge badgeContent={relationshipsPendingBoats.length} color="secondary">
                                <Controls.Button
                                    text="Relaciones"
                                    startIcon={(width > 850) ? <QueryBuilderIcon /> : null}
                                    onClick={() => {
                                        dispatch(startTitlePopup('Relaciones...'));
                                        dispatch(startSizePopup('lg'));
                                        dispatch(startChildPopup('Relaciones'));
                                        openInPopup(true)
                                    }}
                                />
                            </Badge>
                            <Controls.Button
                                text="Carnet nautico"
                                startIcon={(width > 850) ? <RecentActorsIcon /> : null}
                                onClick={() => {
                                    dispatch(startTitlePopup('Observar contravenciones para renovación...'));
                                    dispatch(startSizePopup('lg'));
                                    dispatch(startChildPopup('Carnet'));
                                    openInPopup(true)
                                }}
                            />
                        </Grid>
                    </Grid>
                </Toolbar>

                <TblContainer>
                    <TblHead />
                    <TableBody>
                        {
                            recordsAfterPagingAndSorting().map(item => (
                                <TableRow key={item.matricula}>
                                    <TableCell>{item.nombre}</TableCell>
                                    <TableCell>
                                        {
                                            (item.inscripcion === 'REY' || item.inscripcion === 'E/T')
                                                ? `${item.inscripcion} ${item.matricula}`
                                                : `${item.cuatrigrama} ${item.matricula}`
                                        }
                                    </TableCell>
                                    {/* {
                                        (width > 700) && (<TableCell>{item.inscripcion}</TableCell>)
                                    } */}
                                    <TableCell>{item.amarre}</TableCell>
                                    <TableCell>
                                        <Controls.ActionButton
                                            color="primary"
                                            onClick={() => {
                                                dispatch(startTitlePopup('Datos...'));
                                                dispatch(startSizePopup('md'));
                                                dispatch(startChildPopup('Observar/Nuevo'));
                                                openInPopup(item);
                                            }}>
                                            <Tooltip title="Observar/Modificar">
                                                <VisibilityIcon fontSize="small" />
                                            </Tooltip>
                                        </Controls.ActionButton>
                                        <Controls.ActionButton
                                            color="secondary"
                                            onClick={() => { deleteItem(item.id) }}>
                                            <Tooltip title="Eliminar">
                                                <CloseIcon fontSize="small" />
                                            </Tooltip>
                                        </Controls.ActionButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </TblContainer>
                <TblPagination />
            </Paper>
            <Popup
                title={titlePopup}
                size={sizePopup}
            >
                {
                    (childPopup === 'Observar/Nuevo')
                        ? (<EmbarcacionesForm recordForEdit={recordForEdit} addOrEdit={addOrEdit} />)
                        : (childPopup === 'Carnet')
                            ? (<CarnetNautico />)
                            : (childPopup === 'Embarcaciones')
                                ? (<EmbarcacionesPendientes />)
                                : (<RelacionesPendientes />)
                }
            </Popup>
        </>
    )
}

export default Embarcaciones;

