import React from 'react'
import { Paper, Card, Typography, Box, Menu, MenuItem, Avatar, Divider, ListItemIcon, Tooltip, IconButton } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person';
import { useDispatch, useSelector } from 'react-redux';
import { startLogout } from '../actions/auth';
import Controls from './controls/Controls';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';

export default function PageHeader(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const { title, subTitle, icon, profile, register, name, surname } = props;
    const { title, subTitle, icon, profile, register } = props;
    const { loading } = useSelector(state => state.system);
    const { name = '', surname = '' } = useSelector(state => state.auth);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Paper elevation={0} square style={{ backgroundColor: '#fdfdff' }}>
            <div style={{
                padding: 32,
                display: 'flex',
                marginBottom: 16
            }}>
                <Card sx={{
                    display: 'inline-block',
                    p: 2,
                    color: '#3c44b1',
                }}>
                    {icon}
                </Card>
                <div style={{
                    paddingLeft: 32,
                }}>
                    {
                        (isMobile)
                            ? <Box sx={{ fontSize: 16, fontWeight: '800' }}>
                                {title}
                            </Box>
                            : <Typography
                                variant="h6"
                                component="div">
                                {title}
                            </Typography>
                    }
                    <Typography
                        variant="subtitle2"
                        component="div"
                        style={{ opacity: '0.6' }}
                    >
                        {subTitle}
                    </Typography>
                </div>

                {
                    profile &&
                    // <Card
                    //     sx={{
                    //         display: 'flex',
                    //         position: 'absolute',
                    //         alignItems: 'center',
                    //         right: 25,
                    //         p: 2,
                    //         color: '#3c44b1'
                    //     }}
                    //     disabled={loading}
                    //     onClick={() => { dispatch(startLogout()); }}
                    //     style={{ cursor: 'pointer' }}
                    // >
                    //     <Typography
                    //         sx={{
                    //             mr: 1,
                    //             fontWeight: 'bold'
                    //         }}
                    //     >
                    //         {name}
                    //     </Typography>
                    //     <PersonIcon
                    //         fontSize="large"
                    //     />
                    // </Card>
                    <>
                        <Box sx={{
                            display: 'flex',
                            position: 'absolute',
                            alignItems: 'center',
                            // textAlign: 'center'
                            right: 25,
                            // p: 2,
                            color: '#3c44b1',
                            // backgroundColor: 'red'
                        }}>
                            <Tooltip title="Cuenta personal">
                                <IconButton
                                    onClick={handleClick}
                                    size="small"
                                    sx={{ ml: 2 }}
                                    aria-controls={open ? 'account-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                >
                                    <Avatar sx={{ width: 64, height: 64, fontWeight: 'bold', backgroundColor: '#3c44b1' }}>
                                        {name.charAt(0)}{surname.charAt(0)}
                                    </Avatar>
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Menu
                            anchorEl={anchorEl}
                            // id="account-menu"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 32,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            {/* 
                                <MenuItem onClick={handleClose}>
                                <ListItemIcon>
                                <Settings fontSize="small" />
                                </ListItemIcon>
                                Settings
                                </MenuItem>
                                <Divider /> 
                            */}
                            <MenuItem onClick={() => {
                                handleClose();
                                dispatch(startLogout());
                            }}>
                                <ListItemIcon>
                                    <Logout fontSize="small" />
                                </ListItemIcon>
                                Cerrar sesión
                            </MenuItem>
                        </Menu>
                    </>
                }
                {
                    (!isMobile && register) &&
                    <Box sx={{
                        // display: 'flex',
                        position: 'absolute',
                        // alignItems: 'center',
                        top: 20,
                        right: 2,
                        padding: 2,
                    }}>
                        <Controls.Button
                            sx={{ height: 55, marginBottom: 50 }}
                            // sx={(theme) => ({
                            //     color: 'info.main',
                            //     ...theme.typography.overline,
                            //   })}
                            text="Quiero registrarme!"
                            endIcon={<PersonIcon />}
                            disabled={loading}
                            onClick={() => {
                                navigate('/admin/user/register');
                            }}
                        />
                    </Box>
                }
            </div>
        </Paper>
    )
}
