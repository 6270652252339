// import React from 'react'
// // import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
// import DatePicker from '@mui/lab/DatePicker';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import DateFnsUtils from "@date-io/date-fns";

// // export default function DatePicker(props) {
// const DatePickerControl = (props) => {
//     const { name, label, value, onChange, minDate, maxDate, past, disabled } = props

//     const convertToDefEventPara = (name, value) => ({
//         target: {
//             name, value
//         }
//     })

//     return (
//         <LocalizationProvider utils={DateFnsUtils}>
//             <DatePicker 
//                 disableToolbar 
//                 variant="inline" 
//                 inputVariant="outlined"
//                 label={label}
//                 // format="dd/MMM/yyyy"
//                 format="dd/MM/yyyy"
//                 minDate={minDate}
//                 maxDate={maxDate}
//                 disablePast={past}
//                 name={name}
//                 value={value}
//                 onChange={date => onChange(convertToDefEventPara(name, date))}
//                 disabled={disabled}
//             />
//         </LocalizationProvider>
//     )
// }

// export default DatePickerControl;


import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import esLocale from "date-fns/locale/es";
import Controls from './Controls';

const DatePickerControl = (props) => {
    const { name, label, value, onChange, minDate, maxDate, past, disabled } = props

    const convertToDefEventPara = (name, value) => ({
        target: {
            name, value
        }
    })

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
            <DesktopDatePicker
                name={name}
                label={label}
                inputFormat="dd/MM/yyyy"
                minDate={minDate}
                maxDate={maxDate}
                disablePast={past}
                value={value}
                disabled={disabled}
                onChange={date => onChange(convertToDefEventPara(name, date))}
                renderInput={(params) => (
                    <Controls.Input
                        {...params}
                        inputProps={{ ...params.inputProps, placeholder: "dd/mm/aaaa" }}
                    />
                    // <TextField {...params} helperText={'fldskj'} />
                )}
                // renderInput={(params) => <TextField {...params} />}
            />
        </LocalizationProvider>
    );
}

export default DatePickerControl;