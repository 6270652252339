import { Navigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux';

export const AuthorizedRoute = ({ children }) => {

    const location = useLocation()
    const { from } = useSelector(state => state.auth);

    return ((from === 'clubes' && location.pathname === `/gestion/clubes`) || (from !== 'clubes' && JSON.parse(from).includes(location.pathname.slice(9))))
        ? children
        : <Navigate to={(from === 'clubes') ? '/gestion/clubes' : '/gestion/dashboard'} />

}
