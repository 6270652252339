import { types } from '../types/types';

const initialState = {
    id: '',
    titular: false,
    autorizado: false,
    nombre: '',
    dni: '',
    catCarnet: '',
    fechaCarnet: '',
    lugarNavegacion: '',
    fechaRegresoAprox: '',
    horaAprox: '',
}

export const despachoClubesReducer = (state = initialState, action) => {

    switch (action.type) {

        // case types.despachoDatos:
        //     // console.log('Payload: ', action)
        //     return {
        //         ...state,
        //         ...action.payload
        //     }

        case types.despachoReset:
            return {
                ...initialState,
            };

        case types.despachoGetDB:
            // console.log(...action.payload)
            return {
                // ...state,
                embarcacionesDespachadas: [...action.payload],
            };

        case types.despachoPostDB:
            return {
                ...state,
            }

        case types.despachoDeleteDB:
            return {
                ...state,
            }

        default:
            return state;
    }

}
