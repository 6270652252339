import { useCallback, useMemo, useRef, useState } from "react"
import { Marker, Popup } from "react-leaflet"
import L from 'leaflet';

const DraggableMarker = ({ pos, setPos }) => {
    const [draggable, setDraggable] = useState(false)
    // const [position, setPosition] = useState(pos)
    const markerRef = useRef(null)
    const eventHandlers = useMemo(
        () => ({
            dragend() {
                const marker = markerRef.current
                if (marker != null) {
                    setPos(marker.getLatLng())
                }
            },
        }),
        [],
    )
    const toggleDraggable = useCallback(() => {
        setDraggable((d) => !d)
    }, [])

    const LocationIcon = (iconSize, iconColor) => {
        // return L.icon({
        //     iconUrl: 'http://leafletjs.com/examples/custom-icons/leaf-red.png',
        //     // shadowUrl: 'http://leafletjs.com/examples/custom-icons/leaf-shadow.png',
        //     // iconUrl: <div><span>jajajj</span></div>,
        //     // SearchIcon
        //     // iconUrl: LocationOnIcon,
        //     // iconRetinaUrl: LocationOnIcon,
        //     popupAnchor: [-100, -0],
        //     iconSize: [iconSize]
        // })

        return L.divIcon({
            html: `
        <svg
          viewBox="0 0 395.71 395.71"
          version="1.1"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M197.849,0C122.131,0,60.531,61.609,60.531,137.329c0,72.887,124.591,243.177,129.896,250.388l4.951,6.738
          c0.579,0.792,1.501,1.255,2.471,1.255c0.985,0,1.901-0.463,2.486-1.255l4.948-6.738c5.308-7.211,129.896-177.501,129.896-250.388
          C335.179,61.609,273.569,0,197.849,0z M197.849,88.138c27.13,0,49.191,22.062,49.191,49.191c0,27.115-22.062,49.191-49.191,49.191
          c-27.114,0-49.191-22.076-49.191-49.191C148.658,110.2,170.734,88.138,197.849,88.138z" fill="${iconColor}"></path>
        </svg>`,
            className: "",
            iconSize: [iconSize],
            iconAnchor: [17, 35],
            popupAnchor: [0, -30],
        })
    }

    return (
        <Marker
            draggable={draggable}
            eventHandlers={eventHandlers}
            position={pos}
            icon={LocationIcon(35, 'red')}
            ref={markerRef}>
            <Popup minWidth={90}>
                <span onClick={toggleDraggable}>
                    {draggable
                        ? 'El marcador ahora es arrastrable.'
                        : 'Click aquí para hacer el marcador arrastrable.'}
                </span>
            </Popup>
        </Marker>
    )
}

export default DraggableMarker;
