import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import CheckIcon from '@mui/icons-material/Check';
import { Alert } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';

import PageHeader from '../../components/PageHeader';
import { startRowsPerPage, startTablePageChange } from '../../actions/system';
import { deletePostUsuariosPendientes, savePostUsuariosPendientes, startGetUsuariosPendientes } from '../../actions/admin';
import { CardMedia } from '@mui/material';
import { PdfViewer } from '../../components/PdfViewer';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: 'nombre', disablePadding: true, label: 'Nombre y apellido' },
    { id: 'dni', disablePadding: false, label: 'DNI' },
    { id: 'telefono', disablePadding: false, label: 'Telefono' },
    { id: 'email', disablePadding: false, label: 'Email' },
    { id: 'rol', disablePadding: false, label: 'Rol' },
    { id: 'lugar', disablePadding: false, label: 'Lugar' },
    { id: 'categoria', disablePadding: false, label: 'Categoria Carnet' },
    { id: 'vencimiento', disablePadding: false, label: 'Vencimiento' },
    { id: 'carnet', disablePadding: false, label: 'Carnet timonel' },
];

function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        // align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.default.main,
                backgroundColor: theme.palette.secondary.main,
            },
    title: {
        flex: '1 1 100%',
    },
}));

const EnhancedTableToolbar = (props) => {
    const dispatch = useDispatch();
    const classes = useToolbarStyles();
    const { numSelected, arrayDataSelected, clearSelected } = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
            sx={{ borderTopLeftRadius: 10, borderTopRightRadius: 10, mt: 1 }}
        >
            {numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    {numSelected} registro/s seleccionado/s.
                </Typography>
            ) : (
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                    Listado de selección:
                </Typography>
            )}

            {numSelected > 0 && (
                <>
                    <Tooltip title="Rechazar">
                        <IconButton aria-label="delete"
                            onClick={() => { dispatch(deletePostUsuariosPendientes(arrayDataSelected)); clearSelected([]); }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Guardar">
                        <IconButton aria-label="confirmar"
                            onClick={() => { dispatch(savePostUsuariosPendientes(arrayDataSelected)); clearSelected([]); }}
                        >
                            <CheckIcon />
                        </IconButton>
                    </Tooltip>

                </>
            )}

        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    // root: {
    //     '& > *': {
    //       borderBottom: 'unset',
    //     },
    //   },
    root: {
        width: '100%',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    pageContent: {
        margin: theme.spacing(5),
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    table: {
        marginTop: theme.spacing(3),
        '& thead th': {
            fontWeight: '600',
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.light,
        },
        '& tbody td': {
            fontWeight: '300',
        },
        '& tbody tr:hover': {
            backgroundColor: '#fffbf2',
            cursor: 'pointer',
        },
    },

}));

const Pending = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);
    const pages = [5, 10, 25]
    const { page, rowsPerPage } = useSelector(state => state.system);
    // const [dense, setDense] = useState(false);
    const [dense] = useState(false);
    const { name } = useSelector(state => state.auth);
    const { usuarios } = useSelector(state => state.admin);

    // const [numPages, setNumPages] = useState(null);
    // const [pageNumber, setPageNumber] = useState(1);

    // function onDocumentLoadSuccess({ numPages }) {
    //   setNumPages(numPages);
    // }

    useEffect(() => {
        dispatch(startGetUsuariosPendientes())
    }, [dispatch])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = usuarios.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        dispatch(startTablePageChange(newPage))
    };

    const handleChangeRowsPerPage = (event) => {
        dispatch(startRowsPerPage(parseInt(event.target.value, 10)))
        dispatch(startTablePageChange(0))
    };

    // const handleChangeDense = (event) => {
    //     setDense(event.target.checked);
    // };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, usuarios.length - page * rowsPerPage);

    const openImage = (imageUrl) => {
        const windowName = `image_${Date.now()}`;
        window.open(imageUrl, windowName);
    };

    return (
        <div className={classes.root}>
            <PageHeader
                title="Usuarios pendientes"
                subTitle="Aceptar usuarios al sistema"
                icon={<DirectionsBoatIcon fontSize="large" />}
                profile={true}
                name={name}
            />
            {(usuarios.length === 0)
                ? (<Alert severity="info">No hay usuarios pendientes.</Alert>)
                : (<>
                    <Paper className={classes.pageContent}>
                        <EnhancedTableToolbar numSelected={selected.length} arrayDataSelected={selected} clearSelected={setSelected} />
                        <TableContainer>
                            <Table
                                className={classes.table}
                                aria-labelledby="tableTitle"
                                size={dense ? 'small' : 'medium'}
                                aria-label="enhanced table"
                            >
                                <EnhancedTableHead
                                    classes={classes}
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={handleSelectAllClick}
                                    onRequestSort={handleRequestSort}
                                    rowCount={usuarios.length}
                                />
                                <TableBody>
                                    {stableSort(usuarios, getComparator(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            const isItemSelected = isSelected(row.id);
                                            const labelId = `enhanced-table-checkbox-${index}`;

                                            return (
                                                <TableRow
                                                    hover
                                                    // onClick={(event) => handleClick(event, row.id)}
                                                    role="checkbox"
                                                    aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    key={row.id}
                                                    selected={isItemSelected}
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            checked={isItemSelected}
                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                            onClick={(event) => handleClick(event, row.id)}
                                                        />
                                                    </TableCell>
                                                    <TableCell component="th" id={labelId} scope="row" padding="none" onClick={(event) => handleClick(event, row.id)}>
                                                        {row.nombre} {row.apellido}
                                                    </TableCell>
                                                    {/* <TableCell>{JSON.stringify(row, null, 5)}</TableCell> */}
                                                    <TableCell onClick={(event) => handleClick(event, row.id)}>{row.telefono}</TableCell>
                                                    <TableCell onClick={(event) => handleClick(event, row.id)}>{row.dni}</TableCell>
                                                    <TableCell onClick={(event) => handleClick(event, row.id)}>{row.email}</TableCell>
                                                    <TableCell onClick={(event) => handleClick(event, row.id)}>{(row.rol === 'user' && row.desde !== 'clubes') && 'Prefectura' || (row.rol === 'user' && row.desde === 'clubes') && 'Club' || (row.rol === 'timonel') && 'Particular'}</TableCell>
                                                    <TableCell onClick={(event) => handleClick(event, row.id)}>{row.lugar}</TableCell>
                                                    <TableCell onClick={(event) => handleClick(event, row.id)}>{(row.rol === 'timonel') ? row.categoriaCarnet : '-'}</TableCell>
                                                    <TableCell onClick={(event) => handleClick(event, row.id)}>{(row.rol === 'timonel') ? row.vencimientoCarnet : '-'}</TableCell>
                                                    <TableCell>
                                                        {
                                                            (row.rol === 'timonel')
                                                                ? (`${process.env.REACT_APP_API_URL_FOR_IMAGES}/credentialsUsers/${row.imageCarnet}`.split('.').pop() === 'pdf')
                                                                    ? <PdfViewer
                                                                        sx={{ cursor: 'zoom-in' }}
                                                                        url={`${process.env.REACT_APP_API_URL_FOR_IMAGES}/credentialsUsers/${row.imageCarnet}`}
                                                                    />
                                                                    : <CardMedia
                                                                        component="img"
                                                                        sx={{ width: 180, height: 100, cursor: 'zoom-in' }}
                                                                        onClick={() => {
                                                                            openImage(`${process.env.REACT_APP_API_URL_FOR_IMAGES}/credentialsUsers/${row.imageCarnet}`)
                                                                        }}
                                                                        image={`${process.env.REACT_APP_API_URL_FOR_IMAGES}/credentialsUsers/${row.imageCarnet}`}
                                                                        alt="img_carnet"
                                                                    />
                                                                : '-'
                                                        }
                                                        {/* {
                                                            JSON.stringify(`${process.env.REACT_APP_API_URL_FOR_IMAGES}/credentialsUsers/${row.imageCarnet}`.split('.').pop(), null, 5)
                                                        } */}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            labelRowsPerPage='Filas por pagina:'
                            rowsPerPageOptions={pages}
                            component="div"
                            count={usuarios.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </>)
            }
        </div>
    );
}

export default Pending;