import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Grid, TableBody, TableRow, TableCell, Tooltip, Typography, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { makeStyles } from '@mui/styles';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import MySwal from 'sweetalert2'

import useTable from "../../components/useTable";
import Controls from '../../components/controls/Controls';
import { startGetPendientes, startNewBoatClub, startPendingBoatClubDelete } from '../../actions/embarcacionesClubes';
import ClubAgregarPendientes from './ClubAgregarPendientes';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(theme => ({
    sAlert: {
        zIndex: 9999999999
    },
    dialogWrapper: {
        padding: theme.spacing(2),

        minHeight: p => p.mobile
            ? '100vh'
            : 'unset'
    },
    dialogTitle: {
        padding: 0,
        // paddingRight: '0px',
        // zIndex: '10',
    }
}))

const headCells = [
    { id: 'fullName', label: 'Embarcacion pendiente' },
    { id: 'email', label: 'Matricula' },
    { id: 'mobile', label: 'Descripción' },
    { id: 'actions', label: 'Acciones', disableSorting: true }
]

const ClubPendientes = (props) => {

    const classes = useStyles();
    const Swal = MySwal.mixin({ customClass: { container: classes.sAlert }, })
    const dispatch = useDispatch();
    const { pendientes } = useSelector(state => state.embarcacionClub);
    const [filterFn] = useState({ fn: items => { return items; } })
    const [isOpen, setIsOpen] = useState(false);
    // const [name, setName] = useState('');
    // const [setName] = useState('');
    // const [id, setId] = useState();
    // const [setId] = useState();

    useEffect(() => {
        dispatch(startGetPendientes())
    }, [dispatch])

    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting
    } = useTable(pendientes, headCells, filterFn);

    // const handleSearch = e => {
    //     let target = e.target;
    //     setFilterFn({
    //         fn: items => {
    //             if (target.value == "")
    //                 return items;
    //             else
    //                 return items.filter(x => x.fullName.toLowerCase().includes(target.value))
    //         }
    //     })
    // }



    // #################################### DESPACHAR ITEM SWAL #########################


    // const reportarItem = (ittem, fullName) => {

    //     Swal.fire({
    //         title: '¿Estas seguro?',
    //         text: `Esta acción reportará la embarcación "${fullName}", para su revisión o remoción.`,
    //         icon: 'warning',
    //         // style: { zIndex: 9999 },
    //         showCancelButton: true,
    //         confirmButtonColor: '#3085d6',
    //         cancelButtonColor: '#d33',
    //         cancelButtonText: 'No, esto es un error!',
    //         confirmButtonText: 'Si, hazlo!'
    //     }).then((result) => {
    //         if (result.isConfirmed) {

    //             // const items = JSON.parse(localStorage.getItem('employees'));
    //             // const filtered = items.filter(item => item.id !== ittem);
    //             // localStorage.setItem('employees', JSON.stringify(filtered));
    //             // setRecords(employeeService.getAllEmployees())
    //             console.log('Esta embarcacion fue reportada', ittem)

    //             Swal.fire(
    //                 'Estado:',
    //                 'Esta embarcación fue reportada correctamente.',
    //                 'success'
    //             )
    //         }
    //     })

    // }


    const handleDispatchClick = (id) => {

        // ELIMINAR EMBARCACION PENDIENTE
        // console.log(id)
        // dispatch(startClosePopup());


        Swal.fire({
            title: 'Esta seguro que desea eliminar esta embarcación?',
            // text: 'Recuerde usted es responsable por el despacho de esta embarcación!',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#28a745',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, estoy seguro!',
            cancelButtonText: 'Cancelar',
        }).then((result) => {

            if (result.isConfirmed) {
                dispatch(startPendingBoatClubDelete(id));

                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2500,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'success',
                    title: 'Esta embarcación se elimino correctamente.'
                })
            }
        });
    }

    // const despacharItem = (idBoat, nombreBoat) => {
    //     setId(idBoat);
    //     setName(nombreBoat);
    //     setIsOpen(true);
    // }

    const processFormData = (boat, resetForm) => {

        //   dispatch(startClosePopup());

        dispatch(startNewBoatClub(boat));
        resetForm()
        setIsOpen(false);

    }

    return (
        <>
            <Grid
                container
                // spacing={1}
                direction="row"
                xs={12}
                // justifyContent="space-between"
                justifyContent="flex-end"
                alignItems="center"
            >
                {/* <Grid item> */}
                <Controls.Button
                    text="Agregar embarcación"
                    variant="outlined"
                    startIcon={<AddIcon />}
                    // className={classes.searchButton}
                    onClick={() => { setIsOpen(true) }}
                />
                {/* </Grid> */}
            </Grid>
            <Grid container>
                <Grid item xs={12}>


                    <TblContainer>
                        <TblHead />
                        <TableBody>
                            {
                                recordsAfterPagingAndSorting().map(item =>
                                (<TableRow key={item.id}>
                                    <TableCell>{item.nombre}</TableCell>
                                    <TableCell>
                                        {
                                            (item.inscripcion === 'REY' || item.inscripcion === 'E/T')
                                                ? `${item.inscripcion}${item.matricula}`
                                                : `${item.cuatrigrama}${item.matricula}`
                                        }
                                    </TableCell>
                                    <TableCell>{item.desc}</TableCell>
                                    {/* <TableCell>{item.department}</TableCell> */}
                                    <TableCell>
                                        <Controls.ActionButton
                                            color="secondary"
                                            onClick={() => { handleDispatchClick(item.id) }}
                                        // onClick={() => { despacharItem(item.id, item.nombre) }}
                                        // disabled={true}
                                        >
                                            {/* // onClick={() => { console.log(item.id) }}> */}
                                            <Tooltip title="Eliminar">
                                                <CloseIcon fontSize="small" />
                                            </Tooltip>
                                        </Controls.ActionButton>
                                        {/* <Controls.ActionButton
                                            color="secondary"
                                            onClick={() => { reportarItem(item.id, item.fullName) }}>
                                            <Tooltip title="Reportar">
                                                <CloseIcon fontSize="small" />
                                            </Tooltip>
                                        </Controls.ActionButton> */}
                                    </TableCell>
                                </TableRow>)
                                )
                            }
                        </TableBody>
                    </TblContainer>
                    <TblPagination />

                </Grid>
            </Grid>

            <Dialog
                // open={statusPopupAlternativo}
                open={isOpen}
                maxWidth='md'
                sx={{
                    padding: 2,
                    minHeight: (isMobile) ? '100vh' : 'unset'
                }}
            >
                <DialogTitle
                    sx={{
                        ml: 2,
                        padding: 1,
                        // zIndex: 10
                    }}
                >
                    <div style={{ display: 'flex' }}>
                        <Typography mt={1} variant="h6" component="div" style={{ flexGrow: 1 }}>
                            Agregando embarcación...
                        </Typography>
                        <Controls.ActionButton
                            color="secondary"
                            // onClick={() => { dispatch(closePopupAlternativo()) }}>
                            onClick={() => { setIsOpen(false) }}>
                            <CloseIcon />
                        </Controls.ActionButton>
                    </div>
                </DialogTitle>
                <DialogContent dividers>
                    {/* <EmbarcacionesForm recordForEdit={recordForEdit} addOrEdit={addOrEdit} /> */}
                    <ClubAgregarPendientes saveDataPendientes={processFormData} />
                    {/* <SumarioFormCarga idBoat={contravencionId} saveData={processData} /> */}
                </DialogContent>
            </Dialog>
        </>
    )
}

export default ClubPendientes;