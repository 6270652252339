import React from 'react';
import { useSelector } from 'react-redux';
import {
    Routes,
    Navigate,
    Route
} from 'react-router-dom';
import EmbarcacionesClub from '../pages/ClubNautico/EmbarcacionesClub';
import { MainDashboard } from '../pages/Prefectura/Dashboard/MainDashboard';
import Embarcaciones from '../pages/Prefectura/Embarcaciones';
import ListadoEmbarcaciones from '../pages/Prefectura/OperativoSenade/ListadoEmbarcaciones';
import ListadoEmbarcacionesVisor from '../pages/Prefectura/Visor/ListadoEmbarcacionesVisor';
import SumarioScreen from '../pages/Sumario/SumarioScreen';
import { AuthorizedRoute } from './AuthorizedRoute';

export const DashboardUserRoute = () => {
    const { from } = useSelector(state => state.auth);

    return (
        <Routes>

            {/* <AuthorizedRoute exact path="/prefectura" component={
                (width > 600) ? Embarcaciones : RestrictPortrait
            } isAuthorized={from} isAdmin={role} /> */}

            <Route
                end
                path='/dashboard'
                element={
                    <MainDashboard />
                }
            />

            <Route
                end
                path='/polinave'
                element={
                    <AuthorizedRoute>
                        <Embarcaciones />
                    </AuthorizedRoute>
                }
            />

            <Route
                end
                path='/operativo'
                element={
                    <AuthorizedRoute>
                        <ListadoEmbarcaciones />
                    </AuthorizedRoute>
                }
            />

            <Route
                end
                path='/visor'
                element={
                    <AuthorizedRoute>
                        <ListadoEmbarcacionesVisor />
                    </AuthorizedRoute>
                }
            />

            <Route
                end
                path='/sumarios'
                element={
                    <AuthorizedRoute>
                        <SumarioScreen />
                    </AuthorizedRoute>
                }
            />

            <Route
                end
                path='/clubes'
                element={
                    <AuthorizedRoute>
                        <EmbarcacionesClub />
                    </AuthorizedRoute>}
            />

            <Route
                path='/*'
                element={<Navigate to={(from === 'clubes') ? `/gestion/clubes` : '/gestion/dashboard'} />}
            />

        </Routes>
    )
}
