import React, { Suspense, useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { Grid, TableBody, TableRow, TableCell, Tooltip, Box, Typography, Dialog, DialogTitle, DialogContent, Table, TableHead } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import Controls from '../../components/controls/Controls';
import { startClosePopup, startRowsPerPage, startTablePageChange } from '../../actions/system';
import { contravencionId, startContravencionDelete, startContravencionPut, startContravencionUsuarioTimonelGet } from '../../actions/embarcacionesSumario';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { TablePagination } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { SumarioFormCarga } from './SumarioFormCarga';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(theme => ({
    searchInput: {
        width: '50%'
    },
    searchButton: {
        position: 'absolute',
        right: '10px',
    },
    dialogWrapper: {
        padding: theme.spacing(2),
        minHeight: p => p.mobile
            ? '100vh'
            : 'unset'
    },
    dialogTitle: {
        padding: 0,
        zIndex: '10',
    },
    table: {
        marginTop: theme.spacing(3),
        '& thead th': {
            fontWeight: '600',
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.light,
        },
        '& tbody td': {
            fontWeight: '300',
        },
        '& tbody tr:hover': {
            backgroundColor: '#fffbf2',
            cursor: 'pointer',
        },
    },
    tableColor: {
        backgroundColor: '#f0f0f0',
        borderRadius: 10
    },
}))

const Row = (props) => {
    const { row, popupAlternativo } = props;
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const classes = useStyles();

    const deleteRegistro = (id) => {
        Swal.fire({
            title: '¿Estas seguro?',
            text: "Esta acción no se puede revertir!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No, conservalo!',
            confirmButtonText: 'Si, eliminalo!'
        }).then((result) => {
            if (result.isConfirmed) {

                Swal.fire({
                    title: 'Motivo de eliminacion',
                    icon: 'question',
                    input: 'text',
                    inputLabel: 'Escriba aquí',
                    inputValue: '',
                    confirmButtonColor: '#28a745',
                    confirmButtonText: 'Ok, quiero continuar!',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar',
                    showCancelButton: true,
                    inputValidator: (value) => {
                        if (!value) {
                            return 'Debes escribir el motivo!'
                        }
                    }
                }).then((result) => {
                    if (result.isConfirmed) {

                        dispatch(startContravencionDelete(id, result.value))

                    } else {
                        Swal.fire(
                            'Error...',
                            'Es necesario indicar el motivo de la eliminación del registro.',
                            'error'
                        )
                    }
                });
            }
        })
    }


    return (
        <>
            <TableRow className={classes.root}>
                {
                    (row.modificadoPor !== "" && row.modificadoPor !== undefined && row.modificadoPor !== null)
                        ? <TableCell>
                            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </TableCell>
                        : <TableCell></TableCell>
                }

                <TableCell component="th" scope="row">
                    {row.contravencion}
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.numActa}
                </TableCell>
                <TableCell align="right">{row.funcionario}</TableCell>
                <TableCell align="right">{row.createdAt}</TableCell>
                <TableCell align="right">
                    {
                        (row.estado === true)
                            ? <>
                                <Controls.ActionButton
                                    color="third"
                                    onClick={() => { dispatch(contravencionId(row.id)); popupAlternativo(true); }}
                                >
                                    <Tooltip title="Editar">
                                        <EditIcon fontSize="small" />
                                    </Tooltip>
                                </Controls.ActionButton>
                                <Controls.ActionButton
                                    color="secondary"
                                    onClick={() => { dispatch(startClosePopup()); deleteRegistro(row.id); }}
                                >
                                    <Tooltip title="Eliminar">
                                        <CloseIcon fontSize="small" />
                                    </Tooltip>
                                </Controls.ActionButton>
                            </>
                            : 'Registro eliminado'
                    }
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell className={classes.tableColor} style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Mofificado por:</TableCell>
                                        <TableCell>Fecha modif:</TableCell>
                                        <TableCell align="right">Motivo</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            {/* {row.datosDespachos.titular ? 'Si' : 'No'} */}
                                            {row.modificadoPor}
                                        </TableCell>
                                        <TableCell>{row.modificadoFecha}</TableCell>
                                        <TableCell align="right">{row.modificadoMotivo}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

const SumarioFormTimonel = ({ record, userTimonel }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const pages = [5, 10, 25]
    const { page, rowsPerPage } = useSelector(state => state.system);
    const { recordForEdit } = useSelector(state => state.embarcacion);
    const { timonelesContravenciones, timonelesUsuariosContravenciones, contravencionId } = useSelector(state => state.sumario);
    const [isOpen, setIsOpen] = useState(false);

    const handleChangePage = (event, newPage) => {
        dispatch(startTablePageChange(newPage))
    }

    const handleChangeRowsPerPage = event => {
        dispatch(startRowsPerPage(parseInt(event.target.value, 10)))
        dispatch(startTablePageChange(0))
    }

    const processData = (item) => {
        setIsOpen(false);
        const { numActa, ...rest } = item;
        dispatch(startContravencionPut(recordForEdit.id, contravencionId, rest, 'timonel'))
    }

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <div>
                        <Table className={classes.table} aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell>Contravencion</TableCell>
                                    <TableCell>N° Acta</TableCell>
                                    <TableCell align="right">Funcionario</TableCell>
                                    <TableCell align="right">Fecha</TableCell>
                                    <TableCell align="right">Acciones</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    (userTimonel)
                                        ? timonelesUsuariosContravenciones && timonelesUsuariosContravenciones.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                            ((row.resultado === true) && (row.asignacionContravencion === 'timonel')) && <Row key={row.id} row={row} popupAlternativo={setIsOpen} />
                                        ))
                                        : timonelesContravenciones && timonelesContravenciones.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                            ((row.resultado === true) && (row.asignacionContravencion === 'timonel')) && <Row key={row.id} row={row} popupAlternativo={setIsOpen} />
                                        ))
                                    }
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            page={page}
                            labelRowsPerPage='Filas por pagina:'
                            rowsPerPageOptions={pages}
                            rowsPerPage={rowsPerPage}
                            count={
                                (userTimonel)
                                    ? timonelesUsuariosContravenciones.length
                                    : timonelesContravenciones.length
                            }
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                </Grid>
            </Grid>
            <Dialog
                open={isOpen}
                maxWidth='sm'
                // classes={{ paper: classes.dialogWrapper }}
                sx={{
                    padding: 2,
                    minHeight: (isMobile) ? '100vh' : 'unset'
                }}
            >
                <DialogTitle
                    sx={{
                        ml: 2,
                        padding: 1,
                        // zIndex: 10
                    }}
                >
                    <div style={{ display: 'flex' }}>
                        <Typography variant="h6" mt={1} component="div" style={{ flexGrow: 1 }}>
                            Editar contravenciones...
                        </Typography>
                        <Controls.ActionButton
                            color="secondary"
                            onClick={() => { setIsOpen(false) }}>
                            <CloseIcon />
                        </Controls.ActionButton>
                    </div>
                </DialogTitle>
                <DialogContent dividers>
                    <SumarioFormCarga saveData={processData} />
                </DialogContent>
            </Dialog>
        </>
    )
}

export default SumarioFormTimonel;