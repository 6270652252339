import { Autocomplete, Avatar, Box, Button, CircularProgress, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { startListDependenciasDB } from '../../actions/embarcacionesPrefectura'
import Controls from '../../components/controls/Controls'
import { Form, useForm } from '../../components/useForm'
import MySwal from 'sweetalert2'
import { makeStyles } from '@mui/styles';
import { PdfViewer } from '../../components/PdfViewer';
import { startListClubesDB } from '../../actions/embarcacionesClubes';
import { startAllEmbarcacionesGetToLoadAddRemoveBoatsTimonel } from '../../actions/embarcacionesTimonel';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import { previousDay } from 'date-fns/fp';
import { fetchConToken } from '../../services/fetch';

/* 
amarre: "Yacht"
capacidadPersoas: "54"
cuatrigrama: null
despachada: false
eslora: "545"
estado: true
fechaAlta: Date Thu Jul 20 2023 10:30:38 GMT-0300 (hora estándar de Argentina)
imgEmbarcacion: Object { imagePreview: "blob:http://localhost:3000/8f624ac4-6979-4103-8452-fe6d588b8a46", fileToUpload: File }
imgMatricula: File { name: "papers.png", lastModified: 1689163830607, size: 7512, … }
inscripcion: "REY"
lugar: "SAFE"
manga: "545"
matricula: "43654"
nombre: "LA EMIR"
pendiente: true
prohibicion: false
propietario: "true"
puntal: "54"
*/

// const matriculaRegex = /^PARA-\d{10}\/\d{4}$/;
// const matriculaRegex = /^PARA-\d{1,10}\/\d{4}$/;
// const matriculaRegex = /^[a-zA-Z]{4}-\d{1,10}\/\d{4}$/;
const matriculaRegex = /^[A-Z]{4}-\d{1,10}\/\d{4}$/;

const initialFValues = {
  inscripcion: '',
  cuatrigrama: '',
  matricula: '',
  nombre: '',
  // eslora: '',
  // manga: '',
  // puntal: '',
  titular: true,
  amarre: '',
  // capacidadPersonas: '',
  fechaAlta: new Date(),
  estado: true,
  prohibicion: false,
  despachada: false,
  pendiente: true,
}

const inscripcionItems = [
  { id: 'REY', title: 'REY' },
  { id: 'REJU', title: 'REJU' },
  { id: 'E/T', title: 'E/T' },
]

const propietarioItems = [
  { id: true, title: 'Titular' },
  { id: false, title: 'Autorizado' },
]

const useStyles = makeStyles(theme => ({
  sAlert: {
    zIndex: 9999999999
  },
}))

export const EmbarcacionesForm = ({ saveDataAddBoat }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const Swal = MySwal.mixin({ customClass: { container: classes.sAlert }, })
  const { clubes = [], dependencias = [] } = useSelector(state => state.admin);
  const { allBoats = [], embarcaciones = [] } = useSelector(state => state.embarcacionTimonel);
  const [embarcacion, setEmbarcacion] = useState({ imagePreview: null, file: null })
  const [matricula, setMatricula] = useState({ imagePreview: null, file: null })
  const [disabledInput, setDisabledInput] = useState(true);
  const [disabledSubmitButton, setDisabledSubmitButton] = useState(true);
  const [filterBoats, setFilterBoats] = useState([]);
  const [autoCompleteMatricula, setAutoCompleteMatricula] = useState('');

  // const getBoatsByInscripcionyCuatrigrama = async (inscripcion, cuatrigrama) => {
  //   // ESTO ESTA ACA PORQUE ES AL PEDO PONERLO EN REDUX PORQUE NO LO NECESITO A LO LARGO DE LA APP
  //   // console.log(inscripcion, cuatrigrama)
  //   const data = { 
  //     inscripcion,
  //     cuatrigrama: (inscripcion === 'REY') ? null : cuatrigrama
  //   }
  //   try {
  //     const resp = await fetchConToken(`embarcaciones/prefectura/searchallboats`, data);
  //     const body = await resp.json();
  //     if (body.ok) {
  //       console.log(body.boats)
  //     } else {
  //       Swal.fire('Error', body.msg, 'error')
  //     }
  //   } catch (error) {
  //     // console.log('Error de conexión. Contactese con el administrador.')
  //     Swal.fire('Error', 'Error de conexión. Contactese con el administradorr.', 'error')
  //   }
  // }

  useEffect(() => {
    dispatch(startListDependenciasDB());
    dispatch(startListClubesDB());
  }, [])

  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    if ('nombre' in fieldValues)
      temp.nombre = fieldValues.nombre.length !== 0
        ? ""
        : "Nombre es requerido."

    if ('cuatrigrama' in fieldValues)
      temp.cuatrigrama = (fieldValues.cuatrigrama)
        ? ""
        : "Cuatrigrama es requerido."

    // if ('inscripcion' in fieldValues) {
    //   if (fieldValues.inscripcion === 'REY' || fieldValues.inscripcion === 'REJU') {
    //     temp.inscripcion = fieldValues.inscripcion
    //       ? ""
    //       : "Inscripción es requerida.";
    //   } else if (fieldValues.inscripcion === 'E/T') {
    //     temp.inscripcion = "";
    //   } else {
    //     temp.inscripcion = "Inscripción es requerida.";
    //   }
    // }

    if ('inscripcion' in fieldValues) {
      if (fieldValues.inscripcion === 'REY' || fieldValues.inscripcion === 'E/T') {
        delete temp.cuatrigrama
        temp.inscripcion = fieldValues.inscripcion ? "" : "Inscripción es requerido."
      } else {
        temp.inscripcion = fieldValues.inscripcion ? "" : "Inscripción es requerido."
      }
    }

    if ('matricula' in fieldValues) {
      if (values.inscripcion !== 'E/T') {
        temp.matricula = fieldValues.matricula.length !== 0
          ? (/^[0-9]*$/).test(fieldValues.matricula)
            ? (fieldValues.matricula.length >= 1 && fieldValues.matricula.length <= 6)
              ? ""
              : "La matricula debe contener al menos 1 caracter y como máximo 6 caracteres."
            : "Matricula solo puede contener números."
          : "Matricula es requerido."
      } else {
        // delete temp.matricula
        // temp.matricula = fieldValues.matricula ? "" : "Inscripción es requerido."

        temp.matricula = fieldValues.matricula.length !== 0
          ? matriculaRegex.test(fieldValues.matricula)
            ? (fieldValues.matricula.length >= 11 && fieldValues.matricula.length <= 20)
              ? ""
              : "La expediente debe contener al menos 11 caracter y como máximo 20 caracteres."
            : `El formato del expediente debe ser similar al siguiente ejemplo: "SAFE-1234567899/2023".`
          : "Expediente es requerido."
      }
    }



    // if ('matricula' in fieldValues)
    //   temp.matricula = fieldValues.matricula.length !== 0
    //     ? (/^[0-9]*$/).test(fieldValues.matricula)
    //       ? (fieldValues.matricula.length >= 0 && fieldValues.matricula.length <= 6)
    //         ? ""
    //         : "La matricula debe contener 1 caracter como minimo y 6 caracteres como maximo."
    //       : "Matricula solo puede contener numeros."
    //     : "Matricula es requerido."

    if ('titular' in fieldValues)
      temp.titular = fieldValues.titular ? "" : "Propietario es requerido."

    if ('amarre' in fieldValues)
      temp.amarre = fieldValues.amarre.length !== 0
        ? ""
        : "Amarre es requerido."
    setErrors({ ...temp })
    if (fieldValues === values)
      return Object.values(temp).every(x => x === "")
  }

  const {
    values,
    setValues,
    customValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm
  } = useForm(initialFValues, true, validate);

  const embarcacionChange = (e) => {
    // console.log(e.target.files[0].name)
    if (e.target.files[0].name) {
      var parts = e.target.files[0].name.split('.');
      // if (parts[parts.length - 1].toLowerCase() === 'png' || parts[parts.length - 1].toLowerCase() === 'jpeg') {
      if (['png', 'jpg', 'jpeg'].includes(parts[parts.length - 1].toLowerCase())) {
        setEmbarcacion({ imagePreview: URL.createObjectURL(e.target.files[0]), fileToUpload: e.target.files[0] })
        return true;
      } else {
        setEmbarcacion({ imagePreview: null, fileToUpload: null })
        Swal.fire('Error', 'Error en la extension de archivo, solo se admitirá jpg, jpeg, png.', 'error');
        return false;
      }
    } else {
      return true;
    }
    // setEmbarcacion({ imagePreview: URL.createObjectURL(e.target.files[0]), fileToUpload: e.target.files[0] })
  }

  const matriculaChange = (e) => {
    // console.log(e.target.files[0].name)
    if (e.target.files[0].name) {
      var parts = e.target.files[0].name.split('.');
      // if (parts[parts.length - 1].toLowerCase() === 'png' || parts[parts.length - 1].toLowerCase() === 'jpeg') {
      if (['png', 'jpg', 'jpeg', 'pdf'].includes(parts[parts.length - 1].toLowerCase())) {
        setMatricula({ imagePreview: URL.createObjectURL(e.target.files[0]), fileToUpload: e.target.files[0] })
        return true;
      } else {
        setMatricula({ imagePreview: null, fileToUpload: null })
        Swal.fire('Error', 'Error en la extension de archivo, solo se admitirá jpg, jpeg, png y pdf.', 'error');
        return false;
      }
    } else {
      return true;
    }
    // setMatricula({ imagePreview: URL.createObjectURL(e.target.files[0]), fileToUpload: e.target.files[0] })
  }

  const handleAdd = (e) => {
    e.preventDefault();
    // console.log(errors)
    if (validate()) {

      if (!values.id) {
        if (!embarcacion.fileToUpload) return Swal.fire('Error', 'Es un requerimiento la carga de la imagen de su embarcación.', 'error');
        if (!matricula.fileToUpload) return Swal.fire('Error', 'Es un requerimiento la carga de la imagen del certificado de matricula.', 'error');

        saveDataAddBoat({
          ...values,
          nombre: values.nombre.toUpperCase(),
          cuatrigrama: (values.inscripcion === 'REY' || values.inscripcion === 'E/T') ? null : values.cuatrigrama,
          imgEmbarcacion: embarcacion,
          imgMatricula: matricula.fileToUpload,
          titular: (values.titular === true || values.titular === 'true') ? true : false
          // titular: values.titular
        })
        // console.log(values)
        // console.log(dataAddB)
        return;
      }
      saveDataAddBoat({
        ...values,
        titular: (values.titular === true || values.titular === 'true') ? true : false
      })
      // console.log(values)
      // console.log('validate ok!')
    }
  }

  useEffect(() => {
    // console.log(values)
    if (values.matricula.length === 0 || errors.matricula) {
      setDisabledInput(true)
      setDisabledSubmitButton(true)
    } else {
      if (filterBoats.filter(e => e.matricula === values.matricula).length !== 0) {
        // console.log(filterBoats.filter(e => e.matricula === values.matricula))
        // console.log('sipiii')
        setDisabledInput(true);
        setDisabledSubmitButton(false);
        // console.log(filterBoats.filter(e => e.matricula === values.matricula));
        // values.nombre = 'jaja'

        // console.log(errors)

        // setErrors({
        //   ...errors,
        //   nombre: ''
        // })
        // // validate({
        // //   nombre: values.nombre
        // // })
        // // if (validate()) {
        // validate()

        // console.log(errors, values)
        // console.log(filterBoats)

        setValues({
          ...filterBoats.filter(e => e.matricula === values.matricula)[0],
          titular: true
        })

        setErrors({
          ...errors,
          nombre: '',
          // titular: '',
          amarre: ''
        })

        // console.log(errors, values)

        // return;
      } else {
        setDisabledInput(false)
        setDisabledSubmitButton(false)

        setValues({
          // ...values,

          inscripcion: values.inscripcion,
          matricula: autoCompleteMatricula,

          nombre: '',
          titular: true,
          amarre: ''
        })
      }
    }
  }, [values.matricula])

  useEffect(() => {
    if (values.inscripcion === 'REY') {
      const newREYBoats = allBoats.filter(item1 =>
        (item1.inscripcion === 'REY' || item1.inscripcion === 'E/T') && !embarcaciones.some(item2 => item1.id === item2.id)
      );
      // const filteredArray1 = allBoats.filter(item1 => !embarcaciones.some(item2 => item1.id === item2.id));
      setFilterBoats(newREYBoats);
    } else if (values.inscripcion === 'REJU') {
      const newREJUBoats = allBoats.filter(item1 =>
        item1.inscripcion === 'REJU' && item1.cuatrigrama === values.cuatrigrama && !embarcaciones.some(item2 => item1.id === item2.id)
      );
      setFilterBoats(newREJUBoats);
    } else if (values.inscripcion === 'E/T') {
      setFilterBoats([]);
    }
  }, [values.inscripcion, values.cuatrigrama])

  // console.log(filterBoats)
  // console.log(autoCompleteMatricula)
  // console.log(values.matricula)

  return (
    <>
      <Form onSubmit={handleAdd}>
        <Grid container sx={{ mt: 1, mb: 1 }}>
          <Grid item xs={6}>
            <Controls.RadioGroup
              name="inscripcion"
              label="Su embarcación pertenece a:"
              value={values.inscripcion}
              onChange={handleInputChange}
              items={inscripcionItems}
              error={errors.inscripcion}
            // onClick={() => { (values.rFrom === 'club') ? values.rSelectPrefe = [] : values.rSelectClub = '' }}
            />
            {
              (values.inscripcion === 'REJU') &&
              <FormControl error={errors.cuatrigrama}>
                <InputLabel>Cuatrigrama</InputLabel>
                <Select
                  name="cuatrigrama"
                  label="Cuatrigrama"
                  value={values.cuatrigrama}
                  onChange={handleInputChange}

                // renderValue={(value) => `⚠️  - ${value}`}
                >
                  {dependencias.map(
                    item => (<MenuItem key={item.id} value={item.cuatrigrama}>{item.cuatrigrama} - {item.nombre}</MenuItem>)
                  )}
                </Select>
                {errors.cuatrigrama && <FormHelperText>{errors.cuatrigrama}</FormHelperText>}
              </FormControl>
            }

            <Controls.AutocompleteInput
              name="matricula"
              // label="Matricula*"
              label={(values.inscripcion === 'E/T') ? 'Expediente*' : 'Matricula*'}
              value={values.matricula}
              setValue={customValues}
              setValues={setValues}
              onChange={handleInputChange}
              resetForm={resetForm}
              errors={errors}
              setErrors={setErrors}
              valueMatricula={setAutoCompleteMatricula}
              fixedValueMatricula={autoCompleteMatricula}
              // options={allBoats}
              options={filterBoats}
              error={errors.matricula}
              disabled={(values.inscripcion === '') ? true : false}
            />

            <Controls.Input
              name="nombre"
              label="Nombre*"
              value={values.nombre.toUpperCase()}
              onChange={handleInputChange}
              disabled={disabledInput}
              autoFocus
              error={errors.nombre}
            // className={classes.RegisterTextField}
            />
          </Grid>
          <Grid item xs={6}>
            <Controls.RadioGroup
              name="titular"
              label="Es usted:"
              value={values.titular}
              onChange={handleInputChange}
              items={propietarioItems}
              // disabled={disabledInput}
              error={errors.titular}
            />
            <Controls.Select
              name="amarre"
              label="Lugar de amarre"
              value={values.amarre}
              onChange={handleInputChange}
              options={clubes}
              disabled={disabledInput}
              // options={clubes.filter(obj => obj.nombre !== 'Propio')}
              error={errors.amarre}
            />
            <Box sx={{ p: 2 }}>
              <Grid container flexDirection={'row'}>
                <Grid item container>
                  <Typography
                    sx={{ ml: 1 }}
                    color={'#686769'}
                  > Carga de Imagenes:</Typography>
                </Grid>
                <Grid item container flexDirection={'row'}>

                  <Grid item>
                    <Button sx={{ m: 0.5, textTransform: 'none' }}
                      size={"large"}
                      variant="contained"
                      component="label"
                      disabled={disabledInput}
                    >
                      <Typography sx={{ position: 'relative', bottom: -2 }}>
                        Embarcación
                      </Typography>
                      <input
                        hidden
                        // accept="image/*"
                        accept="image/png, image/jpg, image/jpeg"
                        // multiple
                        type="file"
                        onChange={embarcacionChange}
                      />
                    </Button>
                  </Grid>

                  <Grid item container sm justifyContent={'flex-end'}>
                    {
                      (embarcacion.imagePreview) &&
                      // <Box sx={{height: 50, width: 50}}>
                      <img
                        style={{ width: 120, height: 100, objectFit: 'contain' }}
                        src={embarcacion.imagePreview}
                        // style={styles.image}
                        alt="Thumb"
                      />
                      // </Box>
                    }
                  </Grid>
                </Grid>

                {
                  (!values.titular || values.titular === 'false') &&
                  <Typography
                    sx={{ color: 'red' }}
                  >
                    Usted selecciono "Autorizado", por lo tanto debera anexar dentro del archivo pdf de certificado de matricula, el documento de actuación notarial, caso contrario no se aceptará esta carga.
                  </Typography>
                }
                <Grid item container flexDirection={'row'}>

                  <Grid item>
                    <Button sx={{ m: 0.5, textTransform: 'none' }}
                      size={"large"}
                      variant="contained"
                      component="label"
                      disabled={disabledInput}
                    >
                      <Typography sx={{ position: 'relative', bottom: -2 }}>
                        Certificado matricula
                      </Typography>
                      <input
                        hidden
                        // accept="image/*"
                        accept="image/png, image/jpg, image/jpeg"
                        // multiple
                        type="file"
                        onChange={matriculaChange}
                      />
                    </Button>
                  </Grid>

                  <Grid item container sm justifyContent={'flex-end'}>
                    {
                      (matricula.imagePreview)
                        ? (matricula.fileToUpload.name.split('.').pop() === 'pdf')
                          ? <PdfViewer
                            sx={{ cursor: 'zoom-in' }}
                            url={matricula.imagePreview}
                          />
                          : <img
                            style={{ width: 120, height: 100, objectFit: 'contain' }}
                            src={matricula.imagePreview}
                            // style={styles.image}
                            alt="Thumb"
                          />
                        : null
                    }

                    {/* {
                      (matricula.imagePreview) &&
                      // <Box sx={{height: 50, width: 50}}>
                      <img
                        style={{ width: 120, height: 100, objectFit: 'contain' }}
                        src={matricula.imagePreview}
                        // style={styles.image}
                        alt="Thumb"
                      />
                      // </Box>
                    } */}
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            <Controls.Button
              text="Reset"
              color='default'
              onClick={() => {
                resetForm();
                setAutoCompleteMatricula('')
                setEmbarcacion({ imagePreview: null, file: null });
                setMatricula({ imagePreview: null, file: null });
              }}
            // disabled={disabledInput}
            />
            <Controls.Button
              type="submit"
              text="Agregar"
              disabled={disabledSubmitButton}
            />

            {/* <Box sx={{ mt: .5, ml: .5 }}>
              <Controls.Button
                text="Embarcación"
              // variant="outlined"
              // type="submit"
              // startIcon={<PersonAddIcon />}
              // className={classes.centerButton}
              />
            </Box>
            <Box sx={{ mt: .5, ml: .5 }}>
              <Controls.Button
                text="Certificado matricula"
              // variant="outlined"
              // type="submit"
              // startIcon={<PersonAddIcon />}
              // className={classes.centerButton}
              />
            </Box> */}

          </Grid>
        </Grid>
      </Form>
    </>
  )
}
