import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { TableBody, TableRow, TableCell } from '@mui/material';

import useTable from "../../components/useTable";
import { startGetBoatsAdespachar } from '../../actions/embarcacionesClubes';

// const useStyles = makeStyles(theme => ({
//     sAlert: {
//         zIndex: 9999999999
//     },
// }))

const headCells = [
    { id: 'nombre', label: 'Embarcacion' },
    { id: 'mat', label: 'Matricula' },
    { id: 'desc', label: 'Descripción' },
    { id: 'capacidad', label: 'Max. Capacidad' },
    { id: 'carnet', label: 'Cat. Carnet' }
]

const TableWithStadisticsTotal = (props) => {
    // const classes = useStyles();
    // const Swal = MySwal.mixin({ customClass: { container: classes.sAlert }, })
    // const dispatch = useDispatch();
    const { embarcaciones = [], totalityNumber = [] } = useSelector(state => state.embarcacionClub);
    const [filterFn] = useState({ fn: items => { return items; } })
    // const arr = embarcaciones.concat(totalityNumber)
    const arr = totalityNumber;

    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting
    } = useTable(arr, headCells, filterFn);

    return (
        <>
            {/* <Grid container> */}
            {/* <Grid item xs={12}> */}
            <TblContainer>
                <TblHead />
                <TableBody>
                    {
                        recordsAfterPagingAndSorting().map(item =>
                        // {
                        //     console.log(item)
                        // })
                        (
                            <TableRow key={item.id}>
                                <TableCell>{item.nombre}</TableCell>
                                <TableCell>
                                    {
                                        (item.inscripcion === 'REY' || item.inscripcion === 'E/T')
                                            ? `${item.inscripcion} ${item.matricula}`
                                            : `${item.cuatrigrama} ${item.matricula}`
                                    }
                                </TableCell>
                                <TableCell>{item.desc}</TableCell>
                                <TableCell>{item.capacidadPersonas}</TableCell>
                                <TableCell>{item.categoriaCarnet}</TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </TblContainer>
            <TblPagination />
            {/* </Grid> */}
            {/* </Grid> */}
        </>
    )
}

export default TableWithStadisticsTotal;