import Input from "./Input";
import RadioGroup from "./RadioGroup";
import Select from "./Select";
import Checkbox from "./Checkbox";
import DatePickerControl from "./DatePickerControl";
import DateTimepicker from "./DateTimePicker";
import Button from "./Button";
import ActionButton from "./ActionButton";
import AutocompleteInput from "./AutocompleteInput";

const Controls = {
    Input,
    RadioGroup,
    Select,
    Checkbox,
    DatePickerControl,
    DateTimepicker,
    Button,
    ActionButton,
    AutocompleteInput
}

export default Controls;