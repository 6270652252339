import React, { useState, useEffect, useRef } from 'react'
import { Grid, TableBody, TableRow, TableCell, Toolbar, InputAdornment, Box, Table, TableHead } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useDispatch, useSelector } from 'react-redux';
import { changeRadioOptionCarnet, startGetBoatsCarnet, startGetTimonelCarnet } from '../../actions/embarcacionesPrefectura';
import Controls from '../../components/controls/Controls';
import { startRowsPerPage, startTablePageChange } from '../../actions/system';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { TablePagination } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Search } from "@mui/icons-material";
import Label from '../../components/tableLabelBadge/Label';
import { useForm } from '../../components/useForm';

const useStyles = makeStyles(theme => ({

    searchInput: {
        width: '100%'
    },
    searchButton: {
        position: 'absolute',
        right: '10px',
        // width: '5%'
    },

    dialogWrapper: {
        padding: theme.spacing(2),
        minHeight: p => p.mobile
            ? '100vh'
            : 'unset'

    },
    dialogTitle: {
        padding: 0,
        // paddingRight: '0px',
        zIndex: '10',

    },
    table: {
        marginTop: theme.spacing(3),
        '& thead th': {
            fontWeight: '600',
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.light,
        },
        '& tbody td': {
            fontWeight: '300',
        },
        '& tbody tr:hover': {
            backgroundColor: '#fffbf2',
            cursor: 'pointer',
        },
    },
    tableColor: {
        // backgroundColor: '#d9d9d9',
        // backgroundColor: '#DEDEDE',
        backgroundColor: '#f0f0f0',
        borderRadius: 10
        // '&:hover': {
        //   backgroundColor: 'red',
        // }
    },


}))

const Row = (props) => {
    const classes = useStyles();
    const { row, selected } = props;
    const [open, setOpen] = useState(false);
    const ref = useRef(false);

    row.datosVerificacion.map(e => {
        // if (selected === 'MAT') {
        //     return ((e.estado === true) && (e.asignacionContravencion === 'boat')) ? ref.current = true : ref.current = false
        // } else if (selected === 'DNI') {
        //     return ((e.estado === true) && (e.asignacionContravencion === 'timonel')) ? ref.current = true : ref.current = false
        // }
        return (selected === 'MAT')
            ? ((e.estado === true) && (e.asignacionContravencion === 'boat')) ? ref.current = true : ref.current = false
            : ((e.estado === true) && (e.asignacionContravencion === 'timonel')) ? ref.current = true : ref.current = false

    });

    return (
        <>
            <TableRow className={classes.root}>
                {
                    (row.modificadoPor !== "" && row.modificadoPor !== undefined && row.modificadoPor !== null)
                        ? <TableCell>
                            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </TableCell>
                        : <TableCell></TableCell>
                }

                {
                    (selected === 'MAT')
                        ? <>
                            <TableCell component="th" scope="row">
                                {row.nombre}
                            </TableCell>
                            <TableCell align="right">
                                {/* {row.matricula} */}
                                {
                                    (row.inscripcion === 'REY' || row.inscripcion === 'E/T')
                                        ? `${row.inscripcion}${row.matricula}`
                                        : `${row.cuatrigrama}${row.matricula}`
                                }
                            </TableCell>
                            <TableCell align="right">{row.amarre}</TableCell>
                        </>
                        : <>
                            <TableCell component="th" scope="row">
                                {row.timonelNombre || `${row.nombre} ${row.apellido}`}
                            </TableCell>
                            <TableCell align="right">{row.timonelDni || `${row.dni}`}</TableCell>
                            <TableCell align="right">
                                {
                                    (row.timonelCarnet === null) ? 'No posee' : row.timonelCarnet || row.categoriaCarnet
                                }
                            </TableCell>
                        </>
                }

                <TableCell align="right">
                    <Label
                        variant="ghost"
                        color={
                            (ref.current === true && 'secondary') ||
                            (ref.current === false && 'success')
                        }
                    // color='secondary'
                    >
                        {(ref.current === true) ? 'Con deuda' : 'Libre deuda'}
                    </Label>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell className={classes.tableColor} style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Mofificado por:</TableCell>
                                        <TableCell>Fecha modif:</TableCell>
                                        <TableCell align="right">Motivo</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {/* {row.history.map((historyRow) => ( */}
                                    {/* <TableRow key={historyRow.date}> */}
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            {/* {row.datosDespachos.titular ? 'Si' : 'No'} */}
                                            {row.modificadoPor}
                                        </TableCell>
                                        <TableCell>{row.modificadoFecha}</TableCell>
                                        <TableCell align="right">{row.modificadoMotivo}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>

                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}



const CarnetNautico = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const pages = [5, 10, 25]
    const { page, rowsPerPage } = useSelector(state => state.system);
    const { carnet = {} } = useSelector(state => state.embarcacion);
    const { busqueda } = useSelector(state => state.embarcacion.carnet);
    const [timoneles, setTimoneles] = useState([])

    const initialFValues = {
        radioCriterioBusqueda: (busqueda) ? busqueda : 'MAT',
    }

    const searchItems = [
        { id: 'MAT', title: 'MAT' },
        { id: 'DNI', title: 'DNI' },
    ]

    const {
        values,
        // setValues,
        // errors,
        // setErrors,
        handleInputChange,
        // resetForm
    } = useForm(initialFValues, false);
    // } = useForm(busqueda, false);

    useEffect(() => {
        dispatch(startGetBoatsCarnet())
        dispatch(startGetTimonelCarnet())
    }, [dispatch])

    useEffect(() => {
        if (busqueda !== values.radioCriterioBusqueda) {
            dispatch(changeRadioOptionCarnet(values.radioCriterioBusqueda))
        }
    }, [busqueda, values.radioCriterioBusqueda, dispatch])

    // console.log('busqueda: ', busqueda)

    const handleChangePage = (event, newPage) => {
        // setPage(newPage);
        dispatch(startTablePageChange(newPage))
    }

    const handleChangeRowsPerPage = event => {
        // setRowsPerPage(parseInt(event.target.value, 10))
        dispatch(startRowsPerPage(parseInt(event.target.value, 10)))
        // setPage(0);
        dispatch(startTablePageChange(0))
    }

    useEffect(() => {
        if (carnet.timoneles.usuarioTimoneles) {
            setTimoneles([...carnet.timoneles.usuarioTimoneles, ...carnet.timoneles.timoneles])
        }
    }, [carnet.timoneles])

    return (
        <>
            <Toolbar>
                <Grid container spacing={1}
                    direction="row"
                    justifyContent="center"
                    xs={12}
                    alignItems="center"
                >
                    <Grid item container justifyContent="center" xs={12}>
                        <Controls.RadioGroup
                            name="radioCriterioBusqueda"
                            // label="Criterio de busqueda:"
                            value={values.radioCriterioBusqueda}
                            onChange={handleInputChange}
                            items={searchItems}
                        />
                    </Grid>
                    <Grid item justifyContent="center" xs={8}>
                        <Controls.Input
                            // label="Buscar por matricula"
                            label={(values.radioCriterioBusqueda === 'MAT') ? 'Buscar por matricula' : 'Buscar por dni'}
                            className={classes.searchInput}
                            InputProps={{
                                startAdornment: (<InputAdornment position="start">
                                    <Search />
                                </InputAdornment>)
                            }}
                        // onChange={handleSearch}
                        />
                    </Grid>

                    <Grid item container justifyContent="center" xs={4}>
                        <Controls.Button
                            text="Buscar"
                            variant="outlined"
                            startIcon={<SearchIcon />}
                        // className={classes.searchButton}
                        // onClick={() => { handleSearchClick(search) }}
                        />
                    </Grid>
                </Grid>
            </Toolbar>

            <Grid container>
                <Grid item xs={12}>
                    <div>

                        <Table className={classes.table} aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    {
                                        // (values.radioCriterioBusqueda === 'DNI') ? console.log('dni') : console.log('mat')
                                        (values.radioCriterioBusqueda === 'MAT')
                                            ? <>
                                                <TableCell />
                                                <TableCell>Nombre</TableCell>
                                                <TableCell align="right">Matricula</TableCell>
                                                <TableCell align="right">Amarre</TableCell>
                                                <TableCell align="right">Estado</TableCell>
                                            </>
                                            : <>
                                                <TableCell />
                                                <TableCell>Nombre y Apellido</TableCell>
                                                <TableCell align="right">DNI</TableCell>
                                                <TableCell align="right">Categoria</TableCell>
                                                <TableCell align="right">Estado</TableCell>
                                            </>
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {

                                    // (values.radioCriterioBusqueda === 'MAT')
                                    (busqueda === 'MAT')
                                        ? (carnet.embarcaciones.ok)
                                            ? carnet.embarcaciones.embarcaciones.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                                <Row key={row.matricula} row={row} selected={values.radioCriterioBusqueda} />
                                            ))
                                            : null
                                        : (carnet.timoneles.ok)
                                            // ? carnet.timoneles.timoneles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                            //     <Row key={row.timonelDni} row={row} selected={values.radioCriterioBusqueda} />
                                            // ))
                                            ? timoneles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                                <Row key={row.timonelDni} row={row} selected={values.radioCriterioBusqueda} />
                                            ))
                                            : null

                                }
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            page={page}
                            labelRowsPerPage='Filas por pagina:'
                            rowsPerPageOptions={pages}
                            rowsPerPage={rowsPerPage}
                            // count={(carnet !== undefined) ? carnet.embarcaciones.length : 0}
                            count={(carnet.embarcaciones.ok) ? (values.radioCriterioBusqueda === 'MAT') ? carnet.embarcaciones.embarcaciones.length : carnet.timoneles.timoneles.length : 0}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                </Grid>
            </Grid>
        </>
    )
}

export default CarnetNautico;