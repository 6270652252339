import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const roleItems = [
    { id: 'polinave', nombre: 'Polinave' },
    { id: 'sumarios', nombre: 'Sumarios' },
    { id: 'operativo', nombre: 'Operativo' },
    { id: 'visor', nombre: 'Contrase / Radio / Visor' },
]

function getStyles(name, lista, theme) {
    return {
        fontWeight:
            lista.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const MultipleSelectChip = ({ holderName, getLista, getBackRead, getBack, uid }) => {
    const theme = useTheme();
    const [lista, setLista] = useState([]);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setLista(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    useEffect(() => {
        setLista(getLista)
    }, [])

    useEffect(() => {
        const addPermisos = (id) => {
            if (getBackRead.filter(a => a.id === id).length <= 0) {
                // getBack.push({
                //     id: uid,
                //     permisos: lista
                // })
                // getBack({ ...getBackRead, id: uid, permisos: lista})
                getBack([...getBackRead, ...[{ id: uid, permisos: lista }]])
                // console.log('new added')
            }
            else {
                // getBack([...getBackRead, ...[{id: uid, permisos: lista}]])
                // getBack.map(x => (x.id === uid ? { ...x, permisos: lista } : x));

                // getBackRead.map(eltomodify => (
                //     eltomodify.id === uid
                //         // ? { ...eltomodify , permisos: lista }
                //         ? getBack({...eltomodify, permisos: lista})
                //         : eltomodify
                // ));

                // getBackRead.map(modifyEl => {
                // getBack([{...eltomodify, permisos: lista }])

                // return (modifyEl.id === id)
                //     ? getBack([...getBackRead, ...[{ ...modifyEl, permisos: lista }]])
                //     : modifyEl

                // return console.log(modifyEl.id)
                // });

                // var itemIndex = inventory.findIndex(x => x.item == 'shirt')
                // var item = inventory[itemIndex]
                // item.quantity = 2
                // inventory[itemIndex] = item

                const i = getBackRead.findIndex(x => x.id === id)
                const item = getBackRead[i]
                item.permisos = lista
                // console.log(item)
                // getBack([...getBackRead, ...getBackRead[i]])

                // var item = {...}
                // var items = [{id:2}, {id:2}, {id:2}];
                // var foundIndex = items.findIndex(x => x.id == item.id);
                // items[foundIndex] = item;

                // var item = {...}
                // var items = [{id:2}, {id:2}, {id:2}];
                // const i = getBackRead.findIndex(x => x.id === id);
                // getBackRead[i] = item;

                // console.log('just updated')
            }
        }
        addPermisos(uid)

        // if (getBackRead.filter(a => a === uid).length <= 0) {

        // }

        // arrQQ.filter(a => a.username == name).length <= 0

        // getBack([...getBackRead, ...[{id: uid, permisos: lista}]])
        // if (getBackRead.some(item => item.id !== uid )) {
        // if (getBackRead.filter(a => a.id !== uid)) {
        //     // .filter(a => a.id === 8 )
        //     // getBack([...getBackRead, ...[{id: uid, permisos: lista}]])
        //     console.log('yes' + ' ' + uid)
        // }

        // console.log(getBackRead)
        // const adk = getBackRead.some(a => a.id === uid)
        // const adk = getBackRead.filter(a => a.id === uid)
        // if (adk) {
        // console.log('dentro: ' + getBackRead.filter(a => a.id === uid))
        // }
        // console.log(uid)
        // console.log('afuera: ' + adk)


        // array.some(food => food === '🍰')
        // console.log([...markerRef.current, ...el.filter(element => !markerRef.current.includes(element))])
        // options={clubes.filter(obj => obj.nombre !== 'Propio')}
    }, [lista])

    // console.log(lista)

    return (
        <div>
            <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel>{holderName}</InputLabel>
                <Select
                    // labelId="demo-multiple-chip-label"
                    // id="demo-multiple-chip"
                    multiple
                    value={lista}
                    onChange={handleChange}
                    input={<OutlinedInput label={holderName} />}
                    renderValue={(selected) => (
                        // <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        //     {selected.map((value) => (
                        //         <Chip key={value} label={value} />
                        //     ))}
                        // </Box>

                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {
                                selected.map((value) => {
                                    const filt = roleItems.filter((el) => el.id === value)
                                    return filt.map(({ nombre }) => (
                                        <Chip key={nombre} label={nombre} />
                                    ))
                                })
                            }
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {/* {getLista.map((name) => (
                        <MenuItem
                            key={name}
                            value={name}
                            style={getStyles(name, lista, theme)}
                        >
                            {name}
                        </MenuItem>
                    ))} */}

                    {roleItems.map((name) => (
                        <MenuItem
                            key={name.nombre}
                            value={name.id}
                            style={getStyles(name.nombre, lista, theme)}
                        >
                            {name.nombre}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}

export default MultipleSelectChip;