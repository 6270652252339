import React, { useEffect, useState } from 'react'
import { Grid, Tooltip } from "@mui/material";
import { withStyles } from '@mui/styles';
import useGeoLocation from './hooks/useGeoLocation';
import Clima from './Clima';
import logo from '../assets/pna.svg'
// import { ReactComponent as Logo } from '../assets/pna.svg'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';

const style = {
    sideMenu: {
        display: 'flex',
        position: 'fixed',
        left: '0px',
        top: '0px',
        width: '20%',
        '@media (max-width: 960px)': {
            width: '0%'
        },
        height: '100%',
        backgroundColor: '#253053'
    },
    logo: {
        marginTop: 15,
        maxWidth: '35%',
        cursor: 'pointer',
    }
}

const SideMenu = (props) => {
    const { classes } = props;
    const navigate = useNavigate();
    const location = useGeoLocation();
    const [dataWeather, setDataWeather] = useState({});
    const { loading } = useSelector(state => state.system);

    const apiOpenWeather = async (lat, lng) => {
        const params = {
            // access_token: 'db36e8d0b935904b6c4c9a29a08f0258',
            access_token: process.env.REACT_APP_OPENWEATHER_KEY,
            units: 'metric',
            lang: 'es',
            // lat: '-31.610659',
            // lng: '-60.697292'
        }

        try {
            // api.openweathermap.org/data/2.5/weather?lat=-31.63333&lon=-60.7&appid=f649190f71ae6b0fb1381542cd2ad334&units=metric&lang=es
            const urlAPI = `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lng}&appid=${params.access_token}&units=${params.units}&lang=${params.lang}`
            const response = await fetch(urlAPI);
            const data = await response.json();

            // console.log('Climaaaa: ', data)
            const { weather, main, name } = data;

            return {
                lugar: name,
                temp: main.temp,
                icon: weather[0].icon,
                desc: weather[0].description,
                // min: main.temp_min,
                // max: main.temp_max,
                // humedad: main.humidity,
            }

        } catch (error) {
            throw error;
        }
    };


    useEffect(() => {
        (location.loaded && location.ok)
            ? apiOpenWeather(location.coordinates.lat, location.coordinates.lng).then(clima => { setDataWeather(clima) })
            // ? apiOpenWeather(location.coordinates.lat, location.coordinates.lng).then(clima => { setDataWeather({ clima }) })
            : console.log("Usuario impide acceso a clima, por denegar ubicación.")
        // }, [location.loaded, location.ok, location.coordinates.lat, location.coordinates.lng]);
    }, [location]);

    // console.log(dataWeather)

    return (
        <div className={classes.sideMenu}>

            <Grid
                container
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                >
                    <Grid
                        // item
                        // TO CENTER
                        container
                        justifyContent="center"
                    // #253053
                    >
                        <Tooltip title="Ir a la pagina inicial">
                            <img
                                src={logo}
                                style={{
                                    marginTop: 15,
                                    maxWidth: '35%',
                                    cursor: (!loading) && 'pointer',
                                }}
                                // className={!loading && classes.logo}
                                // className={classes.img}
                                alt='logoPNA'
                                onClick={() => { !loading && navigate('/login') }}
                            />
                        </Tooltip>
                    </Grid>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-end"
                >
                    <Grid item>
                        {
                            (location.loaded && location.ok) &&
                            <Clima
                                lugar={dataWeather.lugar}
                                temp={Math.round(dataWeather.temp)}
                                icon={dataWeather.icon}
                                desc={dataWeather.desc}
                            />
                        }
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default withStyles(style)(SideMenu);
