import React, { useEffect, useRef, useState } from 'react'
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import { Paper, Grid, TableBody, TableRow, TableCell, Toolbar, InputAdornment, Tooltip, Badge, TablePagination, Dialog, DialogTitle, Typography, DialogContent } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Search } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useDispatch, useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import CallMergeIcon from '@mui/icons-material/CallMerge';

import PageHeader from "../../components/PageHeader";
import { changeTableSumario, closePopupAlternativo, openPopupAlternativo, startOpenPopup, startRowsPerPage, startTablePageChange } from '../../actions/system';
import useTable from "../../components/useTable";
import Controls from "../../components/controls/Controls";
import Popup from "../../components/Popup";
import { startRecordForEdit } from '../../actions/embarcacionesPrefectura';
import useWindowDimensions from '../../components/hooks/useWindowDimensions';
import SumarioForm from './SumarioForm';
import { startContravencionGet, startContravencionPendientesGet, startContravencionTimonelGet, startContravencionUsuarioTimonelGet, startGetBoatsSumario, startGetTimonelesSumario } from '../../actions/embarcacionesSumario';
import { SumarioFormCarga } from './SumarioFormCarga';
import { useForm } from '../../components/useForm';
import SumarioFormTimonel from './SumarioFormTimonel';
import { SumarioFormCargaTimonel } from './SumarioFormCargaTimonel';
import SumarioPendientes from './SumarioPendientes';

const useStyles = makeStyles(theme => ({
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3)
    },
    searchInput: {
        width: '100%'
    },
}))

const headTableMat = [
    { id: 'fullName', label: 'Embarcacion' },
    { id: 'email', label: 'Matricula' },
    // { id: 'gender', label: 'Inscripción' },
    { id: 'city', label: 'Amarre' },
    { id: 'actions', label: 'Acciones', disableSorting: true }
]

const headTableDni = [
    { id: 'fullName', label: 'Nombre' },
    { id: 'email', label: 'Dni' },
    { id: 'gender', label: 'Carnet timonel' },
    { id: 'city', label: 'Telefono' },
    { id: 'actions', label: 'Acciones', disableSorting: true }
]

const searchItems = [
    { id: 'MAT', title: 'Embarcaciones' },
    { id: 'DNI', title: 'Personas' },
]
const initialFValues = {
    radioCriterioBusqueda: 'MAT',
}

const SumarioScreen = () => {

    const dispatch = useDispatch();
    const classes = useStyles();
    const [filterFn] = useState({ fn: items => { return items; } })
    const { name, place } = useSelector(state => state.auth);
    const { recordForEdit } = useSelector(state => state.embarcacion);
    const { contravenciones, embarcacionesSumario, timonelesSumario, timonelesUsuariosSumario, timonelesContravenciones, timonelesUsuariosContravenciones, contravencionesPendientes } = useSelector(state => state.sumario);
    const [width, ,] = useWindowDimensions();
    const { page, rowsPerPage, systemRadioTableSumario, statusPopupAlternativo } = useSelector(state => state.system);
    const pages = [5, 10, 25]
    const isUserTimonel = useRef(null)

    const arrTimoneles = [...timonelesUsuariosSumario, ...timonelesSumario]

    const handleChangePage = (event, newPage) => {
        dispatch(startTablePageChange(newPage))

    }

    const handleChangeRowsPerPage = event => {
        dispatch(startRowsPerPage(parseInt(event.target.value, 10)))
        dispatch(startTablePageChange(0))
    }

    const {
        values,
        handleInputChange,
    } = useForm(initialFValues, false);

    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting
    } = useTable(
        (systemRadioTableSumario === 'MAT') ? embarcacionesSumario : arrTimoneles,
        (systemRadioTableSumario === 'MAT') ? headTableMat : headTableDni,
        filterFn);

    // TODO: BUSQUEDA DE SUMARIOS NO IMPLEMENTADA

    // const handleSearch = (e) => {
    //     dispatch(inputSearchSumarioBoat(e.target.value.trim()));
    // }

    // const handleSearchClick = (search) => {
    //     if (search === '') {
    //         dispatch(startGetBoatsSumario())
    //     } else {
    //         dispatch(startGetSearchBoatSumario());
    //     }
    // }

    // const addOrEdit = (boat, resetForm) => {
    //     if (boat.id == 0) {
    //         console.log(boat)
    //         const lugar = { lugar: place };
    //         const boatWithPlace = Object.assign(boat, lugar);
    //         dispatch(startPostNewBoat(boatWithPlace));
    //     } else {
    //         dispatch(startPutEditBoat(boat));
    //     }
    //     resetForm()
    //     dispatch(startRecordForEdit(null));
    //     dispatch(startClosePopup());
    // }

    const openInPopup = item => {
        dispatch(startRecordForEdit(item));
        if (systemRadioTableSumario === 'MAT') {
            dispatch(startContravencionGet(item.id))
        } else {
            // console.log(item)
            if (item.password) {
                isUserTimonel.current = true;
                dispatch(startContravencionUsuarioTimonelGet(item.id));
            } else {
                isUserTimonel.current = false;
                dispatch(startContravencionTimonelGet(item.id));
            }
        }
        dispatch(startOpenPopup());
    }

    useEffect(() => {
        dispatch(startGetBoatsSumario())
        dispatch(startGetTimonelesSumario())
        dispatch(startContravencionPendientesGet());
        if (systemRadioTableSumario === 'MAT') {
            values.radioCriterioBusqueda = 'MAT'
        } else {
            values.radioCriterioBusqueda = 'DNI'
        }
    }, [dispatch, width, systemRadioTableSumario, values])

    return (
        <>
            <PageHeader
                title={`Oficina Sumarios de Prefectura ${place}`}
                subTitle="Listado de embarcaciones y timoneles."
                icon={<DirectionsBoatIcon fontSize="large" />}
                profile={true}
                name={name}
            />
            <Paper className={classes.pageContent}>
                <Toolbar>
                    <Toolbar>
                        <Grid container spacing={1}
                            direction="row"
                            justify="center"
                            // xs={12}
                            alignItems="center"
                        >
                            <Grid item container>
                                <Controls.RadioGroup
                                    name="radioCriterioBusqueda"
                                    value={values.radioCriterioBusqueda}
                                    onChange={handleInputChange}
                                    onClick={() => (systemRadioTableSumario === 'MAT') ? dispatch(changeTableSumario('DNI')) : dispatch(changeTableSumario('MAT'))}
                                    items={searchItems}
                                />
                            </Grid>
                        </Grid>
                    </Toolbar>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                    >
                        <Grid item xs sx={{ flexGrow: 1 }}>
                            <Controls.Input
                                label={(systemRadioTableSumario === 'MAT') ? 'Buscar por matricula' : 'Buscar por dni'}
                                style={{ width: '100%' }}
                                className={classes.searchInput}
                                InputProps={{
                                    startAdornment: (<InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>)
                                }}
                            />
                        </Grid>
                        <Grid item xs="auto">
                            <Controls.Button
                                sx={{ marginLeft: 1 }}
                                text="Buscar"
                                variant="outlined"
                                startIcon={<SearchIcon />}
                            />
                        </Grid>

                        <Grid item>
                            <Badge badgeContent={(contravencionesPendientes) ? contravencionesPendientes.map((i) => i).length : null} color="secondary">
                                <Controls.Button
                                    text="Pendientes"
                                    variant="outlined"
                                    startIcon={(width > 850) ? <CallMergeIcon /> : null}
                                    onClick={() => {
                                        dispatch(openPopupAlternativo())
                                    }}
                                />
                            </Badge>
                        </Grid>
                    </Grid>

                </Toolbar>

                <TblContainer>
                    <TblHead />
                    <TableBody>
                        {
                            (systemRadioTableSumario === 'MAT')
                                ? recordsAfterPagingAndSorting().map(item =>
                                (<TableRow key={item.id}>
                                    <TableCell>{item.nombre}</TableCell>
                                    <TableCell>
                                        {
                                            (item.inscripcion === 'REY' || item.inscripcion === 'E/T')
                                                ? `${item.inscripcion} ${item.matricula}`
                                                : `${item.cuatrigrama} ${item.matricula}`
                                        }
                                    </TableCell>
                                    {/* {
                                        (width > 700) && (<TableCell>{item.inscripcion}</TableCell>)
                                    } */}
                                    <TableCell>{item.amarre}</TableCell>
                                    <TableCell>
                                        <Controls.ActionButton
                                            color="primary"
                                            onClick={() => {
                                                openInPopup(item)
                                            }}>
                                            <Tooltip title="Observar/Modificar">
                                                <VisibilityIcon fontSize="small" />
                                            </Tooltip>
                                        </Controls.ActionButton>
                                    </TableCell>
                                </TableRow>))

                                : recordsAfterPagingAndSorting().map(item =>
                                (<TableRow key={item.id}>
                                    <TableCell>{item.timonelNombre || `${item.nombre} ${item.apellido}`}</TableCell>
                                    {/* <TableCell>{JSON.stringify(item, null, 5)}</TableCell> */}
                                    <TableCell>{item.timonelDni || `${item.dni}`}</TableCell>
                                    {
                                        (width > 700) &&
                                        <TableCell>
                                            {
                                                (item.timonelCarnet === null) ? 'No posee' : item.timonelCarnet || item.categoriaCarnet
                                            }
                                        </TableCell>
                                    }
                                    <TableCell>{item.timonelTelefono || item.telefono}</TableCell>
                                    <TableCell>
                                        <Controls.ActionButton
                                            color="primary"
                                            onClick={() => {
                                                openInPopup(item)
                                            }}>
                                            <Tooltip title="Observar/Modificar">
                                                <VisibilityIcon fontSize="small" />
                                            </Tooltip>
                                        </Controls.ActionButton>
                                    </TableCell>
                                </TableRow>))
                        }
                    </TableBody>
                </TblContainer>
                {
                    (systemRadioTableSumario === 'MAT')
                        ? <TblPagination />
                        : <TablePagination
                            component="div"
                            page={page}
                            labelRowsPerPage='Filas por pagina:'
                            rowsPerPageOptions={pages}
                            rowsPerPage={rowsPerPage}
                            count={arrTimoneles.length}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                }
            </Paper>

            <Dialog
                open={statusPopupAlternativo}
                maxWidth='xl'
                classes={{ paper: classes.dialogWrapper }}
            >
                <DialogTitle className={classes.dialogTitle}>
                    <div style={{ display: 'flex' }}>
                        <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                            Contravenciones pendientes...
                        </Typography>
                        <Controls.ActionButton
                            color="secondary"
                            onClick={() => { dispatch(closePopupAlternativo()) }}>
                            <CloseIcon />
                        </Controls.ActionButton>
                    </div>
                </DialogTitle>
                <DialogContent dividers>
                    <SumarioPendientes />
                </DialogContent>
            </Dialog>

            <Popup
                title="Contravenciones..."
                size="lg"
            >
                {
                    (systemRadioTableSumario === 'MAT')
                        ? ((contravenciones.length === 0)
                            ? <SumarioFormCarga nuevo={true} />
                            : <SumarioForm recordForEdit={recordForEdit} />)
                        : (isUserTimonel.current)
                            ? (timonelesUsuariosContravenciones.length === 0)
                                ? <SumarioFormCargaTimonel nuevo={true} userTimonel={true} />
                                : <SumarioFormTimonel recordForEdit={recordForEdit} userTimonel={true} />
                            : (timonelesContravenciones.length === 0)
                                ? <SumarioFormCargaTimonel nuevo={true} userTimonel={false} />
                                : <SumarioFormTimonel recordForEdit={recordForEdit} userTimonel={false} />
                }
            </Popup>
        </>
    )
}

export default SumarioScreen;

