import React from 'react'

import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux';

export const PrivateRoute = ({ children }) => {

    const { uid } = useSelector(state => state.auth);

    // return (uid === null)
    //     ? <Navigate to="/login" />
    //     : (role === 'admin')
    //         ? <Navigate to="/admin/dashboard" />
    //         : children

    return (uid === null)
        ? <Navigate to="/login" />
        : children
}

// PrivateRoute.propTypes = {
//     isAuthenticated: PropTypes.bool.isRequired,
//     element: PropTypes.func.isRequired
// }
