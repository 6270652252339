import React, { useEffect } from 'react'
import { Box, Grid, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';

import Controls from '../../components/controls/Controls';
import { Form, useForm } from '../../components/useForm';
import { useDispatch, useSelector } from 'react-redux';
import { startListDependenciasDB } from '../../actions/embarcacionesPrefectura';

const inscripcionItems = [
    { id: 'REY', title: 'REY' },
    { id: 'REJU', title: 'REJU' },
    { id: 'E/T', title: 'E/T' },
]

const initialFValues = {
    // id: 0,
    nombre: '',
    matricula: '',
    desc: '',
    inscripcion: '',
    cuatrigrama: '',
    fechaAlta: new Date(),
}

const ClubAgregarPendientes = ({ saveDataPendientes }) => {
    const dispatch = useDispatch();
    // to fix undefined
    // const { dependencias = [] } = useSelector(state => state.admin);
    const { dependencias } = useSelector(state => state.admin);

    useEffect(() => {
        dispatch(startListDependenciasDB());
    }, [])

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('nombre' in fieldValues)
            temp.nombre = fieldValues.nombre ? "" : "Nombre es requerido."

        if ('cuatrigrama' in fieldValues)
            temp.cuatrigrama = fieldValues.cuatrigrama ? "" : "Cuatrigrama es requerido."

        if ('matricula' in fieldValues)
            temp.matricula = fieldValues.matricula.length !== 0
                ? (/^[0-9]*$/).test(fieldValues.matricula)
                    ? (fieldValues.matricula.length >= 0 && fieldValues.matricula.length <= 6)
                        ? ""
                        : "La matricula debe contener 4 caracter como minimo y 6 caracteres como maximo."
                    : "Matricula solo puede contener numeros."
                : "Matricula es requerido."

        if ('inscripcion' in fieldValues) {
            if (fieldValues.inscripcion === 'REY' || fieldValues.inscripcion === 'E/T') {
                delete temp.cuatrigrama
            } else {
                temp.inscripcion = fieldValues.inscripcion ? "" : "Inscripción es requerido."
            }
        }

        setErrors({
            ...temp
        })

        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }

    const {
        values,
        // setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFValues, true, validate);

    const handleSubmit = e => {
        e.preventDefault();

        const temp = {
            ...values,
            nombre: values.nombre.toUpperCase(),
            cuatrigrama:
                (values.inscripcion === 'REY' || values.inscripcion === 'E/T')
                    ? null
                    : values.cuatrigrama,
            desc: (values.desc.trim() !== '') ? values.desc : null
        }

        if (validate()) {
            saveDataPendientes(temp, resetForm);
        }
    }

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Grid container>
                    <Grid item xs={6}>
                        <Controls.Input
                            name="nombre"
                            label="Nombre"
                            value={values.nombre.toUpperCase()}
                            onChange={handleInputChange}
                            error={errors.nombre}
                            autoFocus
                        />
                        <Controls.RadioGroup
                            name="inscripcion"
                            label="Inscripción"
                            value={values.inscripcion}
                            onChange={handleInputChange}
                            items={inscripcionItems}
                            error={errors.inscripcion}
                        />
                        {
                            (values.inscripcion === 'REJU') &&
                            // <Controls.Select
                            //     name="cuatrigrama"
                            //     label="Cuatrigrama"
                            //     value={values.cuatrigrama}
                            //     onChange={handleInputChange}
                            //     // options={[{ id: 1, nombre: 'SAFE' }, { id: 2, nombre: 'ROSA' }, , { id: 3, nombre: 'SNIC' }, , { id: 4, nombre: 'PARA' }]}
                            //     error={errors.cuatrigrama}
                            // />

                            <FormControl error={errors.cuatrigrama}>
                                <InputLabel>Cuatrigrama</InputLabel>
                                <Select
                                    name="cuatrigrama"
                                    label="Cuatrigrama"
                                    value={values.cuatrigrama}
                                    onChange={handleInputChange}
                                // renderValue={(value) => `⚠️  - ${value}`}
                                >
                                    {dependencias.map(
                                        item => (<MenuItem key={item.id} value={item.cuatrigrama}>{item.cuatrigrama} - {item.nombre}</MenuItem>)
                                    )}
                                </Select>
                                {errors.cuatrigrama && <FormHelperText>{errors.cuatrigrama}</FormHelperText>}
                            </FormControl>
                        }
                        <Controls.Input
                            name="matricula"
                            label="Matricula"
                            placeholder="EJ: 012345"
                            value={values.matricula}
                            onChange={handleInputChange}
                            error={errors.matricula}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controls.Input
                            name="desc"
                            label="Descripción"
                            value={values.desc}
                            onChange={handleInputChange}
                            error={errors.desc}
                        />
                        <Controls.DatePickerControl
                            name="fechaAlta"
                            label="Fecha de carga"
                            value={values.fechaAlta}
                            onChange={handleInputChange}
                        />
                        <Box
                            sx={{
                                mt: 1,
                                ml: 1,
                            }}
                        >
                            <Controls.Button
                                text="Reset"
                                color="default"
                                onClick={resetForm}
                            />
                            <Controls.Button
                                style={{ marginLeft: 10 }}
                                type="submit"
                                text="Guardar"
                            />
                        </Box>

                        {/* <div>
                            <Controls.Button
                                type="submit"
                                text="Guardar" />
                            <Controls.Button
                                text="Reset"
                                color="default"
                                onClick={resetForm} />
                        </div> */}
                    </Grid>
                </Grid >
            </Form >
        </>
    )
}

export default ClubAgregarPendientes;