
export const types = {

    authCheckingFinish: '[auth] Finish checking login state',
    authStartLogin: '[auth] Start login',
    authLogin: '[auth] Login',
    authStartRegister: '[auth] Start Register',
    authBlankPassword: '[auth] Blank Password',
    authBlankPasswordButtonIcon: '[auth] Blank Password Button Icon',
    authStartStartTokenRenew: '[auth] Start token renew',
    authLogout: '[auth] Logout',

    systemPopupTitle: '[system] Title',
    systemPopupSize: '[system] Size',
    systemPopupChild: '[system] Child',
    systemOpenPopup: '[system] OpenPopup',
    systemClosePopup: '[system] ClosePopup',
    systemOpenPopupAlternativo: '[system] OpenPopupAlternativo',
    systemClosePopupAlternativo: '[system] ClosePopupAlternativo',
    systemPage: '[system] Table Page Change',
    systemRowsPerPage: '[system] Table rowsPerPage Change',
    systemTableOpenCloseCollapse: '[system] Table Open/Close collapse',
    systemWindowSizeWidth: '[system] Change Width Size Window',
    systemRadioTableSumario: '[system] Change sumarios table',
    systemRadioTablePolinave: '[system] Change carnet table',
    systemLoading: '[system] Change loading',

    //ADMIN PREFECTURA
    adminGetDependencias: '[admin] GetDependencies',
    adminGetClubes: '[admin] GetClubes',
    adminGetUsuariosPendientes: '[admin] GetUsuariosPendientes',
    adminGetUsuariosActivos: '[admin] GetUsuariosActivos',
    adminGetUsuariosActivosSoloPrefe: '[admin] GetUsuariosActivosSoloPrefe',

    //PREFECTURA
    embarcacionGetBoats: '[embarcacionPrefectura] GetBoats',
    embarcacionGetPendingBoats: '[embarcacionPrefectura] GetPendingBoats',
    embarcacionGetRelationshipsPendingBoats: '[embarcacionPrefectura] GetRelationshipsPendingBoats',
    embarcacionGetRelationshipsNoPendingBoats: '[embarcacionPrefectura] GetRelationshipsNoPendingBoats',
    embarcacionPostNewBoats: '[embarcacionPrefectura] PostNewBoat',
    embarcacionPutEditBoat: '[embarcacionPrefectura] PutEditBoat',
    embarcacionDeleteBoat: '[embarcacionPrefectura] DeleteBoat',
    embarcacionSearchBoat: '[embarcacionPrefectura] SearchBoat',
    embarcacionGetSearchBoat: '[embarcacionPrefectura] GetSearchBoat',
    embarcacionRecordForEdit: '[embarcacionPrefectura] EditItem',
    embarcacionGetBoatsCarnet: '[embarcacionPrefectura] GetBoatsCarnet',
    embarcacionGetTimonelCarnet: '[embarcacionPrefectura] GetTimonelCarnet',

    //CLUB
    embarcacionGetClubBoats: '[embarcacionClubes] GetBoats',
    embarcacionSearchClubBoat: '[embarcacionClubes] SearchBoat',
    embarcacionGetSearchClubBoat: '[embarcacionClubes] GetSearchClubBoat',
    
    embarcacionGetBoatsAdespachar: '[embarcacionClubes] GetBoatsParaDespachar',
    embarcacionGetBoatsTotalityNumber: '[embarcacionClubes] GetBoatsTotalityNumber',
    getUsersFromAboat: '[embarcacionClubes] getUsersFromAboat',
    embarcacionSearchDespachoBoat: '[embarcacionClubes] SearchDespachoBoat',
    embarcacionGetSearchDespachoBoat: '[embarcacionClubes] SearchBoat',
    embarcacionPostArriboDespacho: '[embarcacionClubes] Arribo/Despacho',
    embarcacionGetBoatsPendientes: '[embarcacionClubes] GetBoatsPendientes',

    //SENADE
    embarcacionSenadeGetBoats: '[embarcacionSenade] GetBoats',
    // embarcacionSenadeGetBoatsResults: '[embarcacionSenade] GetBoatsResults',
    embarcacionSenadePostBoats: '[embarcacionSenade] PostBoats',
    embarcacionSenadeSearchBoat: '[embarcacionSenade] SearchBoat',
    embarcacionSenadeGetSearchBoat: '[embarcacionSenade] GetSearchBoat',

    // DESPACHO CLUBES
    // despachoDatos: '[despacho] Ingreso Datos',
    despachoReset: '[despacho] Reset',
    despachoGetDB: '[despacho] GetDB',
    despachoPostDB: '[despacho] PostDB / Despacho',
    despachoDeleteDB: '[despacho] DeleteDB / Arribo',

    // SUMARIOS
    sumarioGetBoats: '[sumario] GetBoats',
    sumarioGetTimoneles: '[sumario] GetTimoneles',
    sumarioGetUsuariosTimoneles: '[sumario] GetUsuariosTimoneles',
    sumarioSearchBoat: '[sumario] SearchBoat',
    sumarioGetSearchBoat: '[sumario] GetSearchBoat',
    
    embarcacionContravencionesPendientesGet: '[sumario] ContravencionesPendientesGet',
    embarcacionVerificacionGet: '[sumario] ContravencionesGet',
    embarcacionVerificacionTimonelGet: '[sumario] ContravencionesTimonelGet',
    embarcacionVerificacionUsuarioTimonelGet: '[sumario] ContravencionesUsuarioTimonelGet',
    sumarioContravencionId: '[sumario] ContravencionesId',

    // VISOR
    observadorVisorGetVerificadas: '[visor] GetVerificadas',
    observadorVisorGetProhibic: '[visor] GetProhibidas',

    observadorVisorGetBoats: '[visor] GetBoats',
    observadorVisorSearchBoat: '[visor] SearchBoat',
    observadorVisorGetSearchBoat: '[visor] GetSearchBoat',

    // TIMONELES
    embarcacionGetTimonel: '[timonel] GetBoats',
    embarcacionGetToFormAddRemoveBoatsTimonel: '[timonel] GetAllBoatsToForm',
    usuarioGetTimonel: '[timonel] GetTimonel',
}