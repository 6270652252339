import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import { useDispatch, useSelector } from 'react-redux';
import PageHeader from '../../components/PageHeader';
import CheckIcon from '@mui/icons-material/Check';
import { startRowsPerPage, startTablePageChange } from '../../actions/system';
import { changeUserPermissions, deleteUsuariosActivos, enableUsuariosActivos, startGetUsuariosActivosSoloPrefe } from '../../actions/admin';
import { Alert } from '@mui/lab';
import ClearIcon from '@mui/icons-material/Clear';
import { useTheme } from '@mui/material/styles';
import MultipleSelectChip from '../../components/controls/MultipleSelectChip';
import Button from '../../components/controls/Button';
import { useForm } from '../../components/useForm';
import Swal from 'sweetalert2';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'nombre', disablePadding: true, label: 'Nombre' },
  { id: 'email', disablePadding: false, label: 'Email' },
  { id: 'lugar', disablePadding: false, label: 'Lugar' },
  { id: 'desde', disablePadding: false, label: 'Permisos' },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        // color: theme.palette.text.primary,
        color: theme.palette.default.main,
        backgroundColor: theme.palette.secondary.main,
      },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const dispatch = useDispatch();
  const classes = useToolbarStyles();
  const { numSelected, arrayDataSelected, clearSelected, permissionList } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
      sx={{ borderTopLeftRadius: 10, borderTopRightRadius: 10, mt: 1 }}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} style={{ fontWeight: 'bold' }} color="inherit" variant="subtitle1" component="div">
          {numSelected} registro/s seleccionado/s.
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Listado de selección:
        </Typography>
      )}

      {numSelected > 0 && (
        <>
          <Tooltip title="Actualizar">
            <IconButton
              sx={{ color: '#e3e4e6' }}
              aria-label="confirmar"
              // onClick={() => { dispatch(enableUsuariosActivos(arrayDataSelected)); clearSelected([]); }}
              // onClick={() => { console.log(arrayDataSelected); clearSelected([]); }}
              // onClick={() => { console.log(arrayDataSelected + ' ' + 'OKKK!!' + JSON.stringify(permissionList)); clearSelected([]); }}
              onClick={() => {
                // manejar el error si los chipbox no tienen cambio
                // if (permissionItem === []) return Swal.fire('Error', 'errorisomo.', 'error');
                const arr = [];
                arrayDataSelected.map((selectedItem) => {
                  permissionList.map((permissionItem) => {
                    if (permissionItem.id === selectedItem && permissionItem.permisos.length !== 0) {
                      // console.log([permissionItem])
                      return arr.push(permissionItem)
                    }
                    // return console.log([permissionItem])
                  })
                  // return console.log([selectedItem])
                })
                // console.log(arr)
                dispatch(changeUserPermissions(arr)); 
                clearSelected([]);
              }}
            >
              <CheckIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  pageContent: {
    margin: theme.spacing(5),
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  table: {
    marginTop: theme.spacing(3),
    '& thead th': {
      fontWeight: '600',
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light,
    },
    '& tbody td': {
      fontWeight: '300',
    },
    '& tbody tr:hover': {
      backgroundColor: '#fffbf2',
      cursor: 'pointer',
    },
  },
  isStateFalse: {
    backgroundColor: '#c7c7c7',
  }
}));

const Permissions = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [permission, setPermission] = useState([]);
  const pages = [5, 10, 25]
  const { page, rowsPerPage } = useSelector(state => state.system);
  const [dense] = useState(false);
  const { name } = useSelector(state => state.auth);
  const { usuariosActivosPrefe: usuarios = [] } = useSelector(state => state.admin);

  //   const validate = (fieldValues = values) => {
  //     let temp = { ...errors }
  //     if ('selectRole' in fieldValues)
  //         temp.selectRole = (fieldValues.selectRole.length !== 0)
  //             ? ""
  //             : "Rol de trabajo es requerido."

  //     setErrors({ ...temp })
  //     if (fieldValues === values)
  //         return Object.values(temp).every(x => x === "")
  // }

  // const {
  //     values,
  //     setValues,
  //     errors,
  //     setErrors,
  //     handleInputChange,
  //     resetForm
  // } = useForm({multiSelect1: []}, true, validate);

  useEffect(() => {
    dispatch(startGetUsuariosActivosSoloPrefe())
  }, [dispatch])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = usuarios.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    dispatch(startTablePageChange(newPage))
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(startRowsPerPage(parseInt(event.target.value, 10)))
    dispatch(startTablePageChange(0))
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, usuarios.length - page * rowsPerPage);

  // // console.log(permission)
  // const tellme = (name, id) => {
  //   console.log(name + ' ' + id)
  //   console.log(selected)
  //   console.log(permission)
  //   // console.log(multiSelect1.value)
  // }

  // console.log(permission)

  return (
    <div className={classes.root}>

      <PageHeader
        title="Usuarios activos"
        subTitle="Revocar/cambiar permisos de usuario en el sistema"
        icon={<DirectionsBoatIcon fontSize="large" />}
        profile={true}
        name={name}
      />

      {(usuarios.length === 0)
        ? (<Alert severity="info">No hay usuarios activos.</Alert>)
        : (<>
          <Paper className={classes.pageContent}>
            <EnhancedTableToolbar numSelected={selected.length} arrayDataSelected={selected} clearSelected={setSelected} permissionList={permission} />
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={usuarios.length}
                />
                <TableBody>
                  {stableSort(usuarios, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      const labelMultiSelectId = `multiSelect${index + 1}`;

                      return (
                        <TableRow
                          hover
                          className={`${(row.estado === false) ? classes.isStateFalse : null}`}
                          // style={ (row.estado === false) ? background: 'grey' : null }
                          // onClick={(event) => handleClick(event, row.id)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                              onClick={(event) => handleClick(event, row.id)}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            onClick={(event) => handleClick(event, row.id)}
                          >
                            {row.nombre}
                          </TableCell>
                          {/* <TableCell align="right">{row.email}</TableCell> */}
                          <TableCell>{row.email}</TableCell>
                          <TableCell>{row.lugar}</TableCell>

                          <TableCell>
                            <MultipleSelectChip
                              uid={row.id}
                              getLista={JSON.parse(row.desde)}
                              getBackRead={permission}
                              getBack={setPermission}
                              holderName={'Rol de trabajo'}
                              disabled
                            />
                            {/* <MultipleSelectOption
                              nombre={labelMultiSelectId}
                              value={values.labelMultiSelectId}
                              onChange={handleInputChange}
                              // error={errors.rSelectClub}
                              // uid={row.id}
                              // getList={row.desde}
                              // getList={row.desde}
                              // JSON.parse(getList)
                              // getBack={setPermission}
                              // getBackValues={permission}
                              // options={JSON.parse(row.desde)}
                            /> */}
                          </TableCell>
                          {/* <TableCell>
                            <Button
                              text="Buscar"
                              variant="outlined"
                              // onClick={() => { console.log(permission) }}
                              onClick={() => { tellme(labelMultiSelectId, row.id) }}
                            // onClick={() => { console.log(row.id) }}
                            />
                          </TableCell> */}
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              labelRowsPerPage='Filas por pagina:'
              rowsPerPageOptions={pages}
              component="div"
              count={usuarios.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </>)
      }
    </div>
  );
}

export default Permissions;