import React from 'react';
import {
    Routes,
    Navigate,
    Route
} from 'react-router-dom';
import AdminDashboard from '../pages/AdminDashboard/AdminDashboard';
import DeleteUsers from '../pages/AdminDashboard/DeleteUsers';
import Pending from '../pages/AdminDashboard/Pending';
import Permissions from '../pages/AdminDashboard/Permissions';

export const DashboardAdminRoute = () => {

    return (
        <Routes>

            {/* 
                <Route exact path="/admin/dashboard" component={
                    (width > 600) ? AdminDashboard : RestrictPortrait
                } /> 
            */}

            <Route
                end
                path='/dashboard'
                // element={
                //     (width > 600)
                //         ? <AdminDashboard />
                //         : <RestrictPortrait />
                // }
                element={<AdminDashboard />}
            />

            {/*
                <Route exact path="/admin/user/pending" component={
                    (width > 600) ? Pending : RestrictPortrait
                } /> 
             */}

            <Route
                end
                path='/user/pending'
                // element={
                //     (width > 600)
                //         ? <Pending />
                //         : <RestrictPortrait />
                // }
                element={<Pending />}

            />

            {/* 
                <Route exact path="/admin/user/delete" component={
                    (width > 600) ? DeleteUsers : RestrictPortrait
                } />
            */}

            <Route
                end
                path='/user/delete'
                // element={
                //     (width > 600)
                //         ? <DeleteUsers />
                //         : <RestrictPortrait />
                // }
                element={<DeleteUsers />}

            />

            <Route
                end
                path='/user/permissions'
                // element={
                //     (width > 600)
                //         ? <DeleteUsers />
                //         : <RestrictPortrait />
                // }
                element={<Permissions />}

            />

            <Route
                path='/*'
                // element={
                //     (width > 600)
                //         ? <AdminDashboard />
                //         : RestrictPortrait
                // }
                // element={<AdminDashboard />}
                element={<Navigate to="/admin/dashboard" />}
            />

            {/* <Route
                path='/'
                // element={
                //     (width > 600)
                //         ? <AdminDashboard />
                //         : RestrictPortrait
                // }
                element={<AdminDashboard />}
            /> */}


            {/* <Navigate to="/admin/dashboard" /> */}
        </Routes>
    )
}
