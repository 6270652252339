import React from 'react';
import { useSelector } from 'react-redux';
import {
    Routes,
    Navigate,
    Route
} from 'react-router-dom';
import { Dashboard } from '../pages/Timoneles/Dashboard';
import { Carnet } from '../pages/Timoneles/Carnet';
import { Despacho } from '../pages/Timoneles/Despacho';
import { Contravenciones } from '../pages/Timoneles/Contravenciones';
import { Embarcaciones } from '../pages/Timoneles/Embarcaciones';

export const DashboardTimonelesRoute = () => {
    // const { role } = useSelector(state => state.auth);

    return (
        <Routes>

            <Route
                end
                path='/dashboard'
                element={
                    <Dashboard />
                }
            />

            <Route
                end
                path='/contravenciones'
                element={
                    <Contravenciones />
                }
            />

            <Route
                end
                path='/carnet'
                element={
                    <Carnet />
                }
            />

            <Route
                end
                path='/despacho'
                element={
                    <Despacho />
                }
            />

            <Route
                end
                path='/embarcaciones'
                element={
                    <Embarcaciones />
                }
            />

            <Route
                path='/*'
                element={<Navigate to={'/timonel/dashboard'} />}
            />

        </Routes>
    )
}
