import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Grid, TableBody, TableRow, TableCell, Toolbar, InputAdornment, Tooltip, Typography, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Search } from "@mui/icons-material";
import CallMadeIcon from '@mui/icons-material/CallMade';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import Swal from 'sweetalert2'

import useTable from "../../components/useTable";
import Controls from '../../components/controls/Controls';
import { startSearchDespachoBoat } from '../../actions/embarcacionesClubes';
import { startClosePopup } from '../../actions/system';
import { startDeleteBoat, startListPendientesDB, startPutEditBoat, startRecordForEdit } from '../../actions/embarcacionesPrefectura';
import EmbarcacionesForm from './EmbarcacionesForm';
import { isMobile } from 'react-device-detect';
import { Toast } from '../../components/Toast';

const useStyles = makeStyles(theme => ({
    searchInput: {
        width: '50%'
    },
    searchButton: {
        position: 'absolute',
        right: '10px',
        // width: '5%'
    },
    dialogWrapper: {
        padding: theme.spacing(2),
        minHeight: p => p.mobile
            ? '100vh'
            : 'unset'
    },
    dialogTitle: {
        padding: 0,
        // paddingRight: '0px',
        zIndex: '10',
    }
}))

const headCells = [
    { id: 'fullName', label: 'Embarcacion' },
    { id: 'email', label: 'Matricula' },
    { id: 'mobile', label: 'Descripción' },
    { id: 'actions', label: 'Acciones', disableSorting: true }
]

const EmbarcacionesPendientes = (props) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const { pendingBoats, recordForEdit } = useSelector(state => state.embarcacion);
    const [filterFn] = useState({ fn: items => { return items; } })
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        dispatch(startListPendientesDB())
    }, [dispatch])

    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting
    } = useTable(pendingBoats, headCells, filterFn);

    const handleSearchDespacho = (e) => {
        dispatch(startSearchDespachoBoat(e.target.value.trim()));
    }

    const addOrEdit = (boat, resetForm) => {

        dispatch(startPutEditBoat(boat));
        resetForm()
        dispatch(startRecordForEdit(null));
        setIsOpen(false)

    }

    const openInPopup = (item) => {
        // setRecordForEdit(item)
        dispatch(startRecordForEdit(item));

        // setOpenPopup(true)
        // dispatch(startOpenPopup());
    }

    const deleteItem = (item) => {
        Swal.fire({
            title: '¿Estas seguro?',
            text: "Esta acción no se puede revertir!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No, conservalo!',
            confirmButtonText: 'Si, eliminalo!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(startDeleteBoat(item))
                Toast('success', 'Este registro fue eliminado exitosamente.');
            }
        })
    }

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    {/* <div> */}
                    {/* <Toolbar>
                            <Grid container spacing={1}
                                direction="row"
                                justifyContent="center"
                                xs={12}
                                alignItems="center"
                            >
                                <Grid item justifyContent="center" xs={8}>
                                    <Controls.Input
                                        label="Buscar embarcaciones"
                                        sx={{ width: '95%' }}
                                        className={classes.searchInput}
                                        InputProps={{
                                            startAdornment: (<InputAdornment position="start">
                                                <Search />
                                            </InputAdornment>)
                                        }}
                                        onChange={handleSearchDespacho}
                                    />
                                </Grid>

                                <Grid item container justifyContent="center" xs={4}>
                                    <Controls.Button
                                        text="Buscar..."
                                        variant="outlined"
                                        startIcon={<SearchIcon />}
                                        className={classes.searchButton}
                                    // onClick={() => { handleSearchClick(searchDespacho) }}
                                    />
                                </Grid>
                            </Grid>


                        </Toolbar> */}
                    <TblContainer>
                        <TblHead />
                        <TableBody>
                            {
                                recordsAfterPagingAndSorting().map(item =>
                                (<TableRow key={item.id}>
                                    <TableCell>{item.nombre}</TableCell>
                                    <TableCell>
                                        {/* {item.matricula} */}
                                        {
                                            (item.inscripcion === 'REY' || item.inscripcion === 'E/T')
                                                ? `${item.inscripcion} ${item.matricula}`
                                                : `${item.cuatrigrama} ${item.matricula}`
                                        }
                                    </TableCell>
                                    <TableCell>{item.desc}</TableCell>
                                    {/* <TableCell>{item.department}</TableCell> */}
                                    <TableCell>
                                        <Controls.ActionButton
                                            color="primary"
                                            onClick={() => { setIsOpen(true); openInPopup(item) }}
                                        // onClick={() => { despacharItem(item.id, item.nombre) }}
                                        // disabled={true}
                                        >
                                            {/* // onClick={() => { console.log(item.id) }}> */}
                                            <Tooltip title="Autorizar">
                                                <CallMadeIcon fontSize="small" />
                                            </Tooltip>
                                        </Controls.ActionButton>
                                        <Controls.ActionButton
                                            color="secondary"
                                            onClick={() => { dispatch(startClosePopup()); deleteItem(item.id) }}>
                                            <Tooltip title="Eliminar">
                                                <CloseIcon fontSize="small" />
                                            </Tooltip>
                                        </Controls.ActionButton>
                                    </TableCell>
                                </TableRow>)
                                )
                            }
                        </TableBody>
                    </TblContainer>
                    <TblPagination />
                    {/* </div> */}
                </Grid>
            </Grid>

            <Dialog
                open={isOpen}
                maxWidth='md'
                sx={{
                    padding: 2,
                    minHeight: (isMobile) ? '100vh' : 'unset'
                }}
            >
                <DialogTitle
                    sx={{
                        ml: 2,
                        padding: 1,
                        // zIndex: 10
                    }}
                >
                    <div style={{ display: 'flex' }}>
                        <Typography variant="h6" mt={1} component="div" style={{ flexGrow: 1 }}>
                            Editar embarcación...
                        </Typography>
                        <Controls.ActionButton
                            color="secondary"
                            onClick={() => { setIsOpen(false) }}>
                            <CloseIcon />
                        </Controls.ActionButton>
                    </div>
                </DialogTitle>
                <DialogContent dividers>
                    <EmbarcacionesForm recordForEdit={recordForEdit} addOrEdit={addOrEdit} />
                </DialogContent>
            </Dialog>

        </>
    )
}

export default EmbarcacionesPendientes;