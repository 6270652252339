import React, { useState } from 'react'
import { Table, TableHead, TableRow, TableCell, TablePagination, TableSortLabel } from '@mui/material'
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux'
import { startRowsPerPage, startTablePageChange } from '../actions/system'

const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: '900px',
        // maxWidth: '500px',
        marginTop: theme.spacing(3),
        '& thead th': {
            fontWeight: '600',
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.light,
        },
        '& tbody td': {
            fontWeight: '300',
        },
        '& tbody tr:hover': {
            backgroundColor: '#fffbf2',
            cursor: 'pointer',
        },
    },
    column: {
        '@media (max-width: 700px)': {
            display: 'none'
        },
    }
}))

const useTable = (records, headCells, filterFn,) => {

    const hideColumns = ['gender']

    if (records === undefined) {
        records = []
    }

    const classes = useStyles();
    const dispatch = useDispatch();
    const { page, rowsPerPage } = useSelector(state => state.system);

    const pages = [5, 10, 25]
    // const [page, setPage] = useState(0)
    // const [rowsPerPage, setRowsPerPage] = useState(pages[page])
    const [order, setOrder] = useState()
    const [orderBy, setOrderBy] = useState()

    const TblContainer = props => (
        <Table className={classes.table}>
            {props.children}
        </Table>
    )

    const TblHead = props => {

        const handleSortRequest = cellId => {
            const isAsc = orderBy === cellId && order === "asc";
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(cellId)
        }

        return (<TableHead>
            <TableRow>
                {
                    headCells.map(headCell => (
                        hideColumns.map(hideColumn => (
                            <>
                                <TableCell key={headCell.id}
                                    // className={(headCell.id === hideColumns[0]) ? classes.column : null}
                                    sx={[
                                        // '&:hover': {
                                        //     color: 'red',
                                        //     backgroundColor: 'white',
                                        // },
                                        headCell.id === hideColumns[0] && {
                                            '@media (max-width: 700px)': {
                                                display: 'none'
                                            }
                                        },
                                    ]}
                                    // sx={{
                                    //     // ...(color === 'primary' && {
                                    //     //     color: 'primary.main',
                                    //     //     backgroundColor: 'primary.light'
                                    //     // }),
                                    // }}
                                    sortDirection={orderBy === headCell.id ? order : false}>
                                    {headCell.disableSorting ? headCell.label :
                                        <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={() => { handleSortRequest(headCell.id) }}>
                                            {headCell.label}
                                        </TableSortLabel>
                                    }
                                </TableCell>
                            </>

                        ))
                    ))
                }
            </TableRow>
        </TableHead >)
    }

    const handleChangePage = (event, newPage) => {
        // setPage(newPage);
        dispatch(startTablePageChange(newPage))
    }

    const handleChangeRowsPerPage = event => {
        dispatch(startRowsPerPage(parseInt(event.target.value, 10)))
        // setRowsPerPage(parseInt(event.target.value, 10))
        // setPage(0);
        dispatch(startTablePageChange(0))

    }

    const TblPagination = () => (
        <TablePagination
            component="div"
            page={page}
            labelRowsPerPage='Filas por pagina:'
            rowsPerPageOptions={pages}
            rowsPerPage={rowsPerPage}
            count={records.length}
            // onChangePage={handleChangePage}
            onPageChange={handleChangePage}
            // onChangeRowsPerPage={handleChangeRowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    )

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    const recordsAfterPagingAndSorting = () => {
        return stableSort(filterFn.fn(records), getComparator(order, orderBy))
            .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
    }

    return {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting
    }
}

export default useTable;
