import { format } from "date-fns";
import Swal from "sweetalert2";
import { fetchConToken, fetchSinToken } from "../services/fetch";
import { types } from "../types/types";

export const startGetBoatsSumario = () => {

    return async (dispatch, getState) => {

        const { uid } = getState().auth;

        try {
            const resp = await fetchSinToken(`sumario/${uid}`, {});
            const body = await resp.json();

            if (body.ok) {

                dispatch(GetBoatsSumario(body))
                // console.log(body)

            } else {

                Swal.fire('Error', body.msg, 'error')
                // console.log(body)

            }

        } catch (error) {
            //throw new Error('Error en el sistema. Contactese con el administrador.')
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }
}

const GetBoatsSumario = (boats) => ({
    type: types.sumarioGetBoats,
    payload: boats
});

export const startGetTimonelesSumario = () => {

    return async (dispatch, getState) => {
        const { uid } = getState().auth;

        try {
            // console.log(event);
            const resp = await fetchConToken(`timonel/${uid}`, {});
            const body = await resp.json();

            if (body.ok) {
                dispatch(getTimonelesSumario(body.timonel))
                dispatch(getUsuarioTimonelesSumario(body.usuarioTimonel))
            } else {
                Swal.fire('Error', body.msg, 'error');
            }

        } catch (error) {
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }

}

const getTimonelesSumario = (people) => ({
    type: types.sumarioGetTimoneles,
    payload: people
});

const getUsuarioTimonelesSumario = (people) => ({
    type: types.sumarioGetUsuariosTimoneles,
    payload: people
});

export const inputSearchSumarioBoat = (texto) => ({
    type: types.sumarioSearchBoat,
    payload: texto
});

export const startGetSearchBoatSumario = () => {

    return async (dispatch, getState) => {

        const { uid } = getState().auth;
        const { search } = getState().sumario;
        // console.log(search)
        // if ( search === '' ) {
        //     dispatch(startGetBoats())
        // }

        try {
            const resp = await fetchConToken(`sumario/id/${uid}/search/${search}`, {});
            const body = await resp.json();

            if (body.ok) {

                dispatch(GetSearchBoatSumario(body.embarcacionesSumario))
                // console.log(body)

            } else {

                Swal.fire('Error', body.msg, 'error')
                // console.log(body)

            }

        } catch (error) {
            //throw new Error('Error en el sistema. Contactese con el administrador.')
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }

    // return async (dispatch) => {
    //     console.log(text)
    // }

}

const GetSearchBoatSumario = (boat) => ({
    type: types.sumarioGetSearchBoat,
    payload: boat
});

export const startContravencionPendientesGet = () => {

    return async (dispatch) => {
        // const { uid } = getState().auth;

        try {
            const resp = await fetchConToken(`verificacion/pendientes/all`, {});
            const body = await resp.json();

            if (body.ok) {
                // console.log(body.verificaciones)
                dispatch(contravencionPendientesGet(body.verificacionesPendientes))

            } else {
                Swal.fire('Error', body.msg, 'error');
            }

        } catch (error) {
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }

}

const contravencionPendientesGet = (pendientes) => ({
    type: types.embarcacionContravencionesPendientesGet,
    payload: pendientes
});


export const startContravencionGet = (idBoat) => {

    return async (dispatch) => {
        // const { uid } = getState().auth;

        try {
            const resp = await fetchConToken(`verificacion/${idBoat}`, {});
            const body = await resp.json();
            if (body.ok) {
                // console.log(body.verificaciones)
                dispatch(contravencionGet(body.verificaciones))
            } else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }

}

const contravencionGet = (idBoat) => ({
    type: types.embarcacionVerificacionGet,
    payload: idBoat
});

export const startContravencionTimonelGet = (idTimonel) => {
    return async (dispatch) => {
        // const { uid } = getState().auth;
        try {
            const resp = await fetchConToken(`verificacion/timonel/${idTimonel}`, {});
            const body = await resp.json();
            if (body.ok) {
                // console.log(body.verificaciones)
                dispatch(contravencionTimonelGet(body.verificacionesTimonel))
            } else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }
}

const contravencionTimonelGet = (idTimonel) => ({
    type: types.embarcacionVerificacionTimonelGet,
    payload: idTimonel
});

export const startContravencionUsuarioTimonelGet = (idTimonel) => {
    return async (dispatch) => {
        // const { uid } = getState().auth;
        try {
            const resp = await fetchConToken(`verificacion/usuariotimonel/${idTimonel}`, {});
            const body = await resp.json();
            if (body.ok) {
                // console.log(body.verificaciones)
                dispatch(contravencionUsuarioTimonelGet(body.verificacionesTimonel))
            } else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }
}

const contravencionUsuarioTimonelGet = (idTimonel) => ({
    type: types.embarcacionVerificacionUsuarioTimonelGet,
    payload: idTimonel
});

export const contravencionId = (idBoat) => ({
    type: types.sumarioContravencionId,
    payload: idBoat
});

export const startContravencionPut = (idBoat, idMulta, data, target) => {

    return async (dispatch, getState) => {
        const { name, surname } = getState().auth;

        const tempObj = {
            ...data,
            // funcionario: name
            funcionario: name + ' ' + surname,

        }

        // console.log(tempObj)
        try {
            const resp = await fetchConToken(`verificacion/${idMulta}`, tempObj, 'PUT');
            const body = await resp.json();

            if (body.ok) {
                if (target === 'boat') {
                    dispatch(startContravencionGet(idBoat))
                } else if (target === 'timonel') {
                    dispatch(startContravencionTimonelGet(idBoat))
                    dispatch(startContravencionUsuarioTimonelGet(idBoat));
                }
            } else {
                Swal.fire('Error', body.msg, 'error');
            }

        } catch (error) {
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }

}

export const asignacionContravencionPut = (id, datos) => {
    return async (dispatch, getState) => {
        // const { name } = getState().auth;

        try {
            const resp = await fetchConToken(`verificacion/pendiente/asigcontravencion/${id}`, datos, 'PUT');
            const body = await resp.json();
            if (body.ok) {
                dispatch(startContravencionPendientesGet());
                // Swal.fire('success', 'correcto', 'success');
            } else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }
}

export const startContravencionDelete = (idBoat, motivo) => {

    return async (dispatch, getState) => {
        const { name, surname } = getState().auth;
        // const today = format(new Date(), 'yyyy-MM-dd');

        const tempObj = {
            // funcionario: name,
            funcionario: name + ' ' + surname,
            // modificadoFecha: today,
            modificadoMotivo: motivo
        }

        try {
            const resp = await fetchConToken(`verificacion/${idBoat}`, tempObj, 'DELETE');
            const body = await resp.json();

            if (body.ok) {

                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Este registro fue eliminado exitosamente.',
                    // title: `Este registro fue eliminado exitosamente. ${today}`,
                    showConfirmButton: false,
                    timer: 2000
                })

            } else {
                Swal.fire('Error', body.msg, 'error');
            }

        } catch (error) {
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }
}

export const startSumarioNewContravencion = (item, multa, asignacion) => {
    return async (dispatch, getState) => {
        const { uid, name, surname } = getState().auth;

        /* 
            "embarcacionId": "8",
    "contravencion": "POC",
    "asignacionContravencion": "boat",
    "funcionario": "aranda",
    "resultado": true,
    "numActa": "568/9683",
    "estado": true
        */
        const objBody = {
            embarcacionId: (asignacion === 'boat') ? item.id : null,
            timonelUsuarioId: (item.password) ? item.id : null,
            // timonelId: (asignacion === 'timonel') ? item.id : null,
            timonelId: (item.password) ? null : item.id,
            contravencion: multa.contravencion.toUpperCase(),
            asignacionContravencion: asignacion,
            // funcionario: name,
            funcionario: name + ' ' + surname,
            resultado: true,
            numActa: multa.numActa,
            estado: true

            // embarcacionId: uid,
            // timonelId: timonel.id,
            // contravencion: (multa === '') ? null : multa,
            // asignacionContravencion: 'pendiente',
            // funcionario: name,
            // resultado: boat.novedad,
            // numActa: (boat.numActa === '') ? null : boat.numActa,


            // contravencion: multa,
            // numActa: boat.numActa
            // estado: true
            // modificadoPor: modifPor,
            // modificadoFecha: modifFecha,
            // modificadoMotivo: modifMotivo,
        }
        try {
            const resp = await fetchConToken(`sumario/new/${uid}`, objBody, 'POST');
            const body = await resp.json();
            if (body.ok) {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2500,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })
                Toast.fire({
                    icon: 'success',
                    title: 'Esta verificación se realizo correctamente.'
                })
            } else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }
}