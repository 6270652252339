import Swal from "sweetalert2";

const baseUrl = process.env.REACT_APP_API_URL;
/* 
    baseUrl, dev o prod?:
    REACT_APP_API_URL=http://localhost:4000/api
    REACT_APP_API_URL=https://blabla.com/api

*/

const fetchSinToken = (endpoint, data, method = 'GET') => {
    const url = `${baseUrl}/${endpoint}`;

    if (method === 'GET') {
        return fetch(url);
    } else {
        return fetch(url, {
            method,
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify(data)
        }).catch(err => {
            // console.log(err.message)
            if (err.message === 'NetworkError when attempting to fetch resource.') {
                Swal.fire('Error', 'Error de conexión de red.', 'error');
            }
        });
    }
}

const fetchConToken = (endpoint, data, method = 'GET') => {

    const url = `${baseUrl}/${endpoint}`;
    //Esto puede regresar un null por eso el || '', sino un string vacio
    const token = localStorage.getItem('token') || '';

    if (method === 'GET') {
        return fetch(url, {
            method, // 'GET'
            headers: {
                'x-token': token
            }
        });
    } else {
        return fetch(url, {
            method,
            headers: {
                'Content-type': 'application/json',
                'x-token': token
            },
            body: JSON.stringify(data)
        }).catch(err => {
            // console.log(err.message)
            if (err.message === 'NetworkError when attempting to fetch resource.') {
                Swal.fire('Error', 'Error de conexión de red.', 'error');
            }
        });
    }
}

export {
    fetchSinToken,
    fetchConToken,

}