import { types } from '../types/types';

const initialState = {

    embarcaciones: [],
    // verificaciones: [],
    search: '',
    embarcacionPendiente: {}

}

export const embarcacionSenade = (state = initialState, action) => {

    switch (action.type) {

        case types.embarcacionSenadeGetBoats:
            return {
                ...state,
                embarcaciones: action.payload,
                // ...action.payload,
            }

        case types.embarcacionSenadePostBoats:
            return {
                ...state,
                embarcacionPendiente: action.payload,
                // ...action.payload,
            }

        case types.embarcacionSenadeSearchBoat:
            return {
                ...state,
                search: action.payload,
            }

        case types.embarcacionSenadeGetSearchBoat:
            return {
                ...state,
                embarcaciones: [...action.payload],
            }

        default:
            return state;
    }

}

