import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Alert, AlertTitle, Box, Button, Dialog, DialogContent, DialogTitle, FormHelperText, Grid, Paper, Step, StepLabel, Stepper, Tab, TableBody, TableCell, TableRow, Tabs, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import SailingIcon from '@mui/icons-material/Sailing';
import { isMobile } from 'react-device-detect';

import Swal from 'sweetalert2';

import Controls from "../../components/controls/Controls";
import PageHeader from '../../components/PageHeader';
import { startListDependenciasDB } from '../../actions/embarcacionesPrefectura';
import { startListClubesDB } from '../../actions/embarcacionesClubes';
import { Form, useForm } from '../../components/useForm';
import { startRegisterParticularUserPost, startRegisterUserPost } from '../../actions/admin';
import useTable from '../../components/useTable';
import { changeLoading } from '../../actions/system';
import { PdfViewer } from '../../components/PdfViewer';

// import Swal from 'sweetalert2';

const useStyles = makeStyles(theme => ({
    RegisterTextField: {
        marginBottom: '15px'
    },
    LoginTextField: {
        marginBottom: '15px'
    },
    containerOld: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
    },
    centerButton: {
        display: 'flex',
        justifyContent: 'center'
    },
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3)
    },

}));

const initialFValues = {
    rName: '',
    rApellido: '',
    rDni: '',
    rEmail: '',
    rTelefono: '',
    rDireccion: '',
    rPassword1: '',
    rPassword2: '',
    rFrom: '',
    rSelectClub: '',
    rSelectPrefeDepe: [],
    rSelectPrefe: [],
    categoriaCarnet: '',
    vencimientoCarnet: new Date()
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const getStyles = (name, permisos, theme) => {
    return {
        fontWeight:
            permisos.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const roleItems = [
    { id: 'polinave', nombre: 'Polinave' },
    { id: 'sumarios', nombre: 'Sumarios' },
    { id: 'operativo', nombre: 'Operativo' },
    { id: 'visor', nombre: 'Contrase / Radio / Visor' },
]

const fromItems = [
    { id: 'prefe', title: 'Prefectura' },
    { id: 'club', title: 'Entidad Nautica' },
]

const catCarnetItems = [
    { id: 1, nombre: 'N1' },
    { id: 2, nombre: 'N2' },
    { id: 3, nombre: 'N3' },
    { id: 4, nombre: 'N4' },
    { id: 5, nombre: 'N5' },
    { id: 6, nombre: 'N6' },
    { id: 7, nombre: 'N7' },
    { id: 8, nombre: 'N8' },
    { id: 9, nombre: 'N9' },
    { id: 10, nombre: 'NA' },
    { id: 11, nombre: 'NB' },
]

/* 
TODO: 
-A LA HORA DE DE REGISTRAR USUARIO, QUE VUELVA A LA PAGINA PRINCIPAL.
-BUSQUEDA EN CARNET DE POLINAVE Y SUMARIO
-CUANDO EDITAS CONTRAVENCIONES EN SUMARIO, PONE FECHA CORRECTA, PERO SI LA ELIMINAS PONE FECHA INCORRECTA ///ES POR EL SERVIDOOORRRR EL TIMEZONE
-CONTRAVENCION NUEVA EN EMBARCACIONES O TIMONELES QUE YA TIENEN CONTRAVENCIONES EN SUMARIOS
-EN OPERATIVO VER SI AL MOMENTO DE VERIFICAR LANCE UNA ALERTA DE ENCAUTACION DE UNA EMBARCACION EN EL AGUA QUE TENIA PROHIBICION DE NAVEGAR Y ESTE DATO APAREZCA EN VISOR
-EL BADGE EN POLINAVE AL ELIMINAR UNA EMBARCACION PENDIENTE NO SE ACTUALIZA (PERO SI AL MOMENTO DE GUARDARLA)
*/

const headCells = [
    { id: 'image', label: '' },
    { id: 'name', label: 'Nombre' },
    { id: 'mat', label: 'Matricula' },
    { id: 'actions', label: 'Acciones', disableSorting: true }
]

const RegisterScreen = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { clubes, dependencias = [] } = useSelector(state => state.admin);
    const { loading } = useSelector(state => state.system);
    const theme = useTheme();
    const [permisos, setPermisos] = useState([]);
    const [tabValue, setTabValue] = useState(0);
    const [filterFn] = useState({ fn: items => { return items; } })
    const [carnet, setCarnet] = useState({ imagePreview: null, fileToUpload: null })
    const [isLoading, setIsLoading] = useState(false);

    // STEPPER--------------------------------------------------------------------------------------

    const handleRegisterSubmit = () => {
        // if (activeStep === 0) {
        //     if (validate()) {
        //         if (!carnet.fileToUpload) return Swal.fire('Error', 'Es un requerimiento la carga de la imagen de su carnet de timonel.', 'error');
        //         setActiveStep((prevActiveStep) => prevActiveStep + 1);
        //     }
        // } else {
        //     if (!boats.length) return;

        // const obj = Object.assign(values, {carnet})
        // const objTemp = {
        //     ...values,
        //     carnet,
        //     boats
        // }

        // console.log({ ...values, boats, carnet })
        // console.log(values)
        // dispatch(startRegisterParticularUserPost(boats[0].imgMatricula.files[0], 'pruebaDir', 'file1'));
        if (validate()) {
            if (!carnet.fileToUpload) return Swal.fire('Error', 'Es un requerimiento la carga de la imagen de su carnet de timonel.', 'error');
            setIsLoading(true);
            dispatch(changeLoading(true));
            // dispatch(startRegisterParticularUserPost({ ...values, boats, carnet, setIsLoading }))
            dispatch(startRegisterParticularUserPost({ ...values, carnet, setIsLoading }))
                .then(() => {
                    dispatch(changeLoading(false));
                    navigate('/login');
                })
                .catch(err => {
                    // console.log(err.message)
                    dispatch(changeLoading(false));
                })
        }
        // dispatch(changeLoading(false));

        // startRegisterParticularUserPost({ ...values, boats, carnet, setIsLoading })
        // // .then(() => history.replace('/login'))
        // .then(() => navigate('/login', {replace: true}))
        // .catch((err) => {console.log(err);})
        // }
    };

    // FIN STEPPER--------------------------------------------------------------------------------------

    // const {
    //     TblContainer,
    //     TblHead,
    //     TblPagination,
    //     recordsAfterPagingAndSorting
    // } = useTable(boats, headCells, filterFn);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setPermisos(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const validate = (fieldValues = values) => {

        let temp = { ...errors }
        if ('rName' in fieldValues)
            temp.rName = fieldValues.rName.length !== 0
                ? (/^[a-z\s\u00C0-\u00ff]+$/i).test(fieldValues.rName)
                    ? ""
                    : "Nombre solo puede contener letras sin espacios."
                : "Nombre es requerido."

        if ('rApellido' in fieldValues)
            temp.rApellido = fieldValues.rApellido.length !== 0
                ? (/^[a-z\s\u00C0-\u00ff]+$/i).test(fieldValues.rApellido)
                    ? ""
                    : "Apellido solo puede contener letras."
                : "Apellido es requerido."

        if ('rDni' in fieldValues)
            temp.rDni = fieldValues.rDni.length !== 0
                ? (/^[0-9]*$/).test(fieldValues.rDni)
                    ? (fieldValues.rDni.length >= 7 && fieldValues.rDni.length <= 8)
                        ? ""
                        : "Dni debe contener 7 caracteres como minimo y 8 caracteres como maximo."
                    : "Dni solo puede contener numeros."
                : "Dni es requerido."

        if ('rDireccion' in fieldValues)
            temp.rDireccion = (fieldValues.rDireccion)
                ? ""
                : "Dirección es requerido."

        if ('rTelefono' in fieldValues)
            temp.rTelefono = fieldValues.rTelefono.length !== 0
                ? (/^[0-9]*$/).test(fieldValues.rTelefono)
                    ? (fieldValues.rTelefono.length >= 9 && fieldValues.rTelefono.length <= 11)
                        ? ""
                        : "Telefono debe contener 9 caracteres como minimo y 11 caracteres como maximo."
                    : "Telefono solo puede contener numeros."
                : "Telefono es requerido."

        if ('rEmail' in fieldValues)
            // temp.rEmail = (/$^|.+@.+..+/).test(fieldValues.rEmail) ? "" : "Email no es valido."
            // temp.rEmail = (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/).test(fieldValues.rEmail) ? "" : "Email no es valido."

            temp.rEmail = fieldValues.rEmail.length !== 0
                ? (/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/).test(fieldValues.rEmail)
                    // ? (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/).test(fieldValues.rEmail)
                    ? ""
                    : "Email no es valido."
                : "Email es requerido."

        if ('rPassword1' in fieldValues)
            temp.rPassword1 = fieldValues.rPassword1.length > 7 ? "" : "8 caracteres como minimo es requerido."
        if ('rPassword2' in fieldValues)
            temp.rPassword2 = fieldValues.rPassword2.length > 7 ? "" : "8 caracteres como minimo es requerido."

        if ('categoriaCarnet' in fieldValues)
            temp.categoriaCarnet = (fieldValues.categoriaCarnet)
                ? ""
                : "Categoria de su carnet es requerido."

        if ('rSelectClub' in fieldValues)
            temp.rSelectClub = (fieldValues.rSelectClub)
                ? ""
                : "Lugar es requerido."

        if ('rSelectPrefeDepe' in fieldValues)
            temp.rSelectPrefeDepe = (fieldValues.rSelectPrefeDepe.length !== 0)
                ? ""
                : "Dependencia es requerido."

        if ('rSelectPrefe' in fieldValues)
            temp.rSelectPrefe = (fieldValues.rSelectPrefe.length !== 0)
                ? ""
                : "Rol de trabajo es requerido."

        if ('rFrom' in fieldValues) {
            temp.rFrom = fieldValues.rFrom ? "" : "Inscripción es requerido."

            if (fieldValues.rFrom === 'prefe') {
                delete temp.rSelectClub
            } else {
                // delete temp.rSelectPrefeDepe
                delete temp.rSelectPrefe
            }
        }

        if (tabValue === 0) {
            delete temp.rSelectClub
            delete temp.rSelectPrefe
            delete temp.rFrom

        } else {
            delete temp.categoriaCarnet
            delete temp.rSelectPrefeDepe

            // delete temp.rSelectPrefeDepe
            delete temp.rDireccion
        }

        setErrors({
            ...temp
        })

        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }

    const {
        values,
        // setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFValues, true, validate);

    const handleRegister = (e) => {
        e.preventDefault();
        const { rName, rApellido, rFrom, rSelectClub, rSelectPrefe, rSelectPrefeDepe, rPassword1, rPassword2 } = values;
        const objTempToUpperCase = {
            rName: rName.toUpperCase(),
            rApellido: rApellido.toUpperCase(),
            rSelectClub: (rFrom === 'club') ? rSelectClub : '',
            rSelectPrefe: (rFrom === 'prefe') ? rSelectPrefe : [],
            rSelectPrefeDepe: (rFrom === 'prefe') ? rSelectPrefeDepe : []
        }
        const obj = Object.assign(values, objTempToUpperCase)

        if (rPassword1 !== rPassword2 || rPassword1 === '') {
            return Swal.fire('Error', 'Las contraseñas deben ser iguales.', 'error');
        }

        // if (rSelect === '') {
        //     return Swal.fire('Error', 'Debe seleccionar un rol de trabajo / lugar', 'error');
        // }

        // if (rFrom === 'prefe') {
        //     // const objjj = {}
        //     // const obj = rSelectPrefe.map((x) => {
        //     //     return objjj.append(x)
        //     // })
        //     // console.log(obj)
        //     const onn = JSON.stringify(rSelectPrefe)
        //     console.log(onn)
        // }

        if (validate()) {
            dispatch(changeLoading(true));
            // dispatch(startRegisterUserPost(obj));
            dispatch(startRegisterUserPost(obj))
                .then(() => {
                    dispatch(changeLoading(false));
                    navigate('/login');
                })
                .catch(err => {
                    // console.log(err.message)
                    dispatch(changeLoading(false));
                })
            // resetForm()
        }
    }

    const tabHandleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const carnetHandleChange = (e) => {
        // console.log(e.target.files[0].name)
        if (e.target.files[0].name) {
            var parts = e.target.files[0].name.split('.');
            // if (parts[parts.length - 1].toLowerCase() === 'png' || parts[parts.length - 1].toLowerCase() === 'jpeg') {
            if (['png', 'jpg', 'jpeg', 'pdf'].includes(parts[parts.length - 1].toLowerCase())) {
                setCarnet({ imagePreview: URL.createObjectURL(e.target.files[0]), fileToUpload: e.target.files[0] })
                return true;
            } else {
                setCarnet({ imagePreview: null, fileToUpload: null })
                Swal.fire('Error', 'Error en la extension de archivo, solo se admitirá jpg, jpeg, png y pdf.', 'error');
                return false;
            }
        } else {
            return true;
        }
        // setCarnet({ imagePreview: URL.createObjectURL(e.target.files[0]), fileToUpload: e.target.files[0] })
    }

    useEffect(() => {
        dispatch(startListClubesDB());
        dispatch(startListDependenciasDB());
    }, [dispatch])

    return (
        <>
            <PageHeader
                title="Registrar usuarios"
                subTitle="Agregar usuarios al sistema"
                icon={<DirectionsBoatIcon fontSize="large" />}
            />

            <Paper className={classes.pageContent}>
                <Tabs value={tabValue} onChange={tabHandleChange} centered>
                    <Tab label="PARTICULARES" disabled={loading} />
                    <Tab label="ENTIDADES" disabled={loading} />
                </Tabs>
            </Paper>

            <Paper className={classes.pageContent}>
                <Form onSubmit={handleRegister}>
                    {
                        (tabValue === 1) ?
                            <Grid container sx={{ mt: 1, mb: 1 }}>
                                <Grid item xs={6}>
                                    <Controls.Input
                                        name="rName"
                                        label="Nombre*"
                                        value={values.rName.toUpperCase()}
                                        onChange={handleInputChange}
                                        error={errors.rName}
                                        autoFocus
                                        className={classes.RegisterTextField}
                                    />
                                    <Controls.Input
                                        name="rApellido"
                                        label="Apellido*"
                                        value={values.rApellido.toUpperCase()}
                                        onChange={handleInputChange}
                                        className={classes.RegisterTextField}
                                        error={errors.rApellido}
                                    />
                                    <Controls.Input
                                        name="rDni"
                                        label="Dni*"
                                        value={values.rDni}
                                        onChange={handleInputChange}
                                        error={errors.rDni}
                                        className={classes.RegisterTextField}
                                    />
                                    <Controls.Input
                                        name="rTelefono"
                                        label="Telefono*"
                                        value={values.rTelefono}
                                        onChange={handleInputChange}
                                        className={classes.RegisterTextField}
                                        // autoFocus
                                        error={errors.rTelefono}
                                    />
                                    <Controls.Input
                                        name="rEmail"
                                        label="Email*"
                                        value={values.rEmail.toLowerCase()}
                                        onChange={handleInputChange}
                                        className={classes.RegisterTextField}
                                        // autoFocus
                                        error={errors.rEmail}
                                        autoComplete='off'
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <Controls.Input
                                        name="rPassword1"
                                        type="password"
                                        label="Password*"
                                        value={values.rPassword1}
                                        onChange={handleInputChange}
                                        className={classes.RegisterTextField}
                                        error={errors.rPassword1}
                                        autoComplete='off'
                                    />
                                    <Controls.Input
                                        name="rPassword2"
                                        type="password"
                                        label="Repita su Password*"
                                        value={values.rPassword2}
                                        onChange={handleInputChange}
                                        className={classes.RegisterTextField}
                                        error={errors.rPassword2}
                                    />
                                    <Controls.RadioGroup
                                        name="rFrom"
                                        label="Usted pertenece a:"
                                        value={values.rFrom}
                                        onChange={handleInputChange}
                                        // onClick={() => { (values.rFrom === 'club') ? values.rSelectPrefe = [] : values.rSelectClub = '' }}
                                        items={fromItems}
                                        error={errors.rFrom}
                                    />
                                    {
                                        (values.rFrom === 'club') &&
                                        <>
                                            {/* <Controls.Select
                                        name="rSelectCity"
                                        label="Ciudad"
                                        value={values.rSelectCity}
                                        onChange={handleInputChange}
                                        options={clubes.filter(obj => obj.nombre !== 'Propio')}
                                        error={errors.rSelectCity}
                                    /> */}
                                            <Controls.Select
                                                name="rSelectClub"
                                                label="Lugar"
                                                value={values.rSelectClub}
                                                onChange={handleInputChange}
                                                // options={clubes}
                                                options={clubes.filter(obj => obj.nombre !== 'Propio')}
                                                error={errors.rSelectClub}
                                            />
                                        </>
                                    }
                                    {
                                        (values.rFrom === 'prefe') &&
                                        <>
                                            {/* <Controls.Select
                                        name="rSelectDepe"
                                        label="Dependencia"
                                        value={values.rSelectDepe}
                                        onChange={handleInputChange}
                                        options={clubes.filter(obj => obj.nombre !== 'Propio')}
                                        error={errors.rSelectDepe}
                                    /> */}
                                            <FormControl error={errors.rSelectPrefeDepe}>
                                                <InputLabel>Dependencia</InputLabel>
                                                <Select
                                                    name="rSelectPrefeDepe"
                                                    label="Dependencia"
                                                    value={values.rSelectPrefeDepe || ''}
                                                    onChange={handleInputChange}
                                                // renderValue={(value) => `⚠️  - ${value}`}
                                                >
                                                    {dependencias.map(
                                                        item => (<MenuItem key={item.id} value={item.cuatrigrama}>{item.cuatrigrama} - {item.nombre}</MenuItem>)
                                                    )}
                                                </Select>
                                                {errors.rSelectPrefeDepe && <FormHelperText>{errors.rSelectPrefeDepe}</FormHelperText>}
                                            </FormControl>
                                            <FormControl sx={{ m: 1, width: 300 }} error={errors.rSelectPrefe}>
                                                <InputLabel>Rol de trabajo</InputLabel>
                                                <Select
                                                    name="rSelectPrefe"
                                                    multiple
                                                    value={values.rSelectPrefe}
                                                    // onChange={handleChange}
                                                    onChange={handleInputChange}
                                                    input={<OutlinedInput label="Rol de trabajo" />}
                                                    renderValue={(selected) =>
                                                    // PARA SELECCIONAR LOS MISMOS NOMBRES QUE APARECEN EN LA LISTA SIN CAMBIAR EL ID
                                                    // (
                                                    //     <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    //         {selected.map((value) => (
                                                    //             <Chip key={value} label={value} />
                                                    //         ))}
                                                    //     </Box>
                                                    // )
                                                    (
                                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                            {
                                                                selected.map((value) => {
                                                                    const filt = roleItems.filter((el) => el.id === value)
                                                                    return filt.map(({ nombre }) => (
                                                                        <Chip key={nombre} label={nombre} />
                                                                    ))
                                                                })
                                                            }
                                                        </Box>
                                                    )}
                                                    MenuProps={MenuProps}
                                                >
                                                    {roleItems.map((name) => (
                                                        <MenuItem
                                                            key={name.nombre}
                                                            value={name.id}
                                                            style={getStyles(name.nombre, permisos, theme)}
                                                        >
                                                            {name.nombre}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {errors.rSelectPrefe && <FormHelperText>{errors.rSelectPrefe}</FormHelperText>}
                                            </FormControl>
                                        </>
                                    }
                                    <Box sx={{ ml: .5 }}>
                                        <Controls.Button
                                            text="Registrar..."
                                            sx={{ marginTop: 1, ...(loading) && { color: 'gray' } }}
                                            disabled={loading}
                                            // variant="outlined"
                                            type="submit"
                                            startIcon={<PersonAddIcon />}
                                            className={classes.centerButton}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            :   // PARTICULARES SCREEEN-----------------------------------------------------------------------------
                            <>
                                <Grid container sx={{ mt: 3 }}>
                                    <Grid item xs={6} >
                                        <Controls.Input
                                            name="rName"
                                            label="Nombre*"
                                            value={values.rName.toUpperCase()}
                                            onChange={handleInputChange}
                                            error={errors.rName}
                                            autoFocus
                                            className={classes.RegisterTextField}
                                        />
                                        <Controls.Input
                                            name="rApellido"
                                            label="Apellido*"
                                            value={values.rApellido.toUpperCase()}
                                            onChange={handleInputChange}
                                            className={classes.RegisterTextField}
                                            error={errors.rApellido}
                                        />
                                        <Controls.Input
                                            name="rDni"
                                            label="Dni*"
                                            value={values.rDni}
                                            onChange={handleInputChange}
                                            error={errors.rDni}
                                            className={classes.RegisterTextField}
                                        />
                                        <Controls.Input
                                            name="rDireccion"
                                            label="Dirección*"
                                            value={values.rDireccion.toUpperCase()}
                                            onChange={handleInputChange}
                                            className={classes.RegisterTextField}
                                            error={errors.rDireccion}
                                        />
                                        <Controls.Input
                                            name="rTelefono"
                                            label="Telefono*"
                                            value={values.rTelefono}
                                            onChange={handleInputChange}
                                            className={classes.RegisterTextField}
                                            // autoFocus
                                            error={errors.rTelefono}
                                        />
                                        <FormControl error={errors.rSelectPrefeDepe}>
                                            <InputLabel>Prefectura jurisdiccional perteneciente</InputLabel>
                                            <Select
                                                name="rSelectPrefeDepe"
                                                label="Prefectura jurisdiccional perteneciente"
                                                value={values.rSelectPrefeDepe || ''}
                                                onChange={handleInputChange}
                                            // renderValue={(value) => `⚠️  - ${value}`}
                                            >
                                                {dependencias.map(
                                                    item => (<MenuItem key={item.id} value={item.cuatrigrama}>{item.nombre}</MenuItem>)
                                                )}
                                            </Select>
                                            {errors.rSelectPrefeDepe && <FormHelperText>{errors.rSelectPrefeDepe}</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controls.Input
                                            name="rEmail"
                                            label="Email*"
                                            value={values.rEmail.toLowerCase()}
                                            onChange={handleInputChange}
                                            className={classes.RegisterTextField}
                                            // autoFocus
                                            error={errors.rEmail}
                                            autoComplete='off'
                                        />
                                        <Controls.Input
                                            name="rPassword1"
                                            type="password"
                                            label="Password*"
                                            value={values.rPassword1}
                                            onChange={handleInputChange}
                                            className={classes.RegisterTextField}
                                            error={errors.rPassword1}
                                            autoComplete='off'
                                        />
                                        <Controls.Input
                                            name="rPassword2"
                                            type="password"
                                            label="Repita su Password*"
                                            value={values.rPassword2}
                                            onChange={handleInputChange}
                                            className={classes.RegisterTextField}
                                            error={errors.rPassword2}
                                        />
                                        <Controls.Select
                                            name="categoriaCarnet"
                                            label="Seleccionar categoria de su carnet timonel"
                                            value={values.categoriaCarnet}
                                            onChange={handleInputChange}
                                            options={catCarnetItems}
                                            error={errors.categoriaCarnet}
                                        />
                                        <Controls.DatePickerControl
                                            name="vencimientoCarnet"
                                            label="Fecha vencimiento carnet timonel"
                                            past={true}
                                            value={values.vencimientoCarnet}
                                            onChange={handleInputChange}
                                            error={errors.vencimientoCarnet}
                                        />
                                        <Box sx={{ p: 2 }}>
                                            <Grid container flexDirection={'row'}>
                                                <Grid item container>
                                                    <Typography
                                                        sx={{ ml: 1 }}
                                                        color={'#686769'}
                                                    > Carga de Imagenes:</Typography>
                                                </Grid>
                                                <Grid item container flexDirection={'row'}>

                                                    <Grid item>
                                                        <Button sx={{ m: 0.5, textTransform: 'none' }} startIcon={<PersonAddIcon />} size={"large"} variant="contained" component="label">
                                                            <Typography sx={{ position: 'relative', bottom: -2 }}>
                                                                Imagen carnet timonel
                                                            </Typography>
                                                            <input
                                                                hidden
                                                                accept="image/png, image/jpg, image/jpeg, application/pdf"
                                                                // multiple
                                                                type="file"
                                                                onChange={carnetHandleChange}
                                                            />
                                                        </Button>
                                                    </Grid>

                                                    <Grid item container sm justifyContent={'flex-end'}>
                                                        {/* {
                                                                    (carnet.imagePreview) &&
                                                                    // <Box sx={{height: 50, width: 50}}>
                                                                    <img
                                                                        style={{ width: 120, height: 100, objectFit: 'contain' }}
                                                                        src={carnet.imagePreview}
                                                                        // style={styles.image}
                                                                        alt="Thumb"
                                                                    />
                                                                    // </Box>
                                                                } */}

                                                        {
                                                            (carnet.imagePreview)
                                                                ? (carnet.fileToUpload.name.split('.').pop() === 'pdf')
                                                                    ? <PdfViewer
                                                                        sx={{ cursor: 'zoom-in' }}
                                                                        url={carnet.imagePreview}
                                                                    />
                                                                    : <img
                                                                        style={{ width: 120, height: 100, objectFit: 'contain' }}
                                                                        src={carnet.imagePreview}
                                                                        // style={styles.image}
                                                                        alt="Thumb"
                                                                    />
                                                                : null
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                    {/* <Controls.Button
                                            text="prueba..."
                                            // variant="outlined"
                                            // type="submit"
                                            // startIcon={<PersonAddIcon />}
                                            // className={classes.centerButton}
                                            onClick={() => dispatch(startRegisterParticularUserPost({ ...values, boats, carnet }))}
                                        /> */}
                                </Grid>



                                {/* {activeStep !== steps.length &&
                                    <> */}
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, mb: 2 }}>

                                    <Box sx={{ flex: '1 1 auto' }} />

                                    {/* <Controls.Button
                                        text={activeStep === steps.length - 1 ? 'Registrar' : 'Siguiente'}
                                        disabled={(activeStep === 1) && (!boats.length)}
                                        onClick={handleNext}
                                    /> */}
                                    <Controls.Button
                                        text={'Registrar'}
                                        disabled={isLoading}
                                        onClick={handleRegisterSubmit}
                                        loading={isLoading}
                                    />

                                </Box>
                                {/* </>
                                } */}
                            </>
                    }
                </Form>
            </Paper>
        </>
    )
}

export default RegisterScreen;
