import { types } from '../types/types';

const initialState = {
    // systemPopupTitle: '[system] Title',
    // systemPopupSize: '[system] Size',
    // systemPopupChild: '[system] Child',

    titlePopup: '',
    sizePopup: '',
    childPopup: '',
    statusPopup: false,
    statusPopupAlternativo: false,
    page: 0,
    rowsPerPage: 5,
    tableCollapseOpenClose: false,
    widthWindowsSize: window.innerWidth,
    systemRadioTableSumario: 'MAT',
    loading: false
}

export const systemReducer = (state = initialState, action) => {

    switch (action.type) {

        case types.systemPopupTitle:
            return {
                ...state,
                titlePopup: action.payload,
            }

        case types.systemPopupSize:
            return {
                ...state,
                sizePopup: action.payload,
            }

        case types.systemPopupChild:
            return {
                ...state,
                childPopup: action.payload,
            }

        case types.systemOpenPopup:
            return {
                ...state,
                statusPopup: true,
            }

        case types.systemClosePopup:
            return {
                ...state,
                statusPopup: false,
            }

        case types.systemOpenPopupAlternativo:
            return {
                ...state,
                statusPopupAlternativo: true,
            }

        case types.systemClosePopupAlternativo:
            return {
                ...state,
                statusPopupAlternativo: false,
            }

        case types.systemPage:
            return {
                ...state,
                page: action.payload
            }

        case types.systemRowsPerPage:
            return {
                ...state,
                rowsPerPage: action.payload
            }

        case types.systemTableOpenCloseCollapse:
            return {
                ...state,
                tableCollapseOpenClose: action.payload
            }

        case types.systemWindowSizeWidth:
            return {
                ...state,
                widthWindowsSize: action.payload
            }

        case types.systemRadioTableSumario:
            return {
                ...state,
                systemRadioTableSumario: action.payload
            }

        case types.systemLoading:
            return {
                ...state,
                loading: action.payload
            }

        default:
            return state;
    }

}

