import React, { useEffect, useState } from 'react'
import PageHeader from '../../components/PageHeader'
import { Alert, AlertTitle, Box, Card, Dialog, DialogContent, DialogTitle, Paper, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import { startEmbarcacionesTimonelPorIdDB, startQRDespachar, startTimonelDespacho, startTimonelPorIdDB } from '../../actions/embarcacionesTimonel';
import DespachoForm from './DespachoForm';
import CropFreeIcon from '@mui/icons-material/CropFree';
import CloseIcon from '@mui/icons-material/Close';

import Controls from '../../components/controls/Controls';
import { QrReader } from 'react-qr-reader';
import { isMobile } from 'react-device-detect';
import { ImageLoading } from '../../components/ImageLoading';
import { changeLoading } from '../../actions/system';

export const Despacho = () => {
  const dispatch = useDispatch();
  const { name, place } = useSelector(state => state.auth);
  const { embarcaciones = [], timonel } = useSelector(state => state.embarcacionTimonel);
  const { nombre, dni, telefono, categoriaCarnet, vencimientoCarnet } = timonel;
  const [qrData, setQRData] = useState('');
  const [isOpenQRPopup, setIsOpenQRPopup] = useState(false);
  const [isOpenQRComponent, setIsOpenQRComponent] = useState(false);
  const [parseEmbarcaciones, setParseEmbarcaciones] = useState([]);
  const [showSeleccion, setShowSeleccion] = useState({
    state: false,

    id: null,
    capacidadPersonas: null,

    nombrePropietario: null,
    dniPropietario: null,
    telefonoPropietario: null,
    categoriaCarnet: null,
    qr: null,
    despachada: null,
    usuariosEmbarcaciones: []
  });

  useEffect(() => {
    dispatch(startEmbarcacionesTimonelPorIdDB())
    dispatch(startTimonelPorIdDB());
  }, [])

  useEffect(() => {
    setShowSeleccion({
      state: false,

      id: null,
      capacidadPersonas: null,

      nombrePropietario: null,
      dniPropietario: null,
      telefonoPropietario: null,
      categoriaCarnet: null,
      qr: null,
      despachada: null,
      usuariosEmbarcaciones: []
    })
  }, [embarcaciones])

  // console.log({timonel})

  const processFormData = (item) => {
    // const data = {
    //   ...item,
    //   autorizado: false,
    //   titular: true,
    //   nombrePropietario: nombre,
    //   dniPropietario: dni,
    //   telefonoPropietario: telefono,
    //   catCarnet: categoriaCarnet,
    //   fechaCarnet: vencimientoCarnet,
    //   rol: "titular",
    // }
    // console.log({...data, ...item})
    // console.log(showSeleccion.id)
    // dispatch(startTimonelDespacho(showSeleccion.id, data, true));
    dispatch(changeLoading(true));
    dispatch(startTimonelDespacho({ id: showSeleccion.id, ...item }, true));
  }

  const handleSubmit = (result) => {
    setIsOpenQRComponent(false);
    // setQRData(result?.text);
    // ESTA ES LA LECTURA DESDE TIMONEL, TENGO QUE MANDAR EL ID EMBARCACION QUE ES LO DEL QR,
    // OSEA EL RESULTADO AL HTTP REQUEST, EL ID DEL TIMONEL, COMPROBAR QUE SEA SU EMBARCACION Y
    // COMPROBAR QUE NO ESTE DESPACHADA
    dispatch(startQRDespachar(result.text))
    setIsOpenQRPopup(false);
  }

  useEffect(() => {
    const filteredEmbarcaciones = embarcaciones.filter(el => (
      el.usuariosEmbarcaciones.length > 0 &&
      el.usuariosEmbarcaciones[0].pendiente === false &&
      el.pendiente === false
    ));
    setParseEmbarcaciones(filteredEmbarcaciones);
  }, [embarcaciones]);

  return (
    <>
      <PageHeader
        title={`Despachar su embarcación`}
        subTitle={`Prefectura ${place}`}
        icon={<DirectionsBoatIcon fontSize="large" />}
        profile={true}
        name={name}
      />

      <Paper sx={{ m: 5, p: 3 }}>
        <Typography variant="custom6" gutterBottom component="div" align='center' sx={{ p: 5, mt: 3 }}>
          Seleccione embarcación a despachar:
        </Typography>

        <Box sx={{ display: 'flex', gap: 3, justifyContent: 'center' }}>
          {
            (!parseEmbarcaciones.length)
              ? <Alert severity="info">
                <AlertTitle>Información:</AlertTitle>
                Si usted cargo sus embarcaciones, los administradores deben procesarlas para que ud. pueda manipularlas.
              </Alert>
              : parseEmbarcaciones.map(({ id, categoriaCarnet, capacidadPersonas, nombre, inscripcion, matricula, imgEmbarcacionUrl, qr, despachada, usuariosEmbarcaciones }) => (
                <Card key={id} onClick={() => { setShowSeleccion({ state: true, id, categoriaCarnet, capacidadPersonas, qr, despachada, usuariosEmbarcaciones }) }} sx={{
                  height: '100px',
                  width: '350px',
                  display: 'flex',
                  cursor: 'pointer',
                  ...(showSeleccion.id === id && {
                    border: '1px solid blue'
                  }),
                }}>
                  <ImageLoading key={imgEmbarcacionUrl} sx={{ width: 150, height: 100 }}
                    src={`${process.env.REACT_APP_API_URL_FOR_IMAGES}/boatsImages/${imgEmbarcacionUrl}`}
                  />
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexGrow: 1,
                    backgroundColor: '#dfdfdf'
                  }}>
                    <Typography variant="h6" component="div" align='center' sx={{ userSelect: 'none' }}>
                      {nombre}
                    </Typography>
                    <Typography variant="h6" component="div" align='center' sx={{ userSelect: 'none' }}>
                      {`${inscripcion} ${matricula}`}
                    </Typography>
                  </Box>
                </Card>
              ))
          }
        </Box>

        {/* <Typography variant="h6" component="div" align='center' sx={{ userSelect: 'none' }}>
          {JSON.stringify(embarcaciones, null, 3)}
        </Typography>

        <Typography variant="h6" component="div" align='center' sx={{ userSelect: 'none', mt: 10 }}>
          {JSON.stringify(showSeleccion, null, 3)}
        </Typography> */}

        {(showSeleccion.state) && (
          <Box sx={{ mt: 5 }}>
            {
              (showSeleccion.despachada === true)
                ? <Alert severity="success">
                  <AlertTitle>Exito:</AlertTitle>
                  Su embarcación fue correctamente despachada, buena navegación.
                </Alert>
                : (!showSeleccion.qr)
                  ? <DespachoForm
                    saveDataDespacho={processFormData}
                    data={showSeleccion}
                  // maxTripulantes={showSeleccion.capacidadPersonas}
                  />
                  : <>
                    <Alert severity="info">
                      <AlertTitle>Información:</AlertTitle>
                      Su embarcación fue correctamente despachada, recuerde tiene un lapso de 24hs antes que se caiga su despacho. Para poder escanear su QR con su telefono y concluir el despacho para navegar, cliquee el boton de abajo. Si usted esta en una pc este boton no se mostrara.
                    </Alert>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
                      {
                        (isMobile) &&
                        <Controls.Button
                          text="Escanear QR"
                          variant="contained"
                          startIcon={<CropFreeIcon />}
                          onClick={() => { setIsOpenQRPopup(true); setIsOpenQRComponent(true); }}
                        />
                      }

                      {/* {showQRReader ? (
                      <QrReader
                        delay={300}
                        onError={handleError}
                        onScan={handleScan}
                        style={{ width: '100%' }}
                      />
                    ) : (
                      <Controls.Button
                        text="Escanear QR"
                        variant="contained"
                        startIcon={<CropFreeIcon />}
                        onClick={() => setShowQRReader(true)} // mostrar el lector QR al hacer clic en el botón
                      />
                    )}
                    {result && <p>{result}</p>} */}

                      {/* <QrReader
                      onResult={(result, error) => {
                        if (!!result) {
                          setData(result?.text);
                        }

                        if (!!error) {
                          console.info(error);
                        }
                      }}
                      style={{ width: '100%' }}
                    />
                    <p>{data}</p> */}



                    </Box>
                  </>
            }
          </Box>
        )}
      </Paper>

      <Dialog open={isOpenQRPopup} maxWidth='md' fullScreen={(isMobile ? true : false)} sx={{
        // padding: 2,
        // minHeight: (isMobile) ? '100vh' : 'unset'
      }}>
        <DialogTitle sx={{
          ml: 2,
          padding: 1,
        }}>
          <div style={{ display: 'flex' }}>
            {/* <div style={{ position: 'absolute', right: 20, top: 20 }}> */}
            {/* {
              (isMobile) && */}
            <Typography mt={1} variant="h6" component="div" style={{ flexGrow: 1 }}>
              Scanner QR
            </Typography>
            {/* } */}
            <Controls.ActionButton
              color="secondary"
              onClick={() => { setIsOpenQRComponent(false); setIsOpenQRPopup(false) }}>
              <CloseIcon />
            </Controls.ActionButton>
          </div>
        </DialogTitle>
        <DialogContent dividers>

          <Box sx={{ display: 'flex', justifyContent: 'center', ...(!!qrData && { backgroundColor: 'green' }) }}>
            {
              (isOpenQRComponent) &&
              <QrReader
                scanDelay={200}
                containerStyle={{ width: '25%' }}
                constraints={{ facingMode: 'environment' }}
                onResult={(result, error) => {
                  if (!!result) {
                    handleSubmit(result);
                  }
                }}
              />
            }
          </Box>

          {/* {
            (!!qrData) &&
            <Typography mt={1} variant="h6" component="div" style={{ flexGrow: 1 }}>
              {qrData}
            </Typography>
          } */}

        </DialogContent>
      </Dialog>
    </>
  )
}

