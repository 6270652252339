import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material'
import { Alert, AlertTitle } from '@mui/lab';
import { startSumarioNewContravencion } from '../../actions/embarcacionesSumario';
import { startClosePopup } from '../../actions/system';
import Controls from '../../components/controls/Controls'
import { useForm, Form } from '../../components/useForm';

export const SumarioFormCarga = ({ saveData, nuevo }) => {
    const dispatch = useDispatch();
    const { recordForEdit } = useSelector(state => state.embarcacion);

    const initialFValues = {
        contravencion: '',
        motivo: (!nuevo) ? '' : null,
        numActa: (nuevo) ? '' : null,
        fecha: new Date(),
    }

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('contravencion' in fieldValues) {
            temp.contravencion = fieldValues.contravencion ? "" : "Contravención es requerido."
        }
        if ('motivo' in fieldValues) {
            temp.motivo = fieldValues.motivo ? "" : "Motivo no valido."
        }
        if (nuevo) {
            if ('numActa' in fieldValues)
                temp.numActa = fieldValues.numActa ? "" : "Numero de acta no valido."
        }

        setErrors({
            ...temp
        })

        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }

    const {
        values,
        // setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFValues, true, validate);

    const handleSubmit = e => {
        e.preventDefault();
        if (nuevo === true) {
            dispatch(startSumarioNewContravencion(recordForEdit, values, 'boat'));
            dispatch(startClosePopup());
            // console.log(values)
            // console.log(recordForEdit)
        } else if (validate()) {
            saveData(values, resetForm);
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Grid container>
                <Grid item>
                    {
                        (nuevo === true)
                            ? <Alert severity="info">
                                <AlertTitle>Información:</AlertTitle>
                                Esta embarcación no tiene contravenciones.
                                Para aplicar una, complete los campos debajo.
                            </Alert>

                            : <Alert severity="info">
                                <AlertTitle>Información:</AlertTitle>
                                Tenga en cuenta que al editar estos datos, <strong>sus credenciales quedaran grabadas conjuntamente.</strong>
                            </Alert>
                    }
                    <Controls.Input
                        name="contravencion"
                        label="Contravención"
                        value={values.contravencion}
                        onChange={handleInputChange}
                        error={errors.contravencion}
                    />
                    {
                        (!nuevo) &&
                        <Controls.Input
                            name="motivo"
                            label="Motivo"
                            value={values.motivo}
                            onChange={handleInputChange}
                            error={errors.motivo}
                        />
                    }
                    {
                        (nuevo) &&
                        <Controls.Input
                            name="numActa"
                            label="Numero de acta"
                            value={values.numActa}
                            onChange={handleInputChange}
                            error={errors.numActa}
                        />
                    }
                    <Grid container justify="flex-end">
                        <Grid item>
                            <div>
                                <Controls.Button
                                    type="submit"
                                    text="Guardar" />
                                <Controls.Button
                                    text="Reset"
                                    color="default"
                                    onClick={resetForm} />
                            </div>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </Form>
    )
}
