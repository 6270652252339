import Swal from 'sweetalert2';
import { fetchConToken, fetchSinToken } from '../services/fetch';
import { types } from '../types/types';

export const startSenadeGetBoats = () => {

    return async (dispatch, getState) => {
        const { uid } = getState().auth;

        try {
            // console.log(event);
            const resp = await fetchSinToken(`senade/${uid}`, {});
            const body = await resp.json();

            if (body.ok) {
                // body.embarcaciones.map((i) => {
                // console.log(i.id)
                // dispatch(startSenadeGetBoatsResults(i.id))
                // })
                dispatch(senadeGetBoats(body.embarcaciones))
                // console.log(body.embarcaciones)
            } else {
                Swal.fire('Error', body.msg, 'error');
            }

        } catch (error) {
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }

}

const senadeGetBoats = (boats) => ({
    type: types.embarcacionSenadeGetBoats,
    payload: boats
});

export const startSenadePostNewBoats = (boat, timonel) => {
    return async (dispatch, getState) => {
        try {
            const resp = await fetchConToken(`senade`, boat, 'POST');
            const body = await resp.json();
            // console.log(boat)
            if (body.ok) {
                if (body.existe) {
                    if (body.prohibicion) {
                        return dispatch(startSenadePostVerificacion(body.embarcacion.id, 'INCAUTACION_PROHIBICION', boat, timonel))
                    }
                }
                if (boat.novedad) {
                    // console.log('entro en novedad')
                    if (boat.timonelRegistered) {
                        dispatch(startSenadePostVerificacion(body.embarcacion.id, boat.multa, boat, timonel))
                    } else {
                        dispatch(startSenadePostNewTimonel(body.embarcacion.id, boat, timonel))
                    }
                } else {
                    // console.log('entro sin novedad')
                    dispatch(startSenadePostVerificacion(body.embarcacion.id, boat.multa, boat, timonel))
                }
            } else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            console.log('Error de conexión. Contactese con el administrador.')
            // console.log(error)
        }
    }
}

// const senadePostNewBoats = (boats) => ({
//     type: types.embarcacionSenadePostBoats,
//     payload: boats
// });

// dispatch(startSenadePostNewTimonel(body.embarcacion.id, boat, timonel))
export const startSenadePostNewTimonel = (uidBoat, boat, timonel) => {
    return async (dispatch) => {
        try {
            const resp = await fetchConToken(`timonel/add`, timonel, 'POST');
            const body = await resp.json();
            if (body.ok) {
                // console.log(body.timonel)
                dispatch(startSenadePostVerificacion(uidBoat, boat.multa, boat, body.timonel))
            } else {
                Swal.fire(
                    'Error...',
                    'Surgio un error. Contactese con el administrador.',
                    'error'
                )
            }
        } catch (error) {
            // console.log(error)   
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }
}

export const startSenadePostVerificacion = (uid, multa, boat, timonel) => {
    return async (dispatch, getState) => {
        const { name, surname } = getState().auth;
        // console.log('sii')
        const objBody = {
            embarcacionId: uid,
            timonelUsuarioId: (boat.timonelRegistered === true) ? boat.timonelUsuarioId : null,
            timonelId: (!boat.timonelRegistered) ? timonel.id : null,
            // contravencion: multa,
            contravencion: (multa === '') ? null : multa,
            asignacionContravencion: 'pendiente',
            funcionario: name + ' ' + surname,
            resultado: boat.novedad,
            // numActa: boat.numActa
            numActa: (boat.numActa === '') ? null : boat.numActa,
            // estado: true
            // modificadoPor: modifPor,
            // modificadoFecha: modifFecha,
            // modificadoMotivo: modifMotivo,
        }
        try {
            const resp = await fetchConToken(`verificacion/${uid}`, objBody, 'POST');
            const body = await resp.json();
            if (body.ok) {
                // dispatch(senadePostNewBoats(body.embarcacion))
                // console.log(body)
                dispatch(startSenadeGetBoats())
                // console.log(multa)
                if (multa === 'INCAUTACION_PROHIBICION') {
                    Swal.fire('Advertencia', 'Se cargo registro. La embarcación tiene prohibicion de navegar, incautar ahora.', 'warning');
                    // Swal.fire('Error', 'go registro. La em', 'error');
                } else {
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2500,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })
                    Toast.fire({
                        icon: 'success',
                        title: 'Esta verificación se realizo correctamente.'
                    })
                }
            } else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }
}

// export const startSenadePostVerificacion = (uid, multa, boat, timonel) => {

//     return async (dispatch, getState) => {
//         const { name } = getState().auth;

//         const objBody = {
//             embarcacionId: uid,
//             timonelId: timonel.id,
//             contravencion: multa,
//             asignacionContravencion: 'pendiente',
//             funcionario: name,
//             resultado: boat.novedad,
//             numActa: boat.numActa
//             // estado: true
//             // modificadoPor: modifPor,
//             // modificadoFecha: modifFecha,
//             // modificadoMotivo: modifMotivo,
//         }

//         // console.log('verif: ', objBody, uid)

//         try {
//             const resp = await fetchConToken(`verificacion/${uid}`, objBody, 'POST');
//             const body = await resp.json();

//             if (body.ok) {
//                 // dispatch(senadePostNewBoats(body.embarcacion))
//                 // console.log(body)
//                 dispatch(startSenadeGetBoats())

//                 const Toast = Swal.mixin({
//                     toast: true,
//                     position: 'top-end',
//                     showConfirmButton: false,
//                     timer: 2500,
//                     timerProgressBar: true,
//                     didOpen: (toast) => {
//                         toast.addEventListener('mouseenter', Swal.stopTimer)
//                         toast.addEventListener('mouseleave', Swal.resumeTimer)
//                     }
//                 })
//                 Toast.fire({
//                     icon: 'success',
//                     title: 'Esta verificación se realizo correctamente.'
//                 })

//             } else {
//                 Swal.fire('Error', body.msg, 'error');
//             }

//         } catch (error) {
//             console.log('Error de conexión. Contactese con el administrador.')
//         }
//     }

// }

// const senadePostVerificacion = (boats) => ({
//     type: types.embarcacionSenadePostBoats,
//     payload: boats
// });

export const startSearchBoat = (text) => {
    return (dispatch) => {

        // console.log(text)

        dispatch(SearchBoat(text));
        // dispatch(startGetBoats())
    }

}

const SearchBoat = (text) => ({
    type: types.embarcacionSenadeSearchBoat,
    payload: text
});

export const startGetSearchBoat = () => {

    return async (dispatch, getState) => {

        const { uid } = getState().auth;
        const { search } = getState().senade;
        // console.log(search)
        // if ( search === '' ) {
        //     dispatch(startGetBoats())
        // }

        try {
            const resp = await fetchConToken(`senade/id/${uid}/search/${search}`, {});
            const body = await resp.json();

            if (body.ok) {

                dispatch(GetSearchBoat(body.embarcaciones))
                // console.log(body.visor)

            } else {

                Swal.fire('Error', body.msg, 'error')
                // console.log(body)

            }

        } catch (error) {
            //throw new Error('Error en el sistema. Contactese con el administrador.')
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }
}

const GetSearchBoat = (boat) => ({
    type: types.embarcacionSenadeGetSearchBoat,
    payload: boat
});
