import { types } from '../types/types';

const initialState = {

    contravenciones: [],
    search: '',
    timonelesSumario: [],
    timonelesUsuariosSumario: [],
    timonelesContravenciones: [],
    timonelesUsuariosContravenciones: [],
    contravencionesPendientes: [],

}

export const sumarioReducer = (state = initialState, action) => {

    switch (action.type) {

        case types.sumarioGetBoats:
            return {
                ...state,
                ...action.payload,
            }

        case types.sumarioGetTimoneles:
            return {
                ...state,
                timonelesSumario: action.payload,
            }

        case types.sumarioGetUsuariosTimoneles:
            return {
                ...state,
                timonelesUsuariosSumario: action.payload,
            }

        case types.sumarioSearchBoat:
            return {
                ...state,
                search: action.payload,
            }

        case types.sumarioGetSearchBoat:
            return {
                ...state,
                embarcacionesSumario: [...action.payload],
            }

        case types.embarcacionContravencionesPendientesGet:
            return {
                ...state,
                contravencionesPendientes: action.payload,
            }

        case types.embarcacionVerificacionGet:
            return {
                ...state,
                contravenciones: [...action.payload],
            }

        case types.embarcacionVerificacionTimonelGet:
            return {
                ...state,
                timonelesContravenciones: action.payload,
            }

        case types.embarcacionVerificacionUsuarioTimonelGet:
            return {
                ...state,
                timonelesUsuariosContravenciones: action.payload,
            }

        case types.sumarioContravencionId:
            return {
                ...state,
                contravencionId: action.payload,
            }

        default:
            return state;
    }

}

