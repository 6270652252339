// import React from 'react'
// // import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
// import DateTimePicker from '@mui/lab/DateTimePicker';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import DateFnsUtils from "@date-io/date-fns";
// import { es } from "date-fns/locale";


// export default function DateTimepicker(props) {

//     const { name, label, value, onChange, disablePast, disableFuture } = props


//     const convertToDefEventPara = (name, value) => ({
//         target: {
//             name, value
//         }
//     })

//     return (
//         <LocalizationProvider locale={es} utils={DateFnsUtils}>
//             <DateTimePicker inputVariant="outlined"
//                 name={name}
//                 label={label}
//                 format="dd/MM/yyyy HH:mm"
//                 value={value}
//                 ampm={false}
//                 disablePast={disablePast || false}
//                 disableFuture={disableFuture || false}
//                 onChange={date => onChange(convertToDefEventPara(name, date))}
//                 showTodayButton
//                 todayLabel="Ahora"
//             />
//         </LocalizationProvider>
//     )
// }


// import { TextField } from '@mui/material';
import { FormHelperText } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import esLocale from "date-fns/locale/es";
import Controls from './Controls';

const DateTimepicker = (props) => {
    const { name, label, value, onChange, disablePast, disableFuture, error } = props

    const convertToDefEventPara = (name, value) => ({
        target: {
            name, value
        }
    })

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
            <DateTimePicker
                name={name}
                label={label}
                inputFormat="dd/MM/yyyy HH:mm"
                ampm={false}
                disablePast={disablePast || false}
                disableFuture={disableFuture || false}
                value={value}
                onChange={date => onChange(convertToDefEventPara(name, date))}
                showTodayButton
                todayLabel="Ahora"
                // <TextField {...params} helperText={'fldskj'} />
                renderInput={(params) => (
                    <Controls.Input
                        {...params}
                        error={error}
                        inputProps={{
                            ...params.inputProps,
                            placeholder: "dd/MM/yyyy HH:mm",
                            // readOnly: true
                        }}
                    />
                )}
            />
        </LocalizationProvider>
    );
}

export default DateTimepicker;