import React, { useEffect, useState } from 'react'
import { Box, Dialog, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { format, isAfter, isValid } from 'date-fns';
import CloseIcon from '@mui/icons-material/Close';

import MySwal from 'sweetalert2';

import Controls from "../../components/controls/Controls";
import { useForm, Form } from '../../components/useForm';
import { isMobile } from 'react-device-detect';
import { MapContainer, TileLayer } from 'react-leaflet';
import DraggableMarker from '../../components/controls/openStreetMap/DraggableMarker';
import CurrentLocation from '../../components/controls/openStreetMap/CurrentLocation';
import { useDispatch, useSelector } from 'react-redux';
import { Toast } from "../../components/Toast";


const useStyles = makeStyles(theme => ({
    sAlert: {
        zIndex: 9999999999
    },
}))

const rolItems = [
    { id: 'titular', title: 'Titular' },
    { id: 'autorizado', title: 'Autorizado' },
]

const catCarnetItems = [
    { id: 1, nombre: 'N1' },
    { id: 2, nombre: 'N2' },
    { id: 3, nombre: 'N3' },
    { id: 4, nombre: 'N4' },
    { id: 5, nombre: 'N5' },
    { id: 6, nombre: 'N6' },
    { id: 7, nombre: 'N7' },
    { id: 8, nombre: 'N8' },
    { id: 9, nombre: 'N9' },
    { id: 10, nombre: 'NA' },
    { id: 11, nombre: 'NB' },
]

const initialFValues = {
    // id: 0,
    timonel: '',
    rol: 'titular',
    titular: false,
    autorizado: false,
    actuacionNotarialAutorizado: '',
    nombrePropietario: '',
    dniPropietario: '',
    telefonoPropietario: '',

    fechaSalida: new Date(),
    // fechaRegresoAprox: new Date,
    catCarnet: 'N2',
    lugarNavegacion: '',
    nombrePersonaAcargo: '',
    dniPersonaAcargo: '',
    telefonoPersonaAcargo: '',
    datosTripulantes: '',
    // capacidadPersonas NO LO GUARDA EN LA DB, SINO QUE EN OPERATIVO LO SACO DE TABLA EMBARCACIONES
    capacidadPersonas: '',
    coords: 'Coordenadas',
}

const centerMap = {
    lat: -31.65665781335105,
    lng: -60.70196832974308,
}

const EmbarcacionesForm = ({ saveDataDespacho, data }) => {
    // console.log(data)
    // console.log(maxTripulantes + catCarnet)
    // console.log(maxTripulantes)
    // console.log(catCarnet.slice(1, 2))
    const classes = useStyles();
    const Swal = MySwal.mixin({ customClass: { container: classes.sAlert }, })
    const { users = [] } = useSelector(state => state.embarcacionClub);
    const [position, setPosition] = useState(centerMap)
    const [isOpen, setIsOpen] = useState(false);
    const [user, setUser] = useState();

    const validate = (fieldValues = values) => {
        let temp = { ...errors }

        if ('actuacionNotarialAutorizado' in fieldValues)
            temp.actuacionNotarialAutorizado = fieldValues.actuacionNotarialAutorizado ? "" : "Actuación notarial es requerido."

        if ('timonel' in fieldValues)
            temp.timonel = fieldValues.timonel ? "" : "Timonel es requerido."

        // if ('fechaRegresoAprox' in fieldValues) {
        //     const returnDate = format(fieldValues.fechaRegresoAprox, 'yyyy-MM-dd HH:mm:ss');
        //     const departureDate = format(fieldValues.fechaSalida, 'yyyy-MM-dd HH:mm:ss');

        //     temp.fechaRegresoAprox = isAfter(new Date(returnDate), new Date(departureDate))
        //         ? ""
        //         : "Fecha de regreso es anterior a la fecha de salida."
        // }

        if ('capacidadPersonas' in fieldValues)
            temp.capacidadPersonas = fieldValues.capacidadPersonas.length !== 0
                ? (/^[0-9]*$/).test(fieldValues.capacidadPersonas)
                    ? (Number(fieldValues.capacidadPersonas) <= data.capacidadPersonas)
                        ? ""
                        : `La embarcación puede tener ${data.capacidadPersonas} tripulantes como maximo.`
                    : "La cantidad de tripulantes solo puede contener numeros."
                : "Cantidad de tripulantes es requerido."

        if ('lugarNavegacion' in fieldValues)
            temp.lugarNavegacion = fieldValues.lugarNavegacion ? "" : "Destino o zona de navegación no valido."

        if ('coords' in fieldValues)
            temp.coords = (fieldValues.coords !== 'Coordenadas')
                ? ""
                : "Ingresar al mapa para brindar coordenadas es requerido."

        if ('datosTripulantes' in fieldValues)
            temp.datosTripulantes = fieldValues.datosTripulantes ? "" : `Si no tiene tripulantes, indique "No posee".`

        if (user.titular) delete temp.actuacionNotarialAutorizado;

        setErrors({ ...temp })
        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }

    const {
        values,
        // setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFValues, true, validate);

    const handleSubmit = e => {
        e.preventDefault()

        if (!isValid(values.fechaSalida)) return Toast('error', 'Debe establecer una fecha de salida valida.')
        if (!isValid(values.fechaRegresoAprox)) return Toast('error', 'Debe establecer fecha de regreso.')
        if (!values.fechaRegresoAprox) return Toast('error', 'Debe establecer fecha de regreso posterior a fecha de salida.')
        if (isAfter(values.fechaSalida, values.fechaRegresoAprox)) {
            return Toast('error', 'Fecha de regreso es anterior a la fecha de salida.')
        }
        // if (user.usuario.vencimientoCarnet) return Toast('error', 'Establecer fecha de regreso.')
        
        const obj = {
            usuarioId: user.usuarioId,
            titular: user.titular,
            actuacionNotarialAutorizado: (!user.titular) ? values.actuacionNotarialAutorizado.toUpperCase() : null,
            nombre: `${user.usuario.nombre.toUpperCase()} ${user.usuario.apellido.toUpperCase()}`,
            dni: user.usuario.dni,
            telefono: user.usuario.telefono,
            lugarNavegacion: values.lugarNavegacion.toUpperCase(),
            datosTripulantes: values.datosTripulantes.toUpperCase(),
            fechaRegresoAprox: values.fechaRegresoAprox,
            fechaSalida: values.fechaSalida,
            coordLatitud: `${position.lat}`,
            coordLongitud: `${position.lng}`,
        }
        if (validate()) {
            saveDataDespacho(obj, resetForm);
        }
    }

    useEffect(() => {
        if (position !== centerMap) {
            // console.log(position)
            // console.log(values.coords)
            values.coords = `Lat: ${position.lat} Long: ${position.lng}`;
            validate()
        }
    }, [position])

    useEffect(() => {
        setUser(users.find(i => i.usuario.id === values.timonel))
    }, [values.timonel])

    // console.log(user)

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Grid container>
                    <Grid item sm={6}>
                        {/* {JSON.stringify(data.datosUsuario, null, 4)} */}
                        <FormControl error={errors.timonel}>
                            <InputLabel>Timonel</InputLabel>
                            <Select
                                name="timonel"
                                label="Timonel"
                                value={values.timonel}
                                onChange={handleInputChange}
                            // renderValue={(value) => `⚠️  - ${value}`}
                            >
                                {users.map(
                                    item => (<MenuItem key={item.usuario.id} value={item.usuario.id}>{item.usuario.apellido}, {item.usuario.nombre}</MenuItem>)
                                )}
                            </Select>
                            {errors.timonel && <FormHelperText>{errors.timonel}</FormHelperText>}
                        </FormControl>
                        <Controls.Input
                            name="lugarNavegacion"
                            label="Destino o zona de navegacion"
                            value={values.lugarNavegacion.toUpperCase()}
                            onChange={handleInputChange}
                            error={errors.lugarNavegacion}
                            disabled={values.timonel.length === 0}
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Box sx={{ width: '60%' }}>
                                <Controls.Input
                                    style={{ width: '95%' }}
                                    name="coords"
                                    // label="Coordenadas"
                                    value={values.coords}
                                    onChange={handleInputChange}
                                    error={errors.coords}
                                    disabled
                                />
                            </Box>
                            <Controls.Button
                                style={{ margin: 10 }}
                                text="Mapa"
                                color="default"
                                onClick={() => { setIsOpen(true) }}
                                disabled={values.timonel.length === 0}
                            />
                        </Box>
                        <Controls.DateTimepicker
                            name="fechaSalida"
                            label="Fecha y hora de salida"
                            disablePast={true}
                            value={values.fechaSalida}
                            onChange={handleInputChange}
                            // error={errors.fechaSalida}
                            disabled={values.timonel.length === 0}
                        />
                        <Controls.DateTimepicker
                            name="fechaRegresoAprox"
                            label="Fecha y hora de regreso aprox."
                            disablePast={true}
                            value={values.fechaRegresoAprox}
                            onChange={handleInputChange}
                            // error={errors.fechaRegresoAprox}
                            disabled={values.timonel.length === 0}
                        />
                        <Box>
                            <Controls.Button
                                text="Reset"
                                color="default"
                                onClick={resetForm}
                                disabled={values.timonel.length === 0}
                            />
                            <Controls.Button
                                type="submit"
                                text="Continuar"
                                disabled={values.timonel.length === 0}
                            />
                        </Box>
                    </Grid>
                    <Grid item sm={6}>
                        <Controls.Input
                            name="actuacionNotarialAutorizado"
                            label="Actuacion Notarial"
                            // value={(values.rol === 'titular') ? values.actuacionNotarialAutorizado = '' : values.actuacionNotarialAutorizado}
                            value={values.actuacionNotarialAutorizado}
                            onChange={handleInputChange}
                            disabled={(user !== undefined && user.titular) ? true : false}
                            // error={errors.actuacionNotarialAutorizado}
                            error={(user !== undefined && user.titular === false) && errors.actuacionNotarialAutorizado}
                        />
                        <Controls.Input
                            name="capacidadPersonas"
                            label="Cantidad de tripulantes"
                            value={values.capacidadPersonas}
                            onChange={handleInputChange}
                            error={errors.capacidadPersonas}
                            disabled={values.timonel.length === 0}
                        />
                        <Controls.Input
                            name="datosTripulantes"
                            label="Tripulantes y sus datos"
                            value={values.datosTripulantes.toUpperCase()}
                            placeholder="Tripulantes, dni y telefono"
                            multiline
                            rows={4}
                            maxRows={8}
                            onChange={handleInputChange}
                            error={errors.datosTripulantes}
                            disabled={values.timonel.length === 0}
                        />
                    </Grid>
                </Grid>
            </Form >

            <Dialog
                open={isOpen}
                maxWidth='md'
                sx={{
                    padding: 2,
                    minHeight: (isMobile) ? '100vh' : 'unset'
                }}
            >
                <DialogTitle
                    sx={{
                        ml: 2,
                        padding: 1,
                        // zIndex: 10
                    }}
                >
                    <div style={{ display: 'flex' }}>
                        <Typography mt={1} variant="h6" component="div" style={{ flexGrow: 1 }}>
                            Elija un punto en el mapa...
                        </Typography>
                        <Controls.ActionButton
                            color="secondary"
                            onClick={() => { setIsOpen(false) }}>
                            <CloseIcon />
                        </Controls.ActionButton>
                    </div>
                </DialogTitle>
                <DialogContent dividers>

                    <Grid container>
                        <Grid item xs={12} sx={{ height: '50vh', width: '50vw' }}>
                            <MapContainer center={centerMap} zoom={13} scrollWheelZoom={true} style={{ height: '100%' }}>
                                <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                {/* <DraggableMarker center={centerMap} /> */}
                                <DraggableMarker pos={position} setPos={setPosition} />
                                {/* <CurrentLocation /> */}
                            </MapContainer>
                        </Grid>
                    </Grid>

                </DialogContent>
            </Dialog>
        </>
    )
}

export default EmbarcacionesForm;