import React, { useEffect, useState } from 'react'
import { Box, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { format, isAfter, isValid } from 'date-fns';
import CloseIcon from '@mui/icons-material/Close';

import MySwal from 'sweetalert2';

import Controls from "../../components/controls/Controls";
import { useForm, Form } from '../../components/useForm';
import { isMobile } from 'react-device-detect';
import { MapContainer, TileLayer } from 'react-leaflet';
import DraggableMarker from '../../components/controls/openStreetMap/DraggableMarker';
import CurrentLocation from '../../components/controls/openStreetMap/CurrentLocation';
import { Toast } from '../../components/Toast';
import { useSelector } from 'react-redux';
import CheckIcon from '@mui/icons-material/Check';
import ReplayIcon from '@mui/icons-material/Replay';

/* 
nombrePersonaAcargo: ""
actuacionNotarialAutorizado: ""
autorizado: false
dniPersonaAcargo: ""
telefonoPersonaAcargo: ""

nombrePropietario: "ALVERTO"
dniPropietario: "32370552"
telefonoPropietario: "155104534"
catCarnet: "N5"
rol: "titular"
titular: true

capacidadPersonas: "3"
coordLatitud: "-31.630875463892085"
coordLongitud: "-60.66547393798829"
coords: "Lat: -31.630875463892085 Long: -60.66547393798829"
datosTripulantes: "NO POSEE"
fechaCarnet: Date Sat Apr 29 2023 18:49:13 GMT-0300 (hora estándar de Argentina)
fechaRegresoAprox: Date Sun Apr 30 2023 18:49:13 GMT-0300 (hora estándar de Argentina)
fechaSalida: Date Tue Apr 25 2023 18:41:59 GMT-0300 (hora estándar de Argentina)
lugarNavegacion: "DESTINONORTE"

---------------

nombrePersonaAcargo: "-"
actuacionNotarialAutorizado: "-"
autorizado: false
dniPersonaAcargo: "-"

nombrePropietario: "LALO"
dniPropietario: "78787878"
telefonoPropietario: "155104878"
catCarnet: "N7"
rol: "titular"
titular: true

capacidadPersonas: "3"
coordLatitud: "-31.635698622997168"
coordLongitud: "-60.67869186401368"
coords: "Lat: -31.635698622997168 Long: -60.67869186401368"
datosTripulantes: "NO POSEE
fechaRegresoAprox: Date Fri Apr 28 2023 00:16:56 GMT-0300 (hora estándar de Argentina)
fechaSalida: Date Thu Apr 27 2023 00:16:51 GMT-0300 (hora estándar de Argentina)
lugarNavegacion: "ESTE"
fechaCarnet: "2023"


*/

const useStyles = makeStyles(theme => ({
    sAlert: {
        zIndex: 9999999999
    },
}))

// const rolItems = [
//     { id: 'titular', title: 'Titular' },
//     { id: 'autorizado', title: 'Autorizado' },
// ]

const catCarnetItems = [
    { id: 1, nombre: 'N1' },
    { id: 2, nombre: 'N2' },
    { id: 3, nombre: 'N3' },
    { id: 4, nombre: 'N4' },
    { id: 5, nombre: 'N5' },
    { id: 6, nombre: 'N6' },
    { id: 7, nombre: 'N7' },
    { id: 8, nombre: 'N8' },
    { id: 9, nombre: 'N9' },
    { id: 10, nombre: 'NA' },
    { id: 11, nombre: 'NB' },
]

const initialFValues = {
    // autorizado: false,
    actuacionNotarialAutorizado: '',
    // nombrePersonaAcargo: '',
    // dniPersonaAcargo: '',
    // telefonoPersonaAcargo: '',

    // nombrePropietario: '',
    // dniPropietario: '',
    // telefonoPropietario: '',
    // catCarnet: 'N2',

    // rol: 'titular',
    titular: true,
    fechaSalida: new Date(),
    // fechaRegresoAprox: new Date(),
    lugarNavegacion: '',
    datosTripulantes: '',
    capacidadPersonas: '',
    coords: 'Coordenadas',
}

const centerMap = {
    lat: -31.65665781335105,
    lng: -60.70196832974308,
}

const DespachoForm = ({ saveDataDespacho, data }) => {
    // console.log(maxTripulantes + catCarnet)
    // console.log(maxTripulantes)
    // console.log(catCarnet.slice(1, 2))
    // const classes = useStyles();
    // const Swal = MySwal.mixin({ customClass: { container: classes.sAlert }, })
    const { timonel } = useSelector(state => state.embarcacionTimonel);
    const { id, nombre, apellido, dni, telefono } = timonel;
    const [position, setPosition] = useState(centerMap)
    const [isOpen, setIsOpen] = useState(false);
    const { loading } = useSelector(state => state.system);

    const validate = (fieldValues = values) => {
        let temp = { ...errors }

        // if ('nombrePropietario' in fieldValues)
        //     temp.nombrePropietario = fieldValues.nombrePropietario.length !== 0
        //         ? (/^[a-z\s\u00C0-\u00ff]+$/i).test(fieldValues.nombrePropietario)
        //             ? ""
        //             : "Nombre solo puede contener letras sin espacios."
        //         : "Nombre es requerido."

        // if ('dniPropietario' in fieldValues)
        //     temp.dniPropietario = fieldValues.dniPropietario.length !== 0
        //         ? (/^[0-9]*$/).test(fieldValues.dniPropietario)
        //             ? (fieldValues.dniPropietario.length >= 7 && fieldValues.dniPropietario.length <= 8)
        //                 ? ""
        //                 : "Dni debe contener 7 caracteres como minimo y 8 caracteres como maximo."
        //             : "Dni solo puede contener numeros."
        //         : "Dni es requerido."

        // if ('telefonoPropietario' in fieldValues)
        //     temp.telefonoPropietario = fieldValues.telefonoPropietario.length !== 0
        //         ? (/^[0-9]*$/).test(fieldValues.telefonoPropietario)
        //             ? (fieldValues.telefonoPropietario.length >= 9 && fieldValues.telefonoPropietario.length <= 11)
        //                 ? ""
        //                 : "Telefono debe contener 9 caracteres como minimo y 11 caracteres como maximo."
        //             : "Telefono solo puede contener numeros."
        //         : "Telefono es requerido."

        if (data.usuariosEmbarcaciones !== undefined && !data.usuariosEmbarcaciones[0].titular) {
            if ('actuacionNotarialAutorizado' in fieldValues)
                temp.actuacionNotarialAutorizado = fieldValues.actuacionNotarialAutorizado
                    ? ""
                    : "Actuación notarial es requerido."
        }

        if ('nombrePersonaAcargo' in fieldValues)
            temp.nombrePersonaAcargo = fieldValues.nombrePersonaAcargo.length !== 0
                ? (/^[a-z\s\u00C0-\u00ff]+$/i).test(fieldValues.nombrePersonaAcargo)
                    ? ""
                    : "Nombre solo puede contener letras sin espacios."
                : "Nombre es requerido."

        if ('dniPersonaAcargo' in fieldValues)
            temp.dniPersonaAcargo = fieldValues.dniPersonaAcargo.length !== 0
                ? (/^[0-9]*$/).test(fieldValues.dniPersonaAcargo)
                    ? (fieldValues.dniPersonaAcargo.length >= 7 && fieldValues.dniPersonaAcargo.length <= 8)
                        ? ""
                        : "Dni debe contener 7 caracteres como minimo y 8 caracteres como maximo."
                    : "Dni solo puede contener numeros."
                : "Dni es requerido."

        if ('telefonoPersonaAcargo' in fieldValues)
            temp.telefonoPersonaAcargo = fieldValues.telefonoPersonaAcargo.length !== 0
                ? (/^[0-9]*$/).test(fieldValues.telefonoPersonaAcargo)
                    ? (fieldValues.telefonoPersonaAcargo.length >= 9 && fieldValues.telefonoPersonaAcargo.length <= 11)
                        ? ""
                        : "Telefono debe contener 9 caracteres como minimo y 11 caracteres como maximo."
                    : "Telefono solo puede contener numeros."
                : "Telefono es requerido."

        if ('capacidadPersonas' in fieldValues)
            temp.capacidadPersonas = fieldValues.capacidadPersonas.length !== 0
                ? (/^[0-9]*$/).test(fieldValues.capacidadPersonas)
                    ? (Number(fieldValues.capacidadPersonas) <= data.capacidadPersonas)
                        ? ""
                        : `La embarcación puede tener ${data.capacidadPersonas} tripulantes como maximo.`
                    : "La cantidad de tripulantes solo puede contener numeros."
                : "Cantidad de tripulantes es requerido."

        // if ('catCarnet' in fieldValues)
        //     temp.catCarnet = fieldValues.catCarnet.slice(1, 2) >= catCarnet.slice(1, 2)
        //         ? ""
        //         : `El carnet minimo requerido para esta embarcación es ${catCarnet}.`

        if ('lugarNavegacion' in fieldValues)
            temp.lugarNavegacion = fieldValues.lugarNavegacion ? "" : "Destino o zona de navegación no valido."

        if ('coords' in fieldValues)
            temp.coords = (fieldValues.coords !== 'Coordenadas')
                ? ""
                : "Ingresar al mapa para brindar coordenadas es requerido."

        if ('datosTripulantes' in fieldValues)
            temp.datosTripulantes = fieldValues.datosTripulantes ? "" : `Si no tiene tripulantes, indique "No posee".`

        // if ('rol' in fieldValues) {
        //     if (fieldValues.rol === 'titular') {
        //         delete temp.actuacionNotarialAutorizado
        //         delete temp.nombrePersonaAcargo
        //         delete temp.dniPersonaAcargo
        //         delete temp.telefonoPersonaAcargo
        //     }
        // }

        setErrors({
            ...temp
        })

        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }

    const {
        values,
        // setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFValues, true, validate);

    const handleSubmit = e => {
        e.preventDefault()
        // if (!values.fechaRegresoAprox) return Swal.fire('Error', 'Debe establecer la fecha de regreso aprox.', 'error')

        // if (values.rol === 'titular') {
        //     Object.assign(values, { titular: true, autorizado: false, actuacionNotarialAutorizado: '-', nombrePersonaAcargo: '-', dniPersonaAcargo: '-' })
        // } else if (values.rol === 'autorizado') {
        //     Object.assign(values, { titular: false, autorizado: true })
        // } else {
        //     Swal.fire('Error', 'Error.', 'error')
        // }

        // const { lugarNavegacion, nombrePersonaAcargo, datosTripulantes } = values;
        // const objTempToUpperCase = {
        //     lugarNavegacion: lugarNavegacion.toUpperCase(),
        //     nombrePersonaAcargo: nombrePersonaAcargo.toUpperCase(),
        //     datosTripulantes: datosTripulantes.toUpperCase(),
        //     // coords: JSON.stringify([position.lat, position.lng])
        //     // coords: JSON.stringify(position.lat)
        //     coordLatitud: `${position.lat}`,
        //     coordLongitud: `${position.lng}`,
        // }
        // const obj = Object.assign(values, objTempToUpperCase)

        // if (validate()) {
        //     saveDataDespacho(obj, resetForm);
        //     // console.log(obj)
        // }


        // {
        //     id: 53,
        //     usuarioId: 5,
        //     titular: true,
        //     actuacionNotarialAutorizado: null,
        //     nombre: 'GRINGA ALBONI',
        //     dni: '4323434',
        //     telefono: '543534545',
        //     lugarNavegacion: 'SUR',
        //     datosTripulantes: 'AJA',
        //     fechaRegresoAprox: '2023-09-29T14:30:19.000Z',
        //     fechaSalida: '2023-09-06T12:15:48.148Z',
        //     coordLatitud: '-31.649717220058918',
        //     coordLongitud: '-60.69378802895401'
        //   }

        if (!isValid(values.fechaSalida)) return Toast('error', 'Debe establecer una fecha de salida valida.')
        if (!isValid(values.fechaRegresoAprox)) return Toast('error', 'Debe establecer fecha de regreso.')

        if (!values.fechaRegresoAprox) return Toast('error', 'Debe establecer fecha de regreso posterior a fecha de salida.')

        // const departureDate = format(values.fechaSalida, 'dd/MM/yyyy HH:mm');
        // const returnDate = format(values.fechaRegresoAprox, 'dd/MM/yyyy HH:mm');
        // const returnDate = format(values.fechaRegresoAprox, 'yyyy-MM-dd HH:mm:ss');
        // const departureDate = format(values.fechaSalida, 'yyyy-MM-dd HH:mm:ss');
        // console.log(departureDate)
        // console.log(returnDate)

        // if (!isAfter(new Date(departureDate), new Date(returnDate))) {
        // if (isAfter(new Date(values.fechaSalida), new Date(values.fechaRegresoAprox))) {
        if (isAfter(values.fechaSalida, values.fechaRegresoAprox)) {
            return Toast('error', 'Fecha de regreso es anterior a la fecha de salida.')
        }

        // if (user.usuario.vencimientoCarnet) return Toast('error', 'Establecer fecha de regreso.')
        const obj = {
            usuarioId: id,
            titular: data.usuariosEmbarcaciones[0].titular,
            actuacionNotarialAutorizado:
                (!data.usuariosEmbarcaciones[0].titular)
                    ? (values.actuacionNotarialAutorizado)
                        ? values.actuacionNotarialAutorizado.toUpperCase()
                        : null
                    // ? values.actuacionNotarialAutorizado.toUpperCase()
                    : null,
            nombre: `${nombre.toUpperCase()} ${apellido.toUpperCase()}`,
            dni: dni,
            telefono: telefono,
            lugarNavegacion: values.lugarNavegacion.toUpperCase(),
            datosTripulantes: values.datosTripulantes.toUpperCase(),
            fechaRegresoAprox: values.fechaRegresoAprox,
            fechaSalida: values.fechaSalida,
            coordLatitud: `${position.lat}`,
            coordLongitud: `${position.lng}`,
        }
        if (validate()) {
            saveDataDespacho(obj, resetForm);
        }
    }

    useEffect(() => {
        if (position !== centerMap) {
            // console.log(position)
            // console.log(values.coords)
            values.coords = `Lat: ${position.lat} Long: ${position.lng}`;
            validate()
        }
    }, [position])

    // console.log(data)

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Grid container>
                    <Grid item sm={6}>
                        <Controls.Input
                            name="lugarNavegacion"
                            label="Destino o zona de navegacion"
                            value={values.lugarNavegacion.toUpperCase()}
                            onChange={handleInputChange}
                            error={errors.lugarNavegacion}
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Box sx={{ width: '60%' }}>
                                <Controls.Input
                                    style={{ width: '95%' }}
                                    name="coords"
                                    // label="Coordenadas"
                                    value={values.coords}
                                    onChange={handleInputChange}
                                    error={errors.coords}
                                    disabled
                                />
                            </Box>
                            <Controls.Button
                                style={{ margin: 10 }}
                                text="Mapa"
                                color="default"
                                onClick={() => { setIsOpen(true) }}
                            />
                        </Box>
                        <Controls.DateTimepicker
                            name="fechaSalida"
                            label="Fecha y hora de salida"
                            disablePast={true}
                            value={values.fechaSalida}
                            onChange={handleInputChange}
                        // error={errors.fechaSalida}
                        />
                        <Controls.DateTimepicker
                            name="fechaRegresoAprox"
                            label="Fecha y hora de regreso aprox."
                            disablePast={true}
                            value={values.fechaRegresoAprox}
                            onChange={handleInputChange}
                        // error={errors.fechaRegresoAprox}
                        />
                    </Grid>
                    <Grid item sm={6}>
                        <Controls.Input
                            name="actuacionNotarialAutorizado"
                            label="Actuacion Notarial"
                            // value={(values.rol === 'titular') ? values.actuacionNotarialAutorizado = '' : values.actuacionNotarialAutorizado}
                            value={values.actuacionNotarialAutorizado}
                            onChange={handleInputChange}
                            disabled={(data.usuariosEmbarcaciones !== undefined && data.usuariosEmbarcaciones[0].titular) ? true : false}
                            // error={errors.actuacionNotarialAutorizado}
                            // error={''}
                            error={(data.usuariosEmbarcaciones !== undefined && !data.usuariosEmbarcaciones[0].titular) ? errors.actuacionNotarialAutorizado : false}
                        />
                        <Controls.Input
                            name="capacidadPersonas"
                            label="Cantidad de tripulantes"
                            value={values.capacidadPersonas}
                            onChange={handleInputChange}
                            error={errors.capacidadPersonas}
                        />
                        {/* <Controls.Select
                            name="catCarnet"
                            label="Seleccionar categoria de carnet nautico"
                            value={values.catCarnet}
                            onChange={handleInputChange}
                            options={catCarnetItems}
                            error={errors.catCarnet}
                        /> */}
                        {/* <Controls.DatePickerControl
                            name="fechaCarnet"
                            label="Fecha vencimiento carnet timonel"
                            past={true}
                            value={values.fechaCarnet}
                            onChange={handleInputChange}
                            error={errors.fechaCarnet}
                        /> */}
                        <Controls.Input
                            name="datosTripulantes"
                            label="Tripulantes y sus datos"
                            value={values.datosTripulantes.toUpperCase()}
                            placeholder="Tripulantes, dni y telefono"
                            multiline
                            rows={4}
                            maxRows={8}
                            onChange={handleInputChange}
                            error={errors.datosTripulantes}
                        />
                    </Grid>
                </Grid>
                {/* <Box
                            // display={'flex'}
                            // justifyContent={'flex-end'}
                            sx={{ mt: 3 }}
                        > */}
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', pt: 2, mb: 2 }}>

                    <Controls.Button
                        text="Reset"
                        color="default"
                        onClick={resetForm}

                        disabled={loading}
                        // startIcon={<SaveOutlinedIcon />}
                        loading={loading}
                        startIconWithoutLoading={<ReplayIcon />}
                    />
                    <Controls.Button
                        type="submit"

                        disabled={loading}
                        // startIcon={<SaveOutlinedIcon />}
                        loading={loading}
                        startIconWithoutLoading={<CheckIcon />}

                        text="Enviar despacho"
                    />
                </Box>
            </Form >

            <Dialog
                open={isOpen}
                maxWidth='md'
                sx={{
                    padding: 2,
                    minHeight: (isMobile) ? '100vh' : 'unset'
                }}
            >
                <DialogTitle
                    sx={{
                        ml: 2,
                        padding: 1,
                        // zIndex: 10
                    }}
                >
                    <div style={{ display: 'flex' }}>
                        <Typography mt={1} variant="h6" component="div" style={{ flexGrow: 1 }}>
                            Elija un punto en el mapa...
                        </Typography>
                        <Controls.ActionButton
                            color="secondary"
                            onClick={() => { setIsOpen(false) }}>
                            <CloseIcon />
                        </Controls.ActionButton>
                    </div>
                </DialogTitle>
                <DialogContent dividers>

                    <Grid container>
                        <Grid item xs={12} sx={{ height: '50vh', width: '50vw' }}>
                            <MapContainer center={centerMap} zoom={13} scrollWheelZoom={true} style={{ height: '100%' }}>
                                <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                {/* <DraggableMarker center={centerMap} /> */}
                                <DraggableMarker pos={position} setPos={setPosition} />
                                {/* <CurrentLocation /> */}
                            </MapContainer>
                        </Grid>
                    </Grid>

                </DialogContent>
            </Dialog>
        </>
    )
}

export default DespachoForm;