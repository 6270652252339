import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles"
import { Alert, AlertTitle, Box, Dialog, DialogContent, DialogTitle, Grid, Paper, TableBody, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import SailingIcon from '@mui/icons-material/Sailing';
import CloseIcon from '@mui/icons-material/Close';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { isMobile } from "react-device-detect";

import PageHeader from "../../components/PageHeader";
import useTable from "../../components/useTable";
import Controls from "../../components/controls/Controls";
import { EmbarcacionesForm } from "./EmbarcacionesForm";
import { startAllEmbarcacionesGetToLoadAddRemoveBoatsTimonel, startEmbarcacionesTimonelPorIdDB, startEmbarcacionesTimonelSave } from "../../actions/embarcacionesTimonel";

const useStyles = makeStyles(theme => ({
    table: {
        marginTop: theme.spacing(3),
        '& thead th': {
            fontWeight: '600',
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.light,
        },
        '& tbody td': {
            fontWeight: '300',
        },
        '& tbody tr:hover': {
            backgroundColor: '#fffbf2',
            cursor: 'pointer',
        },
    },
}))

const headCells = [
    { id: 'image', label: '' },
    { id: 'name', label: 'Nombre' },
    { id: 'mat', label: 'Matricula' },
    { id: 'concept', label: 'Concepto' },
    { id: 'actions', label: 'Acciones', disableSorting: true }
]

export const Embarcaciones = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { name, place } = useSelector(state => state.auth);
    const { embarcaciones = [] } = useSelector(state => state.embarcacionTimonel);
    const [filterFn] = useState({ fn: items => { return items; } })
    const [boats, setBoats] = useState([]);
    const { loading } = useSelector(state => state.system);
    const [isOpen, setIsOpen] = useState(false);

    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting
    } = useTable(boats, headCells, filterFn);

    const removeBoatItem = (mat) => {
        setBoats(botes => botes.filter(x => x.matricula !== mat))
    }

    const processFormAddBoat = (item) => {
        const obj = {
            ...item,
            lugar: 'SAFE',
            estado: true,
            prohibicion: false,
            despachada: false,
            pendiente: true
        }
        // console.log(obj)
        setBoats(x => [...x, obj])
        setIsOpen(false);
    }

    useEffect(() => {
        dispatch(startAllEmbarcacionesGetToLoadAddRemoveBoatsTimonel());
        dispatch(startEmbarcacionesTimonelPorIdDB());
    }, [])

    useEffect(() => {
        setBoats(embarcaciones);
    }, [embarcaciones])

    return (
        <>
            <PageHeader
                title={`Gestion de embarcaciónes`}
                subTitle={`Prefectura ${place}`}
                icon={<DirectionsBoatIcon fontSize="large" />}
                profile={true}
                name={name}
            />

            <Paper sx={{ m: 5, p: 3 }}>
                {/* <Typography variant="custom6" gutterBottom component="div" align='center' sx={{ p: 5, mt: 3 }}>
                    Seleccione su embarcación:
                </Typography> */}

                <Grid container>
                    <Grid item xs={12}>
                        {
                            (!boats.length)
                                ? <Alert severity="info" sx={{ m: 3 }}>
                                    <AlertTitle>Información:</AlertTitle>
                                    Para cargar sus embarcaciones con el fin de poder administrarlas,
                                    usted encontrará debajo el boton correspondiente.
                                </Alert>
                                : <>
                                    <TblContainer>
                                        <TblHead />
                                        <TableBody>
                                            {
                                                recordsAfterPagingAndSorting().map(item =>
                                                (<TableRow key={(item.inscripcion === 'REY' || item.inscripcion === 'E/T')
                                                    ? `${item.inscripcion}${item.matricula}`
                                                    : `${item.cuatrigrama}${item.matricula}`}
                                                >
                                                    <TableCell>
                                                        {/* // (item.imgEmbarcacion) 
                                                            // <img
                                                            //     style={{ width: 100, height: 100, objectFit: 'contain', borderRadius: 50 }}
                                                            //     src={URL.createObjectURL(item.img)}
                                                            //     // style={styles.image}
                                                            //     alt="Thumb"
                                                            // /> */}
                                                        <Box
                                                            component="img"
                                                            sx={{
                                                                height: 75,
                                                                width: 75,
                                                                maxHeight: { xs: 75, md: 100 },
                                                                maxWidth: { xs: 75, md: 100 },
                                                                borderRadius: 50,
                                                                //   objectFit: 'contain'
                                                            }}
                                                            alt="img_boat"
                                                            // src={item.imgEmbarcacion.imagePreview}
                                                            src={(item.id) ? `${process.env.REACT_APP_API_URL_FOR_IMAGES}/boatsImages/${item.imgEmbarcacionUrl}` : item.imgEmbarcacion.imagePreview}
                                                        />
                                                    </TableCell>
                                                    <TableCell>{item.nombre.toUpperCase()}</TableCell>
                                                    {/* <TableCell>{JSON.stringify(item, null, 3)}</TableCell> */}
                                                    <TableCell>
                                                        {
                                                            (item.inscripcion === 'REY' || item.inscripcion === 'E/T')
                                                                ? `${item.inscripcion} ${item.matricula}`
                                                                : `${item.cuatrigrama} ${item.matricula}`
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        {
                                                            (item.usuariosEmbarcaciones)
                                                                ? (item.usuariosEmbarcaciones[0]?.pendiente)
                                                                    ? 'Pendiente de autorización'
                                                                    // : (item.titular === true)
                                                                    : (item.usuariosEmbarcaciones[0]?.titular)
                                                                        ? 'Titular'
                                                                        : 'Autorizado'
                                                                : 'Sin guardar'
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        {
                                                            (item.usuariosEmbarcaciones && !item.usuariosEmbarcaciones[0].pendiente) &&
                                                            <Controls.ActionButton
                                                                color="secondary"
                                                                onClick={() => { removeBoatItem(item.matricula) }}
                                                                disabled={loading && true}
                                                            >
                                                                <Tooltip title="Eliminar">
                                                                    <CloseIcon fontSize="small" />
                                                                </Tooltip>
                                                            </Controls.ActionButton>
                                                        }
                                                        {/* <Controls.ActionButton
                                                                    color="secondary"
                                                                    onClick={() => { reportarItem(item.id, item.fullName) }}>
                                                                    <Tooltip title="Reportar">
                                                                        <CloseIcon fontSize="small" />
                                                                    </Tooltip>
                                                                </Controls.ActionButton> */}
                                                    </TableCell>
                                                </TableRow>)
                                                )
                                            }
                                        </TableBody>
                                    </TblContainer>
                                    <TblPagination />
                                </>
                        }

                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', pt: 2, mb: 2 }}>
                            <Controls.Button
                                sx={{ ...(loading) && { color: 'gray' } }}
                                disabled={loading}
                                startIcon={<SailingIcon />}
                                text='Agregar embarcación'
                                variant="outlined"
                                // onClick={() => setIsOpen((value) => !value)}
                                onClick={() => setIsOpen(true)}
                            />
                            <Controls.Button
                                sx={{ ...(loading) && { color: 'gray' } }}
                                disabled={loading}
                                // startIcon={<SaveOutlinedIcon />}
                                loading={loading}
                                startIconWithoutLoading={<SaveOutlinedIcon />}
                                text='Guardar'
                                variant="outlined"
                                onClick={() => {
                                    dispatch(startEmbarcacionesTimonelSave(boats))
                                    // console.log(boats)
                                }}
                            />
                        </Box>

                    </Grid>
                </Grid>
            </Paper >
            <Dialog open={isOpen} maxWidth='md'
                sx={{
                    padding: 2,
                    minHeight: (isMobile) ? '100vh' : 'unset'
                }}
            >
                <DialogTitle sx={{ ml: 2, padding: 1 }}>
                    <div style={{ display: 'flex' }}>
                        <Typography mt={1} variant="h6" component="div" style={{ flexGrow: 1 }}>
                            Agregando embarcación...
                        </Typography>
                        <Controls.ActionButton
                            color="secondary"
                            onClick={() => { setIsOpen(false) }}>
                            <CloseIcon />
                        </Controls.ActionButton>
                    </div>
                </DialogTitle>
                <DialogContent dividers>
                    {/* <RegisterScreenDialogAddBoats saveDataAddBoat={processFormAddBoat} /> */}
                    <EmbarcacionesForm saveDataAddBoat={processFormAddBoat} />
                </DialogContent>
            </Dialog>
        </>

    )
}
