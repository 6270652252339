import Swal from 'sweetalert2';
import { fetchConToken, fetchSinToken } from '../services/fetch';
import { types } from '../types/types';
import { setReset } from './despachoClubes';
import { Toast } from "../components/Toast";

export const startGetUsersFromAboat = (id) => {
    return async (dispatch) => {
        try {
            const resp = await fetchSinToken(`embarcaciones/clubes/getusers/${id}`, {});
            const body = await resp.json();
            if (body.ok) {
                dispatch(getUsersFromAboat(body.embarcacionUsuario))
            } else {
                Swal.fire('Error', body.msg, 'error')
            }
        } catch (error) {
            //throw new Error('Error en el sistema. Contactese con el administrador.')
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }
}
const getUsersFromAboat = (users) => ({
    type: types.getUsersFromAboat,
    payload: users
});

export const startListClubesDB = () => {

    return async (dispatch) => {
        try {
            const resp = await fetchSinToken('clubes/', {});
            const body = await resp.json();

            if (body.ok) {

                dispatch(ListClubesDB(body.clubes))

            } else {
                Swal.fire('Error', body.msg, 'error')
            }

        } catch (error) {
            //throw new Error('Error en el sistema. Contactese con el administrador.')
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }

}
const ListClubesDB = (clubes) => ({
    type: types.adminGetClubes,
    payload: clubes
});
export const startGetClubBoats = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;
        try {
            const resp = await fetchSinToken(`embarcaciones/clubes/${uid}`, {});
            const body = await resp.json();
            if (body.ok) {
                dispatch(GetClubBoats(body.embarcaciones))
            } else {
                Swal.fire('Error', body.msg, 'error')
            }
        } catch (error) {
            //throw new Error('Error en el sistema. Contactese con el administrador.')
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }
}
const GetClubBoats = (boats) => ({
    type: types.embarcacionGetClubBoats,
    payload: boats
});
export const startGetPendientes = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;
        try {
            const resp = await fetchSinToken(`embarcaciones/clubes/pendientes/${uid}`, {});
            const body = await resp.json();
            if (body.ok) {
                dispatch(GetPendientes(body.pendientes))
            } else {
                Swal.fire('Error', body.msg, 'error')
            }
        } catch (error) {
            //throw new Error('Error en el sistema. Contactese con el administrador.')
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }
}

const GetPendientes = (boats) => ({
    type: types.embarcacionGetBoatsPendientes,
    payload: boats
});

export const startGetBoatsTotalityNumber = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;
        try {
            const resp = await fetchSinToken(`embarcaciones/clubes/totalidad/${uid}`, {});
            const body = await resp.json();
            if (body.ok) {
                dispatch(getBoatsTotalityNumber(body.numeroDeEmbarcacionesParaElDashboardClub))
                // dispatch(GetBoatsTotalityNumberForDashboardClub(body.numeroDeEmbarcacionesParaElDashboardClub))
                // console.log(body.aDespachar)
            } else {
                Swal.fire('Error', body.msg, 'error')
                // console.log(body)
            }
        } catch (error) {
            //throw new Error('Error en el sistema. Contactese con el administrador.')
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }
}

const getBoatsTotalityNumber = (number) => ({
    type: types.embarcacionGetBoatsTotalityNumber,
    payload: number
});

export const startGetBoatsAdespachar = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;
        try {
            const resp = await fetchSinToken(`embarcaciones/clubes/despachar/${uid}`, {});
            const body = await resp.json();
            if (body.ok) {
                dispatch(GetBoatsAdespachar(body.aDespachar))
                // dispatch(GetBoatsTotalityNumberForDashboardClub(body.numeroDeEmbarcacionesParaElDashboardClub))
                // console.log(body.aDespachar)
            } else {
                Swal.fire('Error', body.msg, 'error')
                // console.log(body)
            }
        } catch (error) {
            //throw new Error('Error en el sistema. Contactese con el administrador.')
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }
}

const GetBoatsAdespachar = (despacho) => ({
    type: types.embarcacionGetBoatsAdespachar,
    payload: despacho
});

// export const GetBoatsTotalityNumberForDashboardClub = (boats) => ({
//     type: types.embarcacionGetBoatsAdespachar,
//     payload: boats
// });

export const startSearchBoat = (text) => {
    return (dispatch) => {

        // console.log(text)

        dispatch(SearchBoat(text));
        // dispatch(startGetBoats())
    }

}

const SearchBoat = (text) => ({
    type: types.embarcacionSearchClubBoat,
    payload: text
});

export const startSearchDespachoBoat = (texto) => {
    return (dispatch) => {

        // console.log(texto)

        dispatch(SearchDespachoBoat(texto));
        // dispatch(SearchBoat(texto));

    }

}

const SearchDespachoBoat = (texto) => ({
    type: types.embarcacionSearchDespachoBoat,
    payload: texto
});

export const startGetSearchBoat = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;
        const { search } = getState().embarcacionClub;
        // if ( search === '' ) {
        //     dispatch(startGetBoats())
        // }
        try {
            const resp = await fetchConToken(`embarcaciones/clubes/id/${uid}/search/${search}`, {});
            const body = await resp.json();
            if (body.ok) {
                dispatch(GetSearchBoat(body.embarcaciones))
                // console.log(body.embarcaciones)
            } else {
                Swal.fire('Error', body.msg, 'error')
                // console.log(body)
            }
        } catch (error) {
            //throw new Error('Error en el sistema. Contactese con el administrador.')
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }

    // return async (dispatch) => {
    //     console.log(text)
    // }

}

const GetSearchBoat = (boat) => ({
    type: types.embarcacionGetSearchClubBoat,
    payload: boat
});

export const startGetSearchDespachoBoat = () => {

    return async (dispatch, getState) => {

        const { uid } = getState().auth;
        const { searchDespacho } = getState().embarcacionClub;

        // console.log(search)
        // if ( search === '' ) {
        //     dispatch(startGetBoats())
        // }

        try {
            const resp = await fetchConToken(`embarcaciones/clubes/despacho/id/${uid}/search/${searchDespacho}`, {});
            const body = await resp.json();

            if (body.ok) {

                dispatch(GetSearchDespachoBoat(body))
                // console.log(body)

            } else {

                Swal.fire('Error', body.msg, 'error')
                // console.log(body)

            }

        } catch (error) {
            //throw new Error('Error en el sistema. Contactese con el administrador.')
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }

    // return async (dispatch) => {
    //     console.log(text)
    // }

}

const GetSearchDespachoBoat = (boat) => ({
    type: types.embarcacionGetSearchDespachoBoat,
    payload: boat
});


export const startNewBoatClub = (boat) => {

    return async (dispatch, getState) => {
        const { place } = getState().auth;

        const payload = {
            ...boat, //nombre, mat, inscripcion, fechaAlta, desc
            amarre: place,
            despachada: false,
            prohibicion: false,
            pendiente: true,
            estado: true,
        }

        try {
            // console.log(boat, multa, result);
            // const resp = await fetchConToken(`senade/${uid}`, payload, 'POST');
            const resp = await fetchConToken(`embarcaciones/clubes/newboat`, payload, 'POST');
            const body = await resp.json();

            if (body.ok) {
                dispatch(startGetPendientes())

                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2500,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })
                Toast.fire({
                    icon: 'success',
                    title: 'Esta verificación se realizo correctamente.'
                })

            } else {
                Swal.fire('Error', body.msg, 'error');
            }

        } catch (error) {
            console.log('Error de conexión. Contactese con el administrador.')
            // console.log(error)
        }
    }
}

// const NewBoatClub = (boat) => ({
//     type: types.embarcacionSenadePostBoats,
//     payload: boat
// });

export const startPendingBoatClubDelete = (id) => {

    return async (dispatch) => {

        try {
            const resp = await fetchConToken(`embarcaciones/clubes/${id}`, {}, 'DELETE');
            const body = await resp.json();

            if (body.ok) {
                dispatch(startGetPendientes())

                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2500,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })
                Toast.fire({
                    icon: 'success',
                    title: 'Eliminado correctamente.'
                })

            } else {
                Swal.fire('Error', body.msg, 'error');
            }

        } catch (error) {
            console.log('Error de conexión. Contactese con el administrador.')
            // console.log(error)
        }
    }
}

export const startArriboDespacho = (item, action) => {
    return async (dispatch, getState) => {
        const payload = { item, action }
        try {
            // Si action es true, va a despachar
            if (action === true) {
                // Guarda en la tabla despachos todos los datos
                try {
                    const resp = await fetchConToken(`despacho/${item.id}`, payload, 'POST');
                    const body = await resp.json();
                    if (body.ok) {
                        dispatch(despachoNavegacion())
                        // Toast('success', 'Se despacho correctamente.')
                        Toast('success', 'Embarcación despachada correctamente.')
                        dispatch(setReset())
                    } else {
                        // return Swal.fire('Error', body.msg, 'error');
                        return Swal.fire({
                            customClass: {
                                container: 'mui-box-container',
                                // popup: 'mui-toast-popup',
                            },
                            icon: 'error',
                            title: 'Error',
                            text: body.msg,
                            didOpen: () => {
                                const toastContainer = document.querySelector('.mui-box-container');
                                if (toastContainer) {
                                    toastContainer.style.zIndex = 999999999;
                                }
                            },
                        });
                    }
                } catch (error) {
                    console.log('Error de conexión. Contactese con el administrador.')
                }
            } else {
                // Eliminar despacho
                try {
                    const resp = await fetchConToken(`despacho/${item.id}`, {}, 'DELETE');
                    const body = await resp.json();
                    if (body.ok) {
                        dispatch(arriboNavegacion());
                    } else {
                        return Swal.fire('Error', body.msg, 'error');
                    }
                } catch (error) {
                    console.log('Error de conexión. Contactese con el administrador.')
                }
            }
            dispatch(startGetClubBoats());
            dispatch(startGetBoatsAdespachar());
        } catch (error) {
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }
}

const despachoNavegacion = () => ({
    type: types.despachoPostDB
});

const arriboNavegacion = () => ({
    type: types.despachoDeleteDB
});

// const ArriboDespacho = () => ({ type: types.embarcacionPostArriboDespacho });





