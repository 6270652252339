import React, { useEffect } from 'react'
import PageHeader from '../../components/PageHeader'
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, AlertTitle, Box, Card, CardMedia, Paper, Typography, TableBody, TableRow, TableCell, Table, TableHead, TablePagination } from '@mui/material';

import { startEmbarcacionesTimonelPorIdDB } from '../../actions/embarcacionesTimonel';
import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { startRowsPerPage, startTablePageChange } from '../../actions/system';
import { startContravencionGet, startContravencionUsuarioTimonelGet } from '../../actions/embarcacionesSumario';
import Label from '../../components/tableLabelBadge/Label';
import { ImageLoading } from '../../components/ImageLoading';

const useStyles = makeStyles(theme => ({
  table: {
    marginTop: theme.spacing(3),
    '& thead th': {
      fontWeight: '600',
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light,
    },
    '& tbody td': {
      fontWeight: '300',
    },
    '& tbody tr:hover': {
      backgroundColor: '#fffbf2',
      cursor: 'pointer',
    },
  },
}))

const Row = (props) => {
  const { row, popupAlternativo } = props;
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <>
      <TableRow className={classes.root}>

        <TableCell component="th" scope="row">
          {row.contravencion}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.numActa}
        </TableCell>
        <TableCell align="right">{row.funcionario}</TableCell>
        <TableCell align="right">{row.createdAt}</TableCell>
        <TableCell align="right">
          <Label variant="ghost" color={
            (row.estado === true && 'secondary')
            || (row.estado === false && 'success')
          }>
            {(row.estado === true) ? 'Adeuda' : 'Pagado'}
          </Label>
        </TableCell>

      </TableRow>

    </>
  );
}

export const Contravenciones = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const pages = [5, 10, 25]
  const { page, rowsPerPage } = useSelector(state => state.system);
  const { name, place, uid } = useSelector(state => state.auth);
  const { embarcaciones = [] } = useSelector(state => state.embarcacionTimonel);
  const { contravenciones, timonelesUsuariosContravenciones = [] } = useSelector(state => state.sumario);
  const [parseEmbarcaciones, setParseEmbarcaciones] = useState([]);
  const [showContravenciones, setShowContravenciones] = useState({
    state: false,
    id: null
  });

  const handleChangePage = (event, newPage) => {
    dispatch(startTablePageChange(newPage))
  }

  const handleChangeRowsPerPage = event => {
    dispatch(startRowsPerPage(parseInt(event.target.value, 10)))
    dispatch(startTablePageChange(0))
  }

  useEffect(() => {
    dispatch(startEmbarcacionesTimonelPorIdDB())
  }, [])

  useEffect(() => {
    dispatch(startContravencionGet(showContravenciones.id))
  }, [showContravenciones.id])

  useEffect(() => {
    dispatch(startContravencionUsuarioTimonelGet(uid))
  }, [])

  useEffect(() => {
    const filteredEmbarcaciones = embarcaciones.filter(el => (
      el.usuariosEmbarcaciones.length > 0 &&
      el.usuariosEmbarcaciones[0].pendiente === false &&
      el.pendiente === false
    ));
    setParseEmbarcaciones(filteredEmbarcaciones);
  }, [embarcaciones]);

  // console.log(embarcaciones)
  // console.log(showContravenciones)

  return (
    <>
      <PageHeader
        title={`Contravenciones`}
        subTitle={`Prefectura ${place}`}
        icon={<DirectionsBoatIcon fontSize="large" />}
        profile={true}
        name={name}
      />

      <Paper sx={{ m: 5, p: 3 }}>
        <Typography variant="custom6" gutterBottom component="div" align='center' sx={{ p: 5, mt: 3 }}>
          Timonel:
        </Typography>
        <Box sx={{ mt: 0 }}>
          {
            (!timonelesUsuariosContravenciones.length)
              ? <Alert severity="info">
                <AlertTitle>Información:</AlertTitle>
                Titular no tiene contravenciones vigentes a la fecha.
              </Alert>
              : <>
                <Table className={classes.table} aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      {/* <TableCell /> */}
                      <TableCell>Contravencion</TableCell>
                      <TableCell>N° Acta</TableCell>
                      <TableCell align="right">Funcionario</TableCell>
                      <TableCell align="right">Fecha</TableCell>
                      <TableCell align="right">Estado</TableCell>
                      {/* <TableCell align="right">Acciones</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {timonelesUsuariosContravenciones.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                      // ((row.resultado === true) && (row.asignacionContravencion === 'boat')) && <Row key={row.id} row={row} popupAlternativo={setIsOpen} />
                      <Row key={row.id} row={row} />
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                  component="div"
                  page={page}
                  labelRowsPerPage='Filas por pagina:'
                  rowsPerPageOptions={pages}
                  rowsPerPage={rowsPerPage}
                  count={timonelesUsuariosContravenciones.length}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
          }
        </Box>
        <Typography variant="custom6" gutterBottom component="div" align='center' sx={{ p: 5, mt: 3 }}>
          Embarcaciónes:
        </Typography>
        <Box sx={{ display: 'flex', gap: 3, justifyContent: 'center' }}>
          {
            (!parseEmbarcaciones.length)
              ? <Alert severity="info">
                <AlertTitle>Información:</AlertTitle>
                Si usted cargo sus embarcaciones, los administradores deben procesarlas para que ud. pueda manipularlas.
              </Alert>
              : parseEmbarcaciones.map(({ id, nombre, inscripcion, matricula, imgEmbarcacionUrl }) => (
                <Card key={id} onClick={() => { setShowContravenciones({ state: true, id: id }) }} sx={{
                  height: '100px',
                  width: '350px',
                  display: 'flex',
                  cursor: 'pointer',
                  ...(showContravenciones.id === id && {
                    border: '1px solid blue'
                  }),
                }}>
                  <ImageLoading key={imgEmbarcacionUrl} sx={{ width: 150, height: 100 }}
                    src={`${process.env.REACT_APP_API_URL_FOR_IMAGES}/boatsImages/${imgEmbarcacionUrl}`}
                  />
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexGrow: 1,
                    backgroundColor: '#dfdfdf'
                  }}>
                    <Typography variant="h6" component="div" align='center' sx={{ userSelect: 'none' }}>
                      {nombre}
                    </Typography>
                    <Typography variant="h6" component="div" align='center' sx={{ userSelect: 'none' }}>
                      {`${inscripcion} ${matricula}`}
                    </Typography>
                  </Box>
                </Card>
              ))
          }
        </Box>

        {
          (showContravenciones.state) && (
            <Box sx={{ mt: 5 }}>
              {/* <Typography variant="h6" component="div" align='center' sx={{ userSelect: 'none' }}>
                {JSON.stringify(showContravenciones, null, 3)}
              </Typography>

              <Typography variant="h6" component="div" align='center' sx={{ userSelect: 'none' }}>
                {JSON.stringify(contravenciones, null, 3)}
              </Typography> */}
              {
                (!contravenciones.length)
                  ? <Alert severity="info">
                    <AlertTitle>Información:</AlertTitle>
                    Esta embarcacion no tiene contravenciones vigentes a la fecha.
                  </Alert>
                  : <>
                    <Table className={classes.table} aria-label="collapsible table">
                      <TableHead>
                        <TableRow>
                          {/* <TableCell /> */}
                          <TableCell>Contravencion</TableCell>
                          <TableCell>N° Acta</TableCell>
                          <TableCell align="right">Funcionario</TableCell>
                          <TableCell align="right">Fecha</TableCell>
                          <TableCell align="right">Estado</TableCell>
                          {/* <TableCell align="right">Acciones</TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {contravenciones.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                          // ((row.resultado === true) && (row.asignacionContravencion === 'boat')) && <Row key={row.id} row={row} popupAlternativo={setIsOpen} />
                          <Row key={row.id} row={row} />
                        ))}
                      </TableBody>
                    </Table>
                    <TablePagination
                      component="div"
                      page={page}
                      labelRowsPerPage='Filas por pagina:'
                      rowsPerPageOptions={pages}
                      rowsPerPage={rowsPerPage}
                      count={contravenciones.length}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </>
              }
            </Box>


          )
        }
      </Paper >
    </>
  )
}
