import React, { useState, useEffect } from 'react'
import { Box, Grid, Step, StepLabel, Stepper, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';
import Controls from '../../../components/controls/Controls';
import { Form, useForm } from '../../../components/useForm';
import { useDispatch, useSelector } from 'react-redux';
import { startListDependenciasDB } from '../../../actions/embarcacionesPrefectura';

const matriculaRegex = /^[A-Z]{4}-\d{1,10}\/\d{4}$/;
const inscripcionItems = [
    { id: 'REY', title: 'REY' },
    { id: 'REJU', title: 'REJU' },
    { id: 'E/T', title: 'E/T' },
]
const carnetItems = [
    { id: 1, nombre: 'No posee' },
    { id: 2, nombre: 'N1' },
    { id: 3, nombre: 'N2' },
    { id: 4, nombre: 'N3' },
    { id: 5, nombre: 'N4' },
    { id: 6, nombre: 'N5' },
    { id: 7, nombre: 'N6' },
    { id: 8, nombre: 'N7' },
    { id: 9, nombre: 'N8' },
    { id: 10, nombre: 'N9' },
    { id: 11, nombre: 'NA' },
    { id: 12, nombre: 'NB' },
]

const initialFValues = {
    id: 0,
    nombre: '',
    matricula: '',
    sinMatricula: false,
    amarre: '',
    desc: '',
    cuatrigrama: '',
    inscripcion: 'REY',
    fechaAlta: new Date(),
    multa: '',
    numActa: '',
    prohibicion: false,
    novedad: false,

    timonel: '',
    timonelNombre: '',
    timonelDni: '',
    timonelDireccion: '',
    timonelTelefono: '',
    timonelEmail: '',
    timonelCarnet: '',
}

const steps = [
    'Datos de embarcación',
    'Contravención y timonel'
];

const VerificacionGenerica = (props) => {
    const { addOrEdit, recordForEdit } = props
    const dispatch = useDispatch();
    const { clubes, dependencias = [] } = useSelector(state => state.admin);
    const { users = [] } = useSelector(state => state.embarcacionClub);
    const [contravencion, setContravencion] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const [validateFunctionNumber, setValidateFunctionNumber] = useState(0);
    const [user, setUser] = useState();

    // const arrago = { id: 0, nombre: "Propia" };
    // const array = [...clubes, { id: 0, nombre: "Propia" }];
    // console.log(array);

    useEffect(() => {
        dispatch(startListDependenciasDB());
    }, [])

    const validate0 = (fieldValues = values) => {
        let temp = { ...errors }

        if ('cuatrigrama' in fieldValues)
            temp.cuatrigrama = fieldValues.cuatrigrama ? "" : "Cuatrigrama es requerido."
        if ('inscripcion' in fieldValues) {
            if (fieldValues.inscripcion === 'REY' || fieldValues.inscripcion === 'E/T') {
                delete temp.cuatrigrama
            } else {
                temp.inscripcion = fieldValues.inscripcion ? "" : "Inscripción es requerido."
            }
        }
        // if ('matricula' in fieldValues)
        //     temp.matricula = fieldValues.matricula.length !== 0
        //         ? (/^[0-9]*$/).test(fieldValues.matricula)
        //             ? (fieldValues.matricula.length >= 0 && fieldValues.matricula.length <= 6)
        //                 ? ""
        //                 : "La matricula debe contener 4 caracter como minimo y 6 caracteres como maximo."
        //             : "Matricula solo puede contener numeros."
        //         : "Matricula es requerido."

        if ('matricula' in fieldValues) {
            if (values.inscripcion !== 'E/T') {
                temp.matricula = fieldValues.matricula.length !== 0
                    ? (/^[0-9]*$/).test(fieldValues.matricula)
                        ? (fieldValues.matricula.length >= 1 && fieldValues.matricula.length <= 6)
                            ? ""
                            : "La matricula debe contener al menos 1 caracter y como máximo 6 caracteres."
                        : "Matricula solo puede contener números."
                    : "Matricula es requerido."
            } else {
                // delete temp.matricula
                // temp.matricula = fieldValues.matricula ? "" : "Inscripción es requerido."

                temp.matricula = fieldValues.matricula.length !== 0
                    ? matriculaRegex.test(fieldValues.matricula)
                        ? (fieldValues.matricula.length >= 11 && fieldValues.matricula.length <= 20)
                            ? ""
                            : "La expediente debe contener al menos 11 caracter y como máximo 20 caracteres."
                        : `El formato del expediente debe ser similar al siguiente ejemplo: "SAFE-1234567899/2023".`
                    : "Expediente es requerido."
            }
        }

        if ('nombre' in fieldValues) {
            temp.nombre = fieldValues.nombre ? "" : "Nombre de embarcación es requerido."
        }
        if ('amarre' in fieldValues) {
            temp.amarre = fieldValues.amarre ? "" : "Lugar de amarre es requerido."
        }
        // sinMatricula
        if ('sinMatricula' in fieldValues) {
            if (fieldValues.sinMatricula === true) {
                delete temp.cuatrigrama
                delete temp.matricula
            }
        }

        setErrors({ ...temp })
        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }

    const validate1 = (fieldValues = values) => {
        let temp = { ...errors }
        if (
            // ((recordForEdit === null) && values.novedad) ||
            (values.novedad && (values.timonel === '' || values.timonel === 'OTRO'))
        ) {
            if ('timonel' in fieldValues) {
                if (recordForEdit !== null) {
                    temp.timonel = (fieldValues.timonel) ? "" : "Timonel es requerido."
                }
            }

            if ('timonelNombre' in fieldValues)
                temp.timonelNombre = fieldValues.timonelNombre.length !== 0
                    ? (/^[a-z\s\u00C0-\u00ff]+$/i).test(fieldValues.timonelNombre)
                        ? ""
                        : "Nombre de timonel solo puede contener letras."
                    : "Nombre de timonel es requerido."
            if ('timonelDni' in fieldValues)
                temp.timonelDni = fieldValues.timonelDni.length !== 0
                    ? (/^[0-9]*$/).test(fieldValues.timonelDni)
                        ? (fieldValues.timonelDni.length >= 7 && fieldValues.timonelDni.length <= 8)
                            ? ""
                            : "Dni debe contener 7 caracteres como minimo y 8 caracteres como maximo."
                        : "Dni solo puede contener numeros."
                    : "Dni es requerido."
            if ('timonelTelefono' in fieldValues)
                temp.timonelTelefono = fieldValues.timonelTelefono.length !== 0
                    ? (/^[0-9]*$/).test(fieldValues.timonelTelefono)
                        ? (fieldValues.timonelTelefono.length >= 9 && fieldValues.timonelTelefono.length <= 11)
                            ? ""
                            : "Telefono debe contener 9 caracteres como minimo y 11 caracteres como maximo."
                        : "Telefono solo puede contener numeros."
                    : "Telefono es requerido."

            if ('timonelEmail' in fieldValues)
                temp.timonelEmail = fieldValues.timonelEmail.length !== 0
                    ? (/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/).test(fieldValues.timonelEmail)
                        ? ""
                        : "Email no es valido."
                    : ""

            // timonelCarnet
            if ('timonelDireccion' in fieldValues) {
                temp.timonelDireccion = fieldValues.timonelDireccion ? "" : "Dirección es requerido."
            }
            if ('multa' in fieldValues) {
                temp.multa = fieldValues.multa ? "" : "Contravención no valido."
            }
            if ('numActa' in fieldValues) {
                temp.numActa = fieldValues.numActa ? "" : "N° acta no valido."
            }
            if ('timonelCarnet' in fieldValues)
                temp.timonelCarnet = fieldValues.timonelCarnet ? "" : "Carnet de timonel es requerido."
        } else {
            delete temp.timonel
            delete temp.timonelNombre
            delete temp.timonelDni
            delete temp.timonelTelefono
            delete temp.timonelEmail
            delete temp.timonelDireccion
            delete temp.multa
            delete temp.numActa
        }
        setErrors({
            ...temp
        })
        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFValues, true, (validateFunctionNumber === 0) ? validate0 : validate1);

    // const isStepOptional = (step) => {
    //     // return step === 1;
    // };

    // const isStepSkipped = (step) => {
    //     return skipped.has(step);
    // };

    const handleNext = (e) => {
        // let newSkipped = skipped;
        // if (isStepSkipped(activeStep)) {
        //     newSkipped = new Set(newSkipped.values());
        //     newSkipped.delete(activeStep);
        // }
        // if (validate0()) {
        if (validateFunctionNumber === 0) {
            if (validate0()) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                setValidateFunctionNumber(1)
            }
            // } else if (validateFunctionNumber === 1) {
        } else {
            const temp = {
                ...values,
                // matricula: (values.sinMatricula === true) ? null : values.matricula,
                // cuatrigrama:
                //     (values.inscripcion === 'REY')
                //         ? null
                //         : values.cuatrigrama,
                // desc: (values.desc.trim() !== '') ? values.desc : null

                // desc: (values.desc === null) ? '' : values.desc.toUpperCase(),
                // multa: values.multa.toUpperCase(),
                // nombre: values.nombre.toUpperCase(),
                // timonelNombre: values.timonelNombre.toUpperCase(),
                // timonelDireccion: values.timonelDireccion.toUpperCase(),
                // timonelCarnet: (values.timonelCarnet === 'No posee') ? null : values.timonelCarnet

                // -------------------
                desc: (values.desc === null) ? '' : values.desc.toUpperCase(),
                timonelCarnet: (values.timonelCarnet === 'No posee') ? null : values.timonelCarnet,

                timonelRegistered: (values.timonel === '' || values.timonel === 'OTRO') ? false : true,
                // idTimonelRegistered:
                //     (values.timonel !== '' || values.timonel !== 'OTRO')
                //         ? user.usuarioId
                //         : null,

                idTimonelRegistered:
                    (values.timonel !== 'OTRO')
                        ? (user && user.usuarioId) || null
                        : null,

                matricula: (values.sinMatricula) ? null : values.matricula,
                cuatrigrama: (values.sinMatricula) ? null : values.cuatrigrama

                // timonelNombre: (values.timonel !== undefined || values.timonel !== 'OTRO'),
                // timonelDni: data.timonelDni,
                // timonelDireccion: data.timonelDireccion,
                // timonelTelefono: data.timonelTelefono,
                // timonelEmail: data.timonelEmail,
                // timonelCarnet: data.timonelCarnet
            }
            if (validate1()) {
                e.preventDefault();
                addOrEdit(temp, resetForm);
                // console.log(temp)
            }
        }
        // console.log('im ' + activeStep)
        // setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setValidateFunctionNumber(0);
        setErrors({});
    };

    // const handleSkip = () => {
    //     // if (!isStepOptional(activeStep)) {
    //     //     // You probably want to guard against something like this,
    //     //     // it should never occur unless someone's actively trying to break something.
    //     //     throw new Error("You can't skip a step that isn't optional.");
    //     // }

    //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //     setSkipped((prevSkipped) => {
    //         const newSkipped = new Set(prevSkipped.values());
    //         newSkipped.add(activeStep);
    //         return newSkipped;
    //     });
    // };

    // const handleReset = () => {
    //     setActiveStep(0);
    // };

    const handleSubmit = e => {
        e.preventDefault();
        // setOpenPopup(false);
        if (values.sinMatricula === true)
            values.matricula = null
        // if (validate()) {
        //     addOrEdit(values, resetForm);
        //     // console.log(values)
        // }
    }

    useEffect(() => {
        if (recordForEdit != null)
            setValues({
                ...recordForEdit,
                prohibicion: false,
                novedad: false,
                multa: '',
                numActa: '',
                timonel: '',
                timonelNombre: '',
                timonelDni: '',
                timonelDireccion: '',
                timonelTelefono: '',
                timonelEmail: '',
                timonelCarnet: '',
            })
    }, [recordForEdit, setValues])

    useEffect(() => {
        if (!contravencion) {
            values.multa = ''
            values.numActa = ''
            values.timonel = ''
            values.timonelNombre = ''
            values.timonelDni = ''
            values.timonelDireccion = ''
            values.timonelTelefono = ''
            values.timonelEmail = ''
            values.timonelCarnet = ''
        }
    }, [contravencion, values])

    useEffect(() => {
        setUser(users.find(i => i.usuario.id === values.timonel))
    }, [values.timonel])

    useEffect(() => {
        if (recordForEdit === null) {
            if (values.sinMatricula === true) {
                setValues({ ...values, novedad: true })
                setContravencion(true)
            } else {
                setValues({ ...values, novedad: false })
                setContravencion(false)
            }
        }
    }, [values.sinMatricula])

    // console.log(values.timonelCarnet)
    // console.log(values.timonel)
    // console.log(user)
    // console.log(errors)

    // useEffect(() => {
    //     if (values.sinMatricula === true) {
    //         setContravencion(true)
    //     }
    // }, [values.sinMatricula])

    // console.log(recordForEdit)

    // console.log(errors)
    // console.log(values)

    return (
        <>
            <Box sx={{ width: '100%', mb: 3 }}>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        // if (isStepOptional(index)) {
                        //     labelProps.optional = (
                        //         <Typography variant="caption">Optional</Typography>
                        //     );
                        // }
                        // if (isStepSkipped(index)) {
                        //     stepProps.completed = false;
                        // }
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </Box>

            <Form onSubmit={handleNext}>
                {
                    (activeStep === 0) &&
                    <>
                        <Grid container>
                            <Grid item xs={6}>
                                <Controls.RadioGroup
                                    name="inscripcion"
                                    label="Inscripción"
                                    value={values.inscripcion}
                                    onChange={handleInputChange}
                                    items={inscripcionItems}
                                    disabled={(recordForEdit !== null) ? true : false || ((values.sinMatricula === true) ? true : false)}
                                />
                                {/* <Controls.Select
                                        name="cuatrigrama"
                                        label="Cuatrigrama"
                                        value={values.cuatrigrama}
                                        onChange={handleInputChange}
                                        options={[{ id: 1, nombre: 'SAFE' }, { id: 2, nombre: 'ROSA' }, , { id: 3, nombre: 'SNIC' }, , { id: 4, nombre: 'PARA' }]}
                                        disabled={(recordForEdit !== null) ? true : false || ((values.inscripcion === 'REY') ? true : false) || ((values.sinMatricula === true) ? true : false)}
                                        error={errors.cuatrigrama}
                                    /> */}
                                {
                                    (values.inscripcion === 'REJU') &&

                                    <FormControl error={errors.cuatrigrama}>
                                        <InputLabel>Cuatrigrama</InputLabel>
                                        <Select
                                            name="cuatrigrama"
                                            label="Cuatrigrama"
                                            value={values.cuatrigrama}
                                            onChange={handleInputChange}
                                            disabled={(recordForEdit !== null) ? true : false || ((values.inscripcion === 'REY' || values.inscripcion === 'E/T') ? true : false) || ((values.sinMatricula === true) ? true : false)}

                                        // renderValue={(value) => `⚠️  - ${value}`}
                                        >
                                            {dependencias.map(
                                                item => (<MenuItem key={item.id} value={item.cuatrigrama}>{item.cuatrigrama} - {item.nombre}</MenuItem>)
                                            )}
                                        </Select>
                                        {errors.cuatrigrama && <FormHelperText>{errors.cuatrigrama}</FormHelperText>}
                                    </FormControl>
                                }

                                <Controls.Input
                                    name="matricula"
                                    label={(values.inscripcion === 'E/T') ? 'Expediente*' : 'Matricula*'}
                                    value={values.matricula}
                                    onChange={handleInputChange}
                                    error={errors.matricula}
                                    disabled={((recordForEdit !== null) ? true : false) || ((values.sinMatricula === true) ? true : false)}
                                />
                                {
                                    (recordForEdit != null)
                                        ? null
                                        : <Controls.Checkbox
                                            label="No posee matricula"
                                            name="sinMatricula"
                                            value={values.sinMatricula}
                                            onChange={handleInputChange}
                                            onClick={(e) => setContravencion(e.target.checked)}
                                        // onClick={(e) => {setContravencion(e.target.checked); setValues({...values, novedad: true})}}
                                        />
                                }
                                <Controls.Input
                                    name="nombre"
                                    label="Nombre"
                                    value={values.nombre.toUpperCase()}
                                    onChange={handleInputChange}
                                    error={errors.nombre}
                                    disabled={(recordForEdit != null) ? true : false}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <Controls.DatePickerControl
                                    name="fechaAlta"
                                    label="Fecha de carga"
                                    value={values.fechaAlta}
                                    onChange={handleInputChange}
                                    disabled={(recordForEdit != null) ? true : false}
                                />
                                {/* {
                                    (recordForEdit) && */}
                                <Controls.Select
                                    name="amarre"
                                    label="Lugar de amarre"
                                    value={values.amarre}
                                    onChange={handleInputChange}
                                    // options={employeeService.getDepartmentCollection()}
                                    options={[{ id: 0, nombre: "Propio" }, ...clubes]}
                                    error={errors.amarre}
                                    disabled={(recordForEdit != null) ? true : false}
                                />
                                {/* } */}
                                <Controls.Input
                                    // width: '90%'
                                    name="desc"
                                    label="Descripción"
                                    value={(values.desc === null) ? '' : values.desc.toUpperCase()}
                                    onChange={handleInputChange}
                                    error={errors.desc}
                                    disabled={(recordForEdit != null) ? true : false}
                                />
                            </Grid>
                        </Grid>
                    </>
                }

                {
                    (activeStep === 1) &&
                    <>
                        <Grid container>
                            <Grid item xs={6}>
                                <Controls.Checkbox
                                    name="novedad"
                                    label="¿Tiene novedad?"
                                    value={values.novedad}
                                    // value={(values.sinMatricula) ? true : values.novedad}
                                    onClick={(e) => setContravencion(e.target.checked)}
                                    onChange={handleInputChange}
                                    disabled={(values.sinMatricula === true) ? true : false}
                                />
                            </Grid>
                            {
                                (recordForEdit) &&
                                <Grid item xs={6}>
                                    <FormControl error={errors.timonel}>
                                        <InputLabel>Timonel</InputLabel>
                                        <Select
                                            name="timonel"
                                            label="Timonel"
                                            value={values.timonel}
                                            onChange={handleInputChange}
                                            disabled={!contravencion}
                                        // renderValue={(value) => `⚠️  - ${value}`}
                                        >
                                            {users.map(
                                                item => (<MenuItem key={item.usuario.id} value={item.usuario.id}>{item.usuario.apellido}, {item.usuario.nombre}</MenuItem>)
                                            )}
                                            <MenuItem key={'OTRO'} value={'OTRO'}>OTRO</MenuItem>
                                        </Select>
                                        {errors.timonel && <FormHelperText>{errors.timonel}</FormHelperText>}
                                    </FormControl>
                                </Grid>
                            }
                        </Grid>
                        <Grid container>
                            <Grid item xs={6}>
                                <Controls.Input
                                    name="multa"
                                    label="Contravención"
                                    value={values.multa.toUpperCase()}
                                    onChange={handleInputChange}
                                    error={errors.multa}
                                    disabled={!contravencion}
                                />
                                {
                                    (
                                        (contravencion && values.timonel === 'OTRO') ||
                                        (values.sinMatricula) ||
                                        (!recordForEdit && values.novedad)
                                    ) &&
                                    <>
                                        <Controls.Input
                                            name="timonelNombre"
                                            label="Nombre timonel"
                                            value={values.timonelNombre.toUpperCase()}
                                            onChange={handleInputChange}
                                            error={errors.timonelNombre}
                                            disabled={!contravencion}
                                        />
                                        <Controls.Input
                                            name="timonelDni"
                                            label="Dni"
                                            value={values.timonelDni}
                                            onChange={handleInputChange}
                                            error={errors.timonelDni}
                                            disabled={!contravencion}
                                        />
                                        <Controls.Input
                                            name="timonelDireccion"
                                            label="Dirección"
                                            value={values.timonelDireccion.toUpperCase()}
                                            onChange={handleInputChange}
                                            error={errors.timonelDireccion}
                                            disabled={!contravencion}
                                        />
                                    </>
                                }
                            </Grid>
                            <Grid item xs={6}>
                                <Controls.Input
                                    name="numActa"
                                    label="Numero de acta"
                                    value={values.numActa}
                                    onChange={handleInputChange}
                                    error={errors.numActa}
                                    disabled={!contravencion}
                                />
                                {
                                    (
                                        (contravencion && values.timonel === 'OTRO') ||
                                        (values.sinMatricula) ||
                                        (!recordForEdit && values.novedad)
                                    ) &&
                                    <>
                                        <Controls.Input
                                            name="timonelTelefono"
                                            label="Telefono"
                                            value={values.timonelTelefono}
                                            onChange={handleInputChange}
                                            error={errors.timonelTelefono}
                                            disabled={!contravencion}
                                        />
                                        <Controls.Select
                                            name="timonelCarnet"
                                            label="Carnet de timonel"
                                            value={values.timonelCarnet}
                                            onChange={handleInputChange}
                                            options={carnetItems}
                                            error={errors.timonelCarnet}
                                            disabled={!contravencion}
                                        />
                                        <Controls.Input
                                            name="timonelEmail"
                                            label="Email"
                                            value={values.timonelEmail.toLowerCase()}
                                            onChange={handleInputChange}
                                            error={errors.timonelEmail}
                                            disabled={!contravencion}
                                        />
                                    </>
                                }
                            </Grid>
                        </Grid>
                    </>
                }
                {activeStep === steps.length ? (
                    <>
                        {/* <Typography sx={{ mt: 2, mb: 1 }}>
                            All steps completed - you&apos;re finished
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button onClick={handleReset}>Reset</Button>
                        </Box> */}
                        {/* {
                            JSON.stringify(values, null, 5)
                        } */}
                    </>
                ) : (
                    <>
                        {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Controls.Button
                                // color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                // sx={{ mr: 1 }}

                                variant="outlined"
                                text="Atras"
                            />
                            {/* Atras */}
                            {/* </Button> */}
                            <Box sx={{ flex: '1 1 auto' }} />
                            {/* {isStepOptional(activeStep) && (
                                <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                    Skip
                                </Button>
                            )} */}


                            {/* <Button onClick={handleNext}>
                                {activeStep === steps.length - 1 ? 'Finalizar' : 'Siguiente'}
                            </Button> */}
                            <Controls.Button
                                variant="outlined"
                                onClick={handleNext}
                                text={activeStep === steps.length - 1 ? 'Finalizar' : 'Siguiente'}
                            />
                        </Box>
                    </>
                )}
                {/* <div>
                    <Controls.Button
                        type="submit"
                        text="Verificar" />
                    <Controls.Button
                        text="Reset"
                        color="default"
                        onClick={resetForm} />
                </div> */}
            </Form>
        </>
    )
}

export default VerificacionGenerica;