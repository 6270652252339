import { types } from '../types/types';

const initialState = {
    embarcaciones: [],
    search: '',
    searchDespacho: '',
    aDespachar: [],
    totalityNumber: [],
    pendientes: [],
    users: [],
}

export const embarcacionClubesReducer = (state = initialState, action) => {

    switch (action.type) {

        case types.embarcacionGetClubBoats:
            return {
                ...state,
                embarcaciones: [...action.payload],
            }

        case types.embarcacionSearchClubBoat:
            return {
                ...state,
                // searchDespacho: action.payload,
                search: action.payload,
                // ...action.payload,
            }

        case types.embarcacionGetSearchClubBoat:
            return {
                ...state,
                embarcaciones: [...action.payload],
            }

        case types.embarcacionGetBoatsAdespachar:
            // console.log(action.payload)
            return {
                ...state,
                aDespachar: [...action.payload],
            }

        case types.embarcacionGetBoatsTotalityNumber:
            // console.log(action.payload)
            return {
                ...state,
                totalityNumber: [...action.payload],
            }

        case types.embarcacionSearchDespachoBoat:
            return {
                ...state,
                // searchDespacho: action.payload,
                searchDespacho: action.payload,
                // ...action.payload,
            }

        case types.embarcacionGetSearchDespachoBoat:
            return {
                ...state,
                ...action.payload,
            }

        case types.embarcacionPostArriboDespacho:
            return {
                ...state,
                ...action.payload,
            }

        case types.embarcacionGetBoatsPendientes:
            // console.log(action.payload)
            return {
                ...state,
                pendientes: action.payload,
            }

        case types.getUsersFromAboat:
            // console.log(action.payload)
            return {
                ...state,
                users: action.payload,
            }

        default:
            return state;
    }

}


