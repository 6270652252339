import React from 'react'
import { Dialog, DialogTitle, DialogContent, Typography } from '@mui/material';

import Controls from "./controls/Controls";
import CloseIcon from '@mui/icons-material/Close';
// import { Translate } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { startClosePopup } from '../actions/system';
import { isMobile } from 'react-device-detect';

// const useStyles = makeStyles(theme => ({
//     dialogWrapper: {
//         padding: theme.spacing(2),

//         minHeight: p => p.mobile
//             ? '100vh'
//             : 'unset'
//     },
//     dialogTitle: {
//         padding: 0,
//         // paddingRight: '0px',
//         zIndex: '10',

//     }
// }))

export default function Popup(props) {

    // const objStyles = {
    //     mobile: isMobile
    // }

    const dispatch = useDispatch();
    const { statusPopup } = useSelector(state => state.system);
    const { title, children, size = 'sm' } = props;
    // const classes = useStyles(objStyles);

    return (

        <Dialog
            open={statusPopup}
            maxWidth={size}
            // classes={{ paper: classes.dialogWrapper }}
            sx={{
                padding: 2,
                minHeight: (isMobile) ? '100vh' : 'unset'
            }}
        >
            <DialogTitle
                // className={classes.dialogTitle} 
                sx={{
                    ml: 2,
                    padding: 1,
                    zIndex: 10
                }}
            >
                <div style={{ display: 'flex' }}>
                    <Typography
                        variant="h6"
                        mt={1}
                        component="div"
                        style={{ flexGrow: 1 }}
                    >
                        {title}
                    </Typography>
                    {/* <Controls.ActionButton
                        color="secondary"
                        onClick={() => { dispatch(startClosePopup()) }}>
                        <CloseIcon />
                    </Controls.ActionButton> */}
                    <Controls.ActionButton
                        color="secondary"
                        onClick={() => { dispatch(startClosePopup()) }}>
                        <CloseIcon />
                    </Controls.ActionButton>
                </div>
            </DialogTitle>
            <DialogContent dividers>
                {children}
            </DialogContent>
        </Dialog>
    )
}

/* 
const Child = (props) => {
    return <p>{props.text}</p>
};

const Parent = (props) => {
  // children is a property on every React component's props and
  // refers to the React components nested within the Parent component
  return <div> {props.children} </div>
};


const Main = () => {

  return (
    <Parent>
      <Child text={"Hello World"}</Child
      <Child text={"I am a child component"}</Child
      <Child text={"Parent renders as many Child components as are provided"}</Child>
    </Parent>
  ); 

};
*/