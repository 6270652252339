import { types } from '../types/types';

const initialState = {

    search: '',
    pendingBoats: [],
    relationshipsPendingBoats: [],
    relationshipsNoPendingBoats: [],

    carnet: {
        busqueda: 'MAT',
        embarcaciones: {},
        timoneles: {},
    },

}

export const embarcacionReducer = (state = initialState, action) => {

    switch (action.type) {

        case types.embarcacionGetBoats:
            return {
                ...state,
                ...action.payload,
            }

        case types.embarcacionGetPendingBoats:
            return {
                ...state,
                pendingBoats: [...action.payload],
            }

        case types.embarcacionGetRelationshipsPendingBoats:
            return {
                ...state,
                relationshipsPendingBoats: [...action.payload],
            }

        case types.embarcacionGetRelationshipsNoPendingBoats:
            return {
                ...state,
                relationshipsNoPendingBoats: [...action.payload],
            }

        case types.embarcacionPostNewBoats:
            return {
                ...state,
                ...action.payload,
            }

        case types.embarcacionPutEditBoat:
            return {
                ...state,
                ...action.payload,
            }

        case types.embarcacionDeleteBoat:
            return {
                ...state,
                state
                // ...action.payload,
            }

        case types.embarcacionSearchBoat:
            return {
                ...state,
                search: action.payload,
                // ...action.payload,
            }

        case types.embarcacionGetSearchBoat:
            return {
                ...state,
                ...action.payload,
            }

        case types.embarcacionRecordForEdit:
            return {
                ...state,
                ...action.payload,
            }

        // case types.embarcacionGetBoatsCarnet:
        //     return {
        //         ...state,
        //         carnet: {
        //             ...state.carnet,
        //             embarcaciones: [...action.payload]
        //         },
        //     }

        // case types.embarcacionGetTimonelCarnet:
        //     return {
        //         ...state,
        //         carnet: {
        //             ...state.carnet,
        //             timoneles: [...action.payload]
        //         },
        //     }

        case types.embarcacionGetBoatsCarnet:
            return {
                ...state,
                carnet: {
                    ...state.carnet,
                    embarcaciones: action.payload
                },
            }

        case types.embarcacionGetTimonelCarnet:
            return {
                ...state,
                carnet: {
                    ...state.carnet,
                    timoneles: action.payload
                },
            }

        case types.systemRadioTablePolinave:
            return {
                ...state,
                carnet: {
                    ...state.carnet,
                    busqueda: action.payload
                },
            }

        default:
            return state;
    }

}


