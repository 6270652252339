import Swal from "sweetalert2";
import { fetchConToken } from "../services/fetch";
import { useImageUpload } from '../components/hooks/useImageUpload';
import { types } from "../types/types";
import { startListRelacionesPendientesDB } from "./embarcacionesPrefectura";
import { Toast } from "../components/Toast";
import { changeLoading } from "./system";

export const startAcceptRelationship = (idRelationship) => {
    return async (dispatch) => {
        try {
            const resp = await fetchConToken(`embarcaciones/particular/relationship/${idRelationship}`, {}, 'PUT');
            const body = await resp.json();
            if (body.ok) {
                dispatch(startListRelacionesPendientesDB())
                Toast('success', body.msg)
            } else {
                Swal.fire('Error', body.msg, 'error')
            }
        } catch (error) {
            //throw new Error('Error en el sistema. Contactese con el administrador.')
            console.log('Error de conexión. Contactese con el administrador.')
            // console.log(error)
        }
    }
}

export const startAllEmbarcacionesGetToLoadAddRemoveBoatsTimonel = () => {
    return async (dispatch) => {
        try {
            const resp = await fetchConToken(`embarcaciones/particular/allboats`, {});
            const body = await resp.json();
            if (body.ok) {
                dispatch(allEmbarcacionesGetToLoadAddRemoveBoatsTimonel(body.embarcaciones))
                // console.log(body.embarcaciones)
            } else {
                Swal.fire('Error', body.msg, 'error')
            }
        } catch (error) {
            //throw new Error('Error en el sistema. Contactese con el administrador.')
            console.log('Error de conexión. Contactese con el administrador.')
            // console.log(error)
        }
    }
}

const allEmbarcacionesGetToLoadAddRemoveBoatsTimonel = (boats) => ({
    type: types.embarcacionGetToFormAddRemoveBoatsTimonel,
    payload: boats
});

export const startEmbarcacionesTimonelPorIdDB = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;
        try {
            const resp = await fetchConToken(`embarcaciones/particular/${uid}`, {});
            const body = await resp.json();

            if (body.ok) {

                dispatch(embarcacionesTimonelPorIdDB(body.embarcaciones))
                // console.log(body.embarcaciones)

            } else {
                Swal.fire('Error', body.msg, 'error')
            }

        } catch (error) {
            //throw new Error('Error en el sistema. Contactese con el administrador.')
            console.log('Error de conexión. Contactese con el administrador.')
            // console.log(error)
        }
    }
}

const embarcacionesTimonelPorIdDB = (boats) => ({
    type: types.embarcacionGetTimonel,
    payload: boats
});

export const startEmbarcacionesTimonelSave = (boats) => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;
        try {
            dispatch(changeLoading(true));
            const boatsWithImages = await Promise.all(boats.map(async (el) => {
                if (el.id) {
                    // Elementos con id: simplemente los devolvemos sin cambios
                    return {
                        ...el,
                        usuarioId: uid,
                    };
                } else {
                    // const imgEmbarcacionUrl = await useImageUpload(
                    //     el.imgEmbarcacion.fileToUpload,
                    //     'boatsImages',
                    //     (el.inscripcion === 'REY')
                    //         ? 'REY' + el.matricula
                    //         : (el.inscripcion === 'E/T')
                    //             ? 'ET' + el.matricula
                    //             : el.cuatrigrama + el.matricula
                    // );
                    const imgEmbarcacionUrl = await useImageUpload(
                        el.imgEmbarcacion.fileToUpload,
                        'boatsImages',
                        (el.inscripcion === 'REY')
                            ? 'REY' + el.matricula
                            : (el.inscripcion === 'E/T')
                                ? (() => {
                                    const result = el.matricula.replace(/[-/]/g, "_");
                                    const resultAndPrefix = 'ET_' + result;
                                    return resultAndPrefix;
                                })()
                                : el.cuatrigrama + el.matricula
                    );
                    const imgMatriculaUrl = await useImageUpload(
                        el.imgMatricula,
                        'boatsCredentialsImages',
                        (el.inscripcion === 'REY')
                            ? 'REY' + el.matricula
                            : (el.inscripcion === 'E/T')
                                ? (() => {
                                    const result = el.matricula.replace(/[-/]/g, "_");
                                    const resultAndPrefix = 'ET_' + result;
                                    return resultAndPrefix;
                                })()
                                : el.cuatrigrama + el.matricula
                    );
                    const filenameEmbarcacion = imgEmbarcacionUrl.nombre.split('/')
                    const filenameMatricula = imgMatriculaUrl.nombre.split('/')
                    // Crear un nuevo objeto con las propiedades actualizadas
                    const result = {
                        ...el,
                        usuarioId: uid,
                        imgEmbarcacionUrl: filenameEmbarcacion[filenameEmbarcacion.length - 1],
                        imgMatriculaUrl: filenameMatricula[filenameMatricula.length - 1]
                    };
                    return result;
                }
            }))

            const resp = await fetchConToken('embarcaciones/particular/boats/addremove', { uid, boats: boatsWithImages }, 'POST');
            const body = await resp.json();
            if (body.ok) {
                dispatch(startEmbarcacionesTimonelPorIdDB());
                dispatch(changeLoading(false));
                Toast('success', 'Embarcaciones guardadas correctamente.');
            } else {
                dispatch(changeLoading(false));
                Swal.fire('Error', body.msg, 'error')
            }
        } catch (error) {
            //throw new Error('Error en el sistema. Contactese con el administrador.')
            console.log('Error de conexión. Contactese con el administrador.')
            // console.log(error)
        }
    }
}

export const startTimonelPorIdDB = () => {

    return async (dispatch, getState) => {
        const { uid } = getState().auth;

        try {
            const resp = await fetchConToken(`usuarios/id/${uid}`, {});
            const body = await resp.json();

            if (body.ok) {

                dispatch(timonelPorIdDB(body.timonel))
                // console.log(body.embarcaciones)

            } else {
                Swal.fire('Error', body.msg, 'error')
            }

        } catch (error) {
            //throw new Error('Error en el sistema. Contactese con el administrador.')
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }
}

const timonelPorIdDB = (timonel) => ({
    type: types.usuarioGetTimonel,
    payload: timonel
});

export const startTimonelDespacho = (datos, action) => {
    return async (dispatch,) => {

        /* despacho original desde el club
          id: 30,
          nombre: '',
          dni: '',
          horaAprox: '',
          telefonoPersonaAcargo: '',
        //   titular: true,
        //   autorizado: false,
        //   catCarnet: 'N4',
        //   fechaCarnet: '2023-04-29T19:08:02.000Z',
        //   lugarNavegacion: 'ESTE',
        //   fechaRegresoAprox: '2023-04-30T19:08:02.000Z',
        //   rol: 'titular',
        //   actuacionNotarialAutorizado: '-',
        //   nombrePropietario: 'PEPE',
        //   dniPropietario: '8789676',
        //   telefonoPropietario: '7687688787',
        //   fechaSalida: '2023-04-28T18:24:55.765Z',
        //   nombrePersonaAcargo: '-',
        //   dniPersonaAcargo: '-',
        //   datosTripulantes: 'DFSDF',
        //   capacidadPersonas: '2',
        //   coords: 'Lat: -31.631898579147165 Long: -60.66169738769532',
        //   coordLatitud: '-31.631898579147165',
        //   coordLongitud: '-60.66169738769532'
        */

        /* despacho desde seccion timonel
        //   rol: 'titular',
        //   titular: true,
        //   fechaSalida: '2023-04-28T18:24:55.769Z',
        //   lugarNavegacion: 'OESTE',
        //   datosTripulantes: 'GFDG',
        //   capacidadPersonas: '2',
        //   coords: 'Lat: -31.978958480753228 Long: -60.91232299804688',
        //   fechaRegresoAprox: '2023-04-29T19:10:05.000Z',
        //   autorizado: false,
        //   actuacionNotarialAutorizado: '-',
        //   nombrePersonaAcargo: '-',
        //   dniPersonaAcargo: '-',
        //   coordLatitud: '-31.978958480753228',
        //   coordLongitud: '-60.91232299804688',
        //   nombrePropietario: 'LALO',
        //   dniPropietario: '78787878',
        //   telefonoPropietario: '155104878',
        //   catCarnet: 'N7',
        //   fechaCarnet: '2023-04-30'
        */


        // const data = {
        //     ...datos,
        //     id,
        // }

        try {
            // Si action es true, es porque esta despachada
            if (action === true) {
                // Guarda en la tabla despachos todos los datos
                // const payload = getState().despachoClub;
                // const datos = { id: id }
                // const temp = Object.assign(payload, datos)


                try {
                    // const resp = await fetchConToken(`despacho/${datos.id}`, datos, 'POST');
                    // const body = await resp.json();
                    // if (body.ok) {

                        const resp = await fetchConToken(`embarcaciones/particular/action/${datos.id}`, { datos, action: true }, 'PUT');
                        const body = await resp.json();

                        if (body.ok) {
                            dispatch(startEmbarcacionesTimonelPorIdDB())

                            const Toast = Swal.mixin({
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', Swal.stopTimer)
                                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                                }
                            })
                            Toast.fire({
                                icon: 'success',
                                title: 'Sus datos han sido cargados con exito. Recuerde, tiene 24 horas para leer el QR y terminar el tramite de despacho.'
                            })
                            dispatch(changeLoading(false));
                        }

                    // } else {
                    //     Swal.fire('Error', body.msg, 'error');
                    //     dispatch(changeLoading(false));
                    // }
                } catch (error) {
                    console.log('Error de conexión. Contactese con el administrador.')
                    dispatch(changeLoading(false));
                }
            }
            // else {
            //     // Eliminar despacho
            //     try {
            //         const resp = await fetchConToken(`despacho/${id}`, {}, 'DELETE');
            //         const body = await resp.json();
            //         if (body.ok) {
            //             dispatch(arriboNavegacion());
            //         } else {
            //             Swal.fire('Error', body.msg, 'error');
            //         }
            //     } catch (error) {
            //         console.log('Error de conexión. Contactese con el administrador.')
            //     }
            // }
        } catch (error) {
            console.log('Error de conexión. Contactese con el administrador.')
            dispatch(changeLoading(false));
        }
    }
}

export const startQRDespachar = (idBoat) => {

    return async (dispatch, getState) => {
        const { uid } = getState().auth;

        try {
            const resp = await fetchConToken(`embarcaciones/particular/qr/${uid}`, { idBoat }, 'POST');
            const body = await resp.json();

            if (body.ok) {
                dispatch(startEmbarcacionesTimonelPorIdDB())

                // dispatch(embarcacionesTimonelPorIdDB(body.embarcaciones))
                // console.log(body.embarcaciones)

                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2500,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })
                Toast.fire({
                    icon: 'success',
                    title: 'Embarcación correctamente despachada. Le deseamos buena navegación.'
                })

            } else {
                Swal.fire('Error', body.msg, 'error')
            }

        } catch (error) {
            //throw new Error('Error en el sistema. Contactese con el administrador.')
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }
}