import React from 'react'
import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux';

export const TimonelRoute = ({ children }) => {

    const { role, from } = useSelector(state => state.auth);

    return (role === 'timonel')
        ? children
        : (role === 'admin')
            ? <Navigate to={'/admin/dashboard'} />
            : (from === 'clubes')
                ? <Navigate to={'/gestion/clubes'} />
                : <Navigate to={'/gestion/dashboard'} />

    // return (role === 'admin')
    //     ? <Navigate to={'/admin/dashboard'} />
    //     : (role === 'timonel')
    //         ? children
    //         : (from === 'clubes')
    //             ? <Navigate to={'/gestion/clubes'} />
    //             : <Navigate to={'/gestion/dashboard'} />

}

