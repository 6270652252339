import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeSizeWindow } from '../../actions/system';

const getWindowDimensions = () => {
    const {
        innerWidth: width,
        // innerHeight: height 
    } = window;
    return {
        width
        // height
    };
}

const useWindowDimensions = () => {
    const dispatch = useDispatch();
    const { widthWindowsSize } = useSelector(state => state.system);
    // const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [windowSize, setWindowSize] = useState('500');

    useEffect(() => {
        const handleResize = () => {
            // setWindowDimensions(getWindowDimensions());
            if (widthWindowsSize !== getWindowDimensions().width) {
                dispatch(changeSizeWindow(getWindowDimensions().width));
            }
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [widthWindowsSize, dispatch]);

    // return [windowDimensions, windowSize, setWindowSize];
    return [widthWindowsSize, windowSize, setWindowSize];
    // return windowDimensions;
}

export default useWindowDimensions;

// const debounce = (fn, delay) => {
//     let timerId;
//     return (...args) => {
//         clearTimeout(timerId);
//         timerId = setTimeout(fn, delay, [...args]);
//     };
// };

// function useWindowDimensions() {
//     const [size, setSize] = useState([0]);

//     useLayoutEffect(() => {
//         function updateSize() {
//             setSize([window.innerWidth]);
//         }

//         const debouncedUpdateSize = debounce(updateSize, 3000);

//         window.addEventListener('resize', debouncedUpdateSize);
//         updateSize();
//         return () => window.removeEventListener('resize', debouncedUpdateSize);
//     }, []);

//     // console.log('size: ', size)

//     return size;
// }

// export default useWindowDimensions;