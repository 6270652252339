import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { TablePagination } from '@mui/material';
import { Paper, TableBody, TableRow, TableCell, Toolbar, InputAdornment, Tooltip, Card, Box, Typography, Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SearchIcon from '@mui/icons-material/Search';
import { Search } from "@mui/icons-material";
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import PhoneIcon from '@mui/icons-material/Phone';
import Swal from 'sweetalert2';
import { format, isAfter, parseISO } from 'date-fns';

import { startRowsPerPage, startTablePageChange } from '../../../actions/system';
import PageHeader from "../../../components/PageHeader";
import Controls from "../../../components/controls/Controls";
import Label from '../../../components/tableLabelBadge/Label';
import { startGetListProhibidas, startGetListVerificadas, startGetSearchBoat, startSearchBoat, startVisorGetBoats } from '../../../actions/observador';

import L from 'leaflet';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import 'react-leaflet-fullscreen-control'
const boatImage = require('../../../assets/lancha_test.jpg');
const boatImage2 = require('../../../assets/lancha_test2.jpg');

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  searchInput: {
    width: '100%'
  },
  spaceTop: {
    marginTop: '20px'
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3)
  },
  table: {
    marginTop: theme.spacing(3),
    '& thead th': {
      fontWeight: '600',
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light,
    },
    '& tbody td': {
      fontWeight: '300',
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10,
    },
    '& tbody tr:hover': {
      backgroundColor: '#fffbf2',
      // cursor: 'pointer',
    },
  },
  tableColor: {
    backgroundColor: '#f0f0f0',
    borderRadius: 10
  },
  isBoatLatePaintRow: {
    // background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 0%, rgba(0,212,255,1) 100%)';
    background: 'linear-gradient(45deg, #ff8585 0%, #ffffff 20%)',
  },
}));

const Row = (props) => {
  const { row, mapRef, markerRef } = props;
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const refResultado = useRef('');

  const todayDate = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
  const dbReturnDateParsed = parseISO(row.datosDespachos.fechaRegresoAprox)
  const dbReturnDate = format(dbReturnDateParsed, 'yyyy-MM-dd HH:mm:ss');
  // console.log(dbReturnDateParsed)
  // console.log(row.nombre + ' fecha: ' + parseISO(row.datosDespachos.fechaRegresoAprox))
  // yyyy-mm-dd hh:mm:ss
  // console.log(row.nombre + ' fechaDB:' + row.datosDespachos.fechaRegresoAprox + ' fechaNow:' + todayDate)
  // console.log(row.nombre + ' fechaDB:' + dbReturnDateParsed + ' fechaNow:' + todayDate)
  // console.log((row.datosDespachos.fechaRegresoAprox < todayDate) ? row.nombre + ':true' : row.nombre + ':false')
  // console.log(isAfter(row.datosDespachos.fechaRegresoAprox,todayDate))
  // const ddd = isAfter(row.datosDespachos.fechaRegresoAprox,todayDate)
  // const result = isAfter(new Date(2022, 1, 8), new Date(dbReturnDateParsed))

  // const result = isAfter(new Date(todayDate), new Date(dbReturnDate))
  // console.log(row.nombre + ' ' + result)
  const [checkIsLate, setCheckIsLate] = useState(false);

  useEffect(() => {
    // if (row.datosDespachos.fechaRegresoAprox < todayDate) {
    if (isAfter(new Date(todayDate), new Date(dbReturnDate))) {
      // setCheckIsLate(prevState => !prevState)
      setCheckIsLate(true)
    }
  }, [row.datosDespachos.fechaRegresoAprox, todayDate, dbReturnDate])

  const onClickShowMarkerOnMap = (pos, id) => {
    const map = mapRef.current
    if (!map) return;
    map.flyTo(pos, 13)

    // console.log(markerRef.current)
    const filtro = markerRef.current.filter((el) => (el.options.id === id))
    // console.log(filtro)

    // const marker = markerRef.current[id]
    const marker = filtro[0]
    if (marker) {
      // marker.closePopup()
      marker.openPopup()
    }
  }

  return (
    <>
      <TableRow className={`${classes.root} ${(checkIsLate) ? classes.isBoatLatePaintRow : null}`}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell component="th" scope="row">{row.nombre}</TableCell>
        <TableCell>
          {
            (row.inscripcion === 'REY' || row.inscripcion === 'E/T')
              ? `${row.inscripcion}${row.matricula}`
              : `${row.cuatrigrama}${row.matricula}`
          }
        </TableCell>
        <TableCell align="right">{row.amarre}</TableCell>

        <TableCell align="right">
          <Controls.ActionButton
            color="secondary"
            onClick={() => {
              // Swal.fire(
              //   'Error',
              //   'Funcion no implementada, destinado a app mobile.',
              //   'error'
              // )
              // ${bote.datosDespachos.coordLatitud}`, `${bote.datosDespachos.coordLongitud}`
              // {[`${bote.datosDespachos.coordLatitud}`, `${bote.datosDespachos.coordLongitud}`]}
              onClickShowMarkerOnMap([`${row.datosDespachos.coordLatitud}`, `${row.datosDespachos.coordLongitud}`], row.id)
            }}>
            <Tooltip title="Ubicación">
              <LocationOnIcon fontSize="small" />
            </Tooltip>
          </Controls.ActionButton>
          <Controls.ActionButton
            color="fourth"
            onClick={() => {
              Swal.fire({
                title: 'Contacto',
                icon: 'info',
                html:
                  // `Telefono propietario: ${(row.datosDespachos.telefonoPropietario === null | row.datosDespachos.telefonoPropietario === '') ? 'No se proporciono telefono.' : row.datosDespachos.telefonoPropietario}` +
                  // '<br> ' +
                  // `Telefono persona a cargo: ${(row.datosDespachos.telefonoPersonaAcargo === null | row.datosDespachos.telefonoPersonaAcargo === '') ? 'No se proporciono telefono.' : row.datosDespachos.telefonoPersonaAcargo}`,
                  `Telefono: ${row.datosDespachos.telefono}`,
                showCloseButton: false,
                showCancelButton: false,
                focusConfirm: false,
                confirmButtonText: '<i class="fa fa-thumbs-up"></i> OK!',
              })
            }}>
            <Tooltip title="Contacto de amarre">
              <PhoneIcon fontSize="small" />
            </Tooltip>
          </Controls.ActionButton>
        </TableCell>
      </TableRow >
      <TableRow>
        <TableCell className={classes.tableColor} style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Datos y verificación
              </Typography>

              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Titular</TableCell>
                    <TableCell align="right">Nombre y apellido</TableCell>
                    <TableCell align="right">DNI</TableCell>
                    <TableCell align="right">Fecha salida</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {row.datosDespachos.titular ? 'Si' : 'No'}
                    </TableCell>
                    <TableCell align="right">{row.datosDespachos.nombre}</TableCell>
                    <TableCell align="right">
                      {row.datosDespachos.dni}
                    </TableCell>
                    <TableCell align="right">
                      {format(new Date(row.datosDespachos.fechaSalida), 'dd-MM-yyyy HH:mm') + 'Hs'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {/* ----------------------------FIN PRIMER LINEA-------------------------- */}

              {/* ----------------------------SEGUNDA LINEA-------------------------- */}

              <Table size="small" aria-label="purchases" className={classes.spaceTop}>
                <TableHead>
                  <TableRow>
                    {
                      (!row.datosDespachos.titular) &&
                      <TableCell>Actuación Notarial</TableCell>
                    }
                    <TableCell>Zona navegación</TableCell>
                    <TableCell>Tripulantes</TableCell>
                    <TableCell align="right">Fecha regreso</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {
                      (!row.datosDespachos.titular) &&
                      <TableCell component="th" scope="row">
                        {row.datosDespachos.actuacionNotarialAutorizado}
                      </TableCell>
                    }
                    <TableCell>{row.datosDespachos.lugarNavegacion}</TableCell>
                    <TableCell>{row.datosDespachos.datosTripulantes}</TableCell>
                    <TableCell align="right">{format(new Date(row.datosDespachos.fechaRegresoAprox), 'dd-MM-yyyy HH:mm') + 'Hs'}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {/* ----------------------------FIN SEGUNDA LINEA-------------------------- */}

              {/* ----------------------------TERCERA LINEA-------------------------- */}
              <Table size="small" aria-label="purchases" className={classes.spaceTop}>
                <TableHead>
                  <TableRow>
                    {/* <TableCell>Categoria Carnet</TableCell> */}
                    {/* <TableCell>Fecha Vencimiento Carnet</TableCell> */}
                    <TableCell align="right">Resultado control</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {/* <TableCell component="th" scope="row">
                      {row.datosDespachos.catCarnet}
                      {JSON.stringify(row, null, 3)}
                    </TableCell> */}
                    {/* <TableCell>{format(new Date(row.datosDespachos.fechaCarnet), 'dd-MM-yyyy')}</TableCell> */}
                    <TableCell align="right">

                      {
                        row.datosVerificacion.map((i) => {
                          if (i.createdAt === format(new Date(), 'yyyy-MM-dd')) {
                            if (i.resultado === true) {
                              refResultado.current = 'rojo';
                            } else {
                              refResultado.current = 'verde';
                            }
                          }
                        })

                        // row.datosVerificacion.map((i) => {
                        //   return (i.createdAt === format(new Date(), 'yyyy-MM-dd'))
                        //     ? (i.resultado === true)
                        //       ? refResultado.current = 'rojo'
                        //       : refResultado.current = 'verde'
                        //     : null
                        // })
                      }

                      <Label
                        variant="ghost"
                        color={
                          (refResultado.current === 'rojo' && 'secondary') ||
                          (refResultado.current === 'verde' && 'success') ||
                          'info'
                        }
                      >
                        {
                          (refResultado.current === 'rojo' | refResultado.current === 'verde') ? 'Verificado' : 'No verificado'
                        }
                      </Label>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}



const ListadoEmbarcacionesVisor = () => {

  const pages = [5, 10, 25]
  const classes = useStyles();
  const dispatch = useDispatch();
  const { name, place } = useSelector(state => state.auth);
  const { page, rowsPerPage } = useSelector(state => state.system);
  const { verificadas, prohibic, search, embarcaciones } = useSelector(state => state.observador);
  const todayDate = format(new Date(), 'yyyy-MM-dd');

  const mapRef = useRef(null)
  // const markerRef = useRef(null)
  // const markerRef = useRef(new Array())
  const markerRef = useRef([])
  // const markerRef = useRef(Array.from({ length: 2 }, () => React.createRef()))

  const LocationIcon = (iconSize, iconColor) => {
    // return L.icon({
    //   iconUrl: require("../../../assets/prefecturaNaval.png"),
    //   // iconUrl: <div><span>jajajj</span></div>,
    //   // SearchIcon
    //   // iconUrl: LocationOnIcon,
    //   iconRetinaUrl: LocationOnIcon,
    //   popupAnchor: [-0, -0],
    //   iconSize: [iconSize]
    // })

    return L.divIcon({
      html: `
      <svg
        viewBox="0 0 395.71 395.71"
        version="1.1"
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M197.849,0C122.131,0,60.531,61.609,60.531,137.329c0,72.887,124.591,243.177,129.896,250.388l4.951,6.738
        c0.579,0.792,1.501,1.255,2.471,1.255c0.985,0,1.901-0.463,2.486-1.255l4.948-6.738c5.308-7.211,129.896-177.501,129.896-250.388
        C335.179,61.609,273.569,0,197.849,0z M197.849,88.138c27.13,0,49.191,22.062,49.191,49.191c0,27.115-22.062,49.191-49.191,49.191
        c-27.114,0-49.191-22.076-49.191-49.191C148.658,110.2,170.734,88.138,197.849,88.138z" fill="${iconColor}"></path>
      </svg>`,
      className: "",
      iconSize: [iconSize],
      iconAnchor: [17, 35],
      popupAnchor: [0, -30],
    })
  }

  useEffect(() => {
    dispatch(startGetListVerificadas(todayDate))
    dispatch(startGetListProhibidas(todayDate))
    dispatch(startVisorGetBoats());
  }, [dispatch, todayDate])

  const handleChangePage = (event, newPage) => {
    dispatch(startTablePageChange(newPage))
  }

  const handleChangeRowsPerPage = event => {
    dispatch(startRowsPerPage(parseInt(event.target.value, 10)))
    dispatch(startTablePageChange(0))
  }

  const handleSearch = (e) => {
    dispatch(startSearchBoat(e.target.value.trim()));
  }

  const handleSearchClick = (search) => {
    if (search === '') {
      dispatch(startVisorGetBoats())
    } else {
      dispatch(startGetSearchBoat());
    }
  }

  // const onClickShowMarkerOnMap = () => {
  //   const map = mapRef.current
  //   // console.log(map)
  //   if (!map) return;

  //   map.flyTo(MARKER_POSITION, 13)
  //   const marker = markerRef.current
  //   if (marker) {
  //     marker.openPopup()
  //   }
  // }

  const displayMap = useMemo(() => (
    <MapContainer
      ref={mapRef}
      style={{ height: '100%' }}
      center={[-31.65665781335105, -60.70196832974308]}
      zoom={13}
      scrollWheelZoom={true}
      fullscreenControl
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      {
        embarcaciones.map((bote) => {
          // console.log(bote)
          const getRef = (el) => {
            if (el === null) return;

            // console.log(markerRef.current.filter((prev) => (prev === el)))

            // markerRef.current.filter(prev => prev !== el).push(el)
            markerRef.current.push(el)
            // FIXME: graba elementos duplicados en el ref
            // CREO QUE LA SIGUIENTE LINEA LO SOLUCIONARIA
            // markerRef.current = [...markerRef.current, ...markerRef.current.filter(element => !markerRef.current.includes(element))]

            // const fii = markerRef.current.filter((prev) => (prev === el))
            // console.log(fii)

            // const result = [...markerRef.current, ...newArray.filter(a => !markerRef.current.includes(9))]

            // console.log([...markerRef.current, ...el.filter(element => !markerRef.current.includes(element))])

            // if (markerRef.current.filter((prev) => (prev !== el))) {
            //   // markerRef.current.push(el)
            //   markerRef.current.push(el)
            //   // return;
            //   // console.log('true')
            // }
          }
          return <Marker
            id={bote.id}
            icon={LocationIcon(35, 'red')}
            ref={getRef}
            // ref={markerRef[i]}
            // key={getRef}
            // ref={(element) => markerRef.current[bote.id] = element}
            position={[`${bote.datosDespachos.coordLatitud}`, `${bote.datosDespachos.coordLongitud}`]}
          >
            <Popup
              minWidth="auto"
              maxHeight="auto"
            >
              <Grid
                container
                direction="row"
                sx={{
                  height: '250px',
                  width: '600px'
                }}
              >
                <Grid
                  item
                  xs={7}
                  sx={{
                    // backgroundImage: `url(${boatImage})`,
                    backgroundImage: `url(${process.env.REACT_APP_API_URL_FOR_IMAGES}/boatsImages/${bote.imgEmbarcacionUrl})`,
                    backgroundSize: 'cover',
                    // backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    borderRadius: 2,
                    height: '100%',
                    width: '100%'
                  }}
                >
                </Grid>

                <Grid item xs={5}>
                  <Box sx={{ p: 1 }}>
                    <b>Nombre:</b> {bote.nombre}
                    <br />
                    <b>Matricula:</b> {bote.inscripcion}-{bote.matricula}
                    {
                      (bote.desc) &&
                      <>
                        <br />
                        <b>Descripcion:</b> {bote.desc + '.'}
                      </>
                    }
                    <br />
                    <b>Dueño:</b> {bote.datosDespachos.nombre}
                    <br />
                    <b>Cantidad tripulantes:</b> {bote.capacidadPersonas}
                    <br />
                    <b>Amarre:</b> {bote.amarre}
                    <br />
                    {/* <b>MarkerRef:</b> `${JSON.stringify(getRef, null, 3)}` */}
                  </Box>
                </Grid>
              </Grid>

            </Popup>
          </Marker>
        })
      }

    </MapContainer >
  ))

  // const addOrEdit = (boat, resetForm) => {
  //   if (boat.id == 0) {
  //     const datos = { lugar: place, pendiente: true, estado: true, despachada: false };
  //     const boatWithPendiente = Object.assign(boat, datos);
  //     dispatch(startSenadePostNewBoats(boatWithPendiente, boatWithPendiente.multa, boatWithPendiente.novedad));
  //   }

  //   resetForm()
  //   dispatch(startClosePopup());
  // }

  return (
    <>
      <PageHeader
        title={`Visor de control: "${place}"`}
        subTitle="Despachos."
        icon={<DirectionsBoatIcon fontSize="large" />}
        profile={true}
        name={name}
      />
      <Container>
        <Grid
          container
          spacing={2}
        >
          <Grid item xs={9}
            sx={{ mt: 2 }}
          >
            {/* <iframe src="https://www.google.com/maps/d/embed?mid=1YvqBBVX6dgQPX8gCslrqhfc_sM4Q0ss&ehbc=2E312F" style={{ height: '100%', width: '100%'}} frameBorder="0"></iframe> */}
            {/* MAPCONTAINER */}
            {displayMap}
          </Grid>
          <Grid
            container
            item
            xs={3}
            flexDirection='column'
            sx={{ mt: 2 }}
            gap={3}
          >
            {/* <Item>xs=4</Item> */}
            <Card
              sx={{
                pt: 3,
                pb: 3,
                // mt: 3,
                // mr: 3,
                // p: 2,
                // width: '100%',
                // height: '34%',
                backgroundColor: '#f1ff2b'
              }}
            >
              <Typography variant="h6" align="center" gutterBottom>
                En navegación
              </Typography>
              <Typography variant="h3" component="div" align="center">
                {embarcaciones.length}
              </Typography>
            </Card>

            <Card
              sx={{
                pt: 3,
                pb: 3,
                // mt: 3,
                // ml: 3,
                // mr: 3,
                // p: 2,
                // width: '20%',
                backgroundColor: '#f1ff2b'
              }}
            >
              <Typography variant="h6" align="center" gutterBottom>
                Controladas
              </Typography>
              <Typography variant="h3" component="div" align="center">
                {verificadas.length}
              </Typography>
            </Card>

            {/* <Card
              sx={{
                pt: 6,
                pb: 3,
                backgroundColor: '#f1ff2b'
              }}
            >
              <Typography variant="h3" align="center" gutterBottom>
                SAR
              </Typography>
            </Card> */}
          </Grid>
        </Grid>
      </Container>

      {/* <Container
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          // mt: 3
        }}
      >
        <Card
          sx={{
            mt: 3,
            mr: 3,
            p: 2,
            width: '20%',
            backgroundColor: '#f1ff2b'
          }}
        >
          <Typography variant="h6" align="center" gutterBottom>
            En navegación
          </Typography>
          <Typography variant="h3" component="div" align="center">
            {embarcaciones.length}
          </Typography>
        </Card>

        <Card
          sx={{
            mt: 3,
            ml: 3,
            mr: 3,
            p: 2,
            width: '20%',
            backgroundColor: '#f1ff2b'
          }}
        >
          <Typography variant="h6" align="center" gutterBottom>
            Controladas
          </Typography>
          <Typography variant="h3" component="div" align="center">
            {verificadas.length}
          </Typography>
        </Card>

      </Container> */}
      {/* PROHIBICION */}
      {/* <Card
          sx={{
            mt: 3,
            ml: 3,
            p: 2,
            width: '20%',
            backgroundColor: '#f1ff2b'
          }}
        >
          <Typography variant="h6" align="center" gutterBottom>
            Prohibición
          </Typography>
          <Typography variant="h3" component="div" align="center">
            {prohibic.length}
          </Typography>
        </Card> */}

      <Paper className={classes.pageContent}>

        <Toolbar>
          <Grid container spacing={1}
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Controls.Input
                label="Buscar por matricula"
                className={classes.searchInput}
                InputProps={{
                  startAdornment: (<InputAdornment position="start">
                    <Search />
                  </InputAdornment>)
                }}
                onChange={handleSearch}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <Grid item container justify="flex-end" xs={6}>
              <Controls.Button
                text="Buscar"
                style={{ marginLeft: 15 }}
                variant="outlined"
                startIcon={<SearchIcon />}
                onClick={() => { handleSearchClick(search) }}
              />
            </Grid>
          </Grid>
        </Toolbar>

        {/* onClickShowMarkerOnMap */}
        {/* <Controls.Button
          style={{ margin: 10 }}
          text="ir a un punto"
          color="default"
          onClick={() => onClickShowMarkerOnMap()}
        /> */}

        <Table className={classes.table} aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Nombre</TableCell>
              <TableCell>Matricula</TableCell>
              <TableCell align="right">Amarre</TableCell>
              <TableCell align="right">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              embarcaciones.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                <Row key={row.matricula} row={row} mapRef={mapRef} markerRef={markerRef} />
              ))
            }
          </TableBody>
        </Table>
        {/* </TableContainer> */}
        <TablePagination
          component="div"
          page={page}
          labelRowsPerPage='Filas por pagina:'
          rowsPerPageOptions={pages}
          rowsPerPage={rowsPerPage}
          count={embarcaciones.length}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}

export default ListadoEmbarcacionesVisor;