import React from 'react'
// import PropTypes from 'prop-types'

// import { Route, Redirect } from 'react-router-dom'
import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux';

export const UserRoute = ({ children }) => {

    const { role } = useSelector(state => state.auth);

    return (role === 'admin')
        ? <Navigate to={'/admin/dashboard'} />
        // : children
        : (role === 'timonel')
            ? <Navigate to={'/timonel/dashboard'} />
            : children

    // return (role === 'admin')
    //     ? <Navigate to={'/admin/dashboard'} />
    //     : (role === 'timonel')
    //         ? <Navigate to={'/timonel/dashboard'} />
    //         : children


    // return (
    //     <Route { ... rest }
    //         component={ ( props ) => (
    //             (isAdmin === 'admin')
    //                 ? <Component {...props} />
    //                 : <Navigate to='/prefectura' />
    //         )}
    //     />
    // )
}

// AdminRoute.propTypes = {
//     // isAuthorized: PropTypes.bool.isRequired,
//     component: PropTypes.func.isRequired
// }
