import React from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import Controls from '../../../components/controls/Controls';
import PageHeader from '../../../components/PageHeader';
import { Card, CardMedia, Grid, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import oLetter from '../../../assets/letter-o.svg'
import pLetter from '../../../assets/letter-p.svg'
import sLetter from '../../../assets/letter-s.svg'
import vLetter from '../../../assets/letter-v.svg'

const arrOffices = [
    { id: 'polinave', nombre: 'Polinave', color: 'red' },
    { id: 'sumarios', nombre: 'Sumarios', color: 'green' },
    { id: 'operativo', nombre: 'Operativo', color: 'blue' },
    // { id: 'visor', nombre: 'Contrase / Radio / Visor', color: '#772360' },
    { id: 'visor', nombre: 'Visor', color: 'yellow' },
]

export const MainDashboard = () => {
    const { from, role } = useSelector(state => state.auth);
    const { name, place } = useSelector(state => state.auth);
    const navigate = useNavigate();

    return (
        <>
            <PageHeader
                title={`Centro de Actividades`}
                subTitle={`Prefectura ${place}`}
                icon={<DirectionsBoatIcon fontSize="large" />}
                profile={true}
                name={name}
            />
            <Paper sx={{ m: 5, p: 3 }}>
                <Typography variant="custom6" gutterBottom component="div" align='center' sx={{ p: 5, mt: 3 }}>
                    ¿En qué quieres trabajar?
                </Typography>
                <Grid
                    container
                    justifyContent='center'
                    gap={4}
                    sx={{ mt: 10, mb: 10 }}
                >

                    {
                        (role === 'admin') &&
                        <Grid item>
                            <Card
                                sx={{
                                    height: '250px',
                                    width: '250px',
                                    backgroundColor: '#a2c6ff',
                                    cursor: 'pointer',
                                    userSelect: 'none',
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: 'flex',
                                }}
                                onClick={() => { navigate(`/admin/dashboard`) }}
                            >
                                <Box>
                                    <CardMedia
                                        component="img"
                                        height="150"
                                        image={pLetter}
                                        alt="letter_icon"
                                    />
                                    <Typography variant="h5" align="center" gutterBottom={false}>
                                        Admin
                                    </Typography>
                                </Box>
                            </Card>
                        </Grid>
                    }

                    {JSON.parse(from).map((x) => (
                        <Grid item>
                            <Card
                                sx={{
                                    height: '250px',
                                    width: '250px',
                                    backgroundColor:
                                        (x === 'polinave')
                                            ? '#88e59f'
                                            : (x === 'sumarios')
                                                ? '#ffc863'
                                                : (x === 'operativo')
                                                    ? '#9591f8'
                                                    : (x === 'visor')
                                                        ? '#ff9292'
                                                        : null,
                                    // backgroundColor: '#a2c6ff',
                                    // backgroundColor: arrOffices.map((el) => { return (x === el.id) && `${el.color}` }),
                                    cursor: 'pointer',
                                    userSelect: 'none',
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: 'flex',
                                }}
                                onClick={() => { navigate(`/gestion/${x}`) }}
                            >
                                <Box
                                    // m="auto"
                                    sx={{
                                        // display: "flex",
                                        // flexDirection: 'column',
                                        // // height: '100%',
                                        // // width: '100%',
                                        // justifyContent: "center",
                                        // alignItems: "center",


                                        // opacity: .2,
                                        // backgroundColor: 'white'
                                        // backgroundColor:
                                        // (x === 'polinave')
                                        //     // ? '#5ea670'
                                        //     ? 'rgba(94, 166, 112, 0.5)'
                                        //     : (x === 'sumarios')
                                        //         // ? '#ffac68' //255, 172, 104
                                        //         ? 'rgba(255, 172, 104, .5)'
                                        //         : (x === 'operativo')
                                        //             // ? '#716cff' //113, 108, 255
                                        //             ? 'rgba(113, 108, 255, .5)'
                                        //             : (x === 'visor')
                                        //                 // ? '#d06363' //208, 99, 99
                                        //                 ? 'rgba(208, 99, 99, .5)'
                                        //                 : null,

                                    }}
                                >

                                    <CardMedia
                                        component="img"
                                        height="150"
                                        // image={vLetter}
                                        image={(x === 'polinave')
                                            ? pLetter
                                            : (x === 'sumarios')
                                                ? sLetter
                                                : (x === 'operativo')
                                                    ? oLetter
                                                    : (x === 'visor')
                                                        ? vLetter
                                                        : null}
                                        alt="letter_icon"
                                    />
                                    <Typography variant="h5" align="center" gutterBottom={false}
                                    // sx={{position: 'relative', bottom: 10}}
                                    // sx={{color: 'white'}}
                                    >
                                        {
                                            // (x === 'polinave')
                                            //     ? 'Polinave'
                                            //     : (x === 'sumarios')
                                            //         ? 'Sumarios'
                                            //         : (x === 'visor')
                                            //             ? 'Contrase / Radio'
                                            //             : (x === 'operativo')
                                            //                 ? 'Senade'
                                            //                 : 'ERROR'

                                            arrOffices.map((el) => {
                                                return (x === el.id) && `${el.nombre}`
                                            })

                                        }
                                    </Typography>
                                </Box>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Paper>
        </>
    )
}
