import React from 'react'
import { Button as MuiButton, Typography } from "@mui/material";
import { CircularProgress } from '@mui/material';

// FOR BOX COMPONENT
// t - for classes that set margin-top or padding-top
// b - for classes that set margin-bottom or padding-bottom
// l - for classes that set margin-left or padding-left
// r - for classes that set margin-right or padding-right
// x - for classes that set both *-left and *-right
// y - for classes that set both *-top and *-bottom
// blank - for classes that set a margin or padding on all 4 sides of the element

// spacing 	m 	margin 	spacing
// spacing 	mt 	margin-top 	spacing
// spacing 	mr 	margin-right 	spacing
// spacing 	mb 	margin-bottom 	spacing
// spacing 	ml 	margin-left 	spacing
// spacing 	mx 	margin-left, margin-right 	spacing
// spacing 	my 	margin-top, margin-bottom 	spacing
// spacing 	p 	padding 	spacing
// spacing 	pt 	padding-top 	spacing
// spacing 	pr 	padding-right 	spacing
// spacing 	pb 	padding-bottom 	spacing
// spacing 	pl 	padding-left 	spacing
// spacing 	px 	padding-left, padding-right 	spacing
// spacing 	py 	padding-top, padding-bottom 	spacing

// const styles = {
//     fixedStyles: {
//         margin: 0.5,
//         textTransform: 'none',
//     }
// }

export default function Button(props) {

    const { text, size, color, variant, onClick, loading, startIconWithoutLoading, sx = [], ...other } = props
    return (
        <MuiButton
            // style={{...styles.fixedStyles}}
            // sx={{
            //     ...styles.fixedStyles
            // }}
            sx={[
                {
                    margin: 0.5,
                    textTransform: 'none',
                },
                // No puedo poner spread en `sx` directamente porque `SxProps` (tipo de sx) puede ser un array.
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
            variant={variant || "contained"}
            size={size || "large"}
            color={color || "primary"}
            onClick={onClick}
            startIcon={(loading) ? <CircularProgress size={20} /> : startIconWithoutLoading}
            {...other}
        // disabled={true}
        >
            <Typography sx={{ position: 'relative', bottom: -2 }}>
                {text}
            </Typography>
            {/* {text} */}
        </MuiButton>
    )
}


// import React from "react";
// import Button from "@mui/material/Button";
// import { makeStyles } from "@mui/material/styles";
// import DeleteIcon from "@mui/icons-material/Delete";

// const useStyles = makeStyles(theme => ({
//   button: {
//     margin: theme.spacing(1),
//     [theme.breakpoints.down("sm")]: {
//       minWidth: 32,
//       paddingLeft: 8,
//       paddingRight: 8,
//       "& .MuiButton-startIcon": {
//         margin: 0
//       }
//     }
//   },
//   buttonText: {
//     [theme.breakpoints.down("sm")]: {
//       display: "none"
//     }
//   }
// }));

// export default function IconLabelButtons() {
//   const classes = useStyles();

//   return (
//     <div>
//       <Button
//         variant="contained"
//         color="secondary"
//         className={classes.button}
//         startIcon={<DeleteIcon />}
//       >
//         <span className={classes.buttonText}>Delete</span>
//       </Button>
//     </div>
//   );
// }
