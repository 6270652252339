import Swal from 'sweetalert2';
import { useImageUpload } from '../components/hooks/useImageUpload';
import { fetchConToken } from '../services/fetch';
import { types } from '../types/types';
import { changeLoading } from './system';

export const startRegisterParticularUserPost = ({
    carnet,
    rName,
    rApellido,
    rDni,
    rDireccion,
    rEmail,
    rPassword1,
    // rFrom,
    // rSelectClub,
    // rSelectPrefe,
    rSelectPrefeDepe,
    rTelefono,
    categoriaCarnet,
    vencimientoCarnet,
    setIsLoading
}) => {
    // export const startRegisterParticularUserPost = (file, directoryToServer, filenameToServer) => {
    const data = {
        carnet,
        nombre: rName.toUpperCase(),
        apellido: rApellido.toUpperCase(),
        dni: rDni,
        direccion: rDireccion.toUpperCase(),
        telefono: rTelefono,
        email: rEmail,
        password: rPassword1,
        // desde: '-',
        rol: 'timonel',
        estado: true,
        pendiente: true,
        lugar: rSelectPrefeDepe,
        categoriaCarnet,
        vencimientoCarnet
    };

    return async (dispatch) => {
        try {

            const imageCarnet = await useImageUpload(carnet.fileToUpload, 'credentialsUsers', rDni)
            const filenameCarnet = imageCarnet.nombre.split('/')
            const datosToSend = {
                ...data,
                imageCarnet: filenameCarnet[filenameCarnet.length - 1]
            };

            const resp = await fetchConToken('usuarios/new', datosToSend, 'POST');
            const body = await resp.json();
            if (body.ok) {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2500,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })
                Toast.fire({
                    icon: 'success',
                    title: 'Registración exitosa.'
                })
                setIsLoading(false);
            } else {
                Swal.fire('Error', body.msg, 'error');
            }

            // const resp = await useImageUpload(file, directoryToServer, filenameToServer)

            /* 
            // PARA MULTIPLE SUBIDA ASINCRONA:
            -En los argumentos tendria que tener files = [], por defecto
            vacio y en ese arreglo traer todos los archivos a subir.

            const fileUploadPromises = [];
            for ( const file of files ) {
                fileUploadPromises.push( useImageUpload( file ) )
            }
            const photosUrls = await Promise.all( fileUploadPromises );
            */

        } catch (error) {
            console.log('Error de conexión subiendo las imagenes. Contactese con el administrador.')
        }
    }
}

export const startRegisterUserPost = ({ rName, rApellido, rDni, rTelefono, rEmail, rPassword1, rPassword2, rFrom, rSelectClub, rSelectPrefe, rSelectPrefeDepe }) => {
    return async (dispatch) => {
        const data = {
            nombre: rName,
            apellido: rApellido,
            dni: rDni,
            telefono: rTelefono,
            email: rEmail,
            password: rPassword1,
            pendiente: true,
            desde: (rFrom === 'club') ? 'clubes' : JSON.stringify(rSelectPrefe),
            rol: 'user',
            lugar: (rFrom === 'club') ? rSelectClub : rSelectPrefeDepe,
            estado: true
        }

        const resp = await fetchConToken('usuarios/new', data, 'POST');
        const body = await resp.json();

        if (body.ok) {
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
            Toast.fire({
                icon: 'success',
                title: 'Usuario creado correctamente.'
            })
        } else {
            Swal.fire('Error', body.msg, 'error');
            // alert(`Error: ${body.msg}`);
        }
    }
}

export const startGetUsuariosPendientes = () => {
    return async (dispatch) => {
        try {
            const resp = await fetchConToken('usuarios/pending', {});
            const body = await resp.json();
            if (body.ok) {
                dispatch(getUsuariosPendientes(body.usuarios))
            } else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }
}
const getUsuariosPendientes = (payload) => ({
    type: types.adminGetUsuariosPendientes,
    payload: payload
});

export const savePostUsuariosPendientes = (data) => {
    return async (dispatch) => {
        try {
            const resp = await fetchConToken('usuarios/pending/save', data, 'PUT');
            const body = await resp.json();
            if (body.ok) {
                dispatch(startGetUsuariosPendientes())
                Swal.fire('Éxito', 'Registro guardado correctamente', 'success');
            } else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }
}

export const deletePostUsuariosPendientes = (data) => {
    return async (dispatch) => {
        try {
            const resp = await fetchConToken('usuarios/pending/delete', data, 'DELETE');
            const body = await resp.json();
            if (body.ok) {
                dispatch(startGetUsuariosPendientes())
                Swal.fire('Éxito', 'Registro borrado correctamente', 'success');
            } else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }
}

export const startGetUsuariosActivos = () => {
    return async (dispatch) => {

        try {
            const resp = await fetchConToken('usuarios/actives', {});
            const body = await resp.json();

            if (body.ok) {
                dispatch(getUsuariosActivos(body.usuarios))
            } else {
                Swal.fire('Error', body.msg, 'error');
            }

        } catch (error) {
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }
}

const getUsuariosActivos = (payload) => ({
    type: types.adminGetUsuariosActivos,
    payload: payload
});

export const startGetUsuariosActivosSoloPrefe = () => {
    return async (dispatch) => {

        try {
            const resp = await fetchConToken('usuarios/activesprefe', {});
            const body = await resp.json();

            if (body.ok) {
                dispatch(getUsuariosActivosSoloPrefe(body.usuarios))
            } else {
                Swal.fire('Error', body.msg, 'error');
            }

        } catch (error) {
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }
}

const getUsuariosActivosSoloPrefe = (payload) => ({
    type: types.adminGetUsuariosActivosSoloPrefe,
    payload: payload
});

export const deleteUsuariosActivos = (data) => {
    return async (dispatch) => {
        try {
            const resp = await fetchConToken('usuarios/active/delete', data, 'PUT');
            const body = await resp.json();
            if (body.ok) {
                dispatch(startGetUsuariosActivos())
                Swal.fire('Éxito', 'Registro/s deshabilitado/s correctamente', 'success');
            } else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }
}

export const enableUsuariosActivos = (data) => {
    return async (dispatch) => {
        try {
            const resp = await fetchConToken('usuarios/active/enable', data, 'POST');
            const body = await resp.json();
            if (body.ok) {
                dispatch(startGetUsuariosActivos())
                Swal.fire('Éxito', 'Registro/s habilitado/s correctamente.', 'success');
            } else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }
}

export const changeUserPermissions = (data) => {
    return async (dispatch) => {
        try {
            const resp = await fetchConToken('usuarios/permissions', data, 'PUT');
            const body = await resp.json();
            if (body.ok) {
                dispatch(startGetUsuariosActivosSoloPrefe())
                Swal.fire('Éxito', 'Permiso/s cambiado/s correctamente.', 'success');
            } else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }
}

