import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Search } from "@mui/icons-material";
import AddIcon from '@mui/icons-material/Add';
import CallMadeIcon from '@mui/icons-material/CallMade';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import SearchIcon from '@mui/icons-material/Search';
import { Paper, TableBody, TableRow, TableCell, Toolbar, InputAdornment, Tooltip, Grid, Typography, Card, Avatar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PhoneIcon from '@mui/icons-material/Phone';
import Swal from 'sweetalert2'

import PageHeader from "../../components/PageHeader";
import useTable from "../../components/useTable";
import Controls from "../../components/controls/Controls";
import Popup from "../../components/Popup";
import EmbarcacionesDespacho from './EmbarcacionesDespacho';
import { startChildPopup, startClosePopup, startOpenPopup, startSizePopup, startTitlePopup } from '../../actions/system';
import { startArriboDespacho, startGetBoatsAdespachar, startGetBoatsTotalityNumber, startGetClubBoats, startGetSearchBoat, startGetUsersFromAboat } from '../../actions/embarcacionesClubes';
import { startSearchBoat } from '../../actions/embarcacionesClubes';
import useWindowDimensions from '../../components/hooks/useWindowDimensions';
import ClubPendientes from './ClubPendientes';
import TableWithStadisticsTotal from './TableWithStadisticsTotal';
import TableWithStadisticsProhibidas from './TableWithStadisticsProhibidas';
import { format, isAfter, parseISO } from 'date-fns';
import { Container } from '@mui/system';


const useStyles = makeStyles(theme => ({
    neutral: {
        backgroundColor: '#ff0000',
        opacity: '0.25'
    },
    pageContent: {
        margin: theme.spacing(2),
        padding: theme.spacing(3)
    },
    containerCards: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
    },
    cardContent: {
        margin: theme.spacing(3),
        padding: theme.spacing(2),
        width: '20%',
        backgroundColor: '#d2d6fa',
    },
    searchInput: {
        width: '100%'
    },
    newButton: {
        position: 'absolute',
        right: '10px',
    },
    searchButton: {
        position: 'absolute',
        right: '180px'
    }
}))

const ColumnasTabla = [
    { id: 'fullName', label: 'Embarcacion' },
    { id: 'email', label: 'Matricula' },
    { id: 'mobile', label: 'Descripción' },
    { id: 'actions', label: 'Acciones', disableSorting: true }
]

const EmbarcacionesClub = () => {
    /* 
    TODO:
    AGREGAR LO DEL PAPEL 1 COMO BARRA Y LLEVE POPUPS PARA EXTENDER INFO
    PRIMERO EL RADIO INSCRIPCION EN EL FORM, ARRIBA DE TODO Y ESO HABILITE LA CASILLA MATRICULA
    -COLOR A LA EMBARCACION DESPACHADA ALARMA COMO EN EL VISOR SI SUPERO LA HORA DE REGRESO FIXME: ARREGLAR EL SISTEMA DESPACHO PARA QUE ESTO FUNCIONE
    */

    const classes = useStyles();
    const dispatch = useDispatch();
    const { name, place } = useSelector(state => state.auth);
    const { embarcaciones = [], aDespachar = [], search, totalityNumber = [] } = useSelector(state => state.embarcacionClub);
    const { titlePopup, sizePopup, childPopup } = useSelector(state => state.system);
    const [width, ,] = useWindowDimensions();
    const [filterFn] = useState({ fn: items => { return items; } })
    const [isOpen, setIsOpen] = useState(false);

    const [prohib, setProhib] = useState(0)
    const todayDate = format(new Date(), 'yyyy-MM-dd HH:mm:ss');

    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting
    } = useTable(embarcaciones, ColumnasTabla, filterFn);

    const handleSearch = (e) => {
        dispatch(startSearchBoat(e.target.value.trim()));
    }

    const handleSearchClick = (search) => {
        if (search === '') {
            dispatch(startGetClubBoats())
        } else {
            dispatch(startGetSearchBoat());
        }
    }

    const despacharItem = (idBoat, nombre) => {
        dispatch(startClosePopup());
        const item = { id: idBoat }

        Swal.fire({
            title: `Entrada a "${nombre}"`,
            text: `¿Estas seguro?`,
            icon: 'info',
            style: { zIndex: 9999 },
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No, esto es un error!',
            confirmButtonText: 'Si, hazlo!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(startArriboDespacho(item, false));
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2500,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })
                Toast.fire({
                    icon: 'success',
                    title: 'Esta embarcación entró correctamente.'
                })
            }
        })
    }

    useEffect(() => {
        dispatch(startGetClubBoats())
        dispatch(startGetBoatsAdespachar())
        dispatch(startGetBoatsTotalityNumber())
    }, [dispatch])

    return (
        <>
            <PageHeader
                title={`Entidad Nautica "${place}"`}
                subTitle="Despachos."
                icon={<DirectionsBoatIcon fontSize="large" />}
                profile={true}
                name={name}
            />
            {/* <Container>
                <Grid container
                    direction="row"
                    justifyContent="space-evenly"
                >
                    <Grid item>
                        <Card
                            sx={{
                                p: 2,
                                backgroundColor: '#f1ff2b',
                                cursor: 'pointer',
                                m: 3
                            }}
                            onClick={() => {
                                dispatch(startTitlePopup('Totalidad de embarcaciones...'));
                                dispatch(startSizePopup('lg'));
                                dispatch(startChildPopup('totalEmb'));
                                dispatch(startOpenPopup());
                            }}
                        >
                            <Typography variant="h6" align="center" gutterBottom>
                                Totalidad Emb.
                            </Typography>
                            <Typography variant="h3" component="div" align="center">
                                {embarcaciones.length + aDespachar.length}
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item>
                        <Card
                            sx={{
                                p: 2,
                                backgroundColor: '#f1ff2b',
                                m: 3
                            }}
                        >
                            <Typography variant="h6" align="center" gutterBottom>
                                En navegación
                            </Typography>
                            <Typography variant="h3" component="div" align="center">
                                {embarcaciones.length}
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item>
                        <Card
                            sx={{
                                p: 2,
                                backgroundColor: '#f1ff2b',
                                cursor: 'pointer',
                                m: 3
                            }}
                            onClick={() => {
                                dispatch(startTitlePopup('Embarcaciones prohibidas...'));
                                dispatch(startSizePopup('lg'));
                                dispatch(startChildPopup('prohibEmb'));
                                dispatch(startOpenPopup());
                            }}
                        >
                            <Typography variant="h6" align="center" gutterBottom>
                                Con prohibición
                            </Typography>
                            <Typography variant="h3" component="div" align="center">
                                {aDespachar.filter(obj => obj.prohibicion === true).length}
                            </Typography>
                        </Card>
                    </Grid>
                </Grid>
            </Container> */}
            <Container>
                <Grid container
                    direction="row"
                    justifyContent="space-evenly"
                >
                    <Grid item>
                        <Card
                            sx={{
                                p: 2,
                                backgroundColor: 'rgba(228, 207, 158, 1)',
                                cursor: 'pointer',
                                m: 3,
                                height: '175px',
                                width: '200px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}
                            onClick={() => {
                                dispatch(startTitlePopup('Totalidad de embarcaciones...'));
                                dispatch(startSizePopup('lg'));
                                dispatch(startChildPopup('totalEmb'));
                                dispatch(startOpenPopup());
                            }}
                        >
                            <Avatar sx={{ bgcolor: 'rgba(0, 0, 0, .3)', width: 90, height: 90, mt: 1, mb: 1 }}>
                                <Typography sx={{ color: 'rgba(228, 207, 158, 1)', fontWeight: 'bold', mt: 1 }} variant="h3" component="div" align="center">
                                    {/* {embarcaciones.length + totalityNumber.length} */}
                                    {totalityNumber.length}
                                </Typography>
                            </Avatar>
                            <Typography sx={{ mt: 1 }} variant="h6" align="center" gutterBottom>
                                Totalidad Emb.
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item>
                        <Card
                            // rgba(189, 217, 191, 1)
                            // #7b927c
                            sx={{
                                p: 2,
                                backgroundColor: 'rgba(189, 217, 191, 1)',
                                m: 3,
                                height: '175px',
                                width: '200px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}
                        >
                            <Avatar sx={{ bgcolor: 'rgba(0, 0, 0, .3)', width: 90, height: 90, mt: 1, mb: 1 }}>
                                <Typography sx={{ color: "rgba(189, 217, 191, 1)", fontWeight: 'bold', mt: 1 }} variant="h3" component="div" align="center">
                                    {embarcaciones.length}
                                </Typography>
                            </Avatar>
                            <Typography sx={{ mt: 1 }} variant="h6" align="center" gutterBottom>
                                En navegación
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item>
                        <Card
                            sx={{
                                p: 2,
                                // backgroundColor: '#BBDEF0',
                                backgroundColor: 'rgba(255, 140, 140, 1)',
                                cursor: 'pointer',
                                m: 3,
                                height: '175px',
                                width: '200px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}
                            onClick={() => {
                                dispatch(startTitlePopup('Embarcaciones prohibidas...'));
                                dispatch(startSizePopup('lg'));
                                dispatch(startChildPopup('prohibEmb'));
                                dispatch(startOpenPopup());
                            }}
                        >
                            {/* <Avatar sx={{ bgcolor: '#7b919d', width: 90, height: 90, mt: 1, mb: 1 }}> */}
                            <Avatar sx={{ backgroundColor: 'rgba(0, 0, 0, .3)', width: 90, height: 90, mt: 1, mb: 1 }}>
                                <Typography sx={{ color: 'rgba(255, 140, 140, 1)', fontWeight: 'bold', mt: 1 }} variant="h3" component="div" align="center">
                                    {totalityNumber.filter(obj => obj.prohibicion === true).length}
                                </Typography>
                            </Avatar>
                            <Typography sx={{ mt: 1 }} variant="h6" align="center" gutterBottom>
                                Con prohibición
                            </Typography>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            <Paper
                // className={classes.pageContent}
                sx={{ mb: 5, mr: 5, ml: 5, mt: 3, p: 3 }}
            >

                {/* <Toolbar
                    sx={{ mb: 3 }}
                >
                   
                </Toolbar> */}
                <Toolbar>

                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                    >
                        <Grid item xs sx={{ flexGrow: 1 }}>
                            <Controls.Input
                                label="Buscar por matricula"
                                style={{ width: '100%' }}
                                InputProps={{
                                    startAdornment: (<InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>)
                                }}
                                onChange={handleSearch}
                            />
                        </Grid>
                        <Grid item xs="auto">
                            <Controls.Button
                                style={{ marginLeft: 10 }}
                                text="Buscar"
                                variant="outlined"
                                startIcon={(width > 850) ? <SearchIcon /> : null}
                                onClick={() => { handleSearchClick(search) }}
                            />
                            {/* <Controls.Button
                                text="Agregar"
                                variant="outlined"
                                startIcon={(width > 850) ? <AddIcon /> : null}
                                onClick={() => {
                                    dispatch(startTitlePopup('Lista de embarcaciones para agregar...'));
                                    dispatch(startSizePopup('lg'));
                                    dispatch(startChildPopup('Agregar'));
                                    dispatch(startOpenPopup());
                                }}
                            /> */}
                            <Controls.Button
                                text="Despachar"
                                // variant="outlined"
                                // color="third"
                                color="success"
                                startIcon={(width > 850) ? <CallMadeIcon /> : null}
                                onClick={() => {
                                    dispatch(startTitlePopup('Lista de despachos...'));
                                    dispatch(startSizePopup('lg'));
                                    dispatch(startChildPopup('Despachos'));
                                    dispatch(startOpenPopup());
                                }}
                            />
                        </Grid>
                    </Grid>
                </Toolbar>

                <TblContainer>
                    <TblHead />
                    <TableBody>
                        {
                            recordsAfterPagingAndSorting().map(item =>
                            // {
                            //     console.log(item)
                            // })
                            (
                                <TableRow key={item.id}
                                    sx={{
                                        background: (isAfter(new Date(todayDate), new Date(format(parseISO(item.datosDespachos.fechaRegresoAprox), 'yyyy-MM-dd HH:mm:ss'))))
                                            ? 'linear-gradient(45deg, #ff8585 0%, #ffffff 20%)'
                                            : null
                                    }}
                                >
                                    <TableCell>{item.nombre}</TableCell>
                                    <TableCell>
                                        {
                                            (item.inscripcion === 'REY' || item.inscripcion === 'E/T')
                                                ? `${item.inscripcion} ${item.matricula}`
                                                : `${item.cuatrigrama} ${item.matricula}`
                                        }
                                    </TableCell>
                                    <TableCell>{item.desc}</TableCell>
                                    <TableCell>
                                        <Controls.ActionButton
                                            color="primary"
                                            onClick={() => { despacharItem(item.id, item.nombre) }}>
                                            <Tooltip title="Entrada">
                                                <CallReceivedIcon fontSize="small" />
                                            </Tooltip>
                                        </Controls.ActionButton>
                                        <Controls.ActionButton
                                            color="fourth"
                                            onClick={() => {
                                                // Swal.fire({
                                                //     title: 'Contacto',
                                                //     icon: 'info',
                                                //     html:
                                                //         `Telefono propietario: ${(item.datosDespachos.telefonoPropietario === null | item.datosDespachos.telefonoPropietario === '') ? 'No se proporciono telefono.' : item.datosDespachos.telefonoPropietario}` +
                                                //         '<br> ' +
                                                //         `Telefono persona a cargo: ${(item.datosDespachos.telefonoPersonaAcargo === null | item.datosDespachos.telefonoPersonaAcargo === '') ? 'No se proporciono telefono.' : item.datosDespachos.telefonoPersonaAcargo}`,
                                                //     showCloseButton: false,
                                                //     showCancelButton: false,
                                                //     focusConfirm: false,
                                                //     confirmButtonText: '<i class="fa fa-thumbs-up"></i> OK!',
                                                // })
                                                Swal.fire({
                                                    title: 'Contacto',
                                                    icon: 'info',
                                                    html:
                                                        `Telefono: ${item.datosDespachos.telefono}` +
                                                        '<br> ' +
                                                        `Datos tripulantes: ${item.datosDespachos.datosTripulantes}`,
                                                    showCloseButton: false,
                                                    showCancelButton: false,
                                                    focusConfirm: false,
                                                    confirmButtonText: '<i class="fa fa-thumbs-up"></i> OK!',
                                                })
                                            }}>
                                            <Tooltip title="Contacto de amarre">
                                                <PhoneIcon fontSize="small" />
                                            </Tooltip>
                                        </Controls.ActionButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </TblContainer>
                <TblPagination />
            </Paper >
            <Popup
                title={titlePopup}
                size={sizePopup}
            >
                {
                    // (childPopup === 'Despachos')
                    //     ? (<EmbarcacionesDespacho />)
                    //     : (<ClubPendientes />)
                    (childPopup === 'Despachos')
                        ? (<EmbarcacionesDespacho />)
                        : (childPopup === 'Agregar')
                            ? (<ClubPendientes />)
                            : (childPopup === 'totalEmb')
                                ? <TableWithStadisticsTotal />
                                : (childPopup === 'prohibEmb')
                                    ? <TableWithStadisticsProhibidas />
                                    : null

                }
            </Popup>
        </>
    )
}

export default EmbarcacionesClub;
