import React from 'react';
import { makeStyles } from '@mui/styles';
import { CircularProgress } from '@mui/material';

const useStyles = makeStyles({
    loading: {
        height: '100vh',
        width: '100%',
        margin: '0',
        padding: '0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
})

const Loading = () => {
    const classes = useStyles();

    return (
        <div className={classes.loading}>
            {/* <h1>Espere ...</h1> */}
            <CircularProgress size={48}/>
        </div>
    )
}

export default Loading
