import Swal from 'sweetalert2';
import { fetchSinToken, fetchConToken } from '../services/fetch';
import { types } from '../types/types';

export const startLogin = (email, password) => {
    return async (dispatch) => {
        try {
            const resp = await fetchSinToken('auth', { email, password }, 'POST')
            const body = await resp.json();
            if (resp.status !== 200) throw new Error(resp.status);
            if (body.ok) {
                localStorage.setItem('token', body.token);
                localStorage.setItem('token-init-date', new Date().getTime());
                dispatch(login({
                    uid: body.uid,
                    name: body.nombre,
                    surname: body.apellido,
                    dni: body.dni,
                    phone: body.telefono,
                    role: body.rol,
                    from: body.desde,
                    place: body.lugar
                }))
            } else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            // console.log(error.message)
            if (error.message !== 'resp is undefined') {
                // if (error.message === '400') throw new Error(error.message)
                // if (error.message === '401') throw new Error(error.message)
                // if (error.message === '404') throw new Error(error.message)
                // if (error.message === '410') throw new Error(error.message)
                throw new Error(error.message)
            }
        }
    }
}

export const startRegister = (name, email, password, rol, from, place, estado = true, pendiente = false) => {
    return async (dispatch) => {

        const resp = await fetchSinToken('auth/new', {
            name, email, password, rol, from, place, estado, pendiente
        }, 'POST');
        const body = await resp.json();

        if (body.ok) {
            localStorage.setItem('token', body.token);
            localStorage.setItem('token-init-date', new Date().getTime());

            dispatch(login({
                uid: body.uid,
                name: body.name,
                // surname: body.surname,
                role: body.rol,
                from: body.desde,
                place: body.lugar
            }))
        } else {
            Swal.fire('Error', body.msg, 'error');
            // alert(`Error: ${body.msg}`);
        }
    }
}

export const startBlankPassword = (email) => {
    return async (dispatch) => {
        const resp = await fetchSinToken('auth/blankpass', { email: email }, 'POST');
        const body = await resp.json();
        if (body.ok) {
            Swal.fire('Confirmacion', body.msg, 'success');
            dispatch(blankPasswordButtonIconLoading(false))
        } else {
            Swal.fire('Error', body.msg, 'error');
            dispatch(blankPasswordButtonIconLoading(false))
        }
    }
}

export const blankPasswordButtonIconLoading = (state) => ({
    type: types.authBlankPasswordButtonIcon,
    payload: state
});

export const resetPassword = (id, token, password,) => {
    // return async (dispatch) => {
    return new Promise(async (resolve, reject) => {

        const resp = await fetchSinToken(`auth/resetpassword/${id}/${token}`, { password }, 'PUT');
        const body = await resp.json();

        if (body.ok) {
            // Swal.fire('Confirmacion', body.msg, 'success');

            Swal.fire({
                title: 'Confirmacion',
                text: body.msg,
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#28a745',
                confirmButtonText: 'Ok!'
            }).then((result) => {
                if (result.isConfirmed) {
                    resolve()
                }
            })
        } else {
            Swal.fire('Error', body.msg, 'error');
            reject()
            // alert(`Error: ${body.msg}`);
        }
    })
    // }
}

export const startChecking = () => {
    return async (dispatch) => {

        const resp = await fetchConToken('auth/renew');
        const body = await resp.json();

        if (body.ok) {
            localStorage.setItem('token', body.token);
            localStorage.setItem('token-init-date', new Date().getTime());

            dispatch(login({
                uid: body.uid,
                name: body.nombre,
                surname: body.apellido,
                role: body.rol,
                from: body.desde,
                place: body.lugar
            }))
        } else {
            dispatch(checkingFinish());
        }
    }
}

const checkingFinish = () => ({ type: types.authCheckingFinish });

const login = (user) => ({
    type: types.authLogin,
    payload: user
});

export const startLogout = () => {
    return (dispatch) => {

        localStorage.clear();
        dispatch(logout());
    }
}

const logout = () => ({ type: types.authLogout });