// import Swal from 'sweetalert2';
import { types } from '../types/types';

export const startTitlePopup = (payload) => {
    return (dispatch) => {
        dispatch(titlePopup(payload));
    }
}

export const startSizePopup = (payload) => {
    return (dispatch) => {
        dispatch(sizePopup(payload));
    }
}

export const startChildPopup = (payload) => {
    return (dispatch) => {
        dispatch(childPopup(payload));
    }
}

export const startOpenPopup = () => {
    return (dispatch) => {
        dispatch(openPopup());
    }
}

export const startClosePopup = () => {
    return (dispatch) => {
        dispatch(closePopup());
    }
}

export const startTablePageChange = (payload) => {
    return (dispatch) => {
        dispatch(tablePageChange(payload));
    }
}

export const startRowsPerPage = (payload) => {
    return (dispatch) => {
        dispatch(rowsPerPage(payload));
    }
}

// export const startRecordForEdit = (recordForEdit) => {
//     return (dispatch) => {

//         dispatch(RecordForEdit({recordForEdit}));
//         // console.log(item)

//     }
// }

// const RecordForEdit = (item) => ({
//     type: types.systemRecordForEdit,
//     payload: item
// });

export const tableOpenCloseCollapse = (payload) => ({
    type: types.systemTableOpenCloseCollapse,
    payload: payload
});

export const changeSizeWindow = (payload) => ({
    type: types.systemWindowSizeWidth,
    payload: payload
});

const rowsPerPage = (payload) => ({
    type: types.systemRowsPerPage,
    payload: payload
});

const tablePageChange = (payload) => ({
    type: types.systemPage,
    payload: payload
});

const titlePopup = (payload) => ({
    type: types.systemPopupTitle,
    payload: payload
});

const sizePopup = (payload) => ({
    type: types.systemPopupSize,
    payload: payload
});

const childPopup = (payload) => ({
    type: types.systemPopupChild,
    payload: payload
});

const openPopup = () => ({
    type: types.systemOpenPopup
});

const closePopup = () => ({
    type: types.systemClosePopup
});

export const openPopupAlternativo = () => ({
    type: types.systemOpenPopupAlternativo
});

export const closePopupAlternativo = () => ({
    type: types.systemClosePopupAlternativo
});

export const changeTableSumario = (payload) => ({
    type: types.systemRadioTableSumario,
    payload: payload
});

export const changeLoading = (payload) => ({
    type: types.systemLoading,
    payload: payload
});