import Swal from 'sweetalert2';
import { Toast } from "../components/Toast";
import { fetchConToken, fetchSinToken } from '../services/fetch';
import { types } from '../types/types';

export const startListRelacionesPendientesDB = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;
        try {
            const resp = await fetchSinToken(`embarcaciones/prefectura/relacionespendientes/${uid}`, {});
            const body = await resp.json();
            if (body.ok) {
                dispatch(listRelacionesPendientesDB(body.pendientesEmbarcacionUsuario))
                // console.log(body)
            } else {
                Swal.fire('Error', body.msg, 'error')
            }
        } catch (error) {
            //throw new Error('Error en el sistema. Contactese con el administrador.')
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }
}
const listRelacionesPendientesDB = (pendientesEmbarcacionUsuario) => ({
    type: types.embarcacionGetRelationshipsPendingBoats,
    payload: pendientesEmbarcacionUsuario
});

export const startListRelacionesNoPendientesDB = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;
        try {
            const resp = await fetchSinToken(`embarcaciones/prefectura/relacionesnopendientes/${uid}`, {});
            const body = await resp.json();
            if (body.ok) {
                dispatch(listRelacionesNoPendientesDB(body.noPendientes))
                // console.log(body)
            } else {
                Swal.fire('Error', body.msg, 'error')
            }
        } catch (error) {
            //throw new Error('Error en el sistema. Contactese con el administrador.')
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }
}
const listRelacionesNoPendientesDB = (noPendientes) => ({
    type: types.embarcacionGetRelationshipsNoPendingBoats,
    payload: noPendientes
});

export const startListPendientesDB = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;
        try {
            const resp = await fetchSinToken(`embarcaciones/prefectura/pendientes/${uid}`, {});
            const body = await resp.json();
            if (body.ok) {
                dispatch(listPendientesDB(body.pendientes))
                // console.log(body)
            } else {
                Swal.fire('Error', body.msg, 'error')
            }
        } catch (error) {
            //throw new Error('Error en el sistema. Contactese con el administrador.')
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }
}
const listPendientesDB = (pendientes) => ({
    type: types.embarcacionGetPendingBoats,
    payload: pendientes
});

export const startListDependenciasDB = () => {

    return async (dispatch) => {
        try {
            const resp = await fetchSinToken('dependencias/', {});
            const body = await resp.json();

            if (body.ok) {

                dispatch(ListDependenciasDB(body.dependencias))

            } else {
                Swal.fire('Error', body.msg, 'error')
            }

        } catch (error) {
            //throw new Error('Error en el sistema. Contactese con el administrador.')
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }

}

const ListDependenciasDB = (dependencias) => ({
    type: types.adminGetDependencias,
    payload: dependencias
});


export const startGetBoats = () => {

    return async (dispatch, getState) => {

        const { uid } = getState().auth;

        try {
            const resp = await fetchSinToken(`embarcaciones/prefectura/${uid}`, {});
            const body = await resp.json();

            if (body.ok) {

                dispatch(GetBoats(body))
                // console.log(body)

            } else {

                Swal.fire('Error', body.msg, 'error')
                // console.log(body)

            }

        } catch (error) {
            //throw new Error('Error en el sistema. Contactese con el administrador.')
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }
}

const GetBoats = (boats) => ({
    type: types.embarcacionGetBoats,
    payload: boats
});

export const startPostNewBoat = (boat) => {

    return async (dispatch) => {

        const { ...rest } = boat;
        const payload = { ...rest, estado: true, despachada: false, pendiente: false }

        try {
            const resp = await fetchConToken('embarcaciones/prefectura', payload, 'POST');
            const body = await resp.json();

            if (body.ok) {

                // console.log(body.embarcacion)
                // dispatch(PostNewBoat(payload));
                // dispatch(startGetBoats())
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2500,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })
                Toast.fire({
                    icon: 'success',
                    title: 'Se creó embarcación correctamente.'
                })


            }

        } catch (error) {
            console.log('Error de conexión. Contactese con el administrador.')
        }

    }

}

// const PostNewBoat = (boat) => ({
//     type: types.embarcacionPostNewBoats,
//     payload: boat
// });

export const startPutEditBoat = (boat) => {
    return async (dispatch) => {
        try {

            // console.log(event);
            const resp = await fetchConToken(`embarcaciones/prefectura/${boat.id}`, boat, 'PUT');
            const body = await resp.json();

            if (body.ok) {
                dispatch(PutEditBoat(boat));
                dispatch(startGetBoats())
                dispatch(startListPendientesDB())
                dispatch(startListRelacionesPendientesDB())
                // Toast('success', 'Se actualizo embarcación correctamente.');
                Toast('success', body.msg);
            } else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }

    // return async (dispatch) => {
    //     console.log(boat)
    // }

}

const PutEditBoat = (boat) => ({
    type: types.embarcacionPutEditBoat,
    payload: boat
});

export const startDeleteRelationshipBoat = (id) => {
    return async (dispatch) => {
        try {
            const resp = await fetchConToken(`embarcaciones/prefectura/deleterelationship/${id}`, {}, 'DELETE');
            const body = await resp.json();
            if (body.ok) {
                dispatch(startListRelacionesPendientesDB())
                dispatch(startListRelacionesNoPendientesDB())
            } else {
                Swal.fire('Error', body.msg, 'error');
            }
        } catch (error) {
            console.log('Error de conexión. Contactese con el administrador.')
            // throw new Error(error)
        }
    }
}

export const startDeleteBoat = (id) => {
    return async (dispatch) => {

        try {

            const resp = await fetchConToken(`embarcaciones/prefectura/deleteboat/${id}`, {}, 'DELETE');
            const body = await resp.json();

            if (body.ok) {
                dispatch(DeleteBoat());
                dispatch(startGetBoats())
            } else {
                Swal.fire('Error', body.msg, 'error');
            }

        } catch (error) {
            console.log('Error de conexión. Contactese con el administrador.')
            // throw new Error(error)
        }
    }

    // return async (dispatch) => {
    //     console.log(boat)
    // }

}

const DeleteBoat = () => ({
    type: types.embarcacionDeleteBoat,
    // payload: boat
});

export const startSearchBoat = (text) => {
    return (dispatch) => {

        dispatch(SearchBoat(text));
        // dispatch(startGetBoats())

    }

    // return async (dispatch) => {
    //     console.log(text)
    // }

}

const SearchBoat = (text) => ({
    type: types.embarcacionSearchBoat,
    payload: text
});

export const startGetSearchBoat = () => {

    return async (dispatch, getState) => {

        const { search } = getState().embarcacion;
        // console.log(search)
        // if ( search === '' ) {
        //     dispatch(startGetBoats())
        // }

        try {
            const resp = await fetchConToken(`embarcaciones/prefectura/search/${search}`, {});
            const body = await resp.json();

            if (body.ok) {

                dispatch(GetSearchBoat(body))
                // console.log(body)

            } else {

                Swal.fire('Error', body.msg, 'error')
                // console.log(body)

            }

        } catch (error) {
            //throw new Error('Error en el sistema. Contactese con el administrador.')
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }

    // return async (dispatch) => {
    //     console.log(text)
    // }

}

const GetSearchBoat = (boat) => ({
    type: types.embarcacionGetSearchBoat,
    payload: boat
});


export const startRecordForEdit = (recordForEdit) => {
    return (dispatch) => {

        dispatch(RecordForEdit({ recordForEdit }));
        // console.log(recordForEdit)

    }
}

const RecordForEdit = (item) => ({
    type: types.embarcacionRecordForEdit,
    payload: item
});

export const startGetBoatsCarnet = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;
        try {
            const resp = await fetchSinToken(`embarcaciones/prefectura/carnet/${uid}`, {});
            const body = await resp.json();
            if (body.ok) {
                // dispatch(getBoatsCarnet(body.embarcaciones))
                dispatch(getBoatsCarnet(body))
                // console.log(body.embarcaciones)
                // console.log(body)
            } else {
                Swal.fire('Error', body.msg, 'error')
                // console.log(body)
            }
        } catch (error) {
            //throw new Error('Error en el sistema. Contactese con el administrador.')
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }
}

const getBoatsCarnet = (boats) => ({
    type: types.embarcacionGetBoatsCarnet,
    payload: boats
});

export const startGetTimonelCarnet = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;
        try {
            const resp = await fetchSinToken(`timonel/carnet/${uid}`, {});
            const body = await resp.json();
            if (body.ok) {
                // dispatch(getTimonelCarnet(body.timoneles))
                dispatch(getTimonelCarnet(body))
                // console.log(body.timoneles)
                // console.log(body)
            } else {
                Swal.fire('Error', body.msg, 'error')
                // console.log(body)
            }
        } catch (error) {
            //throw new Error('Error en el sistema. Contactese con el administrador.')
            console.log('Error de conexión. Contactese con el administrador.')
        }
    }
}

const getTimonelCarnet = (people) => ({
    type: types.embarcacionGetTimonelCarnet,
    payload: people
});

export const changeRadioOptionCarnet = (boats) => ({
    type: types.systemRadioTablePolinave,
    payload: boats
});

