import { makeStyles } from '@mui/styles';
import React from 'react'

const useStyles = makeStyles(theme => ({
    visible: {
        display: 'initial',
        '@media (max-width: 960px)': {
            display: 'none'
        },
    },
    card: {
        backgroundColor: '#fff',
        // backgroundColor: '#2d3b66',
        borderRadius: '0px 25px 0px 25px',
        margin: '30px',
        padding: '10px 40px 10px 40px',
        // boxShadow: '0px 0px 10px 10px #2d3b66'
    }
}))

const Clima = (props) => {
    const { lugar, temp, icon, desc } = props;
    const classes = useStyles();



    // const icon = `https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/${
    //     weather[0]["icon"]
    //   }.svg`;

    return (
        <div className={classes.visible}>

            <div className={(!lugar || !temp || !desc) ? null : classes.card}>
                <h4>{(lugar) ? `${lugar}`.toUpperCase() : null}</h4>
                <h1>{(temp) ? `${temp} °` : null}</h1>
                <img src={`https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/${icon}.svg`} alt='icon' />
                <h6>{(desc) ? `${desc}`.toUpperCase() : null}</h6>

            </div>

            {/* <h1>CLIma {desc}</h1>
                    <h1>CLIma {desc}</h1>
                    <h1>CLIma {desc}</h1>
                    <h1>CLIma {desc}</h1>
                    <h1>CLIma {desc}</h1>
                    <h1>CLIma {desc}</h1>
                    <h1>CLIma {desc}</h1> */}

        </div>
    );
}

export default Clima;