import React, { useState } from 'react'
import MySwal from 'sweetalert2'
import { Grid, TableBody, TableRow, TableCell, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

import useTable from "../../components/useTable";
import Controls from '../../components/controls/Controls';
import CallMadeIcon from '@mui/icons-material/CallMade';
import { useDispatch, useSelector } from 'react-redux';
import { asignacionContravencionPut } from '../../actions/embarcacionesSumario';

const useStyles = makeStyles(theme => ({

    searchInput: {
        width: '50%'
    },
    searchButton: {
        position: 'absolute',
        right: '10px',
        // width: '5%'
    },

    dialogWrapper: {
        padding: theme.spacing(2),
        minHeight: p => p.mobile
            ? '100vh'
            : 'unset'

    },
    dialogTitle: {
        padding: 0,
        // paddingRight: '0px',
        zIndex: '10',

    },
    sAlert: {
        zIndex: 9999999999
    },
}))

const headCells = [
    { id: 'acta', label: 'N° Acta' },
    { id: 'embarcacion', label: 'Embarcacion' },
    { id: 'matricula', label: 'Matricula' },
    { id: 'timonel', label: 'Timonel' },
    { id: 'dni', label: 'Dni' },
    { id: 'contravencion', label: 'Contravención' },
    { id: 'actions', label: 'Acciones', disableSorting: true }
]

const SumarioPendientes = (props) => {

    const classes = useStyles();
    const Swal = MySwal.mixin({ customClass: { container: classes.sAlert }, })
    const dispatch = useDispatch();
    const { contravencionesPendientes } = useSelector(state => state.sumario);
    // const [filterFn, setFilterFn] = useState({ fn: items => { return items; } })
    const [filterFn] = useState({ fn: items => { return items; } })

    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting
    } = useTable(contravencionesPendientes, headCells, filterFn);

    // const handleSearch = e => {
    //     let target = e.target;
    //     setFilterFn({
    //         fn: items => {
    //             if (target.value == "")
    //                 return items;
    //             else
    //                 return items.filter(x => x.fullName.toLowerCase().includes(target.value))
    //         }
    //     })
    // }


    // const openInPopup = (item) => {
    //     // setRecordForEdit(item)
    //     dispatch(startRecordForEdit(item));

    //     // setOpenPopup(true)
    //     // dispatch(startOpenPopup());
    // }

    const swalPopup = async (id) => {
        /* inputOptions can be an object or Promise */
        const inputOptions = new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    // '#ff0000': 'Red',
                    // '#00ff00': 'Green',
                    // '#0000ff': 'Blue'

                    'boat': 'Embarcación',
                    'timonel': 'Timonel',
                })
            }, 1000)
        })

        const { value: asignacion } = await Swal.fire({
            title: 'Asignar contravención a:',
            input: 'radio',
            inputOptions: inputOptions,
            inputValidator: (value) => {
                if (!value) {
                    return 'Debes elegir una opción!'
                }
            }
        })

        if (asignacion) {
            // console.log(asignacion + '. con id: ' + id)
            dispatch(asignacionContravencionPut(id, { data: asignacion }))

        }

        // if (asignacion === 'boat') {
        //     // Swal.fire({ html: `You selected: ${color}` })
        //     // Swal.fire({ html: `You selected: ${color}` })
        //     // console.log(asignacion + '. con id: ' + id)
        //     dispatch(asignacionContravencionPut(id, asignacion))
        // } else {
        //     dispatch(asignacionContravencionPut(id, asignacion))
        // }
    }



    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <div>
                        <TblContainer>
                            <TblHead />
                            <TableBody>

                                {
                                    recordsAfterPagingAndSorting().map(item =>
                                    (<TableRow key={item.id}>
                                        <TableCell>
                                            {item.numActa}
                                            {/* {JSON.stringify(item, null, 5)} */}
                                        </TableCell>

                                        {/* embarcacion y matricula */}
                                        <TableCell>{item.datosEmbarcacion.nombre}</TableCell>
                                        <TableCell>
                                            {
                                                (item.datosEmbarcacion.inscripcion === 'REY' || item.datosEmbarcacion.inscripcion === 'E/T')
                                                    ? `${item.datosEmbarcacion.inscripcion} ${item.datosEmbarcacion.matricula}`
                                                    : `${item.datosEmbarcacion.cuatrigrama} ${item.datosEmbarcacion.matricula}`
                                            }
                                        </TableCell>

                                        {/* timonel y dni */}
                                        <TableCell>{(item.datosTimonel && item.datosTimonel.timonelNombre) || `${item.datosUsuarioTimonel && item.datosUsuarioTimonel.nombre} ${item.datosUsuarioTimonel && item.datosUsuarioTimonel.apellido}`}</TableCell>
                                        <TableCell>{(item.datosTimonel && item.datosTimonel.timonelDni) || (item.datosUsuarioTimonel && item.datosUsuarioTimonel.dni)}</TableCell>

                                        <TableCell>{item.contravencion}</TableCell>
                                        <TableCell>
                                            <Controls.ActionButton
                                                color="fourth"
                                                onClick={() => swalPopup(item.id)}
                                            // onClick={() => { despacharItem(item.id, item.nombre) }}
                                            // disabled={true}
                                            >
                                                {/* // onClick={() => { console.log(item.id) }}> */}
                                                <Tooltip title="Asignar contravención">
                                                    <CallMadeIcon fontSize="small" />
                                                </Tooltip>
                                            </Controls.ActionButton>

                                        </TableCell>
                                    </TableRow>)
                                    )
                                }
                            </TableBody>
                        </TblContainer>
                        <TblPagination />
                    </div>
                </Grid>
            </Grid>


        </>
    )
}

export default SumarioPendientes;