import { Box, CardMedia, CircularProgress } from '@mui/material';
import React, { useState } from 'react'

export const ImageLoading = ({ src, sx = [] }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  // const openImage = (imageUrl) => {
  //   window.open(imageUrl, '_blank');
  // };

  return (
    <>
      <Box sx={{ position: 'relative' }}>
        {
          !isLoaded &&
          <Box sx={[{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }, ...(Array.isArray(sx) ? sx : [sx])
          ]}>
            <CircularProgress size={48} sx={{opacity: .6}} />
          </Box>
        }

        <CardMedia
          component="img"
          // sx={{ cursor: 'zoom-in' }}
          // onClick={() => { openImage(src) }}
          onLoad={() => setIsLoaded(true)}
          image={src}
          sx={[{ zIndex: 1 }, ...(Array.isArray(sx) ? sx : [sx])]}
          alt="img_boat"
        />
      </Box>
    </>
  )
}
