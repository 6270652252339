import { types } from '../types/types';

const initialState = {

    usuarios: [],
    usuariosActivos: [],
    usuariosActivosPrefe: [],

}

export const adminReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.adminGetDependencias:
            return {
                ...state,
                dependencias: [...action.payload],
            }

        case types.adminGetClubes:
            return {
                ...state,
                clubes: [...action.payload],
            }

        case types.adminGetUsuariosPendientes:
            return {
                ...state,
                usuarios: action.payload,
            }

        case types.adminGetUsuariosActivos:
            return {
                ...state,
                usuariosActivos: action.payload,
            }

        case types.adminGetUsuariosActivosSoloPrefe:
            return {
                ...state,
                usuariosActivosPrefe: action.payload,
            }

        default:
            return state;
    }

}


