import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { TablePagination } from '@mui/material';
import { Paper, TableBody, TableRow, TableCell, Toolbar, InputAdornment, Tooltip, Box, Typography, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import SearchIcon from '@mui/icons-material/Search';
import { Search } from "@mui/icons-material";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CheckIcon from '@mui/icons-material/Check';
import PhoneIcon from '@mui/icons-material/Phone';
import { format } from 'date-fns';
import Swal from 'sweetalert2';

import { startClosePopup, startOpenPopup, startRowsPerPage, startTablePageChange, startTitlePopup } from '../../../actions/system';
import PageHeader from "../../../components/PageHeader";
import Controls from "../../../components/controls/Controls";
import { startGetSearchBoat, startSearchBoat, startSenadeGetBoats, startSenadePostNewBoats } from '../../../actions/embarcacionesSenade';
import Popup from '../../../components/Popup';
import VerificacionGenerica from './VerificacionGenerica';
import Label from '../../../components/tableLabelBadge/Label';
import { startRecordForEdit } from '../../../actions/embarcacionesPrefectura';
import { startGetUsersFromAboat, startListClubesDB } from '../../../actions/embarcacionesClubes';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  searchInput: {
    width: '100%'
  },
  spaceTop: {
    marginTop: '20px'
  },
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3)
  },
  table: {
    marginTop: theme.spacing(3),
    '& thead th': {
      fontWeight: '600',
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light,
    },
    '& tbody td': {
      fontWeight: '300',
    },
    '& tbody tr:hover': {
      backgroundColor: '#fffbf2',
      cursor: 'pointer',
    },
  },
  tableColor: {
    backgroundColor: '#f0f0f0',
    borderRadius: 10
  },

}));

const Row = (props) => {
  const { row } = props;
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const refResultado = useRef('');

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.nombre}
        </TableCell>
        <TableCell>
          {/* {row.matricula} */}
          {
            (row.inscripcion === 'REY' || row.inscripcion === 'E/T')
              ? `${row.inscripcion} ${row.matricula}`
              : `${row.cuatrigrama} ${row.matricula}`
          }
        </TableCell>
        <TableCell align="right">{row.amarre}</TableCell>
        <TableCell align="right">
          <Controls.ActionButton
            color="third"
            onClick={() => {
              dispatch(startTitlePopup('Verificación de embarcaciones despachadas'));
              dispatch(startRecordForEdit(row));
              dispatch(startGetUsersFromAboat(row.id));
              dispatch(startOpenPopup());
            }}>
            <Tooltip title="Verificar">
              <CheckIcon fontSize="small" />
            </Tooltip>
          </Controls.ActionButton>
          <Controls.ActionButton
            color="fourth"
            onClick={() => {
              Swal.fire({
                title: 'Contacto',
                icon: 'info',
                html:
                  // `Telefono propietario: ${(row.datosDespachos.telefonoPropietario === null | row.datosDespachos.telefonoPropietario === '') ? 'No se proporciono telefono.' : row.datosDespachos.telefonoPropietario}` +
                  // '<br> ' +
                  // `Telefono persona a cargo: ${(row.datosDespachos.telefonoPersonaAcargo === null | row.datosDespachos.telefonoPersonaAcargo === '') ? 'No se proporciono telefono.' : row.datosDespachos.telefonoPersonaAcargo}`,
                  `Telefono: ${row.datosDespachos.telefono}`,
                  showCloseButton: false,
                showCancelButton: false,
                focusConfirm: false,
                confirmButtonText: '<i class="fa fa-thumbs-up"></i> OK!',
              })
            }}>
            <Tooltip title="Contacto">
              <PhoneIcon fontSize="small" />
            </Tooltip>
          </Controls.ActionButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={classes.tableColor} style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Datos y verificación
              </Typography>
              {/* ----------------------------PRIMER LINEA-------------------------- */}
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Titular</TableCell>
                    <TableCell align="right">Nombre y apellido</TableCell>
                    <TableCell align="right">DNI</TableCell>
                    <TableCell align="right">Fecha salida</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {row.datosDespachos.titular ? 'Si' : 'No'}
                    </TableCell>
                    <TableCell align="right">{row.datosDespachos.nombre}</TableCell>
                    <TableCell align="right">
                      {row.datosDespachos.dni}
                    </TableCell>
                    <TableCell align="right">
                      {format(new Date(row.datosDespachos.fechaSalida), 'dd-MM-yyyy HH:mm') + 'Hs'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {/* ----------------------------FIN PRIMER LINEA-------------------------- */}

              {/* ----------------------------SEGUNDA LINEA-------------------------- */}

              <Table size="small" aria-label="purchases" className={classes.spaceTop}>
                <TableHead>
                  <TableRow>
                    {
                      (!row.datosDespachos.titular) &&
                      <TableCell>Actuación Notarial</TableCell>
                    }
                    <TableCell>Zona navegación</TableCell>
                    <TableCell>Tripulantes</TableCell>
                    <TableCell align="right">Fecha regreso</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {
                      (!row.datosDespachos.titular) &&
                      <TableCell component="th" scope="row">
                        {row.datosDespachos.actuacionNotarialAutorizado}
                      </TableCell>
                    }
                    <TableCell>{row.datosDespachos.lugarNavegacion}</TableCell>
                    <TableCell>{row.datosDespachos.datosTripulantes}</TableCell>
                    <TableCell align="right">{format(new Date(row.datosDespachos.fechaRegresoAprox), 'dd-MM-yyyy HH:mm') + 'Hs'}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {/* ----------------------------FIN SEGUNDA LINEA-------------------------- */}

              {/* ----------------------------TERCERA LINEA-------------------------- */}
              <Table size="small" aria-label="purchases" className={classes.spaceTop}>
                <TableHead>
                  <TableRow>
                    {/* <TableCell>Categoria Carnet</TableCell> */}
                    {/* <TableCell>Fecha Vencimiento Carnet</TableCell> */}
                    <TableCell align="right">Resultado control</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {/* <TableCell component="th" scope="row">
                      {row.datosDespachos.catCarnet}
                      {JSON.stringify(row, null, 3)}
                    </TableCell> */}
                    {/* <TableCell>{format(new Date(row.datosDespachos.fechaCarnet), 'dd-MM-yyyy')}</TableCell> */}
                    <TableCell align="right">

                      {
                        row.datosVerificacion.map((i) => {
                          if (i.createdAt === format(new Date(), 'yyyy-MM-dd')) {
                            if (i.resultado === true) {
                              refResultado.current = 'rojo';
                            } else {
                              refResultado.current = 'verde';
                            }
                          }
                        })

                        // row.datosVerificacion.map((i) => {
                        //   return (i.createdAt === format(new Date(), 'yyyy-MM-dd'))
                        //     ? (i.resultado === true)
                        //       ? refResultado.current = 'rojo'
                        //       : refResultado.current = 'verde'
                        //     : null
                        // })
                      }

                      <Label
                        variant="ghost"
                        color={
                          (refResultado.current === 'rojo' && 'secondary') ||
                          (refResultado.current === 'verde' && 'success') ||
                          'info'
                        }
                      >
                        {
                          (refResultado.current === 'rojo' | refResultado.current === 'verde') ? 'Verificado' : 'No verificado'
                        }
                      </Label>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {/* ----------------------------FIN TERCERA LINEA-------------------------- */}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const ListadoEmbarcaciones = () => {

  const pages = [5, 10, 25]
  const classes = useStyles();
  const dispatch = useDispatch();
  const { name, place } = useSelector(state => state.auth);
  const { page, rowsPerPage } = useSelector(state => state.system);
  const { embarcaciones, search } = useSelector(state => state.senade);
  const { recordForEdit } = useSelector(state => state.embarcacion);
  const { titlePopup } = useSelector(state => state.system);
  const [setIdrow] = useState(0)

  useEffect(() => {
    dispatch(startListClubesDB());
    dispatch(startSenadeGetBoats())
  }, [dispatch])

  const handleChangePage = (event, newPage) => {
    dispatch(startTablePageChange(newPage))
  }

  const handleChangeRowsPerPage = event => {
    dispatch(startRowsPerPage(parseInt(event.target.value, 10)))
    dispatch(startTablePageChange(0))
  }

  const handleSearch = (e) => {
    dispatch(startSearchBoat(e.target.value.trim()));
  }

  const handleSearchClick = (search) => {
    if (search === '') {
      dispatch(startSenadeGetBoats())
    } else {
      dispatch(startGetSearchBoat());
    }
  }

  const addOrEdit = (data, resetForm) => {
    if (data.id === 0) {
      const boat = {
        timonelRegistered: data.timonelRegistered,
        timonelUsuarioId: data.idTimonelRegistered,
        amarre: data.amarre,
        numActa: data.numActa,
        desc: data.desc.toUpperCase(),
        fechaAlta: data.fechaAlta,
        inscripcion: data.inscripcion,
        matricula: data.matricula,
        cuatrigrama: data.cuatrigrama,
        multa: data.multa.toUpperCase(),
        nombre: data.nombre.toUpperCase(),
        novedad: data.novedad,
        prohibicion: false,
        lugar: place,
        pendiente: true,
        estado: true,
        despachada: false
      }

      const timonel = {
        timonelNombre: data.timonelNombre.toUpperCase(),
        timonelDni: data.timonelDni,
        timonelDireccion: data.timonelDireccion.toUpperCase(),
        timonelTelefono: data.timonelTelefono,
        timonelEmail: data.timonelEmail,
        timonelCarnet: data.timonelCarnet
      }
      dispatch(startSenadePostNewBoats(boat, timonel));
    } else {
      const boat = {
        timonelRegistered: data.timonelRegistered,
        timonelUsuarioId: data.idTimonelRegistered,
        amarre: data.amarre,
        numActa: data.numActa,
        desc: data.desc.toUpperCase(),
        fechaAlta: data.fechaAlta,
        inscripcion: data.inscripcion,
        matricula: data.matricula,
        cuatrigrama: data.cuatrigrama,
        multa: data.multa.toUpperCase(),
        nombre: data.nombre.toUpperCase(),
        novedad: data.novedad,
        prohibicion: data.prohibicion,
        lugar: data.lugar,
        pendiente: data.pendiente,
        estado: data.estado,
        despachada: data.despachada
      }
      const timonel = {
        timonelNombre: data.timonelNombre.toUpperCase(),
        timonelDni: data.timonelDni,
        timonelDireccion: data.timonelDireccion.toUpperCase(),
        timonelTelefono: data.timonelTelefono,
        timonelEmail: data.timonelEmail,
        timonelCarnet: data.timonelCarnet
      }
      dispatch(startSenadePostNewBoats(boat, timonel));
    }
    resetForm()
    dispatch(startRecordForEdit(null));
    dispatch(startClosePopup());
  }

  const openInPopup = () => {
    dispatch(startOpenPopup());
  }

  // console.log(embarcaciones)

  return (
    <>
      <PageHeader
        title={`Embarcaciones despachadas "${place}"`}
        subTitle="Operativo."
        icon={<DirectionsBoatIcon fontSize="large" />}
        profile={true}
        name={name}
      />
      <Paper className={classes.pageContent}>
        <Toolbar>
          <Grid container spacing={1}
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Controls.Input
                label="Buscar por matricula"
                className={classes.searchInput}
                InputProps={{
                  startAdornment: (<InputAdornment position="start">
                    <Search />
                  </InputAdornment>)
                }}
                onChange={handleSearch}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <Grid container item
              justifyContent="flex-start"
              xs={6}
            >
              <Controls.Button
                text="Buscar"
                variant="outlined"
                sx={{ ml: 1 }}
                startIcon={<SearchIcon />}
                onClick={() => { handleSearchClick(search) }}
              />
            </Grid>
            <Grid item container
              justifyContent="flex-end"
              xs={6}
            >
              <Controls.Button
                text="Verificar"
                variant="outlined"
                startIcon={<CheckIcon />}
                onClick={() => {
                  dispatch(startRecordForEdit(null));
                  dispatch(startTitlePopup('Verificación de embarcaciones propias'));
                  openInPopup()
                }}
              />
            </Grid>
          </Grid>
        </Toolbar>
        <Table className={classes.table} aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Nombre</TableCell>
              <TableCell>Matricula</TableCell>
              <TableCell align="right">Amarre</TableCell>
              <TableCell align="right">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {embarcaciones.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <Row key={row.matricula} row={row} dataSend={setIdrow} />
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          page={page}
          labelRowsPerPage='Filas por pagina:'
          rowsPerPageOptions={pages}
          rowsPerPage={rowsPerPage}
          count={embarcaciones.length}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Popup
        title={titlePopup}
      >
        <VerificacionGenerica
          recordForEdit={recordForEdit}
          addOrEdit={addOrEdit} 
          // user={}
          />
      </Popup>
    </>
  );
}

export default ListadoEmbarcaciones;