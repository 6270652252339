import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { TableBody, TableRow, TableCell, Toolbar, InputAdornment, Tooltip, Typography, Dialog, DialogTitle, DialogContent, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MySwal from 'sweetalert2'

import useTable from "../../components/useTable";

// const useStyles = makeStyles(theme => ({
//     sAlert: {
//         zIndex: 9999999999
//     },
// }))

const headCells = [
    { id: 'nombre', label: 'Embarcacion' },
    { id: 'mat', label: 'Matricula' },
    { id: 'desc', label: 'Descripción' },
    // { id: 'capacidad', label: 'Max. Capacidad' },
    // { id: 'carnet', label: 'Cat. Carnet' }
    { id: 'motivoProhibicion', label: 'Motivo de Prohibición' }
]

const TableWithStadisticsProhibidas = (props) => {
    // const classes = useStyles();
    // const Swal = MySwal.mixin({ customClass: { container: classes.sAlert }, })

    const { totalityNumber = [] } = useSelector(state => state.embarcacionClub);
    const [filterFn] = useState({ fn: items => { return items; } })

    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting
    } = useTable(totalityNumber.filter(obj => obj.prohibicion === true), headCells, filterFn);

    return (
        <>
            <TblContainer>
                <TblHead />
                <TableBody>
                    {
                        recordsAfterPagingAndSorting().map(item => (
                            <TableRow key={item.id}>
                                <TableCell>{item.nombre}</TableCell>
                                <TableCell>
                                    {
                                        (item.inscripcion === 'REY' || item.inscripcion === 'E/T')
                                            ? `${item.inscripcion}${item.matricula}`
                                            : `${item.cuatrigrama}${item.matricula}`
                                    }
                                </TableCell>
                                <TableCell>{item.desc}</TableCell>
                                <TableCell>{item.motivoProhib}</TableCell>
                                {/* <TableCell>{item.capacidadPersonas}</TableCell> */}
                                {/* <TableCell>{item.categoriaCarnet}</TableCell> */}
                            </TableRow>
                        ))
                    }
                </TableBody>
            </TblContainer>
            <TblPagination />
        </>
    )
}

export default TableWithStadisticsProhibidas;