const baseUrl = process.env.REACT_APP_API_URL;

export const useImageUpload = async (files, nameFolder, nameFile, endpoint = 'uploads') => {
    const url = `${baseUrl}/${endpoint}`;
    const postData = JSON.stringify({nameFolder, nameFile});

    const formData = new FormData()
    formData.append('archivo', files)
    formData.append('datos', postData)

    // console.log(files)
    // console.log(url)

    // const extension = files.name.split('.')
    
    try {
        const resp = await fetch(url, {
            method: 'POST',
            // CON MULTIPART NO FUNCIONA
            // headers: { 'Content-Type': 'multipart/form-data' },
            body: formData
        })

        if (resp.ok) {
            const body = await resp.json();
            // de extension tomo el ultimo array
            // return {...body, extension: extension[extension.length - 1]};
            return body
        } else {
            throw await resp.json();
        }

    } catch (error) {
        console.log(error)
    }
}