import React from 'react'
import {
    FormControl,
    FormControlLabel,
    Checkbox as MuiCheckbox 
} from '@mui/material';

export default function Checkbox(props) {

    const { name, label, value, onChange, disabled, onClick } = props;


    const convertToDefEventPara = (name, value) => ({
        target: {
            name, value
        }
    })

    return (
        <FormControl>
            <FormControlLabel
                control={
                    <MuiCheckbox
                        name={name}
                        // TO SET A COLOR
                        // sx={{
                        //     [`&, &.${checkboxClasses.checked}`]: {
                        //         color: 'red',
                        //     },
                        // }}
                        color='primary'
                        checked={value}
                        onChange={e => onChange(convertToDefEventPara(name, e.target.checked))}
                        onClick={onClick}
                        disabled={disabled}
                    />
                }
                label={label}
            />
        </FormControl>
    )
}
