import React from 'react'
// import PropTypes from 'prop-types'

// import { Route, Redirect } from 'react-router-dom'
import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux';

export const AdminRoute = ({ children }) => {

    const { role, from } = useSelector(state => state.auth);
        
    return (role === 'admin')
        ? children
        // : <Navigate to={`/gestion/${from}`} />
        : <Navigate to={(from === 'clubes') ? `/gestion/clubes` : '/gestion/dashboard'} />
        

    // return (
    //     <Route { ... rest }
    //         component={ ( props ) => (
    //             (isAdmin === 'admin')
    //                 ? <Component {...props} />
    //                 : <Navigate to='/prefectura' />
    //         )}
    //     />
    // )
}

// AdminRoute.propTypes = {
//     // isAuthorized: PropTypes.bool.isRequired,
//     component: PropTypes.func.isRequired
// }
