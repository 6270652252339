import React from 'react'
import { Button } from '@mui/material';

export default function ActionButton(props) {

    const { color, children, onClick, disabled } = props;

    return (
        <Button
            sx={{
                m: 0.5,
                minWidth: 0,

                ...(color === 'primary' && {
                    color: 'primary.main',
                    backgroundColor: 'primary.light'
                }),
                ...(color === 'secondary' && {
                    color: 'secondary.main',
                    backgroundColor: 'secondary.light'
                }),
                ...(color === 'third' && {
                    color: 'third.main',
                    backgroundColor: 'third.light'
                }),
                ...(color === 'fourth' && {
                    color: 'fourth.main',
                    backgroundColor: 'fourth.light'
                }),

            }}
            disabled={disabled}
            onClick={onClick}>
            {children}
        </Button>
    )
}
