import { combineReducers } from 'redux';
import { authReducer } from './authReducer';
import { systemReducer } from './systemReducer';
import { embarcacionReducer } from './embarcacionReducer';
import { embarcacionClubesReducer } from './embarcacionClubesReducer';
import { adminReducer } from './adminReducer';
import { despachoClubesReducer } from './despachoClubesReducer';
import { embarcacionSenade } from './embarcacionSenade';
import { sumarioReducer } from './sumarioReducer';
import { observadorVisorReducer } from './observadorVisorReducer';
import { embarcacionTimonelReducer } from './embarcacionTimonelReducer';

export const rootReducer = combineReducers({
    auth: authReducer,
    admin: adminReducer,
    system: systemReducer, 
    embarcacion: embarcacionReducer,
    embarcacionClub: embarcacionClubesReducer,
    embarcacionTimonel: embarcacionTimonelReducer,
    despachoClub: despachoClubesReducer,
    senade: embarcacionSenade,
    sumario: sumarioReducer,
    observador: observadorVisorReducer,
})

