import React from 'react'
import PageHeader from '../../components/PageHeader'
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import { useSelector } from 'react-redux';
import { Avatar, Box, Card, Grid, Paper, Typography } from '@mui/material';
import { blueGrey, brown, deepOrange, deepPurple, green, lime } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';

export const Dashboard = () => {
  const { name, place } = useSelector(state => state.auth);
  const navigate = useNavigate();

  return (
    <>
      <PageHeader
        title={`Centro de Actividades para Timoneles`}
        subTitle={`Prefectura ${place}`}
        icon={<DirectionsBoatIcon fontSize="large" />}
        profile={true}
        name={name}
      />

      <Paper sx={{ m: 5, p: 3 }}>
        <Typography variant="custom6" gutterBottom component="div" align='center' sx={{ p: 5, mt: 3 }}>
          ¿Qué quieres gestionar?
        </Typography>

        <Grid item>
          <Box sx={{
            display: 'flex',
            justifyContent: "center",
            alignItems: "center",
            gap: 5
          }}>
            <Card sx={{
              height: '250px',
              width: '250px',
              backgroundColor: deepOrange[400],
              cursor: 'pointer',
              userSelect: 'none',
              borderRadius: 10
            }}
            onClick={() => { navigate(`/timonel/contravenciones`) }}
            >
              <Avatar variant="square" sx={{
                bgcolor: deepOrange[400],
                height: 'auto',
                width: '100%',
                cursor: 'pointer',
                userSelect: 'none',
                fontSize: '170px',
                color: deepOrange[200],
                fontWeight: 900,
                mt: 3,
                p: 0,
              }}>
                C
              </Avatar>
              <Typography variant="h5" align="center" gutterBottom={false} sx={{ color: 'white' }}>
                Contravenciones
              </Typography>
            </Card>

            {/* <Card sx={{
              height: '250px',
              width: '250px',
              backgroundColor: green[400],
              cursor: 'pointer',
              userSelect: 'none',
              borderRadius: 10
            }}
            onClick={() => { navigate(`/timonel/carnet`) }}
            >
              <Avatar variant="square" sx={{
                bgcolor: green[400],
                height: 'auto',
                width: '100%',
                cursor: 'pointer',
                userSelect: 'none',
                fontSize: '170px',
                color: green[200],
                fontWeight: 900,
                mt: 3,
                p: 0,
              }}>
                CT
              </Avatar>
              <Typography variant="h5" align="center" gutterBottom={false} sx={{ color: 'white' }}>
                Carnet Timonel
              </Typography>
            </Card> */}

            <Card sx={{
              height: '250px',
              width: '250px',
              backgroundColor: blueGrey[400],
              cursor: 'pointer',
              userSelect: 'none',
              borderRadius: 10
            }}
            onClick={() => { navigate(`/timonel/despacho`) }}
            >
              <Avatar variant="square" sx={{
                bgcolor: blueGrey[400],
                height: 'auto',
                width: '100%',
                cursor: 'pointer',
                userSelect: 'none',
                fontSize: '170px',
                color: blueGrey[200],
                fontWeight: 900,
                mt: 3,
                p: 0,
              }}>
                D
              </Avatar>
              <Typography variant="h5" align="center" gutterBottom={false} sx={{ color: 'white' }}>
                Despachos
              </Typography>
            </Card>

            <Card sx={{
              height: '250px',
              width: '250px',
              backgroundColor: green[400],
              cursor: 'pointer',
              userSelect: 'none',
              borderRadius: 10
            }}
            onClick={() => { navigate(`/timonel/embarcaciones`) }}
            >
              <Avatar variant="square" sx={{
                bgcolor: green[400],
                height: 'auto',
                width: '100%',
                cursor: 'pointer',
                userSelect: 'none',
                fontSize: '170px',
                color: green[200],
                fontWeight: 900,
                mt: 3,
                p: 0,
              }}>
                E
              </Avatar>
              <Typography variant="h5" align="center" gutterBottom={false} sx={{ color: 'white' }}>
                Embarcaciones
              </Typography>
            </Card>

            {/* <Avatar sx={{
              height: '250px',
              width: '250px',
              bgcolor: deepOrange[500],
              fontSize: '170px',
              color: blueGrey[200],
              // fontWeight: 900,
              // lineHeight: '550px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              
            }}>
              <Typography sx={{ color: 'white', fontSize: '150px',fontWeight: 900, mt: 0, lineHeight: '150px' }}>
                C
              </Typography>
              <Typography variant="h5" align="center" gutterBottom={false} sx={{ color: 'white' }}>
                Despachos
              </Typography>
            </Avatar> */}

          </Box>
        </Grid>
      </Paper >
    </>
  )
}
